import * as Yup from 'yup';
import { textFieldSchema, alphaNumericSpecialCharSchema } from './CommonSchema';

export const BranchSchema = (name, street, city, state, postal_code, country, company, Super_admin) => {
  let branchSchemaValidate;
  let branchSchema = {
    name: textFieldSchema(name),
    street: alphaNumericSpecialCharSchema(street),
    city: alphaNumericSpecialCharSchema(city),
    state: Yup.string().required(state),
    postal_code: Yup.number().required(postal_code),
    country: Yup.string().required(country)
  };
  if (Super_admin) {
    branchSchemaValidate = Yup.object().shape({
      ...branchSchema,
      company: Yup.string().required(company)
    });
    return branchSchemaValidate;
  }
  return (branchSchemaValidate = Yup.object().shape({ ...branchSchema }));
};
