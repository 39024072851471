import { SET_MODAL_FILTER } from './actionTypes';

const initialState = {
  filterModal: {},
  pageName: ''
};

const filterModal = (state = initialState, action) => {
  switch (action.type) {
    case SET_MODAL_FILTER:
      return {
        ...state,
        filterModal: action.filterModal ? action.filterModal : state.filterModal,
        pageName: action.pageName
      };
    default:
      return state;
  }
};

export default filterModal;
