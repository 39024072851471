import { Build } from '@mui/icons-material';
import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ModalForm from '../../components/ModalForm';
import { axiosApi } from '../../helpers/api_helper';
import { MaintenanceRequestSchema } from '../../schemas/MaintenanceSchema';

const RequestForm = ({ productId, userId, onClose, onSubmit, prefilledDetails }) => {
  const { t } = useTranslation();
  const [priorities, setPriorities] = useState([]);
  const isEditForm = Object.keys(prefilledDetails).length > 0;
  const title = isEditForm ? t('maintenanceRequest.editRequest') : t('maintenanceRequest.request');

  const [initialValues, setInitialValues] = useState({
    priority: 'low',
    pick_up_date: '',
    request_description: '',
    ...prefilledDetails
  });

  const fields = [
    {
      id: nanoid(),
      label: t('maintenanceRequest.priority'),
      name: 'priority',
      type: 'dropDown',
      isRequired: true,
      values: priorities,
      label_size: 2,
      field_size: 3
    },
    {
      id: nanoid(),
      label: t('maintenanceRequest.pickUpDate'),
      name: 'pick_up_date',
      isRequired: true,
      type: 'date',
      minDate: new Date(),
      label_size: 3,
      field_size: 4,
      paddingLeft: '2.5vw !important'
    },
    {
      id: nanoid(),
      label: t('common.description'),
      name: 'request_description',
      isRequired: true,
      type: 'textArea',
      label_size: 2,
      field_size: 10
    }
  ];

  const handleSubmit = (values) => {
    let params = {
      priority: values.priority,
      pick_up_date: values.pick_up_date,
      request_description: values.request_description,
      requested_user_id: userId,
      product_id: productId
    };

    if (isEditForm) {
      axiosApi
        .patch(`/maintenance_requests/${values.id}`, { maintenance_request: params })
        .then(() => {
          toast.success(t('maintenanceRequest.updated'));
          onSubmit();
        })
        .catch(() => toast.error(t('common.somethingWentsWrong')));
    } else {
      axiosApi
        .post('/maintenance_requests', { maintenance_request: params })
        .then(() => {
          toast.success(t('maintenanceRequest.requested'));
          onSubmit();
        })
        .catch((err) => {
          let message = err.response?.data?.message?.base;
          toast.error(t(message && message[0] ? `maintenanceRequest.${message[0]}` : 'common.somethingWentsWrong'));
        });
    }
  };

  const fetchRequestPriorities = () => {
    axiosApi
      .get('/maintenance_requests/priorities')
      .then((res) => {
        setPriorities(
          res.data.map((priority) => ({
            key: nanoid(),
            label: t(`maintenanceRequest.priorities.${priority}`),
            value: priority
          }))
        );
      })
      .catch(() => toast.error(t('common.somethingWentsWrong')));
  };

  useEffect(fetchRequestPriorities, []);

  const schema = MaintenanceRequestSchema(
    t('maintenanceRequest.validation.priority'),
    t('maintenanceRequest.validation.pickUpDate'),
    t('maintenanceRequest.validation.description')
  );

  return (
    <ModalForm
      formTitle={title}
      isEditForm={isEditForm}
      preSavedData={isEditForm ? initialValues : {}}
      titleIcon={<Build />}
      initialValues={initialValues}
      fields={fields}
      onSubmit={handleSubmit}
      onClose={onClose}
      onValuesChange={(values) => setInitialValues(values)}
      validationSchema={schema}
    />
  );
};

export default RequestForm;
