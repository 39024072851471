import React, { useEffect, useState } from 'react';
import { Grid, Button, Dialog, Checkbox, Drawer } from '@mui/material';
import form_field_style from '../../../assets/scss/_form_field.module.scss';
import app_form_style from '../../../assets/scss/_app.module.scss';
import button_style from '../../../assets/scss/_button.module.scss';
import { TextField } from '../../../components/Form/TextField/index';
import { MailOutline, Cancel } from '@mui/icons-material';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import ImageUploading from 'react-images-uploading';
import IconImage from '../../../assets/images/icon-image.svg';
import UserImage from '../../../assets/images/profileUserImage.svg';
import { axiosApi } from '../../../helpers/api_helper';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import styles from './index.module.scss';
import { ProfileSchema } from '../../../schemas/ProfileSchema';
import Dropdown from '../../../components/Form/Dropdown';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { resizedataURL, getCroppedImg, scrollBoxShadowStyle, useScrollVerticle } from '../../../helpers/common_service';
import screen from '../../../assets/scss/_app.scss';
import { MobileField } from '../../../components/Form/MobileField';
import { AsYouType } from 'libphonenumber-js';
import 'react-phone-input-2/lib/high-res.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { hasCreateAccess, isUserAdmin, MODEL_USER } from '../../../helpers/access';

const minTabletWidth = parseInt(screen.minTablet);

function UserProfile({ open, onClose }) {
  const loginUser = useSelector((state) => state.login.authUser);
  const [submitCount, setSubmitCount] = useState(1);
  const [profileData, setProfileData] = useState({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [fixedImageResolutions, setFixedImageResolutions] = useState({
    width: 500,
    height: 500
  });
  const [roles, setRoles] = useState([{ label: 'Employee', value: 'employee' }]);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [image, setImages] = useState(null);
  const [crop, setCrop] = useState({
    aspect: 9 / 16,
    x: 81,
    y: 81,
    width: 361,
    height: 361,
    unit: 'px'
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [scrollTop, scrollBottom, scrollProps] = useScrollVerticle();

  const handleImageChanges = async (image) => {
    let newImage = await resizedataURL(image, fixedImageResolutions.width, fixedImageResolutions.height);
    setImages(newImage);
    setIsOpen(true);
  };

  const onSubmit = (values) => {
    const formData = new FormData();
    formData.append('role', values.role);
    formData.append('user[first_name]', values.first_name);
    formData.append('user[last_name]', values.last_name);
    formData.append('user[mobile_number]', values.mobile_number);
    formData.append('user[email]', values.email);
    formData.append('user[is_email_subscribed]', values.is_email_subscribed);
    // formData.append('user[is_telegram_subscribed]', values.is_telegram_subscribed);
    if (formik.values.new_avatar) {
      formData.append('avatar', formik.values.new_avatar.file, formik.values.new_avatar.file.name);
    }
    if (!values.avatar) formData.append('removed_avatar', true);

    axiosApi
      .patch(`/users/${loginUser.id}`, formData, {
        headers: {
          Authorization: `Bearer ${loginUser['remember_token']}`,
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((res) => {
        setProfileData(res.data.data.attributes);
        dispatch({ type: 'LOGIN_USER_SUCCESS', payload: res.data });
        toast.dismiss();
        toast.success(t('myProfile.profileUpdated'));
        onClose();
      })
      .catch((error) => toast.error(t(`user.addUser.${error.response.data?.message_key} ?? 'failedToUpdateUser'`)));
  };

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      role: '',
      mobile_number: '',
      email: '',
      confirm_email: '',
      is_email_subscribed: false,
      // is_telegram_subscribed: false,
      avatar: '',
      new_avatar: ''
    },
    onSubmit,
    validationSchema: ProfileSchema(
      t('myProfile.pleaseEnterFirstName'),
      t('myProfile.pleaseEnterLastName'),
      t('myProfile.pleaseSelectRole'),
      t('myProfile.pleaseEnterMobile'),
      t('common.pleaseEnterEmail'),
      t('myProfile.pleaseEnterConfirmEmail')
    )
  });

  const setValues = () => {
    formik.setValues({
      first_name: profileData.first_name,
      last_name: profileData.last_name,
      role: profileData.role,
      mobile_number: profileData.mobile_number,
      email: profileData.email,
      confirm_email: profileData.email,
      is_email_subscribed: profileData.is_email_subscribed,
      // is_telegram_subscribed: profileData.is_telegram_subscribed,
      avatar: profileData.avatar,
      new_avatar: ''
    });
  };

  const setdisplayError = (name) => {
    if (submitCount === formik.submitCount) {
      setSubmitCount((prev) => prev + 1);
      const element = document.getElementById(name);
      const y = element.getBoundingClientRect().top;
      window.scroll({
        top: y,
        behavior: 'smooth'
      });
    }
  };

  const getProfile = () => {
    axiosApi
      .get(`/users/${loginUser.id}`)
      .then((res) => {
        setProfileData(res.data.data.attributes);
      })
      .catch(() => toast.error(t('common.somethingWentsWrong')));
  };

  const getRoles = () => {
    axiosApi
      .get('/roles')
      .then((res) => {
        setRoles(
          res.data.data.map((role) => ({
            label: role.attributes.label,
            value: role.attributes.name
          }))
        );
      })
      .catch(() => toast.error(t('common.somethingWentsWrong')));
  };

  const cropImageNow = () => {
    if (completedCrop) {
      getCroppedImg(image, completedCrop, 'newFile.jpeg').then((res) => {
        formik.setFieldValue('new_avatar', res);
      });
    } else {
      getCroppedImg(image, crop, 'newFile.jpeg').then((res) => {
        formik.setFieldValue('new_avatar', res);
      });
    }
    setIsOpen(false);
    setImages(null);
    setCompletedCrop(null);
  };

  useEffect(() => {
    getProfile();
    hasCreateAccess(MODEL_USER) && getRoles();
  }, []);

  useEffect(() => {
    if (Object.keys(profileData).length) setValues();
  }, [profileData]);

  useEffect(() => {
    if (formik.submitCount) {
      if (Object.keys(formik.errors).length) {
        setdisplayError(Object.keys(formik.errors)[0]);
      } else {
        setSubmitCount((prev) => prev + 1);
      }
    }
  }, [formik.submitCount]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    if (windowWidth < minTabletWidth && fixedImageResolutions.width > 200) {
      setFixedImageResolutions({ width: 200, height: 200 });
      setCrop({ aspect: 9 / 16 });
    } else if (fixedImageResolutions.width < 500 && windowWidth > minTabletWidth) {
      setFixedImageResolutions({ width: 500, height: 500 });
      setCrop({ aspect: 9 / 16 });
    }
    return () => window.removeEventListener('resize', handleResize);
  }, [windowWidth]);

  // useEffect(() => {
  //   if (formik.values.is_telegram_subscribed && !profileData.is_telegram_subscribed) {
  //     const handleTelegramVerification = () => {
  //       axiosApi
  //         .get('users/profileData.id/create_telegram_subscription_url')
  //         .then((res) => {
  //           if (formik.values.is_telegram_subscribed)
  //             window.open(res.data.telegram_subscription_url, '_blank', 'noreferrer');
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //         });
  //     };
  //     handleTelegramVerification();
  //   }
  // }, [formik.values.is_telegram_subscribed]);

  return (
    <Drawer anchor="right" open={open} onClose={onClose} className={`${app_form_style.drawer} ${styles.drawer}`}>
      <div className={styles.headerContainer}>
        <div className={styles.header}>
          <FontAwesomeIcon icon={faUser} className={app_form_style.solid_list_icon} />
          <h6 className={`${app_form_style.title_name} ${form_field_style.text_3xl}`}>{t('myProfile.editProfile')}</h6>
        </div>
      </div>
      <form
        {...scrollProps}
        style={{
          boxShadow: scrollBoxShadowStyle(scrollTop, scrollBottom),
          transition: 'box-shadow 0.3s',
          overflowY: 'scroll',
          position: 'relative'
        }}
        onSubmit={formik.handleSubmit}
        className={styles.form}>
        <>
          <div>
            <div>
              {t('common.firstName')}
              <span className={app_form_style.required}>*</span>
            </div>
            <div className={app_form_style.valueContainer}>
              <TextField
                id="first_name"
                className={`${app_form_style.TextField} ${form_field_style.TextField_style} ${styles.textFieldWidth}`}
                name="first_name"
                fieldType="alphabet"
                value={formik.values.first_name}
                onChange={formik.handleChange}
                error={formik.errors.first_name}
              />
            </div>
          </div>
          <div>
            <div>
              {t('common.lastName')}
              <span className={app_form_style.required}>*</span>
            </div>
            <div className={app_form_style.valueContainer}>
              <TextField
                id="last_name"
                className={`${app_form_style.TextField} ${form_field_style.TextField_style} ${styles.textFieldWidth}`}
                name="last_name"
                fieldType="alphabet"
                value={formik.values.last_name}
                onChange={formik.handleChange}
                error={formik.errors.last_name}
              />
            </div>
          </div>
          <div>
            <div>
              {t('common.role')} <span className={app_form_style.required}>*</span>
            </div>
            <div
              className={`${form_field_style.grid_text_field_container}  ${styles.textFieldWidth} ${app_form_style.valueContainer}`}>
              <Dropdown
                id="role"
                disabled={!isUserAdmin()}
                className={styles.dropDown}
                name="role"
                type="text"
                placeholder="Select Role"
                value={formik.values.role}
                onChange={formik.handleChange}
                dataList={roles}
                error={formik.touched.role && formik.errors.role}
              />
            </div>
          </div>
          <div>
            <div>
              {t('common.mobile')}
              <span className={app_form_style.required}>*</span>
            </div>
            <div className={app_form_style.valueContainer}>
              <MobileField
                id="mobile_number"
                className={`${app_form_style.TextField} ${form_field_style.TextField_style} ${styles.textFieldWidth}`}
                name="mobile_number"
                country={'in'}
                parent="profile"
                value={formik.values.mobile_number}
                onChange={(phone, country) =>
                  formik.setFieldValue('mobile_number', new AsYouType(country.countryCode).input('+' + phone))
                }
                error={formik.errors.mobile_number}
              />
            </div>
          </div>
          <div>
            <div>
              {t('common.email')} <span className={app_form_style.required}>*</span>
            </div>
            <Grid className={app_form_style.valueContainer}>
              <TextField
                id="email"
                className={`${app_form_style.TextField} ${form_field_style.TextField_style} ${form_field_style.textField_prefix} ${styles.imgField}`}
                name="email"
                type="email"
                value={formik.values.email}
                onChange={(e) => {
                  const value = e.target.value || '';
                  formik.setFieldValue('email', value.toLowerCase());
                }}
                error={formik.errors.email}
                prefixIcon={<MailOutline className={form_field_style.mailIcon} />}
                prefixIconClass={`${app_form_style.icon_container} ${styles.prefixIcon}`}
              />
            </Grid>
          </div>
          <div>
            <div>
              {t('myProfile.confirmEmail')}
              <span className={app_form_style.required}>*</span>
            </div>
            <div className={app_form_style.valueContainer}>
              <TextField
                id="confirm_email"
                className={`${app_form_style.TextField} ${form_field_style.TextField_style} ${form_field_style.textField_prefix} ${styles.imgField}`}
                name="confirm_email"
                type="email"
                value={formik.values.confirm_email}
                onChange={formik.handleChange}
                error={formik.errors.confirm_email}
                prefixIcon={<MailOutline className={form_field_style.mailIcon} />}
                prefixIconClass={`${app_form_style.icon_container} ${styles.prefixIcon}`}
              />
            </div>
          </div>
          <div className={styles.dropDownField}>
            <div className={`${form_field_style.grid_text_field_container} ${app_form_style.valueContainer}`}>
              <Checkbox
                name={t('user.subscribeToEmail')}
                className={styles.checkBox}
                onChange={(e) => formik.setFieldValue('is_email_subscribed', e.target.checked)}
                checked={formik.values.is_email_subscribed}
              />
              <span className={`${styles.label_content}`}>{t('user.subscribeToEmail')}</span>
            </div>
          </div>
          {/* <div className={styles.dropDownField}>
            <div className={`${form_field_style.grid_text_field_container} ${app_form_style.valueContainer}`}>
              <Checkbox
                name={t('user.subscribeToTelegram')}
                className={styles.checkBox}
                onChange={(e) => formik.setFieldValue('is_telegram_subscribed', e.target.checked)}
                checked={formik.values.is_telegram_subscribed}
              />
              <span className={`${styles.label_content}`}>{t('user.subscribeToTelegram')}</span>
            </div>
          </div> */}
          <div>
            <div className={styles.imageUploadContainer}>
              <div>
                <div className={styles.displayAlignCenter}>
                  <img src={IconImage} alt="" className={app_form_style.icon_image} />
                  <h4 className={`${app_form_style.title_name} ${form_field_style.text_xl}`}>
                    {t('myProfile.userImage')}
                  </h4>
                </div>
                <p className={`${form_field_style.text_lg}`}>{t('myProfile.uploadAPhotoToSetYourself')}</p>
                <div className={`${styles.displayAlignCenter} justify-content-center`}>
                  <ImageUploading
                    name="avatar"
                    dataURLKey="data_url"
                    onChange={(avatar) => {
                      if (avatar.length && avatar[0].data_url) handleImageChanges(avatar[0].data_url);
                      else formik.setFieldValue('new_avatar', '');
                    }}>
                    {({ onImageUpload, onImageRemove, isDragging, dragProps }) => (
                      <button
                        className={form_field_style.button_upload}
                        style={isDragging ? { color: 'red' } : null}
                        onClick={onImageUpload}
                        {...dragProps}
                        type="button">
                        {!formik.values.new_avatar && !formik.values.avatar ? (
                          <img className={form_field_style.default_upload_user_image} src={UserImage} alt={''} />
                        ) : (
                          <div className={form_field_style.image_container}>
                            <span
                              className={form_field_style.remove_btn_container}
                              onClick={(e) => e.stopPropagation()}>
                              <Cancel
                                className={form_field_style.remove_btn}
                                onClick={() => {
                                  onImageRemove();
                                  formik.setFieldValue('avatar', '');
                                  formik.setFieldValue('new_avatar', '');
                                }}
                              />
                            </span>
                            <img
                              className={form_field_style.default_upload_user_image}
                              src={formik.values.new_avatar ? formik.values.new_avatar.data_url : formik.values.avatar}
                              alt={''}
                            />
                          </div>
                        )}
                        <span className={form_field_style.text_drop_image}>{t('attachment.dropYourImageHere')}</span>
                        <span className={form_field_style.text_image_type}>{t('attachment.supportsJpgGifPng')}</span>
                      </button>
                    )}
                  </ImageUploading>
                </div>
              </div>
            </div>
          </div>
        </>
      </form>
      <div className={styles.bottomFixed}>
        <Button
          variant="contained"
          className={`${button_style.save_button} ${button_style.btn} ${button_style.mobile_button_width}`}
          onClick={formik.handleSubmit}>
          <span>{t('save')}</span>
        </Button>
        <Button
          type="button"
          variant="contained"
          className={`${button_style.cancel_button} ${button_style.btn} ${button_style.mobile_button_width}`}
          onClick={onClose}>
          <span>{t('cancel')}</span>
        </Button>
      </div>
      {image && (
        <Dialog open={isOpen}>
          <div>
            <ReactCrop
              src={image}
              crop={crop}
              onChange={(c) => setCrop(c)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={1 / 1}
              ruleOfThirds
              circularCrop={true}>
              <img src={image} alt="Crop" height={fixedImageResolutions.height} width={fixedImageResolutions.width} />
            </ReactCrop>
          </div>
          <div className={styles.crop_button_container}>
            <Button
              type="button"
              variant="contained"
              className={`${button_style.cancel_button} ${button_style.btn} ${button_style.mobile_button_width}`}
              onClick={() => {
                setIsOpen(false);
                setImages(null);
              }}>
              {t('cancel')}
            </Button>
            <Button
              type="button"
              variant="contained"
              className={`${button_style.save_button} ${button_style.btn} ${button_style.mobile_button_width}`}
              onClick={cropImageNow}>
              {t('crop')}
            </Button>
          </div>
        </Dialog>
      )}
    </Drawer>
  );
}

export default UserProfile;
