import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import styles from './index.module.scss';

const TimeLineProgress = ({ records, onView }) => {
  const { t } = useTranslation();
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [isShowMoreOpen, setShowMoreOpen] = useState(false);

  const progressDetail = (record, isLeftAlign) => {
    return (
      <div className={styles.progressContent}>
        <div
          className={`${styles.progressName} ${isLeftAlign ? styles.textAlignRight : ''}`}
          onClick={() => onView(record.id)}>
          {record.displayContent}
        </div>
      </div>
    );
  };

  useEffect(() => {
    setFilteredRecords(!isShowMoreOpen && records.length > 6 ? records.slice(0, 3).concat(records.slice(-3)) : records);
  }, [records]);

  return (
    <div className={styles.timelineProgress}>
      {filteredRecords.map((record, index) => (
        <React.Fragment key={record.key}>
          <div className={styles.progressStep}>
            {index % 2 === 0 ? progressDetail(record, true) : <div className={styles.emptyProgressContent}></div>}
            <div className={styles.progressLineContainer}>
              <div className={styles.progressDot} onClick={() => onView(record.id)}></div>
              {index !== filteredRecords.length - 1 && <div className={styles.progressLine} />}
            </div>
            {index % 2 !== 0 ? progressDetail(record, false) : <div className={styles.emptyProgressContent}></div>}
          </div>
          {filteredRecords.length === 6 && records.length > 6 && index === 2 && (
            <div className={styles.progressStep}>
              <div className={`${styles.progressLineContainer} ${styles.showMore}`}>
                <Tooltip placement="right" title={t('showAll')} arrow>
                  <div
                    className={styles.moreDot}
                    onClick={() => {
                      setFilteredRecords(records);
                      setShowMoreOpen(true);
                    }}>
                    <div className={styles.progressDot}></div>
                    <div className={styles.progressDot}></div>
                    <div className={styles.progressDot}></div>
                  </div>
                </Tooltip>
                <div className={styles.progressLine} />
              </div>
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default TimeLineProgress;
