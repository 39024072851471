import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { nanoid } from 'nanoid';
import { useNavigate } from 'react-router-dom';
import { axiosApi } from '../../helpers/api_helper';
import { getDateString } from '../../helpers/common_service';
import { PriorityDisplay } from './Common';
import TableList from '../../components/TableList';
import RequestActions from './RequestActions';
import RequestInfo from './RequestInfo';
import UserDetails from '../User/Detail';
import { maintenanceRequestPage } from '../../helpers/constants';

const MaintenanceRequestList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState({});
  const [statuses, setStatuses] = useState([]);
  const [maintenanceRequests, setMaintenanceRequests] = useState([]);
  const [paginateDetails, setPaginateDetails] = useState({});
  const [filters, setFilters] = useState([]);
  const companyId = useSelector((state) => state.common.companyId);
  const branchId = useSelector((state) => state.common.branchId);
  const [selectedUserDetails, setSelectedUserDetails] = useState({});

  const headCells = [
    {
      id: 'product_name',
      label: t('asset.general.assetName'),
      hasViewLink: true
    },
    {
      id: 'requested_user_name',
      label: t('maintenanceRequest.user'),
      hasViewLink: true
    },
    {
      id: 'requested_date_display',
      label: t('maintenanceRequest.date')
    },
    {
      id: 'priority_display',
      label: t('maintenanceRequest.priority')
    },
    {
      id: 'status_action',
      label: t('common.action')
    }
  ];

  const getUserDetails = (userId) => {
    axiosApi
      .get(`/users/${userId}`)
      .then((res) => setSelectedUserDetails(res.data.data.attributes))
      .catch(() => toast.error('Failed to get user details'));
  };

  const handleViewLink = (rowId, column) => {
    let request = maintenanceRequests.find((request) => request.id === rowId);
    if (column === 'product_name') navigate(`/inventory-details/${request.product_id}?tab=Maintenance`);
    else getUserDetails(request.requested_user_id);
  };

  const fetchMaintenanceRequests = () => {
    let params = {
      company: companyId,
      branch: branchId,
      search: filters.searchText,
      status: filters.customFilter,
      page: filters.currentPage,
      items: filters.rowsPerPage
    };

    setIsLoading(true);
    axiosApi
      .get('/maintenance_requests', { params })
      .then((res) => {
        setMaintenanceRequests(res.data.data.map((maintenance_request) => maintenance_request.attributes));
        setPaginateDetails(res.data.meta);
      })
      .catch(() => toast.error(t('common.somethingWentsWrong')))
      .finally(() => setIsLoading(false));
  };

  let rows = maintenanceRequests.map((maintenance_request) => {
    maintenance_request['requested_date_display'] = getDateString(maintenance_request.requested_date);
    maintenance_request['pick_up_date_display'] = getDateString(maintenance_request.pick_up_date);
    maintenance_request['priority_display'] = <PriorityDisplay priority={maintenance_request.priority} />;
    maintenance_request['status_action'] = (
      <RequestActions
        id={maintenance_request['id']}
        status={maintenance_request['status']}
        productId={maintenance_request['product_id']}
        countryId={maintenance_request['product_country_id']}
        onActionChange={fetchMaintenanceRequests}
        onClick={() => {
          setIsInfoOpen(true);
          setSelectedRequest(maintenance_request);
        }}
      />
    );
    return maintenance_request;
  });

  const fetchRequestStatuses = () => {
    axiosApi
      .get('/maintenance_requests/statuses')
      .then((res) => {
        let requestStatuses = res.data.map((status) => ({
          key: nanoid(),
          label: t(`maintenanceRequest.statuses.${status}`),
          value: status
        }));
        requestStatuses.unshift({ key: nanoid(), label: t('all'), value: '' });
        setStatuses(requestStatuses);
      })
      .catch(() => toast.error(t('common.somethingWentsWrong')));
  };

  useEffect(fetchRequestStatuses, []);

  useEffect(() => {
    if (Object.keys(filters).length > 0) fetchMaintenanceRequests();
  }, [filters]);

  return (
    <>
      <TableList
        pageName={maintenanceRequestPage}
        headCells={headCells}
        rows={rows}
        viewActionColumn={false}
        filterOptionsChange={(values) => setFilters((prevState) => ({ ...prevState, ...values }))}
        onChange={(values) => setFilters((prevState) => ({ ...prevState, ...values }))}
        totalRowsCount={paginateDetails.total_count}
        totalPagesCount={paginateDetails.total_pages}
        loading={isLoading}
        filterDropdownLabel={t('maintenance.status')}
        filterDropdownOptions={statuses}
        viewBtnFn={handleViewLink}
      />
      {isInfoOpen && <RequestInfo maintenanceRequest={selectedRequest} onClose={() => setIsInfoOpen(false)} />}
      <UserDetails
        open={Object.keys(selectedUserDetails).length > 0}
        details={selectedUserDetails}
        onClose={() => setSelectedUserDetails({})}
      />
    </>
  );
};

export default MaintenanceRequestList;
