import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { axiosApi } from '../../helpers/api_helper';
import { getAssetData, getAssetHeadCells } from '../../helpers/common_service';
import Table from '../../components/Table';
import { inventoryPage } from '../../helpers/constants';
import { AUTHORITY_LEVEL_COMPANY, hasReadAccess, MODEL_PRODUCT } from '../../helpers/access';

const Asset = ({ companyId }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [assets, setAssets] = useState([]);
  const authUser = useSelector((state) => state.login.authUser);
  const showMenu = useSelector((state) => state.sideMenu.showMenu);

  const handleBeforeDelete = () => setIsLoading(true);
  const handleAfterDelete = () => setIsLoading(false);
  const fetchAssetHeadCells = useCallback(() => {
    return getAssetHeadCells().map((headCell) => {
      headCell.canSort = false;
      return headCell;
    });
  }, [showMenu]);

  const fetchAssets = () => {
    setIsLoading(true);
    axiosApi
      .get('/products', {
        params: {
          filter: {
            company: companyId,
            ...(hasReadAccess(MODEL_PRODUCT, AUTHORITY_LEVEL_COMPANY) && { branch: authUser.branch_id })
          }
        }
      })
      .then((res) => setAssets(getAssetData(res.data.data)))
      .catch(() => toast.error(t('common.somethingWentsWrong')))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (companyId) fetchAssets();
  }, [companyId]);

  return (
    <Table
      pageName={inventoryPage}
      headCells={fetchAssetHeadCells()}
      rows={assets}
      editBtnLink="edit-inventory"
      viewBtnLink="inventory-details"
      deleteBtnLink="products"
      onDelete={fetchAssets}
      onBeforeDelete={handleBeforeDelete}
      onAfterDelete={handleAfterDelete}
      loading={isLoading}
      showPagination={false}
    />
  );
};

export default Asset;
