import * as Yup from 'yup';
import i18n from 'i18next';
import { isValidPhoneNumber } from 'libphonenumber-js';

export const textFieldSchema = (errMessage) =>
  Yup.string()
    .required(errMessage)
    .matches(/^[^\s][a-zA-Z0-9 ]*$/, i18n.t('onlyAlphaNumericCharsAllowed'));

export const websiteSchema = () =>
  Yup.string().matches(
    /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm,

    i18n.t('invalidURL')
  );

export const mobileSchema = (errMessage) =>
  Yup.string()
    .required(errMessage)
    .ensure()
    .test('is-valid', i18n.t('mobileNumberMustBeValid'), (value) => isValidPhoneNumber(value));

export const alphaNumericSpecialCharSchema = (errMessage) =>
  Yup.string()
    .required(errMessage)
    .matches(/^[^\s][()A-Za-z0-9_@./#&+-,'\s]*$/, i18n.t('noWhiteSpaceAllowedAtTheBeginning'));
