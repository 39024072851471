import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { axiosApi } from '../../../helpers/api_helper';
import CheckOut from './CheckOut';
import CheckIn from './CheckIn';
import Lease from './Lease';
import Repair from './Repair';
import LostOrMissing from './LostOrMissing';
import Broken from './Broken';
import Dispose from './Dispose';
import Donate from './Donate';
import Sell from './Sell';
import {
  CHECK_OUT,
  CHECK_IN,
  BROKEN,
  LEASE,
  REPAIR,
  LOST_OR_MISSING,
  DISPOSE,
  DONATE,
  SOLD
} from '../../../helpers/constants';

const AssetEvents = ({
  status,
  assetStatus,
  assetId,
  assetData,
  onClose,
  afterSubmit,
  eventDetails,
  isEditEvent,
  isPrefilledDetailsPresent,
  handleNaviagateState,
  scheduledStatus = false
}) => {
  const { t } = useTranslation();
  const isOpen = (event) => {
    return status === event && assetStatus !== status;
  };

  const onSubmit = (values) => {
    let status_info = {};
    let assetCurrentStatus = status;
    status_info = { ...values };
    if (status_info.user !== undefined && typeof status_info.user === 'object')
      status_info.user = status_info.user.value;
    if (!values) {
      assetCurrentStatus = assetStatus;
      status_info = {};
    }
    axiosApi
      .put(`/products/${assetId}/update_product_status`, {
        status: assetCurrentStatus,
        status_info: status_info
      })
      .then(() => {
        onClose();
        if (!values) {
          toast.success(t(`asset.assetDetail.moreAction.${status}.schuduleDeleteSuccess`));
        } else if (scheduledStatus) {
          toast.success(t(`asset.assetDetail.moreAction.${status}.schuduleUpdateSuccess`));
        } else if (isEditEvent) {
          toast.success(t(`asset.assetDetail.moreAction.${status}.update`));
        } else {
          toast.success(t(`asset.assetDetail.moreAction.${status}.success`));
        }
        afterSubmit();
      })
      .catch((err) =>
        toast.error(t(err.response?.data?.error?.base ?? `asset.assetDetail.moreAction.${status}.failed`))
      );
  };

  const handleClose = () => {
    if (handleNaviagateState) handleNaviagateState();
    onClose();
  };

  return (
    <>
      {isOpen(CHECK_OUT) && (
        <CheckOut
          eventDetails={eventDetails}
          assetId={assetId}
          assetData={assetData}
          onClose={isPrefilledDetailsPresent ? handleClose : onClose}
          onSubmit={onSubmit}
          prefilledDetails={isPrefilledDetailsPresent}
          scheduledStatus={scheduledStatus}
          onDeleteScheduledEvent={() => onSubmit(false)}
        />
      )}
      {isOpen(CHECK_IN) && (
        <CheckIn eventDetails={eventDetails} assetId={assetId} onClose={onClose} onSubmit={onSubmit} />
      )}
      {isOpen(LEASE) && (
        <Lease
          eventDetails={eventDetails}
          assetId={assetId}
          assetData={assetData}
          onClose={isPrefilledDetailsPresent ? handleClose : onClose}
          onSubmit={onSubmit}
          prefilledDetails={isPrefilledDetailsPresent}
          scheduledStatus={scheduledStatus}
          onDeleteScheduledEvent={() => onSubmit(false)}
        />
      )}
      {isOpen(REPAIR) && (
        <Repair
          eventDetails={eventDetails}
          assetId={assetId}
          onClose={onClose}
          onSubmit={onSubmit}
          countryId={assetData?.country_id}
        />
      )}
      {isOpen(LOST_OR_MISSING) && (
        <LostOrMissing eventDetails={eventDetails} assetId={assetId} onClose={onClose} onSubmit={onSubmit} />
      )}
      {isOpen(BROKEN) && <Broken eventDetails={eventDetails} assetId={assetId} onClose={onClose} onSubmit={onSubmit} />}
      {isOpen(DISPOSE) && (
        <Dispose eventDetails={eventDetails} assetId={assetId} onClose={onClose} onSubmit={onSubmit} />
      )}
      {isOpen(DONATE) && <Donate eventDetails={eventDetails} assetId={assetId} onClose={onClose} onSubmit={onSubmit} />}
      {isOpen(SOLD) && (
        <Sell
          eventDetails={eventDetails}
          assetId={assetId}
          onClose={onClose}
          onSubmit={onSubmit}
          countryId={assetData?.country_id}
        />
      )}
    </>
  );
};

export default AssetEvents;
