import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { axiosApi } from '../../helpers/api_helper';
import InsuranceList from '../AssetDetail/Tabs/Insurance';
import { useSelector } from 'react-redux';

const InsuranceExpiring = ({ beforeFetch, afterFetch, fetchCount }) => {
  const { t } = useTranslation();
  const [insurances, setInsurances] = useState([]);
  const [totalRowsCount, setTotalRowsCount] = useState(0);
  const [totalPagesCount, setTotalPagesCount] = useState(0);
  const table = useSelector((state) => state.table);
  const companyId = useSelector((state) => state.common.companyId);
  const branchId = useSelector((state) => state.common.branchId);
  const [paginationDetails, setPaginationDetails] = useState({
    currentPage: 1,
    rowsPerPage: table?.tableRowCountObj?.count
  });
  const [isLoading, setIsLoading] = useState(false);
  const headCells = [
    {
      id: 'is_active',
      label: t('asset.assetDetail.insuranceTab.active'),
      width: '5%'
    },
    {
      id: 'asset_name',
      label: t('asset.general.assetName'),
      hasViewLink: true
    },
    {
      id: 'insurance_company_name',
      label: t('asset.general.provider'),
      hasViewLink: true
    },
    {
      id: 'product_tag_id',
      label: t('asset.general.tagId')
    },
    {
      id: 'period_of_insurance',
      label: t('asset.assetDetail.insuranceTab.periodOfInsurance')
    },
    {
      id: 'action',
      label: t('common.action'),
      width: '10%'
    }
  ];

  const fetchInsurances = () => {
    let params = {
      filter: { company_id: companyId, branch_id: branchId }
    };
    if (paginationDetails?.currentPage) params['page'] = paginationDetails.currentPage;
    if (paginationDetails?.rowsPerPage) params['items'] = paginationDetails.rowsPerPage;
    params['order'] = 'asc';
    setIsLoading(true);
    beforeFetch();
    axiosApi
      .get('/insurances/expiring', { params })
      .then((res) => {
        setInsurances(res.data.data.map((data) => data.attributes));
        setTotalRowsCount(res.data.meta.total_count);
        setTotalPagesCount(res.data.meta.total_pages);
        if (res.data.data.length) afterFetch();
        fetchCount();
      })
      .catch(() => toast.error(t('common.somethingWentsWrong')))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => fetchInsurances(), [paginationDetails, companyId, branchId]);

  return (
    <InsuranceList
      alertHeadCells={headCells}
      insurances={insurances}
      fetchInsurances={fetchInsurances}
      showHeader={false}
      showDeleteBtn={false}
      isLoading={isLoading}
      onChange={(values) => {
        JSON.stringify(values) !== JSON.stringify(paginationDetails) && setPaginationDetails(values);
      }}
      totalRowsCount={totalRowsCount}
      totalPagesCount={totalPagesCount}
    />
  );
};

export default InsuranceExpiring;
