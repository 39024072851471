import { useSelector } from 'react-redux';
import CommonLoginLayout from '../Login/CommonLoginLayout';
import styles from './index.module.scss';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getFirstMenuLink } from '../../helpers/access';

const PageNotFound = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isAuthenticated = useSelector((state) => state.login.isAuthenticated);

  const handleClick = () => (isAuthenticated ? navigate(getFirstMenuLink()) : navigate('/login'));

  return (
    <CommonLoginLayout>
      <div className={styles.notFoundCode}>404</div>
      <div className={styles.pageNotFound}>{t('pageNotFound.message')}</div>
      <div className={styles.content}>{t('pageNotFound.content')}</div>
      <div className={styles.buttonContainer}>
        <button className={styles.backButton} onClick={handleClick}>
          {isAuthenticated ? t('pageNotFound.goToDashboard') : t('pageNotFound.backToLogin')}
        </button>
      </div>
    </CommonLoginLayout>
  );
};

export default PageNotFound;
