import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { axiosApi } from '../../helpers/api_helper';
import Table from '../../components/Table';
import { RowsPerPage } from '../../helpers/constants';
import { AssetStatusDisplay } from '../Asset/AssetStatus/AssetStatus';
import { useSelector } from 'react-redux';
import { getDateString } from '../../helpers/common_service';
import { reportPage } from '../../helpers/constants';
import styles from './reports.module.scss';

const CustomReport = ({ beforeFetch, afterFetch }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [assets, setAssets] = useState([]);
  const table = useSelector((state) => state.table);
  const [totalRowsCount, setTotalRowsCount] = useState(0);
  const [totalPagesCount, setTotalPagesCount] = useState(0);
  const [paginationDetails, setPaginationDetails] = useState({
    currentPage: 1,
    rowsPerPage: table.tableRowCountObj?.count || RowsPerPage
  });
  const reportHeadCells = useSelector((state) => state.table.reportTableColumns);
  const companyId = useSelector((state) => state.common.companyId);
  const branchId = useSelector((state) => state.common.branchId);

  const rows = assets.map((asset) => {
    asset['status_display'] = <AssetStatusDisplay status={asset.status} />;
    asset['created_at'] = getDateString(asset['created_at']);
    asset['purchase_date'] = getDateString(asset['purchase_date']);
    asset['event_date'] = getDateString(asset['event_date']);
    asset['due_date'] = getDateString(asset['due_date']);
    asset['asset_photo'] = asset.images.length && (
      <img src={asset.images[0].url} alt="" className={styles.asset_photo} />
    );
    return asset;
  });

  useEffect(() => {
    let params = {
      page: paginationDetails.currentPage,
      items: paginationDetails.rowsPerPage,
      filter: { company: companyId, branch: branchId }
    };
    setIsLoading(true);
    beforeFetch();
    axiosApi
      .get('/products', { params })
      .then((res) => {
        setAssets(res.data.data.map((data) => data.attributes));
        setTotalRowsCount(res.data.meta.total_count);
        setTotalPagesCount(res.data.meta.total_pages);
        if (res.data.data.length) afterFetch();
      })
      .catch(() => toast.error(t('common.somethingWentsWrong')))
      .finally(() => setIsLoading(false));
  }, [paginationDetails, companyId, branchId]);

  return (
    <Table
      pageName={reportPage}
      headCells={reportHeadCells}
      rows={rows}
      viewBtnLink="inventory-details"
      viewActionColumn={false}
      showPagination={true}
      onChange={(values) => setPaginationDetails(values)}
      totalRowsCount={totalRowsCount}
      totalPagesCount={totalPagesCount}
      loading={isLoading}
    />
  );
};

export default CustomReport;
