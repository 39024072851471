import { useEffect, useState } from 'react';
import { MenuItem, Select } from '@mui/material';
import { nanoid } from 'nanoid';
import { TextField } from '../TextField';
import { axiosApi } from '../../../helpers/api_helper';
import getSymbolFromCurrency from 'currency-symbol-map';
import appStyles from '../../../assets/scss/_app.module.scss';
import styles from './currencyField.module.scss';

const CurrencyField = ({
  menuOpen,
  selectorName,
  textFieldName,
  selectorValue,
  textFieldValue,
  onValueChange,
  onCountryChange
}) => {
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({});

  const fetchCountries = () => {
    axiosApi
      .get('/countries')
      .then((res) => setCountries(res.data.data.map((country) => ({ ...country.attributes, key: nanoid() }))))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (countries.length > 0) {
      if (!selectorValue) {
        setSelectedCountry(countries[0]);
        onCountryChange(countries[0].id);
      } else if (selectorValue !== selectedCountry.id)
        setSelectedCountry(countries.find((option) => selectorValue === option.id));
    }
  }, [countries, selectorValue]);

  useEffect(fetchCountries, []);

  return (
    <div className={styles.container}>
      <Select
        open={menuOpen}
        name={selectorName}
        value={selectedCountry.id || ''}
        onChange={(e) => onCountryChange(e.target.value)}
        renderValue={() => <span>{selectedCountry.currency?.symbol}</span>}
        IconComponent={() => null}
        className={styles.countrySelect}>
        {countries.map((option) => (
          <MenuItem key={option.key} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
      <TextField
        className={`${appStyles.TextField} ${styles.textField}`}
        name={textFieldName}
        fieldType="number"
        value={textFieldValue}
        onChange={onValueChange}
        prefixInput={getSymbolFromCurrency(selectedCountry.currency?.symbol)}
      />
    </div>
  );
};

export default CurrencyField;
