import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { getDateString } from '../../../../helpers/common_service';
import ShowDetails from '../../../../components/ShowDetail';
import { hasDeleteAccess, hasEditAcccess, MODEL_RESERVATION } from '../../../../helpers/access';

const ReservationDetails = ({ reservation, onClose, onEdit, onDelete }) => {
  const { t } = useTranslation();

  const reservationInfo = [
    {
      key: nanoid(),
      label: t('asset.assetDetail.reserveTab.showDetail.reservedFrom'),
      value: getDateString(reservation.from_date)
    },
    {
      key: nanoid(),
      label: t('asset.assetDetail.reserveTab.showDetail.reservedEnd'),
      value: getDateString(reservation.to_date)
    },
    {
      key: nanoid(),
      label: t('asset.assetDetail.reserveTab.showDetail.reservedTo'),
      value: reservation.reserved_to_user
    },
    {
      key: nanoid(),
      label: t('asset.assetDetail.reserveTab.showDetail.reservedBy'),
      value: reservation.reserved_by_user
    },
    {
      key: nanoid(),
      label: t('asset.assetDetail.reserveTab.showDetail.notes'),
      value: reservation.notes
    }
  ];

  return (
    <ShowDetails
      detailFor={t('asset.assetDetail.reserveTab.showDetail.title')}
      details={reservationInfo}
      canEdit={hasEditAcccess(MODEL_RESERVATION)}
      onClose={onClose}
      onEdit={onEdit}
      canDelete={hasDeleteAccess(MODEL_RESERVATION)}
      onDelete={onDelete}
    />
  );
};

export default ReservationDetails;
