import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { nanoid } from 'nanoid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCube } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { axiosApi } from '../../../../helpers/api_helper';
import * as Yup from 'yup';
import ModalForm from '../../../../components/ModalForm';
import appStyles from '../../../../assets/scss/_app.module.scss';
import ModalFormStyles from '../../../../components/ModalForm/modalForm.module.scss';

const LinkingForm = ({ productId, relation, onSubmit, onClose }) => {
  const { t } = useTranslation();
  const [linkableAssets, setLinkableAssets] = useState([]);
  const title = t(`asset.assetDetail.linkingTab.link${relation === 'parent' ? 'Parent' : 'Child'}Asset`);

  const [initialValues, setInitialValues] = useState({
    id: ''
  });

  const fields = [
    {
      id: nanoid(),
      label: t('asset.general.serialNumber'),
      name: 'asset_tag',
      type: 'autoComplete',
      isRequired: true,
      values: linkableAssets,
      label_size: 3,
      field_size: 9
    }
  ];

  const handleSubmit = (values) => {
    axiosApi
      .post(`/products/${productId}/link_product`, {
        [`${relation}_id`]: values.asset_tag.value
      })
      .then((res) => {
        onSubmit(res.data.data.attributes);
        toast.success(t('asset.assetDetail.linkingTab.inventoryLinkedSuccessfully'));
      })
      .catch((e) => {
        let error = e.response?.data?.message_key || e.message;
        if (e.response?.data?.message_key) error = t(`asset.assetDetail.linkingTab.${e.response?.data?.message_key}`);
        toast.error(error || t('common.somethingWentsWrong'));
      });
  };

  const fetchLinkableAssets = () => {
    let params = relation === 'child' ? { child: true } : {};
    axiosApi.get(`/products/${productId}/eligible_linking_products`, { params }).then((res) => {
      setLinkableAssets(
        res.data.data.map((asset) => ({
          label: `${asset.attributes.serial_number} - ${asset.attributes.asset_name}`,
          value: asset.id
        }))
      );
    });
  };

  useEffect(fetchLinkableAssets, []);

  const schema = Yup.object().shape({
    asset_tag: Yup.object().required(t('asset.assetDetail.linkingTab.selectInventory'))
  });

  const TitleIcon = <FontAwesomeIcon icon={faCube} className={appStyles.solid_list_icon} />;

  return (
    <ModalForm
      className={ModalFormStyles.addLinkStyle}
      formTitle={title}
      titleIcon={TitleIcon}
      initialValues={initialValues}
      fields={fields}
      onSubmit={handleSubmit}
      onClose={onClose}
      onValuesChange={(values) => setInitialValues(values)}
      validationSchema={schema}
    />
  );
};

export default LinkingForm;
