import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropzone, FileItem } from '@dropzone-ui/react';

import FileLineIcon from '../../assets/images/file-line-icon.png';

import form_field_style from '../../assets/scss/_form_field.module.scss';
import styles from './Attachments.module.scss';

const Attachments = ({
  existingAttachments = [],
  onAttachmentsChange = () => {},
  acceptImages = true,
  acceptPdf = true,
  acceptDoc = true,
  acceptCsv = false,
  maxFileSizeInMB = 10,
  maxAttachments = 3,
  containerClassName = '',
  helpText = ''
}) => {
  const { t } = useTranslation();

  const [attachments, setAttachments] = useState([]);
  const [existingAttachmentIds, setExistingAttachmentIds] = useState([]);

  function getFileMetadaFromUrl(url) {
    let xhr = new XMLHttpRequest();
    xhr.open('HEAD', url, false);

    xhr.send();
    const contentType = xhr.getResponseHeader('Content-Type');
    const fileName = url.split('/').pop().split('#')[0].split('?')[0];

    return [contentType.toLowerCase() || 'application/octet-stream.', fileName || 'attachment'];
  }
  function isFileImage(file) {
    const fileType = file['type'];
    if (!fileType) return false;
    return fileType.toLowerCase().startsWith('image');
  }
  useEffect(() => {
    if (existingAttachments.length == 0) return;
    setAttachments(
      existingAttachments.map((attachment) => {
        const [fileType, fileName] = getFileMetadaFromUrl(attachment.url);
        return {
          valid: true,
          file: new File([''], fileName, {
            type: fileType
          }),
          serverFileId: attachment.id,
          ...attachment
        };
      })
    );
    setExistingAttachmentIds(existingAttachments.map((attachment) => attachment.id));
  }, [existingAttachments]);

  useEffect(() => {
    const removedAttachmentIds = existingAttachmentIds.filter(
      (id) => !attachments.find((file) => id === file.serverFileId)
    );
    const newAttachments = attachments.filter((file) => !file.serverFileId && file.valid === true);
    onAttachmentsChange(newAttachments, removedAttachmentIds);
  }, [attachments, existingAttachmentIds, onAttachmentsChange]);

  const updateAttachments = (incommingAttachments) => {
    setAttachments(incommingAttachments);
  };

  const onDelete = (id) => {
    setAttachments(attachments.filter((x) => x.id !== id));
  };

  const acceptedExtensions = [];
  if (acceptImages) acceptedExtensions.push('image/jpg, image/png, image/jpeg');
  if (acceptPdf) acceptedExtensions.push('application/pdf');
  if (acceptDoc) acceptedExtensions.push('application/doc, application/docx');
  if (acceptCsv) acceptedExtensions.push('.csv');

  return (
    <div className={`${styles.attachment_container} ${containerClassName}`}>
      <Dropzone
        onChange={updateAttachments}
        value={attachments}
        accept={acceptedExtensions.join(', ')}
        maxFileSize={maxFileSizeInMB * 1024 * 1024}
        maxFiles={maxAttachments}
        label={t('attachment.dropYourFilesHere')}
        minHeight={'206px'}
        footer={false}
        header={attachments.length !== 0}
        disableScroll>
        {attachments.length === 0 && (
          <>
            <img className={form_field_style.default_upload_image} src={FileLineIcon} alt={''} />
            <p className={form_field_style.text_drop_file}>
              {t(maxAttachments == 1 ? 'attachment.dropYourFileHere' : 'attachment.dropYourFilesHere')}
            </p>
            <p className={form_field_style.text_file_type}>{t(helpText ?? 'attachment.supportsJpgPngPdfDocx')}</p>
          </>
        )}
        {attachments.length > 0 &&
          attachments.map((file) => (
            <FileItem
              {...file}
              key={file.id}
              onDelete={onDelete}
              imageUrl={isFileImage(file.file) ? file.url : FileLineIcon}
              preview
              resultOnTooltip
            />
          ))}
      </Dropzone>
    </div>
  );
};

export default Attachments;
