import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCube, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { ReserveSchema } from '../../../../schemas/ReserveSchema';
import { axiosApi } from '../../../../helpers/api_helper';
import ModalForm from '../../../../components/ModalForm';
import appStyles from '../../../../assets/scss/_app.module.scss';
import { getUsers } from '../../../../helpers/common_service';
import { addDays } from 'date-fns';
import UserForm from '../../../User/Form';

const ReservationForm = ({
  productId,
  reservation,
  onClose,
  getReservations = null,
  setCurrentReservation,
  triggerToaster
}) => {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const isEditReservation = reservation?.id ? true : false;
  const [loading, setLoading] = useState(false);
  const title = t(`asset.assetDetail.reserveForm.${isEditReservation ? 'edit' : 'add'}Reservation`);
  const [isUserFormOpen, setUserFormOpen] = useState(false);
  const [isNewUserAdded, setIsNewUserAdded] = useState(false);

  const [initialValues, setInitialValues] = useState({
    from_date: reservation.from_date,
    to_date: reservation.to_date,
    company: '',
    branch: '',
    assigned_to: isEditReservation
      ? {
          label: reservation.reserved_to_user,
          value: reservation.reserved_to_user_id
        }
      : '',
    notes: '',
    ...reservation
  });
  let preSavedData = isEditReservation ? { ...initialValues } : {};

  const handleNewUser = (newUser) => {
    getUsers(initialValues.branch_id, setUsers);
    setInitialValues({ ...initialValues, assigned_to: newUser });

    setIsNewUserAdded(true);
  };

  const fetchProduct = () => {
    setLoading(true);
    axiosApi
      .get(`/products/${productId}`)
      .then((res) => {
        if (setCurrentReservation) setCurrentReservation(res.data.attributes);
        const product = res.data?.data;
        let obj = {
          ...initialValues,
          company: product?.attributes?.company_name,
          branch: product?.attributes?.branch_name,
          company_id: product?.attributes?.company_id,
          branch_id: product?.attributes?.branch_id
        };
        setInitialValues(obj);
        getUsers(product?.attributes?.branch_id, setUsers);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (productId) fetchProduct();
  }, [productId]);

  const fields = [
    {
      id: nanoid(),
      label: t('asset.assetDetail.reserveForm.schedulefrom'),
      name: 'from_date',
      type: 'date',
      isRequired: true,
      label_size: 2.3,
      field_size: 9
    },
    {
      id: nanoid(),
      label: t('asset.assetDetail.reserveForm.scheduleto'),
      name: 'to_date',
      type: 'date',
      isRequired: true,
      disabled: !initialValues.from_date,
      minDate: Date.parse(initialValues.from_date) && addDays(Date.parse(initialValues.from_date), 1),
      label_size: 2.3,
      field_size: 9
    },
    {
      id: nanoid(),
      label: t('common.company'),
      name: 'company',
      type: 'text',
      isRequired: true,
      disabled: true,
      label_size: 2.3,
      field_size: 9
    },
    {
      id: nanoid(),
      label: t('common.branch'),
      name: 'branch',
      type: 'text',
      isRequired: true,
      disabled: true,
      label_size: 2.3,
      field_size: 9
    },
    {
      id: nanoid(),
      label: t('asset.assetDetail.reserveForm.assignedTo'),
      name: 'assigned_to',
      type: 'autoComplete',
      isRequired: true,
      disabled: !initialValues.branch,
      addModel: () => {
        setUserFormOpen(true);
      },
      values: users,
      label_size: 2.3,
      field_size: 7
    },
    {
      id: nanoid(),
      label: t('asset.assetDetail.moreAction.notes'),
      name: 'notes',
      type: 'textArea',
      label_size: 2.3,
      field_size: 9
    }
  ];

  const schema = ReserveSchema(
    t('asset.assetDetail.reserveForm.enterTheStartDate'),
    t('asset.assetDetail.reserveForm.enterTheEndDate'),
    t('asset.assetDetail.reserveForm.endDateEalierThanStartDate'),
    t('asset.assetDetail.reserveForm.pleaseSelectCompany'),
    t('asset.assetDetail.reserveForm.pleaseSelectBranch'),
    t('asset.assetDetail.reserveForm.pleaseSelectUser')
  );

  const TitleIcon = (
    <FontAwesomeIcon icon={isEditReservation ? faPenToSquare : faCube} className={appStyles.solid_list_icon} />
  );

  const resetForm = () => {
    setInitialValues({});
    onClose();
    if (getReservations) {
      getReservations();
    }
  };

  const handleSubmit = (values) => {
    values.product_id = productId;
    const reservationData = {
      reservation: {
        product_id: productId,
        reserved_to_user_id: values.assigned_to.value,
        from_date: new Date(values.from_date),
        to_date: new Date(values.to_date),
        notes: values.notes
      }
    };

    if (isEditReservation) {
      axiosApi
        .patch(`/reservations/${reservation.id}`, reservationData)
        .then((res) => {
          if (setCurrentReservation) setCurrentReservation({ detail: res.data.data.attributes, action: 'edit' });
          resetForm();
          toast.success(t('asset.assetDetail.reserveTab.reservationUpdatedSuccessfully'));
          onClose();
        })
        .catch((res) => {
          toast.error(
            t(
              `asset.assetDetail.reserveTab.${res.response?.data?.message_key}` ??
                'asset.assetDetail.reserveTab.failedToUpdateReservation'
            )
          );
        });
    } else {
      axiosApi
        .post('/reservations', reservationData)
        .then((res) => {
          if (setCurrentReservation) setCurrentReservation({ detail: res.data.reservation, action: 'create' });
          resetForm();
          toast.success(t('asset.assetDetail.reserveTab.reservationAddedSuccessfully'));
          onClose();
        })
        .catch((err) => {
          let error = err.response?.data?.error?.base;
          error?.map((e) => {
            toast.error(t(`asset.assetDetail.reserveTab.${e}`));
          });
        });
    }
  };

  const handleDelete = () => {
    axiosApi
      .delete(`/reservations/${reservation.id}`)
      .then(() => {
        if (setCurrentReservation) setCurrentReservation({ id: reservation.id, action: 'delete' });
        resetForm();
        triggerToaster(true);
      })
      .catch((err) => {
        toast.error(t(err.response?.data?.error?.base ?? 'common.somethingWentsWrong'));
      });
  };

  return (
    <>
      {!loading && (
        <ModalForm
          isComponentModalOpen={isUserFormOpen}
          isNewUserAdded={isNewUserAdded}
          setIsNewUserAdded={setIsNewUserAdded}
          preSavedData={preSavedData}
          formTitle={title}
          titleIcon={TitleIcon}
          isEditForm={isEditReservation}
          initialValues={initialValues}
          fields={fields}
          onSubmit={handleSubmit}
          onClose={onClose}
          onValuesChange={(values) => setInitialValues(values)}
          validationSchema={schema}
          allowDelete={true}
          onDelete={handleDelete}
        />
      )}
      {isUserFormOpen && (
        <UserForm
          isNavigatedFromEventsForm={true}
          isPreFilledValues={initialValues}
          open={isUserFormOpen}
          details={{}}
          onClose={() => setUserFormOpen(false)}
          onSubmit={() => setUserFormOpen(false)}
          onNewUserAdded={handleNewUser}
        />
      )}
    </>
  );
};

export default ReservationForm;
