import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './Table.module.scss';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton
} from '@mui/material';
import { SET_TABLE_ROW_COUNT } from '../../store/table/actionTypes';
import { rowsPerPageOptions, userPage, RowsPerPage, reportPage } from '../../helpers/constants';
import ActionIconBtn from '../Form/ActionIconBtn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpAZ, faArrowDownZA } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import Loader from '../../components/Loader';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import tickIcon from '../../assets/images/TickIcon.svg';
import wrongIcon from '../../assets/images/WrongIcon.svg';
import { isNavMenuOpen, isNotMobileWidth } from '../../helpers/common_service';
import { toast } from 'react-toastify';
import { axiosApi } from '../../helpers/api_helper';
import DeleteToaster from '../Toaster/DeleteToaster';
import { useLocation, useNavigate } from 'react-router-dom';
import FilterSelect from '../FilterSelect';
import { styled } from '@mui/material/styles';

const Arrowkeys = ['ArrowDown', 'ArrowUp'];

function EnhancedTableHead({ headCells, viewActionColumn, setSortedBy, sortedBy, onSortingValuesChange }) {
  if (!viewActionColumn) {
    headCells = headCells.filter((headCell) => headCell.id !== 'action');
  }

  const handleSort = (isAscending, headCell) => {
    onSortingValuesChange((prev) => ({
      order: prev.column === headCell.id ? (isAscending ? 'asc' : 'desc') : 'asc',
      column: headCell.id
    }));
    setSortedBy((prev) => ({
      sortedBy: prev.headCell === headCell.id ? (isAscending ? 'asc' : 'desc') : 'asc',
      headCell: headCell.id
    }));
  };

  return (
    <TableHead className={styles.table_head}>
      <TableRow>
        {headCells?.map((headCell) => (
          <TableCell
            key={headCell.id}
            className={`${styles.table_cell} ${styles.head_cell}`}
            align="center"
            width={headCell?.width}
            sx={{
              maxWidth: headCell?.maxWidth,
              minWidth: headCell?.minWidth
            }}
            padding={headCell.disablePadding ? 'none' : 'normal'}>
            <div className={`${styles.headerContainer} ${headCell.id === 'action' && styles.justifyContentCenter}`}>
              <span className={`${styles.headerText}`}>{headCell.label}</span>
              {headCell.canSort && (
                <div>
                  <FontAwesomeIcon
                    icon={
                      sortedBy['headCell'] === headCell.id
                        ? sortedBy['sortedBy'] === 'asc'
                          ? faArrowUpAZ
                          : faArrowDownZA
                        : faArrowUpAZ
                    }
                    className={`${styles.headerSortIcon} ${
                      sortedBy['headCell'] && sortedBy['headCell'] !== headCell.id && styles.icon_disabled
                    }`}
                    onClick={() => {
                      handleSort(sortedBy['sortedBy'] === 'asc' ? false : true, headCell);
                    }}
                  />
                </div>
              )}
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  headCells: PropTypes.array.isRequired,
  viewActionColumn: PropTypes.bool.isRequired
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

export default function EnhancedTable({
  id = 'table',
  pageName,
  headCells,
  rows,
  viewBtn = true,
  editBtn = true,
  deleteBtn = true,
  editBtnLink,
  viewBtnLink,
  deleteBtnLink,
  onDelete,
  onBeforeDelete,
  onAfterDelete,
  viewBtnFn,
  editBtnFn,
  deleteBtnFn,
  viewActionColumn = true,
  loading = false,
  noDataMsg = null,
  onChange,
  totalRowsCount,
  totalPagesCount,
  filterOptions,
  showPagination = true,
  onSortingValuesChange,
  canUndo = true
}) {
  const table = useSelector((state) => state.table);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const isNavbarOpen = isNavMenuOpen();
  const [rowData, setRowData] = useState([]);
  const [sortedBy, setSortedBy] = useState({
    sortedBy: 'desc',
    headCell: null
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    table.tableRowCountObj?.page === pageName ? table.tableRowCountObj?.count : RowsPerPage
  );
  const [selectedRow, setSelectedRow] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [isDeleteToasterOpen, setIsDeleteToasterOpen] = useState(false);
  const [deleteOnClose, setDeleteOnClose] = useState(false);
  const [deletedRow, setDeletedRow] = useState(null);
  const lastDeletedToasterId = useRef(null);
  const lastDeletedRowID = useRef(null);

  const columns = headCells.filter((headCell) => headCell.id !== 'action').map((headCell) => headCell.id);
  rows = rows ?? [];
  const hasViewLink = headCells.find((headCell) => headCell.hasViewLink === true) != null;
  viewActionColumn = viewActionColumn && ((viewBtn && !hasViewLink) || editBtn || deleteBtn);

  const emptyTableColumnLength = viewActionColumn
    ? headCells.length
    : pageName !== reportPage && headCells[headCells.length - 1]?.id === 'action'
    ? headCells.length - 1
    : headCells.length;

  const handlePageChange = (type) => {
    setCurrentPage((prev) => {
      if (type === 'increment' && currentPage < totalPagesCount) {
        return ++prev;
      } else if (type === 'decrement' && currentPage > 1) {
        return --prev;
      } else {
        return prev;
      }
    });
  };

  const onUndoDelete = (rowId) => {
    // hando undo is called when the user clicks on the undo button in the toast and have to reconfigure this function once the backend is ready
    onBeforeDelete?.();
    if (deleteOnClose) {
      rows.splice(deletedRow.index, 1, deletedRow.row);
      setRowData(
        rows.map((row) => ({
          id: row.id,
          values: columns.map((column) => row[column] || '-'),
          meta: row.row_meta,
          canEdit: row.can_edit ?? true
        }))
      );
      setDeleteOnClose(false);
      setDeletedRow(null);
      onAfterDelete?.();
    } else
      axiosApi
        .patch(`/${deleteBtnLink}/${rowId}/undo_destroy`)
        .then(() => {
          onDelete();
        })
        .catch((err) => {
          toast.error(t('common.somethingWentsWrong'));
          console.log(err);
        })
        .finally(() => {
          onAfterDelete?.();
          lastDeletedRowID.current = null;
        });
  };

  const handleDelete = (rowId) => {
    lastDeletedRowID.current = rowId;
    onBeforeDelete?.();
    if (pageName !== userPage || deleteOnClose) {
      axiosApi
        .delete(`/${deleteBtnLink}/${rowId}`)
        .then(() => {
          pageName === userPage ? deleteOnClose && setIsDeleteToasterOpen(true) : setIsDeleteToasterOpen(true);
          onDelete();
        })
        .catch((err) => {
          toast.error(t('common.somethingWentsWrong'));
          console.log(err);
        })
        .finally(() => {
          onAfterDelete?.();
          setDeletedRow(null);
          setDeleteOnClose(false);
        });
    } else {
      let temp = {};
      rows = rows.filter((row, index) => {
        if (row.id === rowId) {
          temp['row'] = row;
          temp['index'] = index;
          setDeletedRow(temp);
        }
        return row.id !== rowId;
      });
      setRowData(
        rows.map((row) => ({
          id: row.id,
          values: columns.map((column) => row[column] || '-'),
          meta: row.row_meta,
          canEdit: row.can_edit ?? true
        }))
      );
      setIsDeleteToasterOpen(true);
      setDeleteOnClose(true);
      onAfterDelete?.();
    }
  };

  useEffect(() => {
    dispatch({
      type: SET_TABLE_ROW_COUNT,
      setTableRowCount: { page: pageName, count: rowsPerPage }
    });
  }, [rowsPerPage]);

  useEffect(() => {
    setRowData(
      rows.map((row) => ({
        id: row.id,
        values: columns.map((column) => row[column] || '-'),
        meta: row.row_meta,
        canEdit: row.can_edit ?? true
      }))
    );
  }, [rows]);

  useEffect(() => {
    if (showPagination) onChange({ currentPage, rowsPerPage });
  }, [currentPage, rowsPerPage]);

  useEffect(() => setCurrentPage(1), [filterOptions, rowsPerPage]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleViewLinkClick = (rowId, column) => {
    if (viewBtnLink)
      navigate(`/${viewBtnLink}/${rowId}`, { state: { previousPath: `${location.pathname}${location.search}` } });
    else if (viewBtnFn) viewBtnFn(rowId, column);
  };
  const paginateObject = {
    from: currentPage,
    to: totalPagesCount,
    count: totalRowsCount
  };

  return (
    <>
      <Paper elevation={0} id={id} className={styles.tablePaperContainer}>
        <TableContainer className={styles.tableContainer}>
          <Table
            className={`${styles.table} ${pageName === reportPage ? styles.reportsPageTable : ''}  ${
              !rows.length || loading ? styles.emptyTable : ''
            } ${windowWidth < 1200 && isNavbarOpen ? styles.table_side_menu_open : ''}`}>
            <EnhancedTableHead
              headCells={headCells}
              viewActionColumn={viewActionColumn}
              sortedBy={sortedBy}
              setSortedBy={setSortedBy}
              onSortingValuesChange={onSortingValuesChange}
            />
            {loading ? (
              <TableBody>
                <TableRow className={styles.loader_row}>
                  <TableCell colSpan={emptyTableColumnLength} align="center" className={styles.loader_container}>
                    <Loader loading={loading} />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : rows[0] ? (
              <TableBody>
                {rowData.map((row, index) => {
                  return (
                    <StyledTableRow
                      className={`${selectedRow === index ? styles.selected : ''}`}
                      onClick={() => setSelectedRow(index)}
                      selected={selectedRow === index}
                      tabIndex={-1}
                      key={row.id}
                      onKeyDown={(e) => {
                        if (Arrowkeys.includes(e.key)) {
                          if (e.key === 'ArrowUp' && selectedRow > 0) {
                            setSelectedRow((prev) => --prev);
                          } else if (e.key === 'ArrowDown' && selectedRow < rowData.length - 1) {
                            setSelectedRow((prev) => ++prev);
                          }
                        }
                      }}>
                      {row.values?.map((data, index) => {
                        let cellContent = data;
                        if (Array.isArray(data))
                          cellContent = data.map((value, index) => <div key={index}>{value || '-'}</div>);
                        else if (columns[index] === 'is_active')
                          cellContent = (
                            <img
                              src={data === true ? tickIcon : wrongIcon}
                              alt="active_icon"
                              className={styles.active_icon}
                            />
                          );
                        else if (headCells[index]?.hasViewLink && viewBtn)
                          cellContent = (
                            <div
                              className={styles.onclick_cursor}
                              onClick={() => {
                                handleViewLinkClick(row.id, headCells[index].id);
                              }}>
                              {data}
                            </div>
                          );
                        return (
                          <TableCell
                            align="center"
                            component="td"
                            scope="row"
                            key={index}
                            width={headCells[index]?.width}
                            sx={{
                              maxWidth: headCells[index]?.maxWidth,
                              minWidth: headCells[index]?.minWidth
                            }}
                            className={`${styles.table_cell} ${
                              pageName === reportPage ? styles.reportPageTableCells : ''
                            } ${headCells[index]?.breakWord ? styles.breakWord : ''}`}>
                            {cellContent}
                          </TableCell>
                        );
                      })}
                      {viewActionColumn && (
                        <TableCell
                          align="center"
                          className={`${styles.button_container} ${styles.table_cell}`}
                          width={headCells[headCells.length - 1]?.width}
                          sx={{
                            maxWidth: headCells[headCells.length - 1]?.maxWidth,
                            minWidth: headCells[headCells.length - 1]?.minWidth
                          }}
                          onClick={(e) => e.stopPropagation()}>
                          {(row.meta?.can_modify_row == null || row.meta?.can_modify_row == true) && (
                            <ActionIconBtn
                              disabledAction={!row.canEdit}
                              viewBtn={viewBtn && !hasViewLink}
                              editBtn={editBtn}
                              deleteBtn={deleteBtn}
                              viewBtnLink={viewBtnLink}
                              editBtnLink={editBtnLink}
                              deleteBtnLink={deleteBtnLink}
                              viewBtnFn={viewBtnFn}
                              editBtnFn={editBtnFn}
                              deleteBtnFn={deleteBtnFn}
                              rowId={row.id}
                              onDelete={onDelete}
                              onBeforeDelete={onBeforeDelete}
                              onAfterDelete={onAfterDelete}
                              pageName={pageName}
                              handleDelete={handleDelete}
                              deleteMessage={row.meta?.delete_message}
                              delete_alert_message={row.meta?.delete_alert_message}
                            />
                          )}
                        </TableCell>
                      )}
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={emptyTableColumnLength} align="center" className={styles.no_data_found_msg}>
                    {t(noDataMsg ?? 'table.tableNoData')}
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>

        {showPagination && !loading && !(currentPage === 1 && totalRowsCount <= rowsPerPageOptions[0].value) && (
          <div className={styles.paginate_container}>
            <div className={styles.paginate_dropdown}>
              <FilterSelect
                value={rowsPerPage}
                label={isNotMobileWidth(windowWidth) ? t('table.showPerPage') : t('table.mobileShowPerPage')}
                items={rowsPerPageOptions}
                onChange={(value) => setRowsPerPage(value)}
                containerStyles={styles.filterSelector}
              />
            </div>
            <div className={styles.paginate_buttons_container}>
              <span>
                {isNotMobileWidth(windowWidth)
                  ? t('table.paginateMsg', paginateObject)
                  : t('table.mobilePaginateMsg', paginateObject)}
              </span>
              <div className={styles.paginate_buttons}>
                <IconButton
                  onClick={() => handlePageChange('decrement')}
                  className={`${styles.prevBtn} ${currentPage === 1 ? styles.hide_btn : ''}`}>
                  <KeyboardArrowLeft />
                </IconButton>
                <Button variant="contained" className={styles.midBtn}>
                  {currentPage}
                </Button>
                <IconButton
                  onClick={() => handlePageChange('increment')}
                  className={`${styles.nextBtn} ${currentPage === totalPagesCount ? styles.hide_btn : ''}`}>
                  <KeyboardArrowRight />
                </IconButton>
              </div>
            </div>
          </div>
        )}
        {isDeleteToasterOpen && lastDeletedToasterId.current !== lastDeletedRowID.current && (
          <DeleteToaster
            isDeleteOnClose={deleteOnClose}
            deletedItemId={lastDeletedRowID.current}
            message={`${t(`common.${pageName}DeleteSuccess`)}`}
            onClose={(canDelete) => {
              if (deleteOnClose && deletedRow && canDelete) {
                handleDelete(deletedRow.row.id);
              }
              setIsDeleteToasterOpen(false);
            }}
            canUndo={canUndo}
            handleUndoDelete={onUndoDelete}
            setLastlyDeletedItemId={(value) => (lastDeletedRowID.current = value)}
            setToasterDeleteId={(values) => (lastDeletedToasterId.current = values)}
          />
        )}
      </Paper>
    </>
  );
}
