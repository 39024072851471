import MainImg from '../../assets/images/Landing/hero-img.svg';
import FeaturesImg from '../../assets/images/Landing/features.svg';
import OurFocusImg from '../../assets/images/Landing/our-focus.svg';
import EasyToUseImg from '../../assets/images/Landing/easy-to-use.svg';
import WhiteLogo from '../../assets/images/WhiteAppLogo.svg';
import BlackLogo from '../../assets/images/AppLogo.svg';
import Tracking from '../../assets/images/Landing/Tracking.svg';
import Automated_workflow from '../../assets/images/Landing/Automated_workflow.svg';
import Enhanced_Security from '../../assets/images/Landing/Enhanced_Security.svg';
import Accuracy from '../../assets/images/Landing/accuracy.svg';
import Vector from '../../assets/images/Landing/Vector.png';
import { Menu, ArrowUpward, LinkedIn, Twitter, Facebook, KeyboardArrowRight, Close } from '@mui/icons-material';
import './bootstrap.css';
import './style.css';
import { useEffect, useState } from 'react';

const LandingPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const freaturesBgImage = {
    backgroundImage: `url(${FeaturesImg})`
  };

  const ourFocusBgImage = {
    backgroundImage: `url(${OurFocusImg})`
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = '../../../public/scriptJs/main';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  const listItems = document.querySelectorAll('#navbar li');
  listItems.forEach((item) => {
    item.addEventListener('click', () => setIsOpen(false));
  });

  return (
    <>
      {/* <!-- ======= Header ======= --> */}
      <header id="header" className="fixed-top">
        <div className="container d-flex justify-content-between align-items-center">
          <a href="/login" className="logo me-auto">
            <img src={WhiteLogo} alt="" className="img-fluid" />
          </a>

          <nav
            id="navbar"
            className={`navbar ${isOpen ? 'navbar-mobile' : ''}`}
            onClick={() => {
              if (isOpen) setIsOpen(false);
            }}>
            <ul>
              <li>
                <a className="nav-link scrollto active" href="#home">
                  Home
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#why-assetolio">
                  Why assetolio
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#features">
                  Features
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#easy-to-use">
                  Easy to use
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#our-focus">
                  Our focus
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#contact">
                  Contact
                </a>
              </li>
              <li className="login-link">
                <a className="login scrollto" href="/login">
                  Login
                </a>
              </li>
            </ul>
            {isOpen ? (
              <Close className="mobile-nav-toggle" onClick={() => setIsOpen(false)} />
            ) : (
              <Menu className="mobile-nav-toggle" onClick={() => setIsOpen(true)} />
            )}
          </nav>
          {/* <!-- .navbar --> */}
        </div>
      </header>
      {/* <!-- End Header --> */}

      {/* <!-- ======= Home Section ======= --> */}
      <section id="home" className="d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
              data-aos="fade-up"
              data-aos-delay="200">
              <h3>streamline your asset management process</h3>
              <h1>The Ultimate Asset Management Platform</h1>
              <p>
                Manage the lifecycle of all your IT assets! Our cutting-edge software simplifies the process of
                documenting and managing each IT asset from the moment it is first used to the time it is retired, as
                well as the moves in between.
              </p>
              <div className="d-flex justify-content-center justify-content-lg-start">
                <a href="/login" className="btn-get-started scrollto">
                  Login
                </a>
                {/* <a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" className="glightbox btn-watch-video">
                  <i className="bi bi-play-circle"></i>
                  <span>Watch Video</span>
                </a> */}
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2 hero-img" data-aos="zoom-in" data-aos-delay="200">
              <img src={MainImg} className="img-fluid animated" alt="" />
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Home --> */}

      <main id="main">
        {/* <!-- ======= Why assetolio Section ======= --> */}
        <section id="why-assetolio" className="why-assetolio section-bg">
          <div className="container mt-4">
            <div className="section-title">
              <h2>Why Assetolio</h2>
              <p>The ultimate software solution for managing the lifecycle of all your IT assets!</p>
            </div>

            <div className="row" data-aos="fade-up">
              <div
                className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0"
                data-aos="zoom-in"
                data-aos-delay="100">
                <div className="icon-box">
                  <div className="icon">
                    <img src={Tracking} alt="Streamlined Asset Tracking..." />
                  </div>
                  <h4>
                    <a href="">Streamlined Asset Tracking</a>
                  </h4>
                  <p>
                    Our software makes it easy to track your IT assets throughout their entire lifecycle, from
                    procurement to disposal.
                  </p>
                </div>
              </div>

              <div
                className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
                data-aos="zoom-in"
                data-aos-delay="200">
                <div className="icon-box">
                  <div className="icon">
                    <img src={Automated_workflow} alt="Automated Workflows..." />
                  </div>
                  <h4>
                    <a href="">Automated Workflows</a>
                  </h4>
                  <p>
                    Assetolio automates workflows and ensures that all stakeholders are informed and up-to-date on any
                    changes or incidents.
                  </p>
                </div>
              </div>

              <div
                className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0"
                data-aos="zoom-in"
                data-aos-delay="300">
                <div className="icon-box">
                  <div className="icon">
                    <img src={Accuracy} alt="Accuracy..." />
                  </div>
                  <h4>
                    <a href="">Accurate Reporting</a>
                  </h4>
                  <p>
                    Assetolio provides accurate reports and real-time visibility into your asset inventory, making it
                    easy to identify issues and make data-driven decisions.
                  </p>
                </div>
              </div>

              <div
                className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0"
                data-aos="zoom-in"
                data-aos-delay="400">
                <div className="icon-box">
                  <div className="icon">
                    <img src={Enhanced_Security} alt="Enhanced_Security..." />
                  </div>
                  <h4>
                    <a href="">Enhanced Security</a>
                  </h4>
                  <p>
                    Our software provides a secure environment for managing your assets, ensuring that sensitive
                    information is protected and compliance requirements are met.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End Why assetolio Section --> */}

        {/* <!-- ======= Features Section ======= --> */}
        <section id="features" className="features section-bg">
          <div className="container mt-4" data-aos="fade-up">
            <div className="row">
              <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch order-2 order-lg-1">
                <div className="content-container">
                  <h6>THE FEATURES</h6>
                  <h1>That Make Assetolio Stand Out</h1>
                  <div className="list-container">
                    <span>
                      <img src={Vector} alt="Image3..." className="list-icon" />
                      Unlimited Users
                    </span>
                    <span>
                      <img src={Vector} alt="Image3..." className="list-icon" />
                      Custom Reports
                    </span>
                    <span>
                      <img src={Vector} alt="Image3..." className="list-icon" />
                      Cloud Based
                    </span>
                    <span>
                      <img src={Vector} alt="Image3..." className="list-icon" />
                      Email Alerts
                    </span>
                    <span>
                      <img src={Vector} alt="Image3..." className="list-icon" />
                      Maintenance and Scheduling
                    </span>
                    <span>
                      <img src={Vector} alt="Image3..." className="list-icon" />
                      Complete Check-in and Check-out
                    </span>
                    <span>
                      <img src={Vector} alt="Image3..." className="list-icon" />
                      Barcode Scanning
                    </span>
                  </div>
                  <a className="nav-link scrollto active" href="#contact">
                    <button type="submit">Get Demo</button>
                  </a>
                </div>
              </div>

              <div
                className="col-lg-5 align-items-stretch order-1 order-lg-2 img"
                style={freaturesBgImage}
                data-aos="zoom-in"
                data-aos-delay="150"></div>
            </div>
          </div>
        </section>
        {/* <!-- End Features Section --> */}

        {/* <!-- ======= Easy to use Section ======= --> */}
        <section id="easy-to-use" className="skills">
          <div className="container mt-4" data-aos="fade-up">
            <div className="row">
              <div className="col-lg-6 d-flex align-items-center" data-aos="fade-right" data-aos-delay="100">
                <img src={EasyToUseImg} className="img-fluid" alt="" />
              </div>
              <div className="col-lg-6 pt-4 pt-lg-0 content" data-aos="fade-left" data-aos-delay="100">
                <h6>BEST ASSET MANAGEMENT SOFTWARE</h6>
                <h1>Easy-to-use Platform</h1>
                <p>
                  Assetolio solves these problems by providing an easy-to-use platform that allows you to easily align
                  your hardware and software inventory, asset owners and assignments, and location information in one
                  place. With our software, {"you'll"} have complete visibility into the status of all your assets,
                  enabling you to make informed decisions about when {"it's"} time to replace or upgrade them.
                </p>
                <a className="nav-link scrollto active" href="#contact">
                  <button type="button">Learn More</button>
                </a>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End Easy to use Section --> */}

        <section id="our-focus">
          <section className="features our-focus-content section-bg">
            <div className="container mt-4" data-aos="fade-up">
              <div className="row">
                <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch order-2 order-lg-1">
                  <div>
                    <h6>Our focus</h6>
                    <h1>Save time Track more</h1>
                    <p>
                      Without Assetolio, you would have to manually fill in spreadsheets each time an incident occurs or
                      a new user takes over an asset. This process could be a time-consuming effort, inefficient, and
                      overwhelming when assessing incidents or tracking the current status of any physical asset.
                    </p>
                  </div>
                </div>

                <div
                  className="col-lg-5 align-items-stretch order-1 order-lg-2 img"
                  style={ourFocusBgImage}
                  data-aos="zoom-in"
                  data-aos-delay="150"></div>
              </div>
            </div>
          </section>

          {/* <!-- ======= Cta Section ======= --> */}
          <section id="cta" className="cta">
            <div className="container" data-aos="zoom-in">
              <div className="row">
                <div className="col-lg-9 text-center text-lg-start">
                  <h3>Access Assetolio’s Application</h3>
                  <p>Try Assetolio today and simplify your IT asset management like never before!</p>
                </div>
                <div className="col-lg-3 cta-btn-container text-center">
                  <a className="cta-btn align-middle" href="/login">
                    Login
                  </a>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- End Cta Section --> */}
        </section>

        {/* <!-- ======= Frequently Asked Questions Section ======= --> */}
        {/* <section id="faq" className="faq section-bg">
          <div className="container mt-4" data-aos="fade-up">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <p>
                Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint
                consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat
                sit in iste officiis commodi quidem hic quas.
              </p>
            </div>

            <div className="faq-list">
              <ul>
                <li data-aos="fade-up" data-aos-delay="100">
                  <i className="bx bx-help-circle icon-help"></i>
                  <a data-bs-toggle="collapse" className="collapse" data-bs-target="#faq-list-1">
                    Non consectetur a erat nam at lectus urna duis? <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div id="faq-list-1" className="collapse show" data-bs-parent=".faq-list">
                    <p>
                      Feugiat pretium nibh ipsum consequat. Tempus iaculis urna id volutpat lacus laoreet non curabitur
                      gravida. Venenatis lectus magna fringilla urna porttitor rhoncus dolor purus non.
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="200">
                  <i className="bx bx-help-circle icon-help"></i>
                  <a data-bs-toggle="collapse" data-bs-target="#faq-list-2" className="collapsed">
                    Feugiat scelerisque varius morbi enim nunc? <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div id="faq-list-2" className="collapse" data-bs-parent=".faq-list">
                    <p>
                      Dolor sit amet consectetur adipiscing elit pellentesque habitant morbi. Id interdum velit laoreet
                      id donec ultrices. Fringilla phasellus faucibus scelerisque eleifend donec pretium. Est
                      pellentesque elit ullamcorper dignissim. Mauris ultrices eros in cursus turpis massa tincidunt
                      dui.
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="300">
                  <i className="bx bx-help-circle icon-help"></i>
                  <a data-bs-toggle="collapse" data-bs-target="#faq-list-3" className="collapsed">
                    Dolor sit amet consectetur adipiscing elit? <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div id="faq-list-3" className="collapse" data-bs-parent=".faq-list">
                    <p>
                      Eleifend mi in nulla posuere sollicitudin aliquam ultrices sagittis orci. Faucibus pulvinar
                      elementum integer enim. Sem nulla pharetra diam sit amet nisl suscipit. Rutrum tellus pellentesque
                      eu tincidunt. Lectus urna duis convallis convallis tellus. Urna molestie at elementum eu facilisis
                      sed odio morbi quis
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="400">
                  <i className="bx bx-help-circle icon-help"></i>
                  <a data-bs-toggle="collapse" data-bs-target="#faq-list-4" className="collapsed">
                    Tempus quam pellentesque nec nam aliquam sem et tortor consequat?
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div id="faq-list-4" className="collapse" data-bs-parent=".faq-list">
                    <p>
                      Molestie a iaculis at erat pellentesque adipiscing commodo. Dignissim suspendisse in est ante in.
                      Nunc vel risus commodo viverra maecenas accumsan. Sit amet nisl suscipit adipiscing bibendum est.
                      Purus gravida quis blandit turpis cursus in.
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="500">
                  <i className="bx bx-help-circle icon-help"></i>
                  <a data-bs-toggle="collapse" data-bs-target="#faq-list-5" className="collapsed">
                    Tortor vitae purus faucibus ornare. Varius vel pharetra vel turpis nunc eget lorem dolor?
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div id="faq-list-5" className="collapse" data-bs-parent=".faq-list">
                    <p>
                      Laoreet sit amet cursus sit amet dictum sit amet justo. Mauris vitae ultricies leo integer
                      malesuada nunc vel. Tincidunt eget nullam non nisi est sit amet. Turpis nunc eget lorem dolor sed.
                      Ut venenatis tellus in metus vulputate eu scelerisque.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section> */}
        {/* <!-- End Frequently Asked Questions Section --> */}

        {/* <!-- ======= Contact Section ======= --> */}
        <section id="contact" className="contact">
          <div className="container mt-4" data-aos="fade-up">
            <div className="section-title">
              <h2>Contact</h2>
            </div>

            <div className="d-flex align-items-stretch">
              <form onSubmit={() => {}} className="form">
                <div className="row">
                  <div className="form-group col-md-6">
                    <label htmlFor="name">Your Name</label>
                    <input type="text" name="name" className="form-control" id="name" required />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="name">Your Email</label>
                    <input type="email" className="form-control" name="email" id="email" required />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="name">Subject</label>
                  <input type="text" className="form-control" name="subject" id="subject" required />
                </div>
                <div className="form-group">
                  <label htmlFor="name">Message</label>
                  <textarea className="form-control" name="message" rows="10" required></textarea>
                </div>
                <div className="text-center">
                  <button type="submit">Send Message</button>
                </div>
              </form>
            </div>
          </div>
        </section>
        {/* <!-- End Contact Section --> */}
      </main>

      {/* <!-- ======= Footer ======= --> */}
      <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 footer-links">
                <a href="index.html" className="me-auto">
                  <img src={BlackLogo} alt="" className="img-fluid logo" />
                </a>
                <p>The ultimate software solution for managing the complete lifecycle of your IT assets!</p>
              </div>

              <div className="col-lg-4 col-md-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li>
                    <KeyboardArrowRight />
                    <a className="nav-link scrollto active" href="#home">
                      Home
                    </a>
                  </li>
                  <li>
                    <KeyboardArrowRight />
                    <a className="nav-link scrollto" href="#why-assetolio">
                      Why assetolio
                    </a>
                  </li>
                  <li>
                    <KeyboardArrowRight />
                    <a className="nav-link scrollto" href="#features">
                      Features
                    </a>
                  </li>
                  <li>
                    <KeyboardArrowRight />
                    <a className="nav-link scrollto" href="#easy-to-use">
                      Easy to use
                    </a>
                  </li>
                  <li>
                    <KeyboardArrowRight />
                    <a className="nav-link scrollto" href="#our-focus">
                      Our focus
                    </a>
                  </li>
                  <li>
                    <KeyboardArrowRight />
                    <a className="nav-link scrollto" href="#contact">
                      Contact
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-4 col-md-12 footer-contact">
                <h3>Francium Tech</h3>
                <p>
                  S-4, Godrej azure elsa plaza, No-2.
                  <br />
                  Rajiv Gandhi Salai, Padur, Chennai-603103
                  <br />
                  India <br />
                  <br />
                  <strong>Phone:</strong> +91 98948 89852
                  <br />
                  <strong>Email:</strong> help@assetolio.com
                  <br />
                </p>
                <div className="social-links mt-3">
                  <a href="https://twitter.com/franciumtech" className="twitter" target="_blank" rel="noreferrer">
                    <Twitter />
                  </a>
                  <a href="https://www.facebook.com/franciumtech" className="facebook" target="_blank" rel="noreferrer">
                    <Facebook />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/francium-tech"
                    className="linkedin"
                    target="_blank"
                    rel="noreferrer">
                    <LinkedIn />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container footer-bottom clearfix">
          <div className="copyright text-center">© 2017-2023 Francium Tech. All Rights Reserved</div>
        </div>
      </footer>
      {/* <!-- End Footer --> */}

      {/* <div id="preloader"></div> */}
      <a href="#" className="back-to-top d-flex align-items-center justify-content-center">
        <ArrowUpward />
      </a>
    </>
  );
};

export default LandingPage;
