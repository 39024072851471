import { useState } from 'react';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import ModalForm from '../../../components/ModalForm';
import { passwordChangeSchema } from '../../../schemas/PasswordResetSchema';

import { toast } from 'react-toastify';
import { axiosApi } from '../../../helpers/api_helper';
import { changePasswordPage } from '../../../helpers/constants';
import LockResetIcon from '@mui/icons-material/LockReset';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { eye } from 'react-icons-kit/feather/eye';

const ChangePassword = ({ userId, onClose }) => {
  const [initialValues, setInitialValues] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  let preSavedData = {
    ...initialValues
  };

  const [confirmPasswordAttributes, setConfirmPasswordAttributes] = useState({
    type: 'password',
    icon: eyeOff
  });

  const handleToggle = () => {
    const fieldAttributes = confirmPasswordAttributes;
    const newType = fieldAttributes.type === 'password' ? 'text' : 'password';
    const newIcon = fieldAttributes.type === 'password' ? eye : eyeOff;
    fieldAttributes.type = newType;
    fieldAttributes.icon = newIcon;
    setConfirmPasswordAttributes({ ...confirmPasswordAttributes });
  };

  const { t } = useTranslation();

  const fields = [
    {
      id: nanoid(),
      label: t('myProfile.changePassword.currentPassword'),
      name: 'currentPassword',
      type: 'text',
      field_size: 8,
      label_size: 3.7,
      isRequired: true
    },
    {
      id: nanoid(),
      label: t('myProfile.changePassword.newPassword'),
      name: 'newPassword',
      type: 'password',
      field_size: 8,
      label_size: 3.7,
      canShowPassword: false,
      handleToggle: (fieldName) => handleToggle(fieldName),
      isRequired: true
    },
    {
      id: nanoid(),
      label: t('myProfile.changePassword.confirmPassword'),
      name: 'confirmPassword',
      type: 'password',
      passwordType: confirmPasswordAttributes.type,
      passwordIcon: confirmPasswordAttributes.icon,
      field_size: 8,
      label_size: 3.7,
      isRequired: true,
      handleToggle: (fieldName) => handleToggle(fieldName)
    }
  ];

  const schema = passwordChangeSchema(
    t('myProfile.changePassword.pleaseEnterCurrentPassword'),
    t('myProfile.changePassword.pleaseEnterNewPassword'),
    t('myProfile.changePassword.pleaseEnterConfirmPassword'),
    t('myProfile.changePassword.newPasswordMustNotMatchCurrentPassword'),
    t('myProfile.changePassword.passwordsMustMatch')
  );

  const handleSubmit = () => {
    let params = {
      old_password: initialValues.currentPassword,
      new_password: initialValues.newPassword
    };

    axiosApi
      .patch(`users/${userId}/change_password`, params)
      .then(() => {
        toast.success(t('myProfile.changePassword.passwordChangedSuccessfully'));
        onClose();
      })
      .catch((err) => {
        toast.error(t(`myProfile.changePassword.${err.response.data.message}` || 'common.somethingWentsWrong'), {});
      });
  };

  return (
    <>
      <ModalForm
        preSavedData={preSavedData}
        isEditForm={true}
        submitBtnText={t('submit')}
        formFor={changePasswordPage}
        formTitle={t('myProfile.changePassword.changePassword')}
        titleIcon={<LockResetIcon fontSize="large" />}
        fields={fields}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onClose={onClose}
        onValuesChange={(values) => setInitialValues(values)}
        validationSchema={schema}
      />
    </>
  );
};

export default ChangePassword;
