import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { getDateString } from '../../../helpers/common_service';
import ShowDetails from '../../../components/ShowDetail';

const UserHistoryDetails = ({ userHistory, onClose }) => {
  const { t } = useTranslation();
  const userHistoryDetails = [
    {
      key: nanoid(),
      label: t('common.fullName'),
      value: userHistory.full_name
    },
    {
      key: nanoid(),
      label: t('common.email'),
      value: userHistory.email
    },
    {
      key: nanoid(),
      label: t('asset.general.fromDate'),
      value: getDateString(userHistory.from_date)
    },
    {
      key: nanoid(),
      label: t('asset.general.endDate'),
      value: getDateString(userHistory.end_date)
    }
  ];

  return (
    <ShowDetails
      detailFor={t('asset.assetDetail.activities.userDetails')}
      details={userHistoryDetails}
      onClose={onClose}
    />
  );
};

export default UserHistoryDetails;
