import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import TimeLineProgress from '../../../components/TimeLineProgress';
import { getDateString } from '../../../helpers/common_service';
import InsuranceForm from './Insurance/add';
import LinkingForm from './Link/add';
import MaintenanceForm from './Maintenance/add';
import WarrantyForm from './Warranty/add';
import ReservationForm from './Reservation/add';
import styles from './index.module.scss';
import { axiosApi } from '../../../helpers/api_helper';
import { PARENT, CHILD, statusKeyChange } from '../../../helpers/constants';
import WarrantyDetails from './Warranty/view';
import ConfirmationCard from '../../../components/ConfirmationCard';
import InsuranceDetails from './Insurance/view';
import LikedProductDetails from './Link/view';
import UserHistoryDetails from './UserHistory';
import MaintenanceDetails from './Maintenance/view';
import ReservationDetails from './Reservation/view';
import MaintenanceRequestDetails from '../../MaintenanceRequest/RequestInfo';
import AssetStatusDetail from '../AssetStatusDetail';
import { toast } from 'react-toastify';
import { nanoid } from 'nanoid';
import DeleteToaster from '../../../components/Toaster/DeleteToaster';
import {
  INSURANCE,
  WARRANTY,
  MAINTENANCE,
  RESERVATION,
  LINKED_PRODUCT,
  USER,
  MAINTENANCE_REQUEST,
  STATUS_HISTORY
} from '../../../helpers/constants';

const AssetTimeLineProgress = ({
  reload,
  afterReload,
  formName,
  assetDetails,
  resetFormOpen,
  setAssetParent,
  activities,
  setActivities,
  setCurrentReservation
}) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [addFormName, setAddFormName] = useState('');
  const [editFormName, setEditFormName] = useState('');
  const [selectedActivity, setSelectedActivity] = useState({});
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isDeleteToasterOpen, setIsDeleteToasterOpen] = useState(false);
  const [lastDeletedModelName, setLastDeletedModelName] = useState(null);

  const getDisplayText = (activity) => {
    let values = activity.values;
    switch (activity.model_name) {
      case WARRANTY:
      case INSURANCE:
        return t('asset.assetDetail.activities.addedOn', {
          text: t(`authorizableModels.${activity.model_name}`),
          addDate: getDateString(values.created_at),
          endDate: getDateString(activity.model_name === WARRANTY ? values.expiring_on : values.end_date)
        });
      case MAINTENANCE:
        return t('asset.assetDetail.activities.maintenanceOn', {
          addDate: getDateString(values.created_at)
        });
      case RESERVATION:
        return t('asset.assetDetail.activities.reservedOn', {
          addDate: getDateString(values.created_at),
          fromDate: getDateString(values.from_date),
          toDate: getDateString(values.to_date)
        });
      case USER:
        return t('asset.assetDetail.activities.unAssignedOn', {
          user: values.full_name,
          date: getDateString(values.un_assigned_on)
        });
      case MAINTENANCE_REQUEST:
        return t('asset.assetDetail.activities.requestedOn', {
          user: values.requested_user_name,
          date: getDateString(values.requested_date)
        });
      case STATUS_HISTORY:
        return t('asset.assetDetail.activities.statusChange', {
          fromStatus: t(`statuses.${statusKeyChange[values.from_status] || values.from_status}`),
          toStatus: t(`statuses.${statusKeyChange[values.to_status] || values.to_status}`),
          date: getDateString(values.created_at),
          user: values.action_by
        });
      case LINKED_PRODUCT:
        return t('asset.assetDetail.activities.linkedOn', {
          text: values.asset_name,
          date: getDateString(values.created_at)
        });
      default:
        return '';
    }
  };

  const timeLineActivities = activities.map((activity) => ({
    key: nanoid(),
    displayContent: (
      <span>
        {activity?.values?.relation === PARENT && (
          <span className={styles.parentTag}>{t('asset.assetDetail.linkingTab.parent')}</span>
        )}
        {getDisplayText(activity)}
      </span>
    ),
    id: activity.values.id
  }));

  const removeActivity = () => {
    let filteredActivities = activities.filter((activity) => activity.values.id !== selectedActivity.values.id);
    setActivities(filteredActivities);
  };
  const fetchTimelineActivities = (value = null) => {
    axiosApi
      .get(`/products/${id}/timeline_activities`, { params: { filtered_record: value } })
      .then((res) => setActivities(res.data))
      .catch((err) => console.log(err))
      .finally(() => afterReload());
  };

  const unlinkProduct = (prodId, relation) => {
    axiosApi
      .post(`/products/${id}/unlink_product`, { [`${relation}_id`]: prodId })
      .then(() => {
        removeActivity();
        toast.error(t('asset.assetDetail.activities.unlinkSuccess'));
      })
      .catch((e) => {
        toast.error(e.error || t('common.somethingWentsWrong'));
      });
  };

  const deleteWarranty = () => {
    axiosApi
      .delete(`/warranties/${selectedActivity.values.id}`)
      .then(() => {
        setIsDeleteToasterOpen(true);
        removeActivity();
      })
      .catch((e) => {
        toast.error(e.error || t('common.somethingWentsWrong'));
      });
  };

  const deleteInsurance = () => {
    axiosApi
      .delete(`/products/${id}/insurances/${selectedActivity.values.id}`)
      .then(() => {
        removeActivity();
        setIsDeleteToasterOpen(true);
      })
      .catch((e) => {
        toast.error(e.error || t('common.somethingWentsWrong'));
      });
  };

  const deleteMaintenance = () => {
    axiosApi
      .delete(`/products/${id}/maintenances/${selectedActivity.values.id}`)
      .then(() => {
        removeActivity();
        setIsDeleteToasterOpen(true);
      })
      .catch((e) => {
        toast.error(e.error || t('common.somethingWentsWrong'));
      });
  };
  const deleteReservation = () => {
    axiosApi
      .delete(`/reservations/${selectedActivity.values.id}`)
      .then(() => {
        removeActivity();
        setIsDeleteToasterOpen(true);
      })
      .catch((e) => {
        toast.error(e.error || t('common.somethingWentsWrong'));
      });
  };

  const handleClose = () => {
    setAddFormName('');
    setEditFormName('');
    if ([INSURANCE, WARRANTY, MAINTENANCE, RESERVATION, PARENT, CHILD].includes(formName)) resetFormOpen();
  };

  const handleSubmit = (activityDetails) => {
    let newActivity = {
      model_name: [PARENT, CHILD].includes(addFormName) ? LINKED_PRODUCT : addFormName || editFormName,
      values: activityDetails
    };
    if (addFormName) {
      setAddFormName('');
      setActivities((prevActivities) => [newActivity, ...prevActivities]);
    } else {
      let updatedActivities = activities.map((activity) => {
        if (activity.values.id === selectedActivity.values.id) {
          return newActivity;
        }
        return activity;
      });
      setActivities(updatedActivities);
      setSelectedActivity(newActivity);
      setEditFormName('');
    }
    if ([INSURANCE, WARRANTY, MAINTENANCE, RESERVATION, PARENT, CHILD].includes(formName)) resetFormOpen();
    if (formName === PARENT) setAssetParent(activityDetails.id);
  };

  const handleView = (id) => {
    let activity = activities.find((activity) => activity.values.id === id);
    setSelectedActivity(activity);
  };

  const handleEdit = () => setEditFormName(selectedActivity.model_name);

  const handleDelete = () => {
    setLastDeletedModelName(selectedActivity.model_name);
    switch (selectedActivity.model_name) {
      case LINKED_PRODUCT:
        var relation = selectedActivity.values.id === assetDetails.parent_id ? PARENT : CHILD;
        unlinkProduct(selectedActivity.values.id, relation);
        if (relation === PARENT) setAssetParent(selectedActivity.id);
        break;
      case WARRANTY:
        deleteWarranty();
        break;
      case INSURANCE:
        deleteInsurance();
        break;
      case MAINTENANCE:
        deleteMaintenance();
        break;
      case RESERVATION:
        deleteReservation();
        break;
      default:
        return null;
    }
    setIsConfirmationOpen(false);
    setSelectedActivity({});
  };

  const onClose = () => setSelectedActivity({});
  const onDelete = () => setIsConfirmationOpen(true);

  useEffect(() => {
    if (formName) setAddFormName(formName);
  }, [formName]);

  useEffect(fetchTimelineActivities, [assetDetails]);

  useEffect(() => {
    if (reload) fetchTimelineActivities();
  }, [reload]);

  return (
    <>
      <div className={styles.timeLineContainer}>
        {timeLineActivities.length > 0 ? (
          <TimeLineProgress records={timeLineActivities} onView={handleView} />
        ) : (
          <div className={styles.noRecord}>{t('asset.assetDetail.activities.noActivities')}</div>
        )}
      </div>

      {/* Add & Edit form */}

      {[addFormName, editFormName].includes(INSURANCE) && (
        <InsuranceForm
          productId={id}
          productCountryId={assetDetails.country_id}
          insuranceDetails={editFormName ? selectedActivity.values : {}}
          onClose={handleClose}
          onSubmit={handleSubmit}
        />
      )}

      {[addFormName, editFormName].includes(MAINTENANCE) && (
        <MaintenanceForm
          productId={id}
          productCountryId={assetDetails.country_id}
          maintenanceDetails={editFormName ? selectedActivity.values : {}}
          onClose={handleClose}
          onSubmit={handleSubmit}
        />
      )}

      {[addFormName, editFormName].includes(WARRANTY) && (
        <WarrantyForm
          productId={id}
          warrantyDetails={editFormName ? selectedActivity.values : {}}
          onClose={handleClose}
          onSubmit={handleSubmit}
        />
      )}
      {[editFormName].includes(RESERVATION) && (
        <ReservationForm
          productId={id}
          reservation={editFormName ? selectedActivity.values : {}}
          onClose={handleClose}
          onSubmit={handleSubmit}
          setCurrentReservation={setCurrentReservation}
        />
      )}

      {[PARENT, CHILD].includes(addFormName) && (
        <LinkingForm productId={id} relation={addFormName} onClose={handleClose} onSubmit={handleSubmit} />
      )}

      {/* View details */}

      {selectedActivity.model_name === WARRANTY && (
        <WarrantyDetails warranty={selectedActivity.values} onClose={onClose} onEdit={handleEdit} onDelete={onDelete} />
      )}

      {selectedActivity.model_name === INSURANCE && (
        <InsuranceDetails
          insurance={selectedActivity.values}
          onClose={onClose}
          onEdit={handleEdit}
          onDelete={onDelete}
        />
      )}
      {selectedActivity.model_name === RESERVATION && (
        <ReservationDetails
          reservation={selectedActivity.values}
          onClose={onClose}
          onEdit={handleEdit}
          onDelete={onDelete}
        />
      )}

      {selectedActivity.model_name === LINKED_PRODUCT && (
        <LikedProductDetails assetDetails={selectedActivity.values} onClose={onClose} onDelete={onDelete} />
      )}

      {selectedActivity.model_name === MAINTENANCE && (
        <MaintenanceDetails
          maintenance={selectedActivity.values}
          onClose={onClose}
          onEdit={handleEdit}
          onDelete={onDelete}
        />
      )}

      {selectedActivity.model_name === MAINTENANCE_REQUEST && (
        <MaintenanceRequestDetails maintenanceRequest={selectedActivity.values} onClose={onClose} />
      )}

      {selectedActivity.model_name === USER && (
        <UserHistoryDetails userHistory={selectedActivity.values} onClose={onClose} />
      )}

      {selectedActivity.model_name === STATUS_HISTORY && (
        <AssetStatusDetail
          status={selectedActivity.values.to_status}
          eventDetails={selectedActivity.values}
          onClose={onClose}
        />
      )}

      <ConfirmationCard
        isOpen={isConfirmationOpen}
        onClose={() => setIsConfirmationOpen(false)}
        onDelete={handleDelete}
      />

      {isDeleteToasterOpen && (
        <div className={styles.toasterId}>
          <DeleteToaster
            onClose={() => {
              handleDelete();
              setIsDeleteToasterOpen(false);
            }}
            message={t(`asset.assetDetail.activities.${lastDeletedModelName}DeleteSuccess`)}
            canUndo={false}
          />
        </div>
      )}
    </>
  );
};

export default AssetTimeLineProgress;
