import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Checkbox, Drawer } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { Button } from '@mui/material';
import { TextField } from '../../../components/Form/TextField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { axiosApi } from '../../../helpers/api_helper';
import { MailOutline } from '@mui/icons-material';
import { MobileField } from '../../../components/Form/MobileField';
import { AsYouType } from 'libphonenumber-js';
import { toast } from 'react-toastify';
import { UserSchema } from '../../../schemas/UserSchema';
import { faUserGroup } from '@fortawesome/free-solid-svg-icons';
import { scrollBoxShadowStyle, useScrollVerticle } from '../../../helpers/common_service';
import AddRole from '../../Role/AddRole';
import Dropdown from '../../../components/Form/Dropdown';
import app_form_style from '../../../assets/scss/_app.module.scss';
import form_field_style from '../../../assets/scss/_form_field.module.scss';
import button_style from '../../../assets/scss/_button.module.scss';
import styles from './index.module.scss';
import 'react-phone-input-2/lib/high-res.css';
import {
  AUTHORITY_LEVEL_BRANCH,
  AUTHORITY_LEVEL_COMPANY,
  hasCreateAccess,
  isUserCompanyAdmin,
  isUserSuperAdmin,
  MODEL_USER
} from '../../../helpers/access';

const UserForm = ({
  isNavigatedFromEventsForm = false,
  isPreFilledValues = {},
  open,
  details,
  onClose,
  onSubmit,
  onNewUserAdded = () => {}
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const editUser = Object.keys(details).length > 0;
  const loginUser = useSelector((state) => state.login.authUser);

  const [roles, setRoles] = useState([]);
  const [userData, setUserData] = useState({});
  const [branches, setBranches] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [prevSavedData, setPrevSavedData] = useState({});
  const [canSave, setCanSave] = useState(false);
  const [isToasterActive, setIsToasterActive] = useState(false);
  const [isRoleFormOpen, setIsRoleFormOpen] = useState(false);
  const [scrollTop, scrollBottom, scrollProps] = useScrollVerticle();

  const handleSubmit = (values) => {
    const formData = {
      user: {
        role: values.role,
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        mobile_number: values.mobile_number,
        company_id: values.company,
        branch_id: values.branch,
        is_email_subscribed: values.is_email_subscribed
        // is_telegram_subscribed: values.is_telegram_subscribed
      }
    };
    if (editUser) {
      axiosApi
        .patch(`/users/${userData.id}`, formData)
        .then((res) => {
          navigate('/user-list');
          if (userData.id === loginUser.id) dispatch({ type: 'LOGIN_USER_SUCCESS', payload: res.data });
          onSubmit();
          onClose();
          toast.success(t('user.addUser.userUpdatedSuccessfully'));
        })
        .catch((error) => {
          setIsToasterActive(true);
          toast.error(t('user.addUser.' + (error.response.data?.message_key ?? 'failedToUpdateUser')), {
            onClose: () => setIsToasterActive(false)
          });
        });
    } else {
      axiosApi
        .post('/users', { ...formData })
        .then((res) => {
          if (isNavigatedFromEventsForm) {
            onNewUserAdded({
              label: res.data.data.attributes.full_name,
              value: res.data.data.id
            });
          } else {
            navigate('/user-list');
          }
          onSubmit();
          onClose();
          toast.success(t('user.addUser.userCreatedSuccessfully'));
        })
        .catch((error) =>
          toast.error(t(`user.addUser.${error.response.data?.message_key ?? 'failedToCreateNewUser'}`))
        );
    }
  };

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      role: hasCreateAccess(MODEL_USER, AUTHORITY_LEVEL_BRANCH) ? 'employee' : '',
      email: '',
      mobile_number: '',
      company: isUserSuperAdmin() ? '' : loginUser.company_id,
      branch: hasCreateAccess(MODEL_USER, AUTHORITY_LEVEL_COMPANY) ? '' : loginUser.branch_id,
      is_email_subscribed: true
      // is_telegram_subscribed: false
    },
    onSubmit: handleSubmit,
    validationSchema: UserSchema(
      t('user.addUser.pleaseEnterFirstName'),
      t('user.addUser.pleaseEnterLastName'),
      t('user.addUser.pleaseSelectRole'),
      t('common.pleaseEnterEmail'),
      t('user.addUser.pleaseEnterMobile'),
      t('common.validation.pleaseSelectCompany'),
      t('common.validation.pleaseSelectBranch'),
      isUserSuperAdmin(),
      hasCreateAccess(MODEL_USER, AUTHORITY_LEVEL_COMPANY)
    )
  });

  const getCompanies = () => {
    axiosApi
      .get('/companies', { params: { is_dropdown: true } })
      .then((res) =>
        setCompanies(
          res.data.data.map((company) => ({
            label: company.attributes.name,
            value: company.attributes.id
          }))
        )
      )
      .catch((err) => console.log(err));
  };

  const getBranches = () => {
    axiosApi
      .get('/branches', {
        params: {
          company_id: isUserSuperAdmin() ? formik.values.company : loginUser['company_id'],
          is_dropdown: true
        }
      })
      .then((res) => {
        setBranches(
          res.data.data.map((branch) => ({
            label: branch.attributes.name,
            value: branch.id
          }))
        );
      })
      .catch((err) => console.log(err));
  };

  const invitiationHandler = () => {
    axiosApi
      .get(`/users/${userData.id}/resend_invitation_mail`)
      .then(() => toast.success(t('user.addUser.userResendInvitationSuccessfully')))
      .catch(() => toast.error(t('user.addUser.failedToResendUserInvitation')));
  };

  const getRoles = () => {
    let parameters = {};
    parameters['company_id'] = isUserSuperAdmin() ? formik.values.company : loginUser['company_id'];
    axiosApi
      .get('/roles', {
        params: parameters
      })
      .then((res) => {
        setRoles(
          res.data.data.map((role) => ({
            label: role.attributes.label,
            value: role.attributes.name
          }))
        );
      })
      .catch(() => {
        toast.error(t('user.addUser.failedToGetRoles'));
      });
  };

  const setValues = () => {
    let obj = {
      first_name: userData.first_name,
      last_name: userData.last_name,
      role: userData.role,
      email: userData.email,
      mobile_number: userData.mobile,
      company: userData.company_id,
      branch: userData.branch_id,
      is_email_subscribed: userData.is_email_subscribed
      // is_telegram_subscribed: userData.is_telegram_subscribed
    };
    formik.setValues(obj);
    setPrevSavedData(obj);
  };

  const handleAfterRoleCreate = (roleValue) => {
    getRoles();
    formik.setFieldValue('role', roleValue);
    setIsRoleFormOpen(false);
  };

  useEffect(() => {
    getCompanies();
    getBranches();
    getRoles();
  }, []);

  useEffect(() => setUserData(details), [details]);
  useEffect(() => {
    if (Object.keys(userData).length > 0) setValues();
    else formik.resetForm();
  }, [userData]);

  useEffect(() => {
    if (formik.values.company) {
      getBranches();
      getRoles();
    }
  }, [formik.values.company]);

  useEffect(() => {
    if (editUser) {
      if (JSON.stringify(prevSavedData) !== JSON.stringify(formik.values)) setCanSave(true);
      else setCanSave(false);
    }
  }, [prevSavedData, formik.values]);

  useEffect(() => {
    if (!isNavigatedFromEventsForm || !isPreFilledValues?.company_id || !isPreFilledValues?.branch_id) {
      return;
    }

    const shouldUpdate =
      formik.values.company !== isPreFilledValues.company_id || formik.values.branch !== isPreFilledValues.branch_id;

    if (shouldUpdate) {
      formik.setFieldValue('company', isPreFilledValues.company_id);
      formik.setFieldValue('branch', isPreFilledValues.branch_id);
    }
  }, [isNavigatedFromEventsForm, isPreFilledValues, formik.values.company, formik.values.branch]);

  // useEffect(() => {
  //   if (formik.values.is_telegram_subscribed && !userData.is_telegram_subscribed) {
  //     const handleTelegramVerification = () => {
  //       axiosApi
  //         .get('users/userData.id/create_telegram_subscription_url')
  //         .then((res) => {
  //           if (formik.values.is_telegram_subscribed)
  //             window.open(res.data.telegram_subscription_url, '_blank', 'noreferrer');
  //         })
  //         .catch(() => {
  //           toast.error(t('common.somethingWentsWrong'));
  //         });
  //     };
  //     handleTelegramVerification();
  //   }
  // }, [formik.values.is_telegram_subscribed]);

  return (
    <Drawer anchor="right" open={open} onClose={onClose} className={`${app_form_style.drawer} ${styles.drawer}`}>
      <div className={styles.headerContainer}>
        <div className={styles.header}>
          <FontAwesomeIcon icon={faUserGroup} className={app_form_style.solid_list_icon} />
          <h6 className={`${app_form_style.title_name} ${form_field_style.text_3xl}`}>
            {editUser ? t('user.addUser.editUser') : t('user.addUser.title')}
          </h6>
        </div>
        {editUser && (
          <button className={styles.resendMailButton} onClick={invitiationHandler}>
            <MailOutline />
            {t('user.addUser.resendInvite')}
          </button>
        )}
      </div>
      <form
        onSubmit={formik.handleSubmit}
        className={styles.form}
        {...scrollProps}
        style={{
          boxShadow: scrollBoxShadowStyle(scrollTop, scrollBottom),
          transition: 'box-shadow 0.3s',
          overflowY: 'scroll'
        }}>
        <>
          <div>
            <div className={`${app_form_style.grid_title_name} ${app_form_style.keyContainer}`}>
              <div>
                {t('common.firstName')}
                <span className={app_form_style.required}>*</span>
              </div>
            </div>
            <div className={app_form_style.valueContainer}>
              <TextField
                className={`${form_field_style.textField_prefix} ${form_field_style.TextField_style} ${app_form_style.TextField} `}
                name="first_name"
                fieldType="alphabet"
                value={formik.values.first_name}
                onChange={formik.handleChange}
                error={formik.touched.first_name && formik.errors.first_name}
              />
            </div>
          </div>
          <div>
            <div className={` ${app_form_style.grid_title_name} ${app_form_style.keyContainer}`}>
              <div>
                {t('common.lastName')}
                <span className={app_form_style.required}>*</span>
              </div>
            </div>
            <div className={app_form_style.valueContainer}>
              <TextField
                className={`${form_field_style.textField_prefix} ${form_field_style.TextField_style} ${app_form_style.TextField}`}
                name="last_name"
                fieldType="alphabet"
                value={formik.values.last_name}
                onChange={formik.handleChange}
                error={formik.touched.last_name && formik.errors.last_name}
              />
            </div>
          </div>
          <div>
            <div className={` ${app_form_style.grid_title_name} ${app_form_style.keyContainer}`}>
              {t('common.mobile')}
              <span className={app_form_style.required}>*</span>
            </div>
            <div className={app_form_style.valueContainer}>
              <MobileField
                className={`${form_field_style.textField_prefix} ${form_field_style.TextField_style} ${app_form_style.TextField}`}
                name="mobile_number"
                parent="user"
                country={'in'}
                value={formik.values.mobile_number}
                onChange={(phone, country) =>
                  formik.setFieldValue('mobile_number', new AsYouType(country.countryCode).input('+' + phone))
                }
                error={formik.touched.mobile_number && formik.errors.mobile_number}
              />
            </div>
          </div>
          <div>
            <div className={`${app_form_style.grid_title_name} ${app_form_style.keyContainer}`}>
              {t('common.email')}
              <span className={app_form_style.required}>*</span>
            </div>
            <div className={app_form_style.valueContainer}>
              <TextField
                className={`${form_field_style.textField_prefix} ${form_field_style.TextField_style} ${app_form_style.TextField}`}
                name="email"
                type="text"
                value={formik.values.email}
                onChange={(e) => {
                  const value = e.target.value || '';
                  formik.setFieldValue('email', value.toLowerCase());
                }}
                error={formik.touched.email && formik.errors.email}
                prefixIcon={<MailOutline className={styles.mailIcon} />}
                prefixIconClass={app_form_style.icon_container}
              />
            </div>
          </div>
          <div className={app_form_style.valueContainer}>
            <Checkbox
              name={t('user.subscribeToEmail')}
              onChange={(e) => formik.setFieldValue('is_email_subscribed', e.target.checked)}
              checked={formik.values.is_email_subscribed}
              className={styles.checkBox}
            />
            <span>{t('user.subscribeToEmail')}</span>
          </div>
          {/* {editUser && (
            <div className={app_form_style.valueContainer}>
              <div className="form-group">
                <span className="font-weight-normal">
                  <Checkbox
                    name={t('user.subscribeToTelegram')}
                    onChange={(e) => formik.setFieldValue('is_telegram_subscribed', e.target.checked)}
                    checked={formik.values.is_telegram_subscribed}
                    className={styles.checkBox}
                  />
                  <span>{t('user.subscribeToTelegram')}</span>
                </span>
              </div>
            </div>
          )} */}
          {isUserSuperAdmin() && (
            <div>
              <div className={`${app_form_style.grid_title_name} ${app_form_style.keyContainer}`}>
                {t('common.company')} &nbsp;
                <span className={app_form_style.required}></span>
              </div>
              <div className={app_form_style.valueContainer}>
                <Dropdown
                  disabled={!isUserSuperAdmin()}
                  name="company"
                  type="text"
                  placeholder={t('common.selectCompany')}
                  value={formik.values.company}
                  onChange={formik.handleChange}
                  dataList={companies}
                  error={formik.touched.company && formik.errors.company}
                />
              </div>
            </div>
          )}
          <div>
            <div className={`${app_form_style.grid_title_name} ${app_form_style.keyContainer}`}>
              {t('common.branch')} &nbsp;
              <span className={app_form_style.required}></span>
            </div>
            <div className={app_form_style.valueContainer}>
              <Dropdown
                disabled={!hasCreateAccess(MODEL_USER, AUTHORITY_LEVEL_COMPANY)}
                name="branch"
                type="text"
                placeholder={t('common.selectBranch')}
                value={formik.values.branch}
                onChange={formik.handleChange}
                dataList={branches}
                error={formik.touched.branch && formik.errors.branch}
              />
            </div>
          </div>
          <div>
            <div className={`${app_form_style.grid_title_name} ${app_form_style.keyContainer}`}>
              {t('common.role')} <span className={app_form_style.required}>*</span>
            </div>
            <div className={styles.roleContainer}>
              <div className={app_form_style.valueContainer}>
                <Dropdown
                  name="role"
                  type="text"
                  placeholder="Select Role"
                  value={formik.values.role}
                  onChange={formik.handleChange}
                  dataList={roles}
                  error={formik.touched.role && formik.errors.role}
                />
              </div>
              {isUserCompanyAdmin() && (
                <Button
                  // making disable for product (temporarily)
                  disabled
                  variant="contained"
                  className={`${button_style.btn} ${styles.addRoleButton}`}
                  onClick={() => setIsRoleFormOpen(true)}>
                  <span>{t('add')}</span>
                </Button>
              )}
            </div>
          </div>
        </>
      </form>
      <div className={styles.bottomFixed}>
        <Button
          disabled={editUser && (!canSave || isToasterActive)}
          variant="contained"
          className={` ${button_style.save_button} ${button_style.btn} ${
            editUser && (!canSave || isToasterActive) ? button_style.disabled_btn : ''
          }`}
          onClick={formik.handleSubmit}>
          <span>{editUser ? t('save') : t('create')}</span>
        </Button>
        <Button
          type="button"
          variant="contained"
          className={`${button_style.cancel_button} ${button_style.btn}`}
          onClick={onClose}>
          <span>{t('cancel')}</span>
        </Button>
      </div>
      {isRoleFormOpen && <AddRole onClose={() => setIsRoleFormOpen(false)} afterSubmit={handleAfterRoleCreate} />}
    </Drawer>
  );
};

export default UserForm;
