import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Paper } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SettingsOutlined, FileCopy } from '@mui/icons-material';
// import { nanoid } from 'nanoid';
import CustomReport from './CustomReport';
import SetupColumn from './SetupColumn';
import appStyles from '../../assets/scss/_app.module.scss';
import buttonStyle from '../../assets/scss/_button.module.scss';
import styles from './reports.module.scss';
import { SET_FILTERS } from '../../store/table/actionTypes';
import { SET_MODAL_FILTER } from '../../store/filterModal/actionTypes';
import { axiosApi } from '../../helpers/api_helper';
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons';
import Dropdown from '../../components/Form/Dropdown';
import { toast } from 'react-toastify';

const Reports = () => {
  const { t } = useTranslation();
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  // const location = useLocation();
  const [isLoaded, setIsLoaded] = useState(false);
  // const [currentTab, setCurrentTab] = useState('');
  const [isSetupOpen, setIsSetupOpen] = useState(false);
  const reportColumns = useSelector((state) => state.table.reportTableColumns);
  const downloadType = [
    { value: 'pdf', label: 'As PDF' },
    { value: 'csv', label: 'As CSV' }
  ];
  // const tabs = [{ key: nanoid(), id: 'custom-reports', value: 'Custom Reports' }];

  const companyId = useSelector((state) => state.common.companyId);
  const branchId = useSelector((state) => state.common.branchId);

  const downloadReport = (fileType) => {
    let parameters = {
      filter: {
        company: companyId,
        branch: branchId
      },
      header: reportColumns.map((column) => {
        return column.id;
      })
    };
    axiosApi
      .get(`products/report_download?download_format=${fileType}`, {
        responseType: 'blob',
        params: parameters
      })
      .then((res) => {
        const url = window.URL.createObjectURL(res.data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `report.${fileType}`);
        document.body.appendChild(link);
        link.click();
        // toast.success(t('report.reportDownloaded'));
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.message ? t(`report.${err?.response?.data?.message}`) : t('common.somethingWentsWrong')
        );
      });
  };

  useEffect(() => {
    dispatch({
      type: SET_FILTERS,
      companyId: companyId,
      branchId: branchId
    });
  }, [companyId, branchId]);

  useEffect(() => {
    return () => {
      dispatch({
        type: SET_MODAL_FILTER,
        pageName: ''
      });
      dispatch({
        type: SET_FILTERS,
        companyId: '',
        branchId: ''
      });
    };
  }, []);

  return (
    <Paper elevation={0} className={`${appStyles.flexHeightAuto} ${!isLoaded && appStyles.h_100}`}>
      <div className={`${appStyles.header_container} ${styles.header}`}>
        <span className={styles.title}>
          <FileCopy className={appStyles.solid_list_icon} />
          <h6 className={`${appStyles.title_name} ${styles.text_3xl}`}>{t('navbarItems.reports')}</h6>
        </span>
        <div className={styles.filtersContainer}>
          <div className={styles.action_btns}>
            <Dropdown
              icon={<FontAwesomeIcon icon={faFileArrowDown} />}
              type="text"
              isFilter={true}
              value=""
              dataList={downloadType}
              placeholder={t('table.tableList.downloadReport')}
              className={styles.more_action}
              onChange={(e) => {
                downloadReport(e.target.value);
              }}
            />
          </div>
          <Button
            classes={{ root: styles.hoverEffectRoot }}
            className={`${buttonStyle.filter_btn} ${styles.setupButton}`}
            onClick={() => setIsSetupOpen(true)}>
            <SettingsOutlined fontSize="medium" />
            <span>{t('report.setupColumns')}</span>
          </Button>
        </div>
      </div>
      {/* <div className={styles.tabHeader}>
        {tabs.map((tab) => {
          return (
            <span
              key={tab.key}
              className={currentTab === tab.id ? styles.selectedTab : ''}
              onClick={() => {
                navigate({ search: `?tab=${tab.id}` });
                setIsLoaded(false);
              }}>
              {tab.value}
            </span>
          );
        })}
      </div> */}
      <div className={styles.tabContainer}>
        {/* {currentTab === 'custom-reports' && ( */}
        <CustomReport
          beforeFetch={() => setIsLoaded(false)}
          afterFetch={() => setIsLoaded(true)}
          filters={(companyId, branchId)}
        />
        {/* )} */}
      </div>
      <SetupColumn isOpen={isSetupOpen} onClose={() => setIsSetupOpen(false)} />
    </Paper>
  );
};

export default Reports;
