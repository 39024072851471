import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { axiosApi } from '../../helpers/api_helper';
import { getDateString } from '../../helpers/common_service';
import { CHECK_OUT } from '../../helpers/constants';
import Table from '../../components/Table';
import ShowDetails from '../../components/ShowDetail';
import CheckOutForm from '../AssetDetail/Events/CheckOut';
import { useSelector } from 'react-redux';
import { hasCreateAccess, MODEL_PRODUCT } from '../../helpers/access';

const CheckOut = ({ beforeFetch, afterFetch, fetchCount }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [paginateDetails, setPaginateDetails] = useState({});
  const [paginateFilters, setPaginateFilters] = useState({});
  const [expiringCheckOutAssets, setExpiringCheckOutAssets] = useState([]);
  const [selectedCheckOutAsset, setSelectedCheckOutAsset] = useState({});
  const [showCheckOutDetail, setShowCheckOutDetail] = useState(false);
  const [showCheckOutEditForm, setShowCheckoutEditForm] = useState(false);
  const companyId = useSelector((state) => state.common.companyId);
  const branchId = useSelector((state) => state.common.branchId);

  const tabItems = [
    {
      id: nanoid(),
      key: 'checkOut',
      value: t('asset.assetDetail.moreAction.checkOut.checkOutDetails')
    },
    { id: nanoid(), key: 'asset', value: t('asset.assetDetail.title') }
  ];

  const [currentTab, setCurrentTab] = useState(tabItems[0].key);

  const headCells = [
    {
      id: 'asset_name',
      label: t('asset.general.assetName'),
      hasViewLink: true
    },
    {
      id: 'tag_id',
      label: t('asset.general.tagId')
    },
    {
      id: 'due_date_display',
      label: t('asset.general.endDate')
    },
    {
      id: 'assigned_to',
      label: t('asset.general.assignedTo')
    },
    {
      id: 'action',
      label: t('common.action'),
      width: '10%'
    }
  ];

  const checkOutInfo = [
    {
      key: nanoid(),
      label: t('common.company'),
      value: selectedCheckOutAsset.company_name
    },
    {
      key: nanoid(),
      label: t('common.branch'),
      value: selectedCheckOutAsset.branch_name
    },
    {
      key: nanoid(),
      label: t('asset.general.assignedTo'),
      value: selectedCheckOutAsset.assigned_to
    },
    {
      key: nanoid(),
      label: t('asset.assetDetail.moreAction.checkOut.assignedDate'),
      value: getDateString(selectedCheckOutAsset?.status_info?.checkOutDate)
    },
    {
      key: nanoid(),
      label: t('asset.assetDetail.moreAction.checkOut.dueToDate'),
      value: getDateString(selectedCheckOutAsset?.status_info?.dueToDate)
    },
    {
      key: nanoid(),
      label: t('common.email'),
      value: selectedCheckOutAsset?.status_info?.email
    },
    {
      key: nanoid(),
      label: t('asset.assetDetail.moreAction.notes'),
      value: selectedCheckOutAsset?.status_info?.notes
    }
  ];

  const assetInfo = [
    {
      key: nanoid(),
      label: t('asset.general.tagId'),
      value: selectedCheckOutAsset.tag_id
    },
    {
      key: nanoid(),
      label: t('asset.general.assetName'),
      value: selectedCheckOutAsset.asset_name
    },
    {
      key: nanoid(),
      label: t('asset.general.model'),
      value: selectedCheckOutAsset.model
    },
    {
      key: nanoid(),
      label: t('asset.general.serialNumber'),
      value: selectedCheckOutAsset.serial_number
    },
    {
      key: nanoid(),
      label: t('asset.general.cost'),
      value: selectedCheckOutAsset.purchase_price
    }
  ];

  const handleView = (selectedAssetId) => {
    setSelectedCheckOutAsset(expiringCheckOutAssets.find((asset) => asset.id === selectedAssetId));
    setShowCheckOutDetail(true);
  };

  const handleEdit = (selectedAssetId) => {
    setSelectedCheckOutAsset(expiringCheckOutAssets.find((asset) => asset.id === selectedAssetId));
    setShowCheckoutEditForm(true);
  };

  const handleShowDetailClose = () => {
    setShowCheckOutDetail(false);
    setCurrentTab(tabItems[0].key);
  };

  const handleMoreDetailNavigate = () => {
    navigate(currentTab === 'asset' ? `/inventory-details/${selectedCheckOutAsset.id}` : '');
  };

  const handleUpdate = (values) => {
    let status_info = { ...values };
    if (status_info.user !== undefined && typeof status_info.user === 'object')
      status_info.user = status_info.user.value;

    setIsLoading(true);
    beforeFetch();
    axiosApi
      .put(`/products/${selectedCheckOutAsset.id}/update_product_status`, {
        status: CHECK_OUT,
        status_info
      })
      .then(() => {
        setShowCheckoutEditForm(false);
        toast.success(t(`asset.assetDetail.moreAction.checkOut.update`));
        fetchExpiringCheckouts();
      })
      .catch((err) =>
        toast.error(t(err.response?.data?.error?.base ?? `asset.assetDetail.moreAction.checkOut.failedUpdate`))
      )
      .finally(() => setIsLoading(false));
  };

  const fetchExpiringCheckouts = () => {
    let params = {
      filter: { company_id: companyId, branch: branchId }
    };
    if (paginateFilters?.currentPage) params['page'] = paginateFilters.currentPage;
    if (paginateFilters?.rowsPerPage) params['items'] = paginateFilters.rowsPerPage;

    setIsLoading(true);
    beforeFetch();
    axiosApi
      .get('/products/expiring_checkouts', { params })
      .then((res) => {
        setExpiringCheckOutAssets(
          res.data.data.map((data) => ({
            ...data.attributes,
            due_date_display: getDateString(data.attributes.due_date)
          }))
        );
        setPaginateDetails({
          rowsCount: res.data.meta.total_count,
          pagesCount: res.data.meta.total_pages
        });
        if (res.data.data.length) afterFetch();
        fetchCount();
      })
      .catch((err) =>
        toast.error(t(err.response?.data?.error?.base ?? `asset.assetDetail.moreAction.checkOut.fetchFailed`))
      )
      .finally(() => setIsLoading(false));
  };

  useEffect(fetchExpiringCheckouts, [paginateFilters, companyId, branchId]);

  return (
    <>
      <Table
        headCells={headCells}
        rows={expiringCheckOutAssets}
        showPagination={true}
        editBtn={hasCreateAccess(MODEL_PRODUCT)}
        viewBtnFn={handleView}
        editBtnFn={handleEdit}
        onChange={(values) => setPaginateFilters(values)}
        totalRowsCount={paginateDetails.rowsCount}
        totalPagesCount={paginateDetails.pagesCount}
        loading={isLoading}
        deleteBtn={false}
        filterOptions={(companyId, branchId)}
      />
      {showCheckOutDetail && (
        <ShowDetails
          detailFor={'Details'}
          details={currentTab === 'checkOut' ? checkOutInfo : assetInfo}
          moreDetailsFn={currentTab === 'asset' && handleMoreDetailNavigate}
          tabItems={tabItems}
          currentTab={currentTab}
          handleTabChange={(value) => setCurrentTab(value)}
          onClose={handleShowDetailClose}
        />
      )}
      {showCheckOutEditForm && (
        <CheckOutForm
          eventDetails={selectedCheckOutAsset.status_info}
          assetId={selectedCheckOutAsset.id}
          assetData={selectedCheckOutAsset}
          onClose={() => setShowCheckoutEditForm(false)}
          onSubmit={handleUpdate}
        />
      )}
    </>
  );
};

export default CheckOut;
