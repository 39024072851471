import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { nanoid } from 'nanoid';
import { axiosApi } from '../../helpers/api_helper';
import { feedbackPage } from '../../helpers/constants';
import TableList from '../../components/TableList';

const Feedbacks = () => {
  const { t } = useTranslation();
  const [filters, setFilters] = useState({});
  const [paginateDetails, setPaginateDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [feedbackTypes, setFeedbackTypes] = useState([]);
  const [feedbacks, setFeedbacks] = useState([]);
  const companyId = useSelector((state) => state.common.companyId);
  const branchId = useSelector((state) => state.common.branchId);

  const feedbackHeadCells = [
    {
      id: 'user_name',
      label: t('name')
    },
    {
      id: 'user_email',
      label: t('common.email')
    },
    {
      id: 'content',
      label: t('feedback.title')
    },
    {
      id: 'action',
      label: t('common.action')
    }
  ];

  const fetchFeedbacks = () => {
    if (!filters.customFilter) return;
    let params = {
      company: companyId,
      branch: branchId,
      search: filters.searchText,
      feedback_type: filters.customFilter,
      page: filters.currentPage,
      items: filters.rowsPerPage
    };

    setIsLoading(true);
    axiosApi
      .get('/feedbacks', { params })
      .then((res) => {
        setFeedbacks(res.data.data.map((feedback) => feedback.attributes));
        setPaginateDetails(res.data.meta);
      })
      .catch(() => toast.error(t('common.somethingWentsWrong')))
      .finally(() => setIsLoading(false));
  };

  const getFeedbackTypes = () => {
    axiosApi
      .get('/feedbacks/feedback_types')
      .then((res) =>
        setFeedbackTypes(res.data.map((type) => ({ key: nanoid(), label: t(`feedback.types.${type}`), value: type })))
      )
      .catch(() => toast.error(t('common.somethingWentsWrong')));
  };

  useEffect(() => getFeedbackTypes(), []);
  useEffect(() => fetchFeedbacks(), [filters]);

  return (
    <TableList
      pageName={feedbackPage}
      headCells={feedbackHeadCells}
      rows={feedbacks}
      deleteBtnLink="feedbacks"
      onDelete={fetchFeedbacks}
      editBtn={false}
      viewBtn={false}
      filterOptionsChange={(values) => setFilters((prevState) => ({ ...prevState, ...values }))}
      loading={isLoading}
      onChange={(values) => setFilters((prevState) => ({ ...prevState, ...values }))}
      totalRowsCount={paginateDetails.total_count}
      totalPagesCount={paginateDetails.total_pages}
      canUndo={false}
      filterDropdownLabel={t('feedback.type')}
      filterDropdownOptions={feedbackTypes}
    />
  );
};

export default Feedbacks;
