import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { Dialog, Button, Box, Grid, FormControl, FormControlLabel, RadioGroup, Radio, Checkbox } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { TextField } from '../Form/TextField';
import { axiosApi } from '../../helpers/api_helper';
import DropDown from '../Form/Dropdown';
import DatePicker from '../Form/DatePicker';
import AutoComplete from '../Form/AutoComplete';
import Attachments from '../Attachments';
import appStyles from '../../assets/scss/_app.module.scss';
import formStyles from '../../assets/scss/_form_field.module.scss';
import buttonStyles from '../../assets/scss/_button.module.scss';
import styles from './modalForm.module.scss';
import variables from '../../assets/scss/_app.scss';
import ConfirmationCard from '../ConfirmationCard';
import { changePasswordPage, DATE_FILTER_TODAY, END_TIME } from '../../helpers/constants';
import { IRREVERSIBLE_STATUSES } from '../../helpers/constants';
import { toast } from 'react-toastify';
import CurrencyField from '../Form/CurrencyField';
import { Icon } from 'react-icons-kit';
import { TextField as PasswordTextField } from '@mui/material';

const ModalForm = ({
  isComponentModalOpen = false,
  isNewUserAdded = false,
  setIsNewUserAdded = () => {},
  className,
  isToasterActive,
  preSavedData,
  formFor,
  formTitle,
  titleIcon,
  isEditForm,
  initialValues,
  fields,
  onSubmit,
  submitBtnText,
  onClose,
  onValuesChange,
  validationSchema,
  onUpdateAttachments,
  allowDelete,
  onDelete,
  scheduledStatus
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const windowWidth = parseInt(variables.maxMobile);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const [windowWidth900, setWindowWidth] = useState(window.innerWidth);
  const [isAnotherModelOpen, setIsAnotherModalOpen] = useState(false);

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema
  });

  const maintenanceFormName = 'Add Maintenance';

  const checkFieldValue = (fieldName, value, isAutoComplete) => {
    if ((formFor === 'checkOut' || 'lease') && fieldName === 'user') {
      axiosApi
        .get(`users/${isAutoComplete ? value.value : value}`)
        .then((res) => formik.setFieldValue('email', res.data.data.attributes.email))
        .catch(() => toast.error(t('user.addUser.failedToGetUserData')));
      formik.setFieldValue(fieldName, value);
    } else {
      if (fieldName === 'company') {
        formik.setFieldValue('branch', '');
        formik.setFieldValue('user', '');
      } else if (fieldName === 'branch') {
        formik.setFieldValue('user', '');
      }

      formik.setFieldValue(fieldName, value);
    }
  };

  const dropDownField = (fieldName, values, disabled = false) => {
    return (
      <>
        <DropDown
          disabled={disabled}
          name={fieldName}
          type="text"
          value={formik.values[`${fieldName}`] === null ? '' : formik.values[`${fieldName}`]}
          onChange={(e) => checkFieldValue(fieldName, e.target.value, false)}
          dataList={values}
          placeholder={t(`common.select${fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}`)}
          error={formik.touched[`${fieldName}`] && formik.errors[`${fieldName}`]}
        />
      </>
    );
  };

  const autoCompleteField = (fieldName, options, disabled = false) => {
    return (
      <>
        <AutoComplete
          disabled={disabled}
          options={options}
          value={formik.values[`${fieldName}`]}
          onChange={(_, option) => checkFieldValue(fieldName, option, true)}
        />
        {formik.touched[`${fieldName}`] && formik.errors[`${fieldName}`] && (
          <span className={styles.error_msg}>{formik.errors[`${fieldName}`]}</span>
        )}
      </>
    );
  };

  const PasswordField = (fieldName, passwordType, passwordIcon, handlePasswordToggle, canShowPassword = true) => {
    return (
      <>
        <PasswordTextField
          className={`${appStyles.TextField} ${formStyles.TextField_style} ${styles.passwordField} ${
            !canShowPassword && styles.iconlessPasswordField
          }`}
          type={canShowPassword ? passwordType : 'password'}
          name={fieldName}
          value={formik.values[`${fieldName}`]}
          onChange={formik.handleChange}
          variant="outlined"
          InputProps={{
            autoComplete: 'off',
            endAdornment: (
              <span
                id={fieldName}
                className={styles.eye}
                onClick={() => canShowPassword && handlePasswordToggle(fieldName)}>
                {canShowPassword && <Icon icon={passwordIcon} size={20} />}
              </span>
            ),
            inputProps: {
              autoComplete: 'new-password'
            }
          }}
        />
        {formik.touched[`${fieldName}`] && formik.errors[`${fieldName}`] && (
          <div className={styles.error_msg}>{formik.errors[`${fieldName}`]}</div>
        )}
      </>
    );
  };

  const textField = (fieldName, type, prefixIcon, suffixText, disabled = false) => {
    let isTextFieldDisabled =
      formik.values.isSendMail !== undefined &&
      !formik.values.isSendMail &&
      IRREVERSIBLE_STATUSES.includes(formFor) &&
      fieldName === 'email';

    return (
      <>
        <TextField
          disabled={disabled || isTextFieldDisabled}
          className={`${appStyles.TextField} ${formStyles.TextField_style} ${
            isTextFieldDisabled && styles.disabled_text_field
          }`}
          autoComplete="off"
          name={fieldName}
          fieldType={type || 'specialChar'}
          value={formik.values[`${fieldName}`]}
          onChange={formik.handleChange}
          prefixIcon={
            prefixIcon ? <FontAwesomeIcon icon={prefixIcon} className={`${formStyles.prefix_icon_style}`} /> : null
          }
          prefixIconClass={`${appStyles.icon_container} ${styles.icon}`}
          suffixText={suffixText || null}
          suffixTextClass={styles.suffixTextClass}
        />
        {formik.touched[`${fieldName}`] && formik.errors[`${fieldName}`] && (
          <span className={styles.error_msg}>{formik.errors[`${fieldName}`]}</span>
        )}
      </>
    );
  };

  const currencyField = (selectorName, textFieldName, menuOpen) => {
    return (
      <>
        <CurrencyField
          menuOpen={menuOpen}
          selectorName={selectorName}
          textFieldName={textFieldName}
          selectorValue={formik.values[`${selectorName}`]}
          textFieldValue={formik.values[`${textFieldName}`]}
          onValueChange={formik.handleChange}
          onCountryChange={(value) => formik.setFieldValue(`${selectorName}`, value)}
        />
        {formik.touched[`${textFieldName}`] && formik.errors[`${textFieldName}`] && (
          <div className={styles.error_msg}>{formik.errors[`${textFieldName}`]}</div>
        )}
      </>
    );
  };

  const dateField = (
    fieldName,
    disabled = false,
    minDate = null,
    maxDate = null,
    disablePast = false,
    disableFuture = false
  ) => {
    const expiringDateTimeHandler = (fieldName, value) => {
      if (END_TIME.includes(fieldName)) {
        value.$d.setHours(23, 59, 59, 0);
      } else if (DATE_FILTER_TODAY.includes(fieldName)) {
        if (value.$d.toDateString() === new Date().toDateString()) {
          const date = new Date();
          value.$d.setHours(date.getHours(), date.getMinutes(), 0, 0);
        } else {
          const date = new Date();
          value.$d.setTime(value.$d.getTime() - date.getTimezoneOffset() * 60 * 1000);
        }
      }
      return value;
    };

    return (
      <>
        <DatePicker
          disabled={disabled}
          disablePast={disablePast}
          disableFuture={disableFuture}
          onChange={(value) => {
            if (value) {
              formik.setFieldValue(fieldName, expiringDateTimeHandler(fieldName, value));
            } else {
              formik.setFieldValue(fieldName, '');
            }
          }}
          value={formik.values[`${fieldName}`]}
          minDate={minDate}
          maxDate={maxDate}
          className={disabled && styles.disabled_datepicker}
        />
        {formik.touched[`${fieldName}`] && formik.errors[`${fieldName}`] && (
          <div className={styles.error_msg}>{formik.errors[`${fieldName}`]}</div>
        )}
      </>
    );
  };

  const textAreaField = (fieldName) => {
    return (
      <>
        <textarea
          value={formik.values[`${fieldName}`]}
          name={fieldName}
          rows="4"
          onChange={formik.handleChange}
          className={styles.text_area}></textarea>
        {formik.touched[`${fieldName}`] && formik.errors[`${fieldName}`] && (
          <div className={styles.error_msg}>{formik.errors[`${fieldName}`]}</div>
        )}
      </>
    );
  };

  const checkBox = (fieldName) => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            name={fieldName}
            className={styles.checkBox}
            onChange={formik.handleChange}
            checked={formik.values.isSendMail}
          />
        }
        label={t(`asset.assetDetail.moreAction.${fieldName}`)}
      />
    );
  };

  const radioField = (fieldName, defaultValue, values) => {
    return (
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name={fieldName}
          onChange={formik.handleChange}
          defaultValue={defaultValue}>
          {values.map((value) => (
            <FormControlLabel
              key={value.id}
              value={value.label}
              control={<Radio />}
              label={value.label}
              className={styles.radio_btn}
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  };

  const attachmentUpload = (attachments) => {
    return (
      <div className={styles.attachment_container}>
        <div className={styles.attachment_title}>
          <FontAwesomeIcon icon={faPaperclip} className={`${styles.title_icon}`} size="lg" />
          <span className={`${appStyles.header_container} ${styles.text_3xl}`}>{t('common.attachments')}</span>
        </div>
        <div className={`${appStyles.upload_image_container}`}>
          <Attachments onAttachmentsChange={onUpdateAttachments} existingAttachments={attachments} />
        </div>
      </div>
    );
  };

  useEffect(
    () => onValuesChange(formik.values),
    [
      formik.values.company,
      formik.values.branch,
      formik.values.user,
      formik.values.checkOutDate,
      formik.values.leaseBegins,
      formik.values.start_date,
      formik.values.due_date,
      formik.values.from_date,
      formik.values.to_date
    ]
  );

  useEffect(() => {
    if (isEditForm) {
      if (JSON.stringify(preSavedData) !== JSON.stringify(formik.values)) setCanSave(true);
      else setCanSave(false);
    }
  }, [formik.values, formik.submitCount]);

  useEffect(() => {
    function handleWindowResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (isAnotherModelOpen && !isComponentModalOpen) setIsAnotherModalOpen(false);
  }, [isComponentModalOpen]);

  useEffect(() => {
    if (isNewUserAdded) {
      formik.setValues(initialValues);
      setIsNewUserAdded(false);
    }
  }, [isNewUserAdded]);

  return (
    <Dialog
      open={true}
      onClose={onClose}
      classes={{
        root: isAnotherModelOpen ? styles.drawerRoot : ''
      }}>
      <Box
        className={`${styles.card}  ${formTitle === maintenanceFormName && styles.maintenance} ${
          formFor === changePasswordPage ? styles.changePassword : ''
        } ${className}`}>
        <div className={styles.header}>
          <div className={`${appStyles.header_container} ${styles.text_3xl}`}>
            {titleIcon}
            {formTitle}
          </div>
          <hr className={styles.horizontal_line} />
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onValuesChange(formik.values);
            formik.handleSubmit();
          }}>
          <Grid
            container
            rowSpacing={window.innerWidth < windowWidth ? 2 : 3}
            columnSpacing={{ sm: 2, md: 3 }}
            className={`${appStyles.grid_container} ${styles.grid_container}`}>
            {fields.map((field) => {
              return (
                <React.Fragment key={field.id}>
                  {field.type !== 'attachment' && (
                    <>
                      <Grid
                        item
                        xs={12}
                        sm={3}
                        md={field.label_size || 3}
                        paddingLeft={windowWidth900 > 900 ? field.paddingLeft || 0 : {}}
                        className={`${appStyles.grid_title_name} ${styles.grid_field_name_container}`}>
                        <span className={styles.grid_field_name}>
                          {field.label}
                          {field.isRequired && <span className={appStyles.required}>*</span>}
                        </span>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        padding={window.innerWidth < windowWidth && '0 !important'}
                        sm={field?.addLink ? 6 : 9}
                        md={field?.addLink ? field.field_size || 7 : field.field_size || 9}
                        className={styles.grid_textField}>
                        {field.type === 'dropDown' && dropDownField(field.name, field.values, field.disabled)}

                        {field.type === 'autoComplete' && autoCompleteField(field.name, field.values, field.disabled)}

                        {field.type === 'date' &&
                          dateField(
                            field.name,
                            field.disabled,
                            field.minDate,
                            field.maxDate,
                            field.disablePast,
                            field.disableFuture
                          )}

                        {field.type === 'text' &&
                          textField(field.name, field.inputType, field.prefixIcon, field.suffixText, field.disabled)}

                        {field.type === 'currency' &&
                          currencyField(field.selectorName, field.textFieldName, field.menuOpen)}

                        {field.type === 'textArea' && textAreaField(field.name)}

                        {field.type === 'checkBox' && checkBox(field.name)}

                        {field.type === 'radio' && radioField(field.name, field.defaultValue, field.values)}

                        {field.type === 'password' &&
                          PasswordField(
                            field.name,
                            field.passwordType,
                            field.passwordIcon,
                            field.handleToggle,
                            field.canShowPassword
                          )}
                      </Grid>
                    </>
                  )}
                  {field?.addLink && (
                    <Grid item xs={12} sm={3} md={2}>
                      <Button
                        disabled={field.disabled}
                        variant="contained"
                        className={`${buttonStyles.btn} ${styles.btn} ${field.disabled && styles.disabled_btn}`}
                        onClick={() =>
                          navigate(field.addLink, {
                            state: {
                              previousFormValues: formik.values,
                              status: formFor,
                              assetId: id
                            }
                          })
                        }>
                        <span>{t('add')}</span>
                      </Button>
                    </Grid>
                  )}
                  {field?.addModel && (
                    <Grid item xs={12} sm={3} md={2}>
                      <Button
                        disabled={field.disabled}
                        variant="contained"
                        className={`${buttonStyles.btn} ${styles.btn} ${field.disabled && styles.disabled_btn}`}
                        onClick={() => {
                          setIsAnotherModalOpen(true);
                          field.addModel();
                        }}>
                        <span>{t('add')}</span>
                      </Button>
                    </Grid>
                  )}
                  {field.type === 'attachment' && attachmentUpload(field.attachments)}
                </React.Fragment>
              );
            })}
          </Grid>
          <hr className={styles.horizontal_line} />
          <div className={`${styles.buttons_container}`}>
            <div className={`${buttonStyles.buttons_container}`}>
              {(scheduledStatus || isEditForm) && allowDelete && (
                <Button
                  variant="contained"
                  className={`${buttonStyles.delete_btn}`}
                  onClick={() => setIsDeleteConfirmOpen(true)}>
                  {t('delete')}
                </Button>
              )}
            </div>
            <div className={`${buttonStyles.buttons_container}`}>
              <Button
                disabled={isEditForm && (!canSave || isToasterActive)}
                type="submit"
                variant="contained"
                className={`${buttonStyles.save_button} ${buttonStyles.btn} ${buttonStyles.mobile_button_width} ${
                  isEditForm && (!canSave || isToasterActive) ? buttonStyles.disabled_btn : ''
                }`}>
                <span>{t(`${scheduledStatus || isEditForm ? 'update' : submitBtnText || 'add'}`)}</span>
              </Button>
              <Button
                type="button"
                variant="contained"
                className={`${buttonStyles.cancel_button} ${buttonStyles.btn} ${buttonStyles.mobile_button_width}`}
                onClick={onClose}>
                <span>{t('cancel')}</span>
              </Button>
            </div>
            <ConfirmationCard
              isOpen={isDeleteConfirmOpen}
              onClose={() => setIsDeleteConfirmOpen(false)}
              onDelete={onDelete}
            />
          </div>
        </form>
      </Box>
    </Dialog>
  );
};

export default ModalForm;
