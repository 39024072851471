import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseIcon from '@mui/icons-material/Close';
import styles from './deleteToaster.module.scss';

const DeleteToaster = ({
  deletedItemId,
  isDeleteOnClose,
  setLastlyDeletedItemId = () => {},
  message,
  canUndo = false,
  handleUndoDelete = () => {},
  onClose,
  setToasterDeleteId = () => {}
}) => {
  const { t } = useTranslation();
  let deleteOnClose = true;

  useEffect(() => {
    setToasterDeleteId(deletedItemId);
  }, []);

  // function to return deleteItemId to parent component

  return (
    <>
      {toast(
        <span>
          <FontAwesomeIcon icon={faTrashCan} className={styles.toast_delete_icon} />
          {message}
        </span>,
        {
          className: styles.toast_failure,
          pauseOnHover: true,
          progressClassName: styles.toast_failure_progressbar,
          onClose: () => {
            canUndo && isDeleteOnClose && setLastlyDeletedItemId(null);
            toast.dismiss();
            onClose(deleteOnClose);
          },
          closeButton: (
            <>
              {canUndo && (
                <button
                  onClick={() => {
                    deleteOnClose = false;
                    setToasterDeleteId(null);
                    onClose(deleteOnClose);
                    handleUndoDelete(deletedItemId);
                  }}
                  className={styles.undo_button}>
                  {t('common.undo')}
                </button>
              )}

              <CloseIcon
                className={styles.close_icon}
                onClick={() => {
                  toast.dismiss();
                }}
              />
            </>
          )
        }
      )}
    </>
  );
};

export default DeleteToaster;
