import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRecycle } from '@fortawesome/free-solid-svg-icons';
import { DisposeSchema } from '../../../schemas/AssetEventsSchema';
import ModalForm from '../../../components/ModalForm';
import appStyles from '../../../assets/scss/_app.module.scss';
import AlertCard from '../../../components/AlertCard';
import { DISPOSE } from '../../../helpers/constants';

const Dispose = ({ eventDetails = {}, onClose, onSubmit }) => {
  const { t } = useTranslation();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [initialValues, setInitialValues] = useState({
    disposeDate: '',
    disposeTo: '',
    notes: '',
    ...eventDetails
  });

  const fields = [
    {
      id: nanoid(),
      label: t('asset.assetDetail.moreAction.dispose.disposeDate'),
      name: 'disposeDate',
      maxDate: new Date(),
      type: 'date',
      isRequired: true,
      label_size: 2.6,
      field_size: 9.4
    },
    {
      id: nanoid(),
      label: t('asset.assetDetail.moreAction.dispose.disposeTo'),
      name: 'disposeTo',
      type: 'text',
      label_size: 2.6,
      field_size: 9.4
    },
    {
      id: nanoid(),
      label: t('asset.assetDetail.moreAction.notes'),
      name: 'notes',
      type: 'textArea',
      label_size: 2.6,
      field_size: 9.4
    }
  ];

  const schema = DisposeSchema(t('asset.assetDetail.moreAction.dispose.pleaseEnterDisposeDate'));

  const TitleIcon = <FontAwesomeIcon icon={faRecycle} className={appStyles.solid_list_icon} />;
  const handleSubmit = () => {
    setShowConfirmation(true);
  };
  return (
    <>
      <ModalForm
        formFor={DISPOSE}
        formTitle={t('statuses.dispose')}
        titleIcon={TitleIcon}
        initialValues={initialValues}
        fields={fields}
        onSubmit={handleSubmit}
        onClose={onClose}
        onValuesChange={(values) => setInitialValues(values)}
        isEditForm={initialValues.scheduled_status}
        validationSchema={schema}
      />

      {showConfirmation && (
        <AlertCard
          isOpen={showConfirmation}
          onClose={() => setShowConfirmation(false)}
          content={t('asset.assetDetail.moreAction.dispose.disposeConfirm')}
          onConfirm={() => onSubmit(initialValues)}
        />
      )}
    </>
  );
};

export default Dispose;
