import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, IconButton, Paper } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { companyPage, rowsPerPageOptions, RowsPerPage } from '../../helpers/constants';
import { axiosApi } from '../../helpers/api_helper';
import { SET_TABLE_ROW_COUNT } from '../../store/table/actionTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import CreateButton from '../../components/Form/Button/CreateButton';
import app_style from '../../assets/scss/_app.module.scss';
import textField_style from '../../components/Form/TextField/textField.module.scss';
import CompanyDetail from './CompanyDetail';
import tableStyles from '../../components/Table/Table.module.scss';
import styles from './companyList.module.scss';
import { SET_MODAL_FILTER } from '../../store/filterModal/actionTypes';
import { toast } from 'react-toastify';
import { isNotMobileWidth } from '../../helpers/common_service';
import Loader from '../../components/Loader';
import ConfirmationCard from '../../components/ConfirmationCard';
import DeleteToaster from '../../components/Toaster/DeleteToaster';
import FilterSelect from '../../components/FilterSelect';
import appStyles from '../../assets/scss/_app.module.scss';
import { getCompaniesLists } from '../../store/actions';
import { isUserSuperAdmin } from '../../helpers/access';

const CompanyList = () => {
  const [companies, setCompanies] = useState([]);
  const [totalRowsCount, setTotalRowsCount] = useState(0);
  const [totalPagesCount, setTotalPagesCount] = useState(0);
  const table = useSelector((state) => state.table);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(
    table.tableRowCountObj?.page === 'company' ? table.tableRowCountObj?.count : RowsPerPage
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const authUser = useSelector((state) => state.login?.authUser);
  const [loginUserCompanyDetails, setLoginUserCompanyDetails] = useState({});
  const [isToasterOpen, setIsToasterOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [deletedCompanyId, setDeletedCompanyId] = useState(null);
  const is_super_admin = isUserSuperAdmin();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const paginateObject = {
    from: currentPage,
    to: totalPagesCount,
    count: totalRowsCount
  };
  const getCompanies = async (searchText = '') => {
    let search = searchText.replace(/\+/g, '%2B');
    setLoading(true);
    await axiosApi
      .get('/companies', {
        params: {
          search,
          page: currentPage,
          items: rowsPerPage
        }
      })
      .then((res) => {
        if (currentPage > 1 && res.data.data.length === 0) {
          setCurrentPage(currentPage - 1);
          return;
        }
        setCompanies(res.data.data.map((data) => data.attributes));
        setTotalRowsCount(res.data.meta.total_count);
        setTotalPagesCount(res.data.meta.total_pages);
      })
      .catch((err) => {
        toast.error(t('common.somethingWentsWrong'));
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  const getLoginUserCompanyDetails = (companyId) => {
    axiosApi
      .get(`/companies/${companyId}`)
      .then((res) => setLoginUserCompanyDetails(res.data.data.attributes))
      .catch(() => toast.error(t('company.companyList.failedToFetchCompanyDetail')));
  };

  const setAuthUser = () => {
    axiosApi
      .get(`/users/${authUser.id}`)
      .then((res) => dispatch({ type: 'LOGIN_USER_SUCCESS', payload: res.data }))
      .catch(() => toast.error(t('myProfile.failedToUpdateprofile')));
  };

  useEffect(() => {
    dispatch({
      type: SET_TABLE_ROW_COUNT,
      setTableRowCount: { page: 'company', count: rowsPerPage }
    });
  }, [rowsPerPage]);

  const handlePageChange = (type) => {
    setCurrentPage((prev) => {
      if (type === 'increment' && currentPage < totalPagesCount) {
        return ++prev;
      } else if (type === 'decrement' && currentPage > 1) {
        return --prev;
      } else {
        return prev;
      }
    });
  };

  const handleDelete = async () => {
    setIsOpen(false);
    setLoading(true);
    await axiosApi
      .delete(`/companies/${deletedCompanyId}`)
      .then(async () => {
        setAuthUser();
        setIsToasterOpen(true);
        await getCompanies();
        getCompaniesLists(dispatch, { is_dropdown: true });
      })
      .catch(() => toast.error(t('company.companyDetails.companyDeleteFailed')))
      .finally(() => setLoading(false));
  };

  const onUndoDelete = () => {
    setLoading(true);
    axiosApi
      .patch(`/companies/${deletedCompanyId}/undo_destroy`)
      .then(() => {
        setDeletedCompanyId(null);
        getCompanies();
      })
      .catch(() => {
        toast.error(t('common.somethingWentsWrong'));
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (is_super_admin) getCompanies();
  }, [currentPage, rowsPerPage]);

  useEffect(() => {
    if (authUser.company_id) getLoginUserCompanyDetails(authUser.company_id);
  }, [authUser]);

  useEffect(() => setCurrentPage(1), [rowsPerPage]);

  useEffect(() => {
    dispatch({
      type: SET_MODAL_FILTER,
      pageName: companyPage
    });

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <Paper elevation={0} className={`${appStyles.paperScrollable}`}>
        <div className={styles.company_header_container}>
          <div className={`${app_style.header_container} ${is_super_admin ? styles.companyList_header : ''}`}>
            <div className={styles.left_header}>
              <div className={styles.header_name}>
                <FontAwesomeIcon icon={faGlobe} className={styles.company_icon} />
                <h6 className={`${app_style.title_name} ${styles.text_3xl}`}>
                  {is_super_admin ? t('company.companyList.title') : t('navbarItems.myCompany')}
                </h6>
              </div>
              <div className={`${textField_style.input_container} ${styles.search_field}`}>
                <input
                  placeholder={`${t('common.search')}...`}
                  className={styles.searchInput}
                  onChange={(e) => {
                    getCompanies(e.target.value);
                    setCurrentPage(1);
                  }}
                />
              </div>
            </div>
            {companies.length !== 0 && is_super_admin && (
              <div
                className={`${styles.flex_end_container} ${!authUser.company_id ? styles.flex_between_container : ''}`}>
                <CreateButton
                  buttonTitle={t('company.companyList.addCompany')}
                  onClick={() => navigate('/add-company')}
                  className={styles.add_company_btn}
                />
              </div>
            )}
          </div>
          <hr className={styles.horizontal_line} />
        </div>
        <div className={styles.company_body_container}>
          {authUser.company_id && !is_super_admin && (
            <>
              <CompanyDetail
                key={loginUserCompanyDetails.id}
                onDelete={setAuthUser}
                companyDetails={loginUserCompanyDetails}
                isLoginUserCompanyDetail={false}
                setIsOpen={setIsOpen}
                setDeletedCompanyId={setDeletedCompanyId}
              />
            </>
          )}
          <div>
            {loading ? (
              <Loader loading={loading} loaderClass={styles.loader} />
            ) : (
              <div className={styles.company_list_container}>
                <div className={styles.company_list}>
                  {companies.map((company) => (
                    <CompanyDetail
                      key={company.id}
                      companyDetails={company}
                      onDelete={getCompanies}
                      setIsOpen={setIsOpen}
                      setDeletedCompanyId={setDeletedCompanyId}
                    />
                  ))}
                </div>
                {companies.length === 0 && is_super_admin && (
                  <div className={styles.no_data_msg}>{t('company.companyList.noCompanies')}</div>
                )}
              </div>
            )}
            {totalRowsCount > 5 && is_super_admin && (
              <div className={`${tableStyles.paginate_container} ${styles.paginate_con_fixed}`}>
                <div className={tableStyles.paginate_dropdown}>
                  <FilterSelect
                    value={rowsPerPage}
                    label={isNotMobileWidth(windowWidth) ? t('table.showPerPage') : t('table.mobileShowPerPage')}
                    items={rowsPerPageOptions}
                    onChange={(value) => setRowsPerPage(value)}
                    containerStyles={tableStyles.filterSelector}
                  />
                </div>
                <div className={tableStyles.paginate_buttons_container}>
                  <span>
                    {isNotMobileWidth(windowWidth)
                      ? t('table.paginateMsg', paginateObject)
                      : t('table.mobilePaginateMsg', paginateObject)}
                  </span>
                  <div className={tableStyles.paginate_buttons}>
                    {currentPage > 1 && (
                      <IconButton onClick={() => handlePageChange('decrement')} className={tableStyles.prevBtn}>
                        <KeyboardArrowLeft />
                      </IconButton>
                    )}
                    <Button variant="contained" className={tableStyles.midBtn}>
                      {currentPage}
                    </Button>
                    {currentPage < totalPagesCount && (
                      <IconButton onClick={() => handlePageChange('increment')} className={tableStyles.nextBtn}>
                        <KeyboardArrowRight />
                      </IconButton>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Paper>
      <ConfirmationCard isOpen={isOpen} onClose={() => setIsOpen(false)} onDelete={handleDelete} />
      {isToasterOpen && loading && (
        <DeleteToaster
          deletedItemId={deletedCompanyId}
          message={t('common.companyDeleteSuccess')}
          toast={toast}
          canUndo={true}
          handleUndoDelete={onUndoDelete}
          onClose={() => setIsToasterOpen(false)}
        />
      )}
    </>
  );
};

export default CompanyList;
