import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faCube } from '@fortawesome/free-solid-svg-icons';
import { MaintenanceSchema } from '../../../../schemas/MaintenanceSchema';
import { axiosApi } from '../../../../helpers/api_helper';
import ModalForm from '../../../../components/ModalForm';
import appStyles from '../../../../assets/scss/_app.module.scss';

const MaintenanceForm = ({ productId, productCountryId, maintenanceDetails, onClose, onSubmit }) => {
  const { t } = useTranslation();
  const isEditMaintenance = maintenanceDetails?.id ? true : false;
  const [toasterIsActive, setToasterIsActive] = useState(false);
  const [statuses, setStatuses] = useState([]);
  const title = t(`asset.assetDetail.maintenanceTab.${isEditMaintenance ? 'edit' : 'add'}Maintenance`);

  const maintenanceStatuses = statuses.map((key) => ({
    label: t(`maintenance.statuses.${key}`),
    value: key
  }));

  const [initialValues, setInitialValues] = useState({
    title: '',
    maintenance_by: '',
    due_date: '',
    date_completed: '',
    repair_cost: '',
    country_id: productCountryId,
    status: 'scheduled',
    notes: '',
    ...maintenanceDetails
  });

  const fields = [
    {
      id: nanoid(),
      label: t('maintenance.title'),
      name: 'title',
      type: 'text',
      isRequired: true,
      label_size: 2,
      field_size: 10
    },
    {
      id: nanoid(),
      label: t('maintenance.maintenanceBy'),
      name: 'maintenance_by',
      type: 'text',
      label_size: 2,
      field_size: 10
    },
    {
      id: nanoid(),
      label: t('maintenance.dueDate'),
      name: 'due_date',
      type: 'date',
      isRequired: true,
      label_size: 2,
      field_size: 4
    },
    {
      id: nanoid(),
      label: t('maintenance.dateCompleted'),
      name: 'date_completed',
      type: 'date',
      disabled: !initialValues.due_date,
      minDate: initialValues.due_date,
      label_size: 2,
      field_size: 4,
      paddingLeft: '2.5vw !important'
    },
    {
      id: nanoid(),
      label: t('maintenance.repairCost'),
      type: 'currency',
      selectorName: 'country_id',
      textFieldName: 'repair_cost',
      menuOpen: false,
      label_size: 2,
      field_size: 4
    },
    {
      id: nanoid(),
      label: t('maintenance.status'),
      name: 'status',
      type: 'dropDown',
      values: maintenanceStatuses,
      label_size: 2,
      field_size: 4,
      paddingLeft: '2.5vw !important'
    },
    {
      id: nanoid(),
      label: t('asset.assetDetail.moreAction.notes'),
      name: 'notes',
      type: 'textArea',
      label_size: 2,
      field_size: 10
    }
  ];

  const handleSubmit = (values) => {
    if (isEditMaintenance) {
      axiosApi
        .patch(`/products/${productId}/maintenances/${maintenanceDetails.id}`, values)
        .then((res) => {
          onClose();
          onSubmit(res.data.data.attributes);
          toast.success(t('asset.assetDetail.maintenanceTab.maintenanceUpdatedSuccessfully'));
        })
        .catch((e) => {
          let error = e?.error || e.message;
          if (e.response?.data?.message_key)
            error = t(`asset.assetDetail.maintenanceTab.${e.response?.data?.message_key}`);
          toast.error(error || t('common.somethingWentsWrong')),
            {
              onClose: () => setToasterIsActive(false)
            };
        });
    } else {
      axiosApi
        .post(`/products/${productId}/maintenances`, values)
        .then((res) => {
          onClose();
          onSubmit(res.data.data.attributes);
          toast.success(t('asset.assetDetail.maintenanceTab.maintenanceAddedSuccessfully'));
        })
        .catch((e) => {
          let error = e?.error || e.message;
          if (e.response?.data?.message_key)
            error = t(`asset.assetDetail.maintenanceTab.${e.response?.data?.message_key}`);
          toast.error(error || t('common.somethingWentsWrong'));
        });
    }
  };

  const getStatuses = () => {
    axiosApi
      .get('/maintenances/statuses')
      .then((res) => setStatuses(res.data))
      .catch(() => toast.error(t('common.somethingWentsWrong')));
  };

  const schema = MaintenanceSchema(
    t('asset.assetDetail.maintenanceAdd.pleaseSelectStartDate'),
    t('asset.assetDetail.maintenanceAdd.endDateEalierThanStartDate'),
    t('asset.assetDetail.maintenanceAdd.titleRequired')
  );

  const TitleIcon = (
    <FontAwesomeIcon icon={isEditMaintenance ? faPenToSquare : faCube} className={appStyles.solid_list_icon} />
  );

  useEffect(getStatuses, []);

  return (
    <ModalForm
      isToasterActive={toasterIsActive}
      preSavedData={isEditMaintenance ? initialValues : {}}
      formTitle={title}
      titleIcon={TitleIcon}
      isEditForm={isEditMaintenance}
      initialValues={initialValues}
      fields={fields}
      onSubmit={handleSubmit}
      onClose={onClose}
      onValuesChange={(values) => setInitialValues(values)}
      validationSchema={schema}
    />
  );
};

export default MaintenanceForm;
