import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Favorite } from '@mui/icons-material';
import { DonateSchema } from '../../../schemas/AssetEventsSchema';
import ModalForm from '../../../components/ModalForm';
import AlertCard from '../../../components/AlertCard';
import { DONATE } from '../../../helpers/constants';

const Donate = ({ eventDetails = {}, onClose, onSubmit }) => {
  const { t } = useTranslation();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [initialValues, setInitialValues] = useState({
    donatedDate: '',
    donatedTo: '',
    notes: '',
    isSendMail: false,
    email: '',
    ...eventDetails
  });

  const fields = [
    {
      id: nanoid(),
      label: t('asset.assetDetail.moreAction.donate.donatedDate'),
      name: 'donatedDate',
      type: 'date',
      maxDate: new Date(),
      isRequired: true,
      label_size: 2.5,
      field_size: 9.5
    },
    {
      id: nanoid(),
      label: t('asset.assetDetail.moreAction.donate.donatedTo'),
      name: 'donatedTo',
      type: 'text',
      label_size: 2.5,
      field_size: 9.5
    },
    {
      id: nanoid(),
      label: t('asset.assetDetail.moreAction.notes'),
      name: 'notes',
      type: 'textArea',
      label_size: 2.5,
      field_size: 9.5
    },
    {
      id: nanoid(),
      name: 'isSendMail',
      type: 'checkBox',
      label_size: 2.5,
      field_size: 9.5
    },
    {
      id: nanoid(),
      name: 'email',
      type: 'text',
      prefixIcon: faEnvelope,
      label_size: 2.5,
      field_size: 9.5
    }
  ];

  const schema = DonateSchema(
    t('asset.assetDetail.moreAction.donate.pleaseEnterDonatedDate'),
    t('common.pleaseEnterEmail')
  );

  const handleSubmit = () => {
    setShowConfirmation(true);
  };
  return (
    <>
      <ModalForm
        formFor={DONATE}
        formTitle={t('statuses.donate')}
        titleIcon={<Favorite />}
        initialValues={initialValues}
        fields={fields}
        onSubmit={handleSubmit}
        onClose={onClose}
        onValuesChange={(values) => setInitialValues(values)}
        validationSchema={schema}
        isEditForm={initialValues.scheduled_status}
      />
      {showConfirmation && (
        <AlertCard
          isOpen={showConfirmation}
          onClose={() => setShowConfirmation(false)}
          content={t('asset.assetDetail.moreAction.donate.donateConfirm')}
          onConfirm={() => onSubmit(initialValues)}
        />
      )}
    </>
  );
};

export default Donate;
