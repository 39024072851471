import { useEffect, useState } from 'react';
import { Paper } from '@mui/material';
import AppLogo from '../../assets/images/AppLogo.svg';
import AppLogoVertical from '../../assets/images/AppLogoVertical.svg';
import styles from './index.module.scss';
import variables from '../../assets/scss/_app.scss';

const CommonLoginLayout = (props) => {
  const width = parseInt(variables.maxTabletLg);
  const [imageSrc, setImageSrc] = useState('');

  useEffect(() => {
    function handleResize() {
      const windowWidth = window.innerWidth;
      setImageSrc(windowWidth > width ? AppLogo : AppLogoVertical);
    }

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.headerGradiant}></div>
      <div className={styles.logoContainer}>
        <img src={imageSrc} alt="App logo" className={styles.logo} />
      </div>
      <div className={styles.body}>
        <Paper elevation={0} className={styles.form}>
          {props.children}
        </Paper>
      </div>
    </div>
  );
};

export default CommonLoginLayout;
