import { useEffect, useLayoutEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import { Paper } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faCircle } from '@fortawesome/free-solid-svg-icons';
import InsuranceAlerts from './Insurance';
import CheckOutAlert from './CheckoutDueDate';
import styles from './alerts.module.scss';
import { useTranslation } from 'react-i18next';
import { axiosApi } from '../../helpers/api_helper';
import WarrantyAlerts from './Warranty';
import MaintenanceAlerts from './Maintenance';
import ReservationAlerts from './Reservation';
import MaintenanceRequestAlerts from './MaintenanceRequests';
import { SET_MODAL_FILTER } from '../../store/filterModal/actionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { setNewAlertsCount } from '../../store/actions';
import {
  hasAccess,
  hasReadAccess,
  MODEL_INSURANCE,
  MODEL_MAINTENANCE,
  MODEL_MAINTENANCE_REQUEST,
  MODEL_PRODUCT,
  MODEL_RESERVATION,
  MODEL_WARRANTY,
  showBranchFilter,
  showCompanyFilter,
  hasCreateAccess
} from '../../helpers/access';

const alertsPage = 'alerts';

const Alerts = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.common.companyId);
  const branchId = useSelector((state) => state.common.branchId);
  const [isLoaded, setIsLoaded] = useState(false);
  const [alertCounts, setAlertCounts] = useState({
    warranty_count: 0,
    insurance_count: 0,
    reservation_count: 0,
    maintenance_count: 0,
    checkout_count: 0,
    maintenance_request_count: 0
  });
  const [newAlertCounts, setNewAlertCounts] = useState({
    warranty_count: 0,
    insurance_count: 0,
    reservation_count: 0,
    maintenance_count: 0,
    checkout_count: 0,
    maintenance_request_count: 0
  });
  const [currentMenuTab, setCurrentMenuTab] = useState('');
  let menuTabNames = [
    ...(hasAccess(MODEL_WARRANTY)
      ? [
          {
            key: nanoid(),
            value: 'Warranty',
            alertsCount: alertCounts.warranty_count,
            newAlertsCount: newAlertCounts.warranty_count
          }
        ]
      : []),
    ...(hasAccess(MODEL_INSURANCE)
      ? [
          {
            key: nanoid(),
            value: 'Insurance',
            alertsCount: alertCounts.insurance_count,
            newAlertsCount: newAlertCounts.insurance_count
          }
        ]
      : []),
    ...(hasCreateAccess(MODEL_RESERVATION)
      ? [
          {
            key: nanoid(),
            value: 'Reservation',
            alertsCount: alertCounts.reservation_count,
            newAlertsCount: newAlertCounts.reservation_count
          }
        ]
      : []),
    ...(hasCreateAccess(MODEL_MAINTENANCE)
      ? [
          {
            key: nanoid(),
            value: 'Maintenance',
            alertsCount: alertCounts.maintenance_count,
            newAlertsCount: newAlertCounts.maintenance_count
          }
        ]
      : []),
    ...(hasReadAccess(MODEL_MAINTENANCE_REQUEST, 'self', false) && 1 == 0
      ? [
          {
            key: nanoid(),
            value: 'MaintenanceRequest',
            alertsCount: alertCounts.maintenance_request_count,
            newAlertsCount: newAlertCounts.maintenance_request_count
          }
        ]
      : []),
    ...(hasCreateAccess(MODEL_PRODUCT)
      ? [
          {
            key: nanoid(),
            value: 'Assigned',
            alertsCount: alertCounts.checkout_count,
            newAlertsCount: newAlertCounts.checkout_count
          }
        ]
      : [])
  ];

  const handleBeforeFetch = () => setIsLoaded(false);
  const handleAfterFetch = () => setIsLoaded(true);

  useLayoutEffect(() => {
    setCurrentMenuTab(menuTabNames[0]['value']);
  }, []);

  const fetchCount = () => {
    let params = {};
    if (showCompanyFilter() && companyId) params['company_id'] = companyId;
    if (showBranchFilter() && branchId) params['branch_id'] = branchId;
    axiosApi.get('/alerts/alerts_count', { params }).then((res) => {
      const data = res.data;
      setAlertCounts((prev) => ({
        ...prev,
        warranty_count: data.all_alerts_count['Warranty'] || 0,
        insurance_count: data.all_alerts_count['Insurance'] || 0,
        reservation_count: data.all_alerts_count['Reservation'] || 0,
        maintenance_count: data.all_alerts_count['Maintenance'] || 0,
        checkout_count: data.all_alerts_count['Product'] || 0,
        maintenance_request_count: data.all_alerts_count['MaintenanceRequest'] || 0
      }));
      setNewAlertCounts((prev) => ({
        ...prev,
        warranty_count: data.new_alerts_count['Warranty'] || 0,
        insurance_count: data.new_alerts_count['Insurance'] || 0,
        reservation_count: data.new_alerts_count['Reservation'] || 0,
        maintenance_count: data.new_alerts_count['Maintenance'] || 0,
        checkout_count: data.new_alerts_count['Product'] || 0,
        maintenance_request_count: data.new_alerts_count['MaintenanceRequest'] || 0
      }));
      dispatch(setNewAlertsCount(data.new_alerts_count['Total'] || 0));
    });
  };

  useEffect(() => {
    fetchCount();
    dispatch({
      type: SET_MODAL_FILTER,
      pageName: alertsPage
    });
    return () => fetchCount();
  }, []);
  useEffect(() => {
    fetchCount();
  }, [companyId, branchId]);
  return (
    <Paper elevation={0} className={`${styles.flexHeightAuto} ${!isLoaded && styles.h_100}`}>
      <div className={`${styles.header_container} ${styles.headerContainer}`}>
        <div>
          <FontAwesomeIcon icon={faBell} className={styles.solid_list_icon} />
          <h6 className={`${styles.title_name} ${styles.text_3xl}`}>{t('navbarItems.alerts')}</h6>
        </div>
      </div>
      <div className={styles.tabHeader}>
        {menuTabNames.map((menu) => {
          return (
            <span
              key={menu.key}
              className={currentMenuTab === menu.value ? styles.selectedTab : ''}
              onClick={() => {
                setCurrentMenuTab(menu.value);
                fetchCount();
                setIsLoaded(false);
              }}>
              <span className={styles.tab}>
                {menu.newAlertsCount > 0 && (
                  <FontAwesomeIcon className={styles.new_alert_indication_icon} icon={faCircle} />
                )}
                {menu.value}
                <span className={currentMenuTab === menu.value ? styles.active_count : styles.inactive_count}>
                  {menu.alertsCount}
                </span>
              </span>
            </span>
          );
        })}
      </div>
      <div className={styles.tabContainer}>
        {currentMenuTab === 'Warranty' && (
          <WarrantyAlerts fetchCount={fetchCount} beforeFetch={handleBeforeFetch} afterFetch={handleAfterFetch} />
        )}
        {currentMenuTab === 'Insurance' && (
          <InsuranceAlerts fetchCount={fetchCount} beforeFetch={handleBeforeFetch} afterFetch={handleAfterFetch} />
        )}
        {currentMenuTab === 'Reservation' && (
          <ReservationAlerts fetchCount={fetchCount} beforeFetch={handleBeforeFetch} afterFetch={handleAfterFetch} />
        )}
        {currentMenuTab === 'Maintenance' && (
          <MaintenanceAlerts fetchCount={fetchCount} beforeFetch={handleBeforeFetch} afterFetch={handleAfterFetch} />
        )}
        {currentMenuTab === 'Assigned' && <CheckOutAlert fetchCount={fetchCount} afterFetch={handleAfterFetch} />}
        {currentMenuTab === 'MaintenanceRequest' && (
          <MaintenanceRequestAlerts
            fetchCount={fetchCount}
            beforeFetch={handleBeforeFetch}
            afterFetch={handleAfterFetch}
          />
        )}
      </div>
    </Paper>
  );
};

export default Alerts;
