import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { axiosApi } from '../../helpers/api_helper';
import DeleteButton from '../../components/Form/Button/DeleteButton';
import { toast } from 'react-toastify';
import Table from '../../components/Table';
import { dateRangeDisplay } from '../../helpers/common_service';
import styles from './alerts.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AUTHORITY_LEVEL_COMPANY, hasReadAccess, showCompanyFilter } from '../../helpers/access';
import { RESERVATION } from '../../helpers/constants';

const ReservationAlerts = ({ fetchCount, beforeFetch, afterFetch }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [reservations, setReservations] = useState([]);
  const reservation = 'Reservation';
  const [isLoading, setIsLoading] = useState(false);
  const companyId = useSelector((state) => state.common.companyId);
  const branchId = useSelector((state) => state.common.branchId);
  const headCells = [
    ...(reservations.find((e) => e.is_new_alert === true)
      ? [
          {
            id: 'new_alert',
            label: '',
            width: '8px'
          }
        ]
      : []),
    {
      id: 'product_tag_id',
      label: t('asset.general.tagId')
    },
    {
      id: 'asset_name',
      label: t('asset.general.assetName'),
      hasViewLink: true
    },
    {
      id: 'period_of_reservation',
      label: t('asset.assetDetail.reserveTab.duration')
    },
    {
      id: 'alert_message',
      label: t('alert.message')
    },
    {
      id: 'actions',
      label: t('alert.action')
    }
  ];

  useEffect(() => {
    if (!reservations.length) beforeFetch();
  }, [reservations]);

  const fetchReservations = () => {
    setIsLoading(true);
    let params = {};
    params['relation'] = reservation;
    if (showCompanyFilter() && companyId) params['company_id'] = companyId;
    if (hasReadAccess(RESERVATION, AUTHORITY_LEVEL_COMPANY) && branchId) params['branch_id'] = branchId;
    beforeFetch();
    axiosApi
      .get('/alerts', { params })
      .then((res) => {
        setReservations(
          res.data.data.map((alert) => ({
            ...alert.attributes.alert_detail.data.attributes,
            alert_message: alert.attributes.message,
            id: alert.attributes.id,
            period_of_reservation: dateRangeDisplay(
              alert.attributes.alert_detail.data.attributes.from_date,
              alert.attributes.alert_detail.data.attributes.to_date
            ),
            product_id: alert.attributes.product_id,
            is_new_alert: alert.attributes.is_new_alert,
            new_alert: alert.attributes.is_new_alert ? (
              <FontAwesomeIcon className={styles.new_alert_indication_icon} icon={faCircle} />
            ) : (
              ' '
            ),
            actions: <DeleteButton onClick={() => handleMarkAlertAsSeen(alert.attributes.id)} />
          }))
        );
        if (res.data.data.length) afterFetch();
      })
      .catch(() => toast.error(t('common.somethingWentsWrong')))
      .finally(() => setIsLoading(false));
  };
  const handleViewBtnClick = (alertId) => {
    navigate(`/inventory-details/${reservations.find((x) => x.id === alertId).product_id}?tab=Reserve&subTab=History`);
  };
  const handleMarkAlertAsSeen = (alertId) => {
    setIsLoading(true);
    beforeFetch();
    axiosApi
      .get(`/alerts/${alertId}/mark_as_seen/`)
      .then(() => {
        setReservations((prev) => prev.filter((alert) => alert.id !== alertId));
        fetchCount();
      })
      .catch(() => {
        toast.error(t('common.somethingWentsWrong'));
      })
      .finally(() => {
        setIsLoading(false);
        afterFetch();
      });
  };
  useEffect(() => fetchReservations(), []);
  useEffect(() => {
    fetchReservations();
  }, [companyId, branchId]);
  return (
    <Table
      pageName={reservation}
      headCells={headCells}
      rows={reservations}
      loading={isLoading}
      noDataMsg="asset.assetDetail.reserveTab.noReservation"
      showPagination={false}
      viewActionColumn={false}
      viewBtnFn={handleViewBtnClick}
    />
  );
};

export default ReservationAlerts;
