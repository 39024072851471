import React from 'react';
import styles from './textField.module.scss';

export const TextField = ({
  id,
  prefixInput,
  prefixIcon,
  prefixIconClass,
  suffixIcon,
  suffixIconClass,
  suffixText,
  suffixTextClass,
  disabled = false,
  className,
  placeHolder = '',
  fieldType,
  maxLength,
  ...props
}) => {
  const hasError = props.error !== undefined ? !!props.error : false;

  const numbers = (event) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  const alphabets = (event) => {
    if (!/^[A-Za-z]*$/.test(event.key)) {
      event.preventDefault();
    }
  };

  const alphaNumeric = (event) => {
    if (!/^[A-Za-z0-9 ]*$/.test(event.key)) {
      event.preventDefault();
    }
  };

  const specialChar = (event) => {
    if (!/^[()A-Za-z0-9_@.,/#&*+-:'\s]*$/.test(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <>
      <div className={styles.container} id={id}>
        {prefixIcon && <span className={prefixIconClass}>{prefixIcon}</span>}

        <div className={`${styles.input_container} ${className} ${disabled ? styles.disable : ''}`}>
          {prefixInput && <span>{prefixInput}</span>}
          <input
            disabled={disabled}
            className={styles.text_field}
            placeholder={placeHolder}
            maxLength={maxLength}
            {...props}
            onKeyPress={(e) =>
              (fieldType === 'number' && numbers(e)) ||
              (fieldType === 'alphabet' && alphabets(e)) ||
              (fieldType === 'alphaNumeric' && alphaNumeric(e)) ||
              (fieldType === 'specialChar' && specialChar(e)) ||
              null
            }
          />
        </div>

        {suffixIcon && <span className={suffixIconClass}>{suffixIcon}</span>}
        {suffixText && <span className={suffixTextClass}>{suffixText}</span>}
      </div>
      {hasError && <div className={styles.error_msg}>{props.error}</div>}
    </>
  );
};
