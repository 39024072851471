import { SET_COMPANY_ID, REMOVE_COMPANY_ID } from './actionTypes';

export const setDashboardDropDownCompany = (companyId) => {
  return {
    type: SET_COMPANY_ID,
    payload: { companyId }
  };
};

export const removeDashboardDropDownCompany = () => {
  return {
    type: REMOVE_COMPANY_ID,
    payload: {}
  };
};
