import * as Yup from 'yup';

export const MaintenanceSchema = (due_date, completion_earlier, title) =>
  Yup.object().shape({
    due_date: Yup.date().required(due_date),
    date_completed: Yup.date()
      .when('due_date', (scheduled_time, schema) => {
        if (scheduled_time) {
          const currentDay = new Date(scheduled_time.getTime());
          return schema.min(currentDay, completion_earlier);
        } else {
          return schema;
        }
      })
      .nullable(),
    title: Yup.string().required(title)
  });

export const MaintenanceRequestSchema = (priority, pick_up_date, request_description) =>
  Yup.object().shape({
    priority: Yup.string().required(priority),
    pick_up_date: Yup.date().required(pick_up_date),
    request_description: Yup.string().required(request_description)
  });

export const MaintenanceRequestRejectSchema = (reject_description) =>
  Yup.object().shape({
    reject_description: Yup.string().required(reject_description)
  });
