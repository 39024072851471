import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { Category, FileCopy } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';
import app_style from '../../../assets/scss/_app.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserGroup,
  faGlobe,
  faCube,
  faFlag,
  faCodeBranch,
  faKey,
  faWrench,
  faMessage,
  faArrowRightFromBracket
} from '@fortawesome/free-solid-svg-icons';
import styles from './navbar.module.scss';
import { useNavigate } from 'react-router-dom';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router-dom';
import { logoutUser } from '../../../store/actions';
import FeedbackForm from '../../FeedbackForm';
import screen from '../../../assets/scss/_app.scss';
import {
  hasAccess,
  hasCreateAccess,
  hasReadAccess,
  isUserAdmin,
  isUserCompanyAdmin,
  isUserSuperAdmin,
  MODEL_BRANCH,
  MODEL_INSURANCE,
  MODEL_MAINTENANCE_REQUEST,
  MODEL_PRODUCT,
  MODEL_USER,
  MODEL_WARRANTY
} from '../../../helpers/access';

const NavBar = () => {
  const { t } = useTranslation();
  const showMenu = useSelector((state) => state.sideMenu.showMenu);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const location = useLocation();
  const dispatch = useDispatch();
  const [hoverItem, sethoverItem] = useState();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const maxMobileScreen = parseInt(screen.maxMobile);
  const minDesktopScreen = parseInt(screen.minDesktop);
  const [isFeedbackFormOpen, setIsFeedbackFormOpen] = useState(false);
  const handlePopoverOpen = (event) => {
    if (windowWidth > maxMobileScreen) setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handlePopoverCloseTablet = () => {
    setTimeout(() => {
      setAnchorEl(null);
    }, 800);
  };

  const navigate = useNavigate();
  var menuItems = [
    ...(hasAccess(MODEL_PRODUCT)
      ? [
          {
            id: nanoid(),
            slug: 'inventory',
            menu_item: t('navbarItems.inventory'),
            InActiveIcon: (
              <FontAwesomeIcon
                icon={faCube}
                className={` ${hoverItem === 'inventory' ? styles.hover_menu_icon : styles.font_icon}`}
              />
            ),
            activeIcon: <FontAwesomeIcon icon={faCube} className={app_style.solid_list_icon} />,
            link: '/inventory-list',
            child_links: ['/add-inventory', '/inventory-details', '/edit-inventory']
          }
        ]
      : []),
    ...(hasAccess(MODEL_PRODUCT) || hasAccess(MODEL_WARRANTY) || hasAccess(MODEL_INSURANCE)
      ? [
          {
            id: nanoid(),
            slug: 'expiring',
            menu_item: t('navbarItems.expiring'),
            InActiveIcon: (
              <FontAwesomeIcon
                icon={faFlag}
                className={` ${hoverItem === 'expiring' ? styles.hover_menu_icon : styles.font_icon}`}
              />
            ),
            activeIcon: <FontAwesomeIcon icon={faFlag} className={app_style.solid_list_icon} />,
            link: '/expiring'
          }
        ]
      : []),
    ...(isUserAdmin()
      ? [
          {
            id: nanoid(),
            slug: 'reports',
            menu_item: t('navbarItems.reports'),
            InActiveIcon: (
              <FileCopy
                size="large"
                className={` ${hoverItem === 'reports' ? styles.hover_menu_icon : styles.font_icon}`}
              />
            ),
            activeIcon: <FileCopy size="large" className={app_style.solid_list_icon} />,
            link: '/reports'
          }
        ]
      : []),
    ...(hasReadAccess(MODEL_USER)
      ? [
          {
            id: nanoid(),
            slug: 'users',
            menu_item: t('navbarItems.users'),
            InActiveIcon: (
              <FontAwesomeIcon
                icon={faUserGroup}
                className={` ${hoverItem === 'users' ? styles.hover_menu_icon : styles.font_icon}`}
              />
            ),
            activeIcon: <FontAwesomeIcon icon={faUserGroup} className={app_style.solid_list_icon} />,
            link: '/user-list',
            child_links: ['/add-user', '/edit-user']
          }
        ]
      : []),
    ...(hasReadAccess(MODEL_BRANCH)
      ? [
          {
            id: nanoid(),
            slug: 'branches',
            menu_item: t('navbarItems.branches'),
            InActiveIcon: (
              <FontAwesomeIcon
                icon={faCodeBranch}
                className={hoverItem === 'branches' ? styles.hover_menu_icon : styles.font_icon}
              />
            ),
            activeIcon: <FontAwesomeIcon icon={faCodeBranch} className={app_style.solid_list_icon} />,

            link: '/branch-list',
            child_links: ['/add-branch', '/edit-branch']
          }
        ]
      : []),
    ...(isUserSuperAdmin()
      ? [
          {
            id: nanoid(),
            slug: 'companies',
            menu_item: t('navbarItems.companies'),
            InActiveIcon: (
              <FontAwesomeIcon
                icon={faGlobe}
                className={` ${hoverItem === 'companies' ? styles.hover_menu_icon : styles.font_icon}`}
              />
            ),
            activeIcon: <FontAwesomeIcon icon={faGlobe} className={app_style.solid_list_icon} />,
            link: '/company-list',
            child_links: ['/add-company', '/edit-company']
          }
        ]
      : []),
    ...(hasReadAccess(MODEL_MAINTENANCE_REQUEST)
      ? [
          {
            id: nanoid(),
            slug: 'maintenanceRequests',
            menu_item: t('navbarItems.maintenanceRequests'),
            InActiveIcon: (
              <FontAwesomeIcon
                icon={faWrench}
                className={` ${hoverItem === 'maintenanceRequests' ? styles.hover_menu_icon : styles.font_icon}`}
              />
            ),
            activeIcon: <FontAwesomeIcon icon={faWrench} className={app_style.solid_list_icon} />,
            link: '/maintenance-request-list'
          }
        ]
      : []),
    ...(hasCreateAccess(MODEL_PRODUCT)
      ? [
          {
            id: nanoid(),
            slug: 'assetProperties',
            menu_item: t('navbarItems.assetProperties'),
            InActiveIcon: (
              <Category className={` ${hoverItem === 'assetProperties' ? styles.hover_mui_icon : styles.mui_icon}`} />
            ),
            activeIcon: <Category className={app_style.solid_list_icon} />,
            link: '/asset-properties-list'
          }
        ]
      : []),
    ...(isUserCompanyAdmin() && 1 == 0
      ? [
          {
            id: nanoid(),
            slug: 'roles',
            menu_item: t('navbarItems.roles'),
            InActiveIcon: (
              <FontAwesomeIcon
                icon={faKey}
                className={` ${hoverItem === 'roles' ? styles.hover_menu_icon : styles.font_icon}`}
              />
            ),
            activeIcon: <FontAwesomeIcon icon={faKey} className={app_style.solid_list_icon} />,
            link: '/role-list'
          }
        ]
      : []),
    {
      id: nanoid(),
      slug: 'feedback',
      menu_item: t('feedback.title'),
      InActiveIcon: (
        <FontAwesomeIcon
          icon={faMessage}
          className={` ${hoverItem === 'feedback' ? styles.hover_menu_icon : styles.font_icon}`}
        />
      ),
      activeIcon: <FontAwesomeIcon icon={faMessage} className={app_style.solid_list_icon} />,
      link: '/feedbacks',
      callBackFn: () => (isUserSuperAdmin() ? navigate('/feedbacks') : setIsFeedbackFormOpen(true))
    },
    {
      id: nanoid(),
      slug: 'logOut',
      menu_item: t('navbarItems.logout'),
      InActiveIcon: <FontAwesomeIcon icon={faArrowRightFromBracket} className={app_style.solid_list_icon} />,
      activeIcon: <FontAwesomeIcon icon={faArrowRightFromBracket} className={app_style.solid_list_icon} />,
      callBackFn: () => dispatch(logoutUser(navigate))
    }
  ];

  if (windowWidth > maxMobileScreen) menuItems = menuItems.filter((item) => item.slug !== 'logOut');

  useEffect(() => {
    function handleWindowResize() {
      setWindowWidth(window.innerWidth);
      handlePopoverClose();
    }
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const isActiveByPath = (link, child_links = []) => {
    return location.pathname === link || child_links.some((link) => location.pathname.includes(link));
  };

  return (
    <>
      <div
        className={
          windowWidth > maxMobileScreen
            ? `${showMenu === 'none' && styles.hide_navbar} ${styles.navbar_auto_show} `
            : showMenu === 'flex'
            ? styles.navbar_manual_show
            : styles.navbar_manual_hide
        }
        onClick={() => {
          if (windowWidth < minDesktopScreen) {
            dispatch({
              type: showMenu === 'none' ? ' ' : 'HIDE_MENU'
            });
          }
        }}>
        <div>
          <div className={styles.overall_menu_conatainer}>
            <div className={`${styles.side_menu}`}>
              {menuItems.map((item) => (
                <div key={item.slug}>
                  <div id="mouse-over-popover">
                    <div
                      onMouseOver={() => sethoverItem(item.slug)}
                      onMouseOut={() => sethoverItem()}
                      onMouseEnter={handlePopoverOpen}
                      onMouseLeave={handlePopoverClose}
                      onTouchEnd={handlePopoverCloseTablet}
                      className={`${styles.menu_item_container} ${
                        item.slug === 'logOut' ? styles.logout_menu_container : ''
                      } ${isActiveByPath(item.link, item.child_links) ? styles.menu_item_active : ''} ${
                        showMenu === 'none' ? styles.hide_menu_item_container : ''
                      }`}
                      onClick={() => (item.callBackFn ? item.callBackFn() : navigate(item.link))}>
                      {showMenu === 'none' && (
                        <>
                          {hoverItem === item.slug && (
                            <Popover
                              id={'mouse-over-popover'}
                              sx={{
                                pointerEvents: 'none'
                              }}
                              open={open}
                              anchorEl={anchorEl}
                              anchorOrigin={{
                                vertical: 'center',
                                horizontal: 'right'
                              }}
                              transformOrigin={{
                                vertical: 'center',
                                horizontal: 'left'
                              }}
                              onClose={handlePopoverClose}
                              disableRestoreFocus
                              className={styles.popover_item}
                              elevation={3}>
                              <Typography className={styles.typography_hover}>{item.menu_item}</Typography>
                            </Popover>
                          )}
                        </>
                      )}
                      <>
                        <div className={styles.menu_item_icon}>
                          {isActiveByPath(item.link, item.child_links) ? item.activeIcon : item.InActiveIcon}
                        </div>
                        <div className={showMenu === 'none' ? styles.hide_menu_item : styles.show_menu_item}>
                          <div className={`${styles.menu_item_name} ${styles.medium} ${styles.text_xl}`}>
                            {item.menu_item}
                          </div>
                          {item.slug !== 'logOut' && (
                            <span
                              className={`${
                                isActiveByPath(item.link, item.child_links)
                                  ? styles.menu_item_active_arrow
                                  : styles.menu_item_arrow
                              } ${
                                hoverItem === item.slug && !isActiveByPath(item.link, item.child_links)
                                  ? styles.hover_menu_arrow_icon
                                  : ''
                              }`}></span>
                          )}
                        </div>
                      </>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* <div className={styles.navbar_profile_info}>
          <div className={styles.bottom_border_line}></div>
          <div
            className={showMenu === 'flex' ? styles.profile_info_container : styles.hide_profile_info_container}
            onClick={() => navigate('/profile')}>
            <div>
              <img src={authUser?.avatar || UserImage} alt="Profile Pic" className={styles.profile_pic} />
            </div>
            {showMenu === 'flex' && (
              <div className={styles.profile_info}>
                <div className={`${styles.profile_name} ${styles.bold} ${styles.text_lg}`}>
                  {authUser?.first_name + ' ' + authUser?.last_name}
                </div>
                <div className={`${styles.profile_role} ${styles.regular} ${styles.text_md}`}>{t(authUser?.role)}</div>
              </div>
            )}
          </div>
        </div> */}
      </div>
      {isFeedbackFormOpen && <FeedbackForm onClose={() => setIsFeedbackFormOpen(false)} />}
    </>
  );
};

export default NavBar;
