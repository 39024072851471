import { useEffect, useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { axiosApi } from '../../helpers/api_helper';
import { TextField } from '../../components/Form/TextField';
import { CompanySchema } from '../../schemas/CompanySchema';
import { Formik, useFormik } from 'formik';
import { Button, Paper, Dialog, Checkbox } from '@mui/material';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Cancel } from '@mui/icons-material';
import DefaultImage from '../../assets/images/image-upload-1.png';
import ImageUploading from 'react-images-uploading';
import IconImage from '../../assets/images/icon-image.svg';
import app_form_style from '../../assets/scss/_app.module.scss';
import form_style from '../../assets/scss/_form_field.module.scss';
import styles from './addCompany.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { resizedataURL, getCroppedImg } from '../../helpers/common_service';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import button_style from '../../assets/scss/_button.module.scss';
import screen from '../../assets/scss/_app.scss';
import { getCompanies } from '../../helpers/common_service';
// import BackButton from '../../components/Form/Button/BackButton';
import Loader from '../../components/Loader';
import { Grid } from '@mui/material';
import { getCompaniesLists } from '../../store/actions';
import event_styles from '../../components/ModalForm/modalForm.module.scss';
import { CountryDropdown } from 'react-country-region-selector';

const minTabletWidth = parseInt(screen.minTablet);

const CreateCompany = () => {
  const dispatch = useDispatch();
  const loginUser = useSelector((state) => state.login.authUser);
  const [companyData, setCompanyData] = useState({});
  const editCompany = useLocation().pathname.includes('edit');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [fixedImageResolutions, setFixedImageResolutions] = useState({
    width: 500,
    height: 500
  });
  const [image, setImages] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [crop, setCrop] = useState({
    aspect: 9 / 16,
    x: 81,
    y: 81,
    width: 361,
    height: 361,
    unit: 'px'
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [prevSavedData, setPrevSavedData] = useState({});
  const [isToasterActive, setIsToasterActive] = useState(false);
  const [canSave, setCanSave] = useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();

  const handleImageChanges = async (image) => {
    let newImage = await resizedataURL(image, fixedImageResolutions.width, fixedImageResolutions.height);
    setImages(newImage);
    setIsOpen(true);
  };

  const cropImageNow = () => {
    if (completedCrop) {
      getCroppedImg(image, completedCrop, 'newFile.jpeg').then((res) => {
        formik.setFieldValue('new_logo', res);
      });
    } else {
      getCroppedImg(image, crop, 'newFile.jpeg').then((res) => {
        formik.setFieldValue('new_logo', res);
      });
    }
    setIsOpen(false);
    setImages(null);
    setCompletedCrop(null);
  };

  const onSubmit = (values) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('company[name]', values.name);
    formData.append('company[website]', values.website);
    formData.append('company[is_employees_email_subscribed]', values.is_employees_email_subscribed);
    // formData.append('company[is_employees_telegram_subscribed]', values.is_employees_telegram_subscribed);
    formData.append('company[city]', values.city);
    formData.append('company[country]', values.country);

    if (values.new_logo?.file) {
      formData.append(editCompany ? 'updated_logo' : 'company[logo]', values.new_logo.file, values.new_logo.file.name);
    }

    if (editCompany) {
      if (!values.logo) {
        formData.append('removed_logo', true);
      }
      axiosApi
        .patch(`/companies/${id}`, formData, {
          headers: {
            Authorization: `Bearer ${loginUser['remember_token']}`,
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(() => {
          setLoading(false);
          navigate('/company-list', { state: { company_icon_updated: true } });
          toast.success(t('company.addCompany.companyUpdateSuccess'));
        })
        .catch((err) => {
          setIsToasterActive(true);
          setLoading(false);
          toast.error(t(err.response?.data?.message_key) ?? t('company.addCompany.companyUpdateFailed'), {
            onClose: () => setIsToasterActive(false)
          });
        });
    } else {
      axiosApi
        .post(`/companies`, formData, {
          headers: {
            Authorization: `Bearer ${loginUser['remember_token']}`,
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(() => {
          setLoading(false);
          navigate('/company-list');
          toast.success(t('company.addCompany.companyCreateSuccess'));
          getCompanies();
          getCompaniesLists(dispatch, { is_dropdown: true });
        })
        .catch((err) => {
          setLoading(false);
          toast.error(t(`company.addCompany.${err.response?.data?.message_key || 'companyCreateFailed'}`));
        });
    }
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      website: 'https://',
      logo: '',
      new_logo: '',
      is_employees_email_subscribed: true,
      // is_employees_telegram_subscribed: true,
      city: '',
      country: ''
    },
    validationSchema: CompanySchema(
      t('company.addCompany.yupCompanyName'),
      t('company.addCompany.pleaseEnterCity'),
      t('company.addCompany.pleaseSelectCountry')
    ),
    onSubmit
  });

  const getCompanyData = () => {
    setIsPageLoading;
    axiosApi
      .get(`/companies/${id}`)
      .then((res) => setCompanyData(res.data.data.attributes))
      .catch(() => toast.error(t('company.addCompany.companyDataFetchFailed')))
      .finally(() => setIsPageLoading(false));
  };

  const setValues = () => {
    let obj = {
      name: companyData.name,
      website: companyData.website,
      logo: companyData.logo,
      new_logo: '',
      is_employees_email_subscribed: companyData.is_employees_email_subscribed,
      // is_employees_telegram_subscribed: companyData.is_employees_telegram_subscribed,
      city: companyData.city,
      country: companyData.country
    };
    formik.setValues(obj);
    setPrevSavedData(obj);
  };

  useEffect(() => {
    if (editCompany && id) {
      getCompanyData();
    }
  }, []);

  useEffect(() => {
    if (Object.keys(companyData).length > 0) {
      setValues();
    }
  }, [companyData]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    if (windowWidth < minTabletWidth && fixedImageResolutions.width > 200) {
      setFixedImageResolutions({ width: 200, height: 200 });
      setCrop({ aspect: 9 / 16 });
    } else if (fixedImageResolutions.width < 500 && windowWidth > minTabletWidth) {
      setFixedImageResolutions({ width: 500, height: 500 });
      setCrop({ aspect: 9 / 16 });
    }
    return () => window.removeEventListener('resize', handleResize);
  }, [windowWidth]);

  useEffect(() => {
    if (editCompany) {
      if (JSON.stringify(formik.values) !== JSON.stringify(prevSavedData)) setCanSave(true);
      else setCanSave(false);
    }
  }, [formik.values, prevSavedData]);

  return (
    <>
      {editCompany && isPageLoading ? (
        <Loader isPageLoader={false} loading={false} />
      ) : (
        <>
          {/* <div>
            <BackButton />
          </div> */}
          <Paper elevation={0} className={`${app_form_style.flexHeightAuto}`}>
            <div className={`${styles.page_header} ${app_form_style.header_container}`}>
              <FontAwesomeIcon icon={faGlobe} className={app_form_style.solid_list_icon} />
              <span className={`${styles.page_name} ${app_form_style.title_name}`}>
                {`${editCompany ? t('edit') : t('add')} ${t('common.company')}`}
              </span>
            </div>
            <hr className={styles.horizontal_line} />
            <Formik>
              <form
                className={`${app_form_style.form_style} ${app_form_style.gridContainer}  ${app_form_style.grid_container}`}
                onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();
                }}>
                <div className={`${app_form_style.outerContainer}`}>
                  <div className={`${app_form_style.field_styles_con} `}>
                    <div className={`${app_form_style.grid_title_name} ${app_form_style.keyContainer}`}>
                      <div className={styles.gridTitleContainer}>
                        <span className={styles.field_name}>{t('company.addCompany.companyName')}</span>
                        <span className={app_form_style.required}>*</span>
                      </div>
                    </div>
                    <Grid item sm={6} md={5} className={app_form_style.valueContainer}>
                      <TextField
                        fieldType="alphaNumeric"
                        name="name"
                        value={formik.values.name}
                        className={styles.input_field}
                        onChange={formik.handleChange}
                      />
                      {formik.submitCount > 0 && formik.errors.name && (
                        <div className={styles.error_msg}>{formik.errors.name}</div>
                      )}
                    </Grid>
                  </div>
                  <div className={`${app_form_style.field_styles_con} ${styles.containerGrid}`}>
                    <div className={`  ${app_form_style.grid_title_name} ${app_form_style.keyContainer}`}>
                      {t('branch.general.city')} <span className={app_form_style.required}>*</span>
                    </div>
                    <div
                      className={`${form_style.grid_text_field_container} ${event_styles.grid_textField} ${app_form_style.valueContainer}`}>
                      <TextField
                        className={`${form_style.textField_prefix} ${form_style.TextField_style} ${app_form_style.TextField}`}
                        name="city"
                        fieldType="specialChar"
                        value={formik.values.city}
                        onChange={formik.handleChange}
                        error={formik.touched.city && formik.errors.city}
                      />
                    </div>
                  </div>
                  <div className={`${app_form_style.field_styles_con} ${styles.containerGrid}`}>
                    <div className={`  ${app_form_style.grid_title_name} ${app_form_style.keyContainer}`}>
                      <div>
                        {t('branch.general.country')}
                        <span className={app_form_style.required}>*</span>
                      </div>
                    </div>
                    <div
                      className={`${form_style.grid_text_field_container} ${event_styles.grid_textField} ${
                        formik.values.country === '' ? form_style.custom_dropdown_placeholder : ''
                      } ${app_form_style.valueContainer}`}>
                      <CountryDropdown
                        id="country"
                        defaultOptionLabel={t('branch.addBranch.form.selectCountry')}
                        name="country"
                        className={` ${styles.text_field}  ${form_style.icon_remove}`}
                        value={formik.values.country}
                        onChange={(_, e) => {
                          formik.handleChange(e);
                        }}
                        errors={formik.errors.country}
                      />
                      {formik.touched.country && formik.errors.country && (
                        <div className={`${styles.error_msg}`}>{formik.errors.country}</div>
                      )}
                    </div>
                  </div>
                  <div className={`${app_form_style.field_styles_con} ${styles.containerGrid}`}>
                    <div className={app_form_style.keyContainer}>
                      <span className={styles.field_name}>{t('company.addCompany.website')}</span>
                    </div>
                    <div className={app_form_style.valueContainer}>
                      <div className={`${styles.input_field_container}`}>
                        <TextField
                          type="text"
                          name="website"
                          value={formik.values.website}
                          className={styles.input_field}
                          onChange={formik.handleChange}
                          error={formik.errors.website}
                        />
                        {formik.submitCount > 0 && formik.errors.website && (
                          <div className={styles.error_msg}>{formik.errors.website}</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={`${app_form_style.field_styles_con} ${styles.containerGrid} `}>
                    <div className={app_form_style.keyContainer}></div>
                    <div className={app_form_style.valueContainer}>
                      <div>
                        <Checkbox
                          name={t('company.addCompany.employeesEmailSubscription')}
                          className={styles.check_box}
                          onChange={(e) => {
                            formik.setFieldValue('is_employees_email_subscribed', e.target.checked);
                          }}
                          checked={formik.values.is_employees_email_subscribed}
                        />
                        <span className={`${styles.label_content}`}>
                          {t('company.addCompany.employeesEmailSubscription')}
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* <div className={`${app_form_style.field_styles_con} ${styles.containerGrid} `}>
                    <div className={app_form_style.keyContainer}></div>
                    <div className={app_form_style.valueContainer}>
                      <div>
                        <Checkbox
                          name={t('company.addCompany.employeesTelegramSubscription')}
                          className={styles.check_box}
                          onChange={(e) => {
                            formik.setFieldValue('is_employees_telegram_subscribed', e.target.checked);
                          }}
                          checked={formik.values.is_employees_telegram_subscribed}
                        />
                        <span className={`${styles.label_content}`}>
                          {t('company.addCompany.employeesTelegramSubscription')}
                        </span>
                      </div>
                    </div>
                  </div> */}
                  <div className={`${app_form_style.field_styles_con} ${styles.containerGrid}`}>
                    <div className={styles.imageUploadContainer}>
                      <div className={`${app_form_style.title_name_container} pl-0`}>
                        <img src={IconImage} alt="" className={app_form_style.icon_image} />
                        <h4 className={`${app_form_style.title_name} ${form_style.text_xl}`}>
                          {t('company.addCompany.companyLogo')}
                        </h4>
                        <span className={app_form_style.required}>*</span>
                      </div>
                      <p className={`${app_form_style.name_details} ${form_style.text_xl} pl-0`}>
                        {t('company.addCompany.uploadCompanyLogoMsg')}
                      </p>
                      <div className={app_form_style.upload_image_container}>
                        <ImageUploading
                          name="logo"
                          value={formik.values.logo}
                          dataURLKey="data_url"
                          acceptType={['jpg', 'jpeg', 'png']}
                          onChange={(image) => {
                            if (image.length && image[0].data_url) handleImageChanges(image[0].data_url);
                            else formik.setFieldValue('new_logo', '');
                          }}>
                          {({ onImageUpload, onImageRemove, isDragging, dragProps }) => (
                            <button
                              className={form_style.button_upload}
                              style={isDragging ? { color: 'red' } : null}
                              onClick={onImageUpload}
                              {...dragProps}
                              type="button">
                              {(!editCompany && !formik.values.new_logo) ||
                              (editCompany && !formik.values.logo && !formik.values.new_logo) ? (
                                <img className={form_style.default_upload_image} src={DefaultImage} alt={''} />
                              ) : (
                                <div className={form_style.image_container}>
                                  <span
                                    className={form_style.remove_btn_container}
                                    onClick={(e) => e.stopPropagation()}>
                                    <Cancel
                                      className={form_style.remove_btn}
                                      onClick={() => {
                                        onImageRemove();
                                        formik.setFieldValue('logo', '');
                                        formik.setFieldValue('new_logo', '');
                                      }}
                                    />
                                  </span>
                                  <img
                                    className={form_style.default_upload_image}
                                    src={formik.values.new_logo ? formik.values.new_logo.data_url : formik.values.logo}
                                    alt={''}
                                  />
                                </div>
                              )}
                              <span className={form_style.text_drop_image}>{t('attachment.dropYourImageHere')}</span>
                              <span className={form_style.text_image_type}>{t('attachment.supportsJpgGifPng')}</span>
                              {formik.submitCount > 0 && formik.errors.logo && (
                                <span className={`${styles.error_msg} ${form_style.text_image_type}`}>
                                  {formik.errors.logo}
                                </span>
                              )}
                            </button>
                          )}
                        </ImageUploading>
                      </div>
                    </div>
                  </div>
                  {image && (
                    <Dialog open={isOpen}>
                      <div>
                        <ReactCrop
                          src={image}
                          crop={crop}
                          onChange={(c) => setCrop(c)}
                          onComplete={(c) => setCompletedCrop(c)}
                          aspect={1 / 1}
                          ruleOfThirds>
                          <img
                            src={image}
                            alt="Crop"
                            height={fixedImageResolutions.height}
                            width={fixedImageResolutions.width}
                          />
                        </ReactCrop>
                      </div>
                      <div className={styles.crop_button_container}>
                        <Button
                          type="button"
                          variant="contained"
                          className={`${button_style.cancel_button} ${button_style.btn} ${button_style.mobile_button_width}`}
                          onClick={() => {
                            setIsOpen(false);
                            setImages(null);
                          }}>
                          {t('cancel')}
                        </Button>
                        <Button
                          type="button"
                          variant="contained"
                          className={`${button_style.save_button} ${button_style.btn} ${button_style.mobile_button_width}`}
                          onClick={() => {
                            cropImageNow();
                          }}>
                          {t('crop')}
                        </Button>
                      </div>
                    </Dialog>
                  )}
                  <div
                    className={`${app_form_style.field_styles_con} ${styles.containerGrid} ${styles.buttons_container}`}>
                    <Button
                      className={`${styles.save_button} ${styles.btn} ${styles.mobile_button_width} ${
                        editCompany ? !canSave || isToasterActive || loading : loading ? button_style.disabled_btn : ''
                      }`}
                      variant="contained"
                      type="submit"
                      disabled={editCompany ? !canSave || isToasterActive || loading : loading}>
                      <span>{editCompany ? t('save') : t('add')}</span>
                    </Button>
                    <Button
                      className={`${styles.cancel_button} ${styles.btn} ${styles.mobile_button_width} ml-0`}
                      onClick={() => navigate(-1)}>
                      <span>{t('cancel')}</span>
                    </Button>
                  </div>
                </div>
              </form>
            </Formik>
          </Paper>
        </>
      )}
    </>
  );
};

export default CreateCompany;
