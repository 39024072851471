import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { axiosApi } from '../../../../helpers/api_helper';
import Table from '../../../../components/Table';
import CreateButton from '../../../../components/Form/Button/CreateButton';
import WarrantyForm from './AddWarranty';
import app_form_style from '../../../../assets/scss/_app.module.scss';
import styles from '../tabStyles.module.scss';
import { useState } from 'react';
import DeleteToaster from '../../../../components/Toaster/DeleteToaster';
import { hasCreateAccess, hasDeleteAccess, hasEditAcccess, MODEL_WARRANTY } from '../../../../helpers/access';
import { WARRANTY_TAB } from '../../../../helpers/constants';

const WarrantyList = ({
  id,
  alertHeadCells,
  warranties,
  canCreate,
  fetchWarranties,
  showHeader = true,
  showDeleteBtn = true,
  isLoading,
  onChange,
  totalRowsCount,
  totalPagesCount,
  showPagination
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [warrantyDetails, setWarrantyDetails] = useState({});
  const [isWarrantyFormOpen, setIsWarrantyFormOpen] = useState(false);
  const [isDeleteToasterOpen, setIsDeleteToasterOpen] = useState(false);
  canCreate = canCreate && hasCreateAccess(WARRANTY_TAB);

  if (warranties != null)
    warranties = warranties.map((a) => ({
      ...a.attributes,
      expiring_on_display: new Date(a.attributes.expiring_on)?.toLocaleDateString(),
      duration_display: a.attributes.duration
        ? a.attributes.duration +
          ' ' +
          (a.attributes.duration === 1 ? t('common.month') : t('asset.assetDetail.warrantyTab.months'))
        : ''
    }));

  const headCells = [
    {
      id: 'is_active',
      numeric: false,
      disablePadding: true,
      label: t('warranty.active'),
      width: '5%'
    },
    {
      id: 'expiring_on_display',
      numeric: false,
      disablePadding: true,
      label: t('warranty.expiringOn'),
      width: '15%'
    },
    {
      id: 'duration_display',
      numeric: true,
      disablePadding: true,
      label: t('warranty.duration'),
      width: '15%'
    },
    {
      id: 'notes',
      numeric: false,
      disablePadding: true,
      label: t('warranty.notes')
    },
    {
      id: 'action',
      numeric: true,
      disablePadding: false,
      label: t('common.action'),
      width: '15%'
    }
  ];

  const onDelete = (warrantyId) => {
    axiosApi
      .delete(`/warranties/${warrantyId}`)
      .then(() => {
        fetchWarranties();
        setIsDeleteToasterOpen(true);
      })
      .catch((e) => {
        toast.error(e.error || t('common.somethingWentsWrong'));
      });
  };

  const onEdit = (id) => {
    setIsWarrantyFormOpen(true);
    setWarrantyDetails(warranties.find((warranty) => warranty.id === id));
  };

  const handleViewClick = (warrantyId, column) => {
    if (column === alertHeadCells[1].id) {
      let product_id = warranties.find((warranty) => warranty.id === warrantyId).product_id;
      navigate(`/inventory-details/${product_id}`);
    }
  };

  return (
    <>
      {showHeader && (
        <div className={`${app_form_style.subheader_container} ${styles.tabAddButton}`}>
          {canCreate && (
            <CreateButton
              buttonTitle={t('table.tableList.addTitle', {
                titleName: 'warranty'
              })}
              onClick={() => setIsWarrantyFormOpen(true)}
              className={`${styles.addButton}`}
            />
          )}
        </div>
      )}

      <Table
        pageName="warranty"
        headCells={alertHeadCells || headCells}
        rows={warranties}
        viewBtnFn={(id, column) => {
          handleViewClick(id, column);
        }}
        viewBtn={false}
        viewActionColumn={hasEditAcccess(MODEL_WARRANTY) || hasDeleteAccess(MODEL_WARRANTY)}
        deleteBtn={showDeleteBtn && hasEditAcccess(MODEL_WARRANTY)}
        editBtnFn={onEdit}
        deleteBtnFn={onDelete}
        loading={isLoading}
        noDataMsg="asset.assetDetail.warrantyTab.noWarrantyHasBeenAdded"
        onChange={onChange}
        totalRowsCount={totalRowsCount}
        totalPagesCount={totalPagesCount}
        showPagination={showPagination}
      />

      {isWarrantyFormOpen && (
        <WarrantyForm
          productId={id}
          warrantyDetails={warrantyDetails}
          onClose={() => {
            setWarrantyDetails({});
            setIsWarrantyFormOpen(false);
          }}
          onSubmit={fetchWarranties}
        />
      )}
      {isDeleteToasterOpen && isLoading && (
        <DeleteToaster
          onClose={() => setIsDeleteToasterOpen(false)}
          message={t('asset.assetDetail.warrantyTab.warrantyDeletedSuccessfully')}
          canUndo={false}
        />
      )}
    </>
  );
};

export default WarrantyList;
