import { FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import styles from './Dropdown.module.scss';
import Divider from '@mui/material/Divider';

const Dropdown = ({
  id,
  name,
  icon,
  disabled,
  onChange,
  dataList,
  label,
  value,
  className,
  error,
  placeholder,
  showIcon = true,
  open,
  isFilter = false
}) => {
  const hasError = error !== undefined ? !!error : false;
  return (
    <>
      <div className={`${styles.text_container} ${className}`} id={id}>
        <FormControl className={styles.container} variant="filled" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel
            className={disabled ? styles.disable : value ? styles.label_blue : styles.label_pink}
            id="demo-simple-select-standard-label">
            {label}
          </InputLabel>
          <Select
            open={open}
            displayEmpty
            disabled={disabled}
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            label={label}
            className={`${styles.dropdown} ${className} ${disabled ? styles.disable : ''}`}
            name={name}
            value={dataList.length ? value : ''}
            onChange={onChange}
            IconComponent={showIcon && KeyboardArrowDownIcon}
            renderValue={
              value === ''
                ? () => (
                    <span className={styles.dropdownPlaceholder}>
                      {icon && <span>{icon}</span>}
                      <span className={styles.placeholder}>{placeholder}</span>
                    </span>
                  )
                : undefined
            }>
            {dataList.map((option) => [
              option.addSeparator && <Divider />,
              <MenuItem
                className={`${
                  ['delete', 'edit'].includes(option.value)
                    ? option.value === 'delete'
                      ? styles.deleteContainer
                      : styles.editContainer
                    : styles.dropdownContainer
                } ${!isFilter ? styles.dropdownItem : ''}
              `}
                key={option.value || ''}
                value={option.value || ''}>
                {option.itemIcon != null && <span className={styles.icon}>{option.itemIcon}</span>}
                <span>{option.label}</span>
              </MenuItem>
            ])}
          </Select>
        </FormControl>
      </div>
      {hasError && <div className={styles.error_msg}>{error}</div>}
    </>
  );
};

export default Dropdown;
