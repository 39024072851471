import * as Yup from 'yup';

export const ReserveSchema = (from_date, to_date, to_date_earlier, company, branch, assigned_to) =>
  Yup.object({
    from_date: Yup.string().required(from_date),
    to_date: Yup.date()
      .required(to_date)
      .when('from_date', (start_time, schema) => {
        if (start_time) {
          return schema.min(start_time, to_date_earlier);
        } else {
          return schema;
        }
      }),
    company: Yup.string().required(company),
    branch: Yup.string().required(branch),
    assigned_to: Yup.object().required(assigned_to)
  });
