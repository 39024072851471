import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCube, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { axiosApi } from '../../../../helpers/api_helper';
import ModalForm from '../../../../components/ModalForm';
import * as Yup from 'yup';
import appStyles from '../../../../assets/scss/_app.module.scss';

const WarrantyForm = ({ productId, warrantyDetails, onClose, onSubmit }) => {
  const { t } = useTranslation();
  const isEditWarranty = warrantyDetails?.id ? true : false;
  const formTitle = t(`asset.assetDetail.warrantyTab.${isEditWarranty ? 'edit' : 'add'}Warranty`);
  const [initialValues, setInitialValues] = useState({
    duration: '',
    expiring_on: '',
    notes: '',
    ...warrantyDetails
  });

  let preSavedData = isEditWarranty ? { ...initialValues } : {};

  const fields = [
    {
      id: nanoid(),
      label: t('warranty.duration'),
      name: 'duration',
      type: 'text',
      inputType: 'number',
      isRequired: true,
      suffixText: initialValues.duration === '1' ? t('common.month') : t('asset.assetDetail.warrantyTab.months'),
      label_size: 2,
      field_size: 10
    },
    {
      id: nanoid(),
      label: t('warranty.expiringOn'),
      name: 'expiring_on',
      type: 'date',
      isRequired: true,
      label_size: 2,
      field_size: 10
    },
    {
      id: nanoid(),
      label: t('warranty.notes'),
      name: 'notes',
      type: 'textArea',
      label_size: 2,
      field_size: 10
    }
  ];

  const handleSubmit = (values) => {
    values.product_id = productId;

    if (isEditWarranty) {
      axiosApi
        .patch(`/warranties/${warrantyDetails.id}`, values)
        .then((res) => {
          toast.success(t('asset.assetDetail.warrantyTab.warrantyUpdatedSuccessfully'));
          onClose();
          onSubmit(res.data.data.attributes);
        })
        .catch((err) => {
          const response = err.response?.data?.error?.base;
          toast.error(response && response[0] ? response[0] : t('common.somethingWentsWrong'));
        });
    } else {
      axiosApi
        .post(`/warranties`, values)
        .then((res) => {
          toast.success(t('asset.assetDetail.warrantyTab.warrantyAddedSuccessfully'));
          onClose();
          onSubmit(res.data.data.attributes);
        })
        .catch((err) => {
          const response = err.response?.data?.error?.base;
          toast.error(response && response[0] ? response[0] : t('common.somethingWentsWrong'));
        });
    }
  };

  const schema = Yup.object().shape({
    duration: Yup.string().required(t('asset.assetDetail.warrantyAdd.pleaseEnterDuration')),
    expiring_on: Yup.date().required(t('asset.assetDetail.warrantyAdd.pleaseEnterExpiringDate'))
  });

  const TitleIcon = (
    <FontAwesomeIcon icon={isEditWarranty ? faPenToSquare : faCube} className={appStyles.solid_list_icon} />
  );

  return (
    <ModalForm
      preSavedData={preSavedData}
      formTitle={formTitle}
      titleIcon={TitleIcon}
      isEditForm={isEditWarranty}
      initialValues={initialValues}
      fields={fields}
      onSubmit={handleSubmit}
      onClose={onClose}
      onValuesChange={(values) => setInitialValues(values)}
      validationSchema={schema}
    />
  );
};

export default WarrantyForm;
