import * as Yup from 'yup';
import { mobileSchema } from './CommonSchema';

export const ProfileSchema = (first_name, last_name, role, mobile, email, confirm_email) =>
  Yup.object({
    first_name: Yup.string().required(first_name),
    last_name: Yup.string().required(last_name),
    role: Yup.string().required(role),
    mobile_number: mobileSchema(mobile),
    email: Yup.string().email().required(email),
    confirm_email: Yup.string().email().required(confirm_email)
  });
