import * as Yup from 'yup';

export const InsuranceSchema = (start_date, end_date, end_date_earlier, insurance_company_name) =>
  Yup.object().shape({
    start_date: Yup.date().required(start_date),
    end_date: Yup.date()
      .when('start_date', (start_time, schema) => {
        if (start_time) {
          const currentDay = new Date(start_time.getTime());
          return schema.min(currentDay, end_date_earlier);
        } else {
          return schema;
        }
      })
      .required(end_date),
    insurance_company_name: Yup.string().required(insurance_company_name)
  });
