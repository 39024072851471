import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';
import { ExtensionOffOutlined } from '@mui/icons-material';
import { BrokenSchema } from '../../../schemas/AssetEventsSchema';
import ModalForm from '../../../components/ModalForm';
import { BROKEN } from '../../../helpers/constants';

const Broken = ({ eventDetails = {}, onClose, onSubmit }) => {
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState({
    brokenDate: '',
    notes: '',
    ...eventDetails
  });

  const fields = [
    {
      id: nanoid(),
      label: t('asset.assetDetail.moreAction.broken.brokenDate'),
      name: 'brokenDate',
      type: 'date',
      disableFuture: true,
      isRequired: true,
      label_size: 2,
      field_size: 10
    },
    {
      id: nanoid(),
      label: t('asset.assetDetail.moreAction.notes'),
      name: 'notes',
      type: 'textArea',
      label_size: 2,
      field_size: 10
    }
  ];

  const schema = BrokenSchema(t('asset.assetDetail.moreAction.broken.pleaseEnterBrokenDate'));

  return (
    <ModalForm
      formFor={BROKEN}
      formTitle={t('statuses.broken')}
      titleIcon={<ExtensionOffOutlined />}
      initialValues={initialValues}
      fields={fields}
      onSubmit={onSubmit}
      onClose={onClose}
      onValuesChange={(values) => setInitialValues(values)}
      isEditForm={initialValues.scheduled_status}
      validationSchema={schema}
    />
  );
};

export default Broken;
