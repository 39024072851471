import { ArrowBack, Lock, Visibility, VisibilityOff, Info } from '@mui/icons-material';
import { CircularProgress, Tooltip } from '@mui/material';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { axiosApi } from '../../helpers/api_helper';
import { resetPasswordSchema } from '../../schemas/PasswordResetSchema';
import CommonLoginLayout from '../Login/CommonLoginLayout';
import commonStyles from '../Login/index.module.scss';
import styles from './index.module.scss';

const ResetPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [passwordType, setSetPasswordType] = useState('password');
  const token = new URLSearchParams(window.location.search).get('token');

  const onSubmit = ({ password }) => {
    setLoading(true);
    axiosApi
      .patch(`/password_reset`, { token, password })
      .then(() => navigate('/reset-success'))
      .catch(() => toast.error('forgotPassword.passwordReset.resetFailed'))
      .finally(() => setLoading(false));
  };

  const { errors, values, handleChange, handleSubmit, touched } = useFormik({
    initialValues: { password: '', passwordConfirmation: '' },
    validationSchema: resetPasswordSchema(
      t('forgotPassword.passwordReset.yupPassword'),
      t('forgotPassword.passwordReset.yupConfirmPassword'),
      t('forgotPassword.passwordReset.yupPassMustMatch')
    ),
    onSubmit
  });

  return (
    <CommonLoginLayout className={commonStyles.container}>
      <div className={commonStyles.formHeader}>
        <span>{t('forgotPassword.passwordReset.resetPassword')}</span>
      </div>
      <p>{t('keepYourPasswordsPrivateNeverShareAPasswordWithAnyoneElse')}</p>
      <form
        className={commonStyles.inputContainer}
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(values);
        }}>
        <div className={commonStyles.label}>{t('login.form.password')}</div>
        <div className={commonStyles.fieldContainer}>
          <div className={commonStyles.field}>
            <span className={commonStyles.iconContainer}>
              <Lock className={commonStyles.icon} />
            </span>
            <input
              type={passwordType}
              name="password"
              className={commonStyles.input}
              value={values.password}
              onChange={handleChange}
            />
            <Tooltip title={t('forgotPassword.passwordReset.passwordRules')} arrow placement="right">
              <Info className={commonStyles.fieldIcon} />
            </Tooltip>
          </div>
          {touched.password && errors.password && <div className={commonStyles.error_msg}>{errors.password}</div>}
        </div>
        <div className={commonStyles.label}>{t('forgotPassword.passwordReset.confirmPassword')}</div>
        <div className={commonStyles.fieldContainer}>
          <div className={commonStyles.field}>
            <span className={commonStyles.iconContainer}>
              <Lock className={commonStyles.icon} />
            </span>
            <input
              type={passwordType}
              name="passwordConfirmation"
              className={commonStyles.input}
              value={values.passwordConfirmation}
              onChange={handleChange}
            />
            {passwordType === 'text' ? (
              <Visibility onClick={() => setSetPasswordType('password')} className={commonStyles.fieldIcon} />
            ) : (
              <VisibilityOff onClick={() => setSetPasswordType('text')} className={commonStyles.fieldIcon} />
            )}
          </div>
          {touched.passwordConfirmation && errors.passwordConfirmation && (
            <div className={commonStyles.error_msg}>{errors.passwordConfirmation}</div>
          )}
        </div>
        <button type="submit" className={commonStyles.button}>
          {isLoading ? (
            <>
              <span className={commonStyles.circularProgress}>
                <CircularProgress size="small" />
              </span>
              {t('forgotPassword.passwordReset.resetting')}
            </>
          ) : (
            t('forgotPassword.passwordReset.resetPassword')
          )}
        </button>
      </form>
      <div className={styles.backLinkContainer}>
        <Link to="/login" className={styles.backLink}>
          <ArrowBack />
          {t('forgotPassword.backToLogin')}
        </Link>
      </div>
    </CommonLoginLayout>
  );
};

export default ResetPassword;
