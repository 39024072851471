import { Autocomplete, TextField } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import styles from './autoComplete.module.scss';

const AutoComplete = ({ options, value, onChange, disabled }) => {
  return (
    <Autocomplete
      disabled={disabled}
      disableClearable
      id="auto-select"
      options={options}
      value={value}
      onChange={onChange}
      className={styles.auto_complete_style}
      popupIcon={<KeyboardArrowDownIcon />}
      renderInput={(params) => <TextField {...params} />}
    />
  );
};

export default AutoComplete;
