import styles from './DeleteButton.module.scss';
import button_style from '../../../assets/scss/_button.module.scss';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
const DeleteButton = ({ buttonTitle, onClick, className = '' }) => {
  const { t } = useTranslation();
  return (
    <>
      <Button
        sx={{ transition: 'scale 100ms ease' }}
        className={`${button_style.btn} ${styles.delete_button} ${className}`}
        onClick={onClick}>
        <span className={`${styles.addBtnText}`}>{buttonTitle || t('alert.clear')}</span>
      </Button>
    </>
  );
};

export default DeleteButton;
