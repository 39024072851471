import { Button, Card } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import defaultLogo from '../../../assets/images/CompanyIcon.jpeg';
import styles from './companyDetail.module.scss';
import button_styles from '../../../assets/scss/_button.module.scss';
import { useTranslation } from 'react-i18next';
import { hasEditAcccess, isUserSuperAdmin, MODEL_COMPANY } from '../../../helpers/access';

const CompanyDetail = ({ companyDetails, setIsOpen, setDeletedCompanyId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Card className={styles.company_card} key={companyDetails.id}>
      <div className={styles.logo_and_name}>
        <span className={styles.company_logo}>
          <img src={companyDetails.logo || defaultLogo} />
        </span>

        <div className={styles.content}>
          <span>
            <a href={companyDetails?.website} target="_blank" rel="noreferrer">
              {companyDetails.name}
            </a>
          </span>
          <div className={styles.location}>
            {companyDetails.city &&
              t('company.companyList.companyHeadquarterLocation', {
                city: companyDetails.city,
                country: companyDetails.country
              })}
          </div>
          <p className={styles.website}>
            {companyDetails.website && (
              <a href={companyDetails.website} target="_blank" rel="noreferrer">
                {companyDetails?.website}
              </a>
            )}
          </p>
        </div>
      </div>

      <div className={styles.content_actions}>
        {hasEditAcccess(MODEL_COMPANY) && (
          <Button
            variant="contained"
            className={`${button_styles.save_button} ${button_styles.btn} ${styles.company_btn}`}
            onClick={() => navigate(`/edit-company/${companyDetails.id}`)}>
            <span>{t('Edit')}</span>
          </Button>
        )}
        {isUserSuperAdmin() && (
          <Button
            variant="contained"
            className={`${button_styles.cancel_button} ${button_styles.btn} ${styles.company_btn}`}
            onClick={() => {
              setDeletedCompanyId(companyDetails.id);
              setIsOpen(true);
            }}>
            <span>{t('Delete')}</span>
          </Button>
        )}
      </div>
    </Card>
  );
};

export default CompanyDetail;
