import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { nanoid } from 'nanoid';
import FeedbackIcon from '@mui/icons-material/Feedback';
import { axiosApi } from '../../helpers/api_helper';
import ModalForm from '../ModalForm';

const FeedbackForm = ({ onClose }) => {
  const { t } = useTranslation();
  const [feedbackTypes, setFeedbackTypes] = useState([]);
  const [initialValues, setInitialValues] = useState({
    type: '',
    content: ''
  });

  const fields = [
    {
      id: nanoid(),
      label: t('asset.assetDetail.type'),
      name: 'type',
      type: 'dropDown',
      isRequired: true,
      values: feedbackTypes,
      label_size: 2,
      field_size: 10
    },
    {
      id: nanoid(),
      label: t('feedback.title'),
      name: 'content',
      isRequired: true,
      type: 'textArea',
      label_size: 2,
      field_size: 10
    }
  ];

  const schema = Yup.object().shape({
    type: Yup.string().required(t('feedback.validationMessage.type')),
    content: Yup.string().required(t('feedback.validationMessage.content'))
  });

  const handleClose = () => onClose(false);

  const handleSubmit = (values) => {
    axiosApi
      .post('/feedbacks', { feedback: { ...values, feedback_type: values.type } })
      .then(() => {
        toast.success(t('feedback.submitSuccess'));
        onClose(false);
      })
      .catch(() => toast.error(t('feedback.submitFailed')));
  };

  const getFeedbackTypes = () => {
    axiosApi
      .get('/feedbacks/feedback_types')
      .then((res) => setFeedbackTypes(res.data.map((type) => ({ label: t(`feedback.types.${type}`), value: type }))))
      .catch(() => toast.error(t('common.somethingWentsWrong')));
  };

  useEffect(getFeedbackTypes, []);

  return (
    <ModalForm
      formTitle={t('feedback.title')}
      titleIcon={<FeedbackIcon />}
      initialValues={initialValues}
      fields={fields}
      onSubmit={handleSubmit}
      onClose={handleClose}
      onValuesChange={(values) => setInitialValues(values)}
      validationSchema={schema}
    />
  );
};

export default FeedbackForm;
