import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';
import { toast } from 'react-toastify';
import { Logout } from '@mui/icons-material';
import { CheckOutSchema } from '../../../schemas/AssetEventsSchema';
import { axiosApi } from '../../../helpers/api_helper';
import ModalForm from '../../../components/ModalForm';
import { CHECK_OUT } from '../../../helpers/constants';
import UserForm from '../../User/Form';

const CheckOut = ({
  eventDetails = {},
  onClose,
  assetData,
  onSubmit,
  prefilledDetails,
  scheduledStatus,
  onDeleteScheduledEvent
}) => {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [isUserFormOpen, setisUserFormOpen] = useState(false);
  const [isNewUserAdded, setIsNewUserAdded] = useState(false);

  let obj = {};
  if (prefilledDetails) {
    obj = {
      ...prefilledDetails?.formData,
      checkOutDate:
        prefilledDetails.formData.checkOutDate !== ''
          ? prefilledDetails.formData.checkOutDate.$d
            ? new Date(prefilledDetails.formData.checkOutDate.$d)
            : new Date(prefilledDetails.formData.checkOutDate)
          : '',
      dueToDate:
        prefilledDetails.formData.dueToDate !== ''
          ? prefilledDetails.formData.dueToDate.$d
            ? new Date(prefilledDetails.formData.dueToDate.$d)
            : new Date(prefilledDetails.formData.dueToDate)
          : '',
      user: prefilledDetails.newUserId
    };
  }
  const [initialValues, setInitialValues] = useState({
    company: assetData?.company_id,
    branch: assetData?.branch_id,
    user: scheduledStatus ? scheduledStatus.user : '',
    checkOutDate: scheduledStatus ? new Date(scheduledStatus.checkOutDate) : '',
    dueToDate: scheduledStatus && scheduledStatus.dueToDate ? new Date(scheduledStatus.dueToDate) : '',
    notes: scheduledStatus ? scheduledStatus.notes : '',
    isSendMail: scheduledStatus ? scheduledStatus.isSendMail : false,
    ...eventDetails
  });

  let preSavedData = Object.keys(obj).length ? obj : { ...initialValues, ...eventDetails };

  const fields = [
    {
      id: nanoid(),
      label: t('asset.assetDetail.moreAction.checkOut.assignTo'),
      name: 'user',
      type: 'autoComplete',
      isRequired: true,
      disabled: !initialValues.branch,
      addModel: () => {
        setisUserFormOpen(true);
      },
      values: users,
      label_size: 2,
      field_size: 8
    },
    {
      id: nanoid(),
      label: t('asset.assetDetail.moreAction.checkOut.assignDate'),
      name: 'checkOutDate',
      type: 'date',
      maxDate: new Date(),
      isRequired: true,
      label_size: 2,
      field_size: 10
    },
    {
      id: nanoid(),
      label: t('asset.assetDetail.moreAction.checkOut.dueToDate'),
      name: 'dueToDate',
      type: 'date',
      disabled: !initialValues.checkOutDate,
      minDate: initialValues.checkOutDate,
      label_size: 2,
      field_size: 10
    },
    {
      id: nanoid(),
      label: t('asset.assetDetail.moreAction.notes'),
      name: 'notes',
      type: 'textArea',
      label_size: 2,
      field_size: 10
    }
  ];

  const schema = CheckOutSchema(
    t('common.validation.pleaseSelectCompany'),
    t('common.validation.pleaseSelectBranch'),
    t('common.validation.pleaseSelectUser'),
    t('asset.assetDetail.moreAction.checkOut.pleaseEnterCheckOutDate'),
    t('common.pleaseEnterEmail')
  );

  const getUsers = () => {
    axiosApi
      .get('/users', {
        params: {
          filter: { branch_id: assetData?.branch_id },
          is_dropdown: true
        }
      })
      .then((res) => {
        setUsers(
          res.data.data.map((user) => ({
            label: user.attributes.full_name,
            value: user.attributes.id
          }))
        );
      })
      .catch(() => toast.error(t('asset.assetDetail.statusInfo.failedUser')));
  };

  const handleNewUser = (newUser) => {
    getUsers();
    setInitialValues({ ...initialValues, user: newUser });
    setIsNewUserAdded(true);
  };

  useEffect(() => {
    if (Object.keys(assetData).length) getUsers();
  }, [assetData]);

  return (
    <>
      <ModalForm
        preSavedData={preSavedData}
        formFor={CHECK_OUT}
        isNewUserAdded={isNewUserAdded}
        setIsNewUserAdded={setIsNewUserAdded}
        formTitle={t('statuses.checkOut')}
        titleIcon={<Logout />}
        initialValues={Object.keys(obj).length ? obj : initialValues}
        fields={fields}
        onSubmit={onSubmit}
        onClose={onClose}
        onValuesChange={(values) => setInitialValues(values)}
        validationSchema={schema}
        scheduledStatus={scheduledStatus}
        isEditForm={initialValues.scheduled_status}
        allowDelete={scheduledStatus ? true : false}
        onDelete={onDeleteScheduledEvent}
      />

      <UserForm
        isNavigatedFromEventsForm={true}
        isPreFilledValues={initialValues}
        open={isUserFormOpen}
        details={{}}
        onClose={() => setisUserFormOpen(false)}
        onSubmit={() => setisUserFormOpen(false)}
        onNewUserAdded={handleNewUser}
      />
    </>
  );
};

export default CheckOut;
