import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Close } from '@mui/icons-material';
import { Badge } from '@mui/material';
import { branchPage, userPage } from '../../helpers/constants';
import FilterModal from '../Form/FilterModal';
import textField_style from '../../components/Form/TextField/textField.module.scss';
import styles from './TableList.module.scss';
import FilterSelect from '../FilterSelect';
import { useEffect, useState } from 'react';

const Filters = ({
  setFilterOptions,
  initialValues,
  filterFields,
  onValuesChange,
  showFilterModal,
  setShowFilterModal,
  filterName,
  filterOptions,
  table,
  pageName,
  badgeInvisibility,
  filterDropdownLabel,
  filterDropdownOptions = []
}) => {
  const [isInputPresent, setIsInputPresent] = useState(false);
  const [searchText, setSearchText] = useState('');

  const handleSearch = (value) => {
    if (!isInputPresent) setIsInputPresent(true);
    setSearchText(value);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isInputPresent) setFilterOptions((prevState) => ({ ...prevState, searchText }));
    }, 500);
    return () => clearTimeout(timer);
  }, [searchText]);

  return (
    <>
      <div className={`${styles.filter_container}`}>
        {pageName === branchPage && (
          <Badge color="secondary" variant="dot" invisible={badgeInvisibility} className={styles.filter_modal_badge}>
            <div
              className={styles.filter_icon_container}
              id="filterModal"
              onClick={() => pageName === branchPage && setShowFilterModal(true)}>
              <FontAwesomeIcon icon={faFilter} className={styles.solid_list_icon} />
            </div>
          </Badge>
        )}
        <div
          className={`${textField_style.input_container} ${styles.search_field} ${
            pageName === userPage && styles.user_search_field
          }`}>
          {/* <Search className={styles.search_icon} disabled={filterOptions.searchText === ''} /> */}
          <input
            placeholder="Search..."
            className={styles.searchInput}
            onChange={(e) => handleSearch(e.target.value)}
            value={table?.tableRowCountObj.page === pageName ? searchText : ''}
          />
          {filterOptions.searchText !== '' && (
            <Close className={styles.search_cancel_button} onClick={() => setSearchText('')} />
          )}
        </div>
      </div>
      {filterDropdownOptions.length > 0 && (
        <FilterSelect
          value={filterOptions.customFilter}
          label={filterDropdownLabel}
          items={filterDropdownOptions}
          onChange={(value) => setFilterOptions((prev) => ({ ...prev, customFilter: value }))}
        />
      )}
      {showFilterModal && (
        <FilterModal
          pageName={pageName}
          showFilterModal={showFilterModal}
          setShowFilterModal={() => setShowFilterModal(false)}
          container={'filterModal'}
          onValuesChange={onValuesChange}
          filterFields={filterFields}
          initialValues={initialValues}
          filterName={filterName}
        />
      )}
    </>
  );
};

export default Filters;
