import Dashboard from '../pages/Dashboard';
import Landing from '../pages/LandingPage';
import Login from '../pages/Login';
import ForgotPassword from '../pages/ForgotPassword';
import CheckEmail from '../pages/ForgotPassword/CheckEmail';
import ResetPassword from '../pages/ForgotPassword/ResetPassword';
import ResetSuccess from '../pages/ForgotPassword/ResetSuccess';
import AssetDetails from '../pages/AssetDetail';
import AssetList from '../pages/Asset/AssetList';
import AddAsset from '../pages/Asset/AddAsset';
import AddBranch from '../pages/Branch/AddBranch';
import BranchList from '../pages/Branch/BranchList';
import CreateCompany from '../pages/Company/AddCompany';
import CompanyList from '../pages/Company/CompanyList';
import { Navigate } from 'react-router-dom';
import UserList from '../pages/User/UserList';
import Expiring from '../pages/Expiring';
import Alerts from '../pages/Alerts';
import Reports from '../pages/Reports';
import Feedbacks from '../pages/Feedbacks';
import ImportAssets from '../pages/Asset/ImportAssets';
import ImportUsers from '../pages/User/ImportUsers';
import MaintenanceRequestList from '../pages/MaintenanceRequest';
import AssetPropertiesList from '../pages/AssetProperties';
import RoleList from '../pages/Role/RoleList';
import {
  hasAccess,
  hasCreateAccess,
  isUserAdmin,
  isUserCompanyAdmin,
  isUserSuperAdmin,
  MODEL_BRANCH,
  MODEL_BRAND,
  MODEL_CATEGORY,
  MODEL_MAINTENANCE_REQUEST,
  MODEL_PRODUCT,
  MODEL_PRODUCT_TYPE,
  MODEL_USER
} from '../helpers/access';

const allRoutes = () => {
  const authenticatedRoutes = [
    { path: '/dashboard', component: () => (isUserAdmin() ? <Dashboard /> : <Navigate to="/" />) },
    { path: '/expiring', component: Expiring },
    { path: '/alerts', component: Alerts },
    {
      path: '/add-company',
      component: () => (isUserSuperAdmin() ? <CreateCompany /> : <Navigate to="/branch-list" />)
    },
    {
      path: '/company-list',
      component: () => <CompanyList />
    },
    {
      path: '/edit-company/:id',
      component: () => (isUserSuperAdmin() ? <CreateCompany /> : <Navigate to="/branch-list" />)
    },
    {
      path: '/add-inventory',
      component: () => (hasCreateAccess(MODEL_PRODUCT) ? <AddAsset /> : <Navigate to="/inventory-list" />)
    },
    {
      path: '/import-assets',
      component: () => (hasCreateAccess(MODEL_PRODUCT) ? <ImportAssets /> : <Navigate to="/inventory-list" />)
    },
    { path: '/inventory-list', component: AssetList },
    { path: '/edit-inventory/:id', component: AddAsset },
    { path: '/inventory-details/:id', component: AssetDetails },
    {
      path: '/add-branch',
      component: () => (hasCreateAccess(MODEL_BRANCH) ? <AddBranch /> : <Navigate to="/branch-list" />)
    },
    {
      path: '/branch-list',
      component: () => (hasAccess(MODEL_BRANCH) ? <BranchList /> : <Navigate to="/inventory-list" />)
    },
    {
      path: '/edit-branch/:id',
      component: () => (hasCreateAccess(MODEL_BRANCH) ? <AddBranch /> : <Navigate to="/" />)
    },
    {
      path: '/user-list',
      component: () => (hasAccess(MODEL_USER) ? <UserList /> : <Navigate to={-1} />)
    },
    {
      path: '/import-users',
      component: () => (hasCreateAccess(MODEL_USER) ? <ImportUsers /> : <Navigate to="/user-list" />)
    },
    { path: '/reports', component: () => (isUserAdmin() ? <Reports /> : <Navigate to={-1} />) },
    { path: '/feedbacks', component: Feedbacks },
    {
      path: '/maintenance-request-list',
      component: () => (hasAccess(MODEL_MAINTENANCE_REQUEST) ? <MaintenanceRequestList /> : <Navigate to={-1} />)
    },
    {
      path: '/asset-properties-list',
      component: () =>
        hasAccess(MODEL_BRAND) || hasAccess(MODEL_PRODUCT_TYPE) || hasAccess(MODEL_CATEGORY) ? (
          <AssetPropertiesList />
        ) : (
          <Navigate to={-1} />
        )
    },
    {
      path: '/role-list',
      component: () => (isUserCompanyAdmin() ? <RoleList /> : <Navigate to="/user-list" />)
    }
  ];
  return authenticatedRoutes;
};
const nonAuthenticatedRoutes = [
  { path: '/', component: Landing },
  { path: '/login', component: Login },
  { path: '/forgot-password', component: ForgotPassword },
  { path: '/check-email', component: CheckEmail },
  { path: '/reset-password', component: ResetPassword },
  { path: '/reset-success', component: ResetSuccess }
];

export { allRoutes, nonAuthenticatedRoutes };
