import * as Yup from 'yup';
import { mobileSchema } from './CommonSchema';

export const UserSchema = (first_name, last_name, role, email, mobile, company, branch, Super_admin, Company_admin) => {
  let BranchSchemaValidate;
  let branchValidate = {
    first_name: Yup.string().required(first_name),
    last_name: Yup.string().required(last_name),
    role: Yup.string().required(role),
    email: Yup.string().email().required(email),
    mobile_number: mobileSchema(mobile)
  };
  if (Super_admin) {
    BranchSchemaValidate = Yup.object({
      ...branchValidate,
      company: Yup.string().when('role', (role, schema) => {
        if (role !== 'super_admin') {
          return schema.required(company);
        }
      }),
      branch: Yup.string().when('role', (role, schema) => {
        if (role !== 'super_admin') {
          return schema.required(branch);
        }
      })
    });
    return BranchSchemaValidate;
  } else if (Company_admin) {
    BranchSchemaValidate = Yup.object({
      ...branchValidate,
      branch: Yup.string().required(branch).nullable()
    });
    return BranchSchemaValidate;
  }
  return (BranchSchemaValidate = Yup.object().shape({ ...branchValidate }));
};

export const RoleSchema = (name) =>
  Yup.object({
    name: Yup.string().required(name)
  });
