import {
  SET_COMMON_COMPANY_ID,
  REMOVE_COMMON_COMPANY_ID,
  SET_COMMON_BRANCH_ID,
  REMOVE_COMMON_BRANCH_ID,
  SET_COMMON_COMPANIES,
  SET_COMMON_BRANCHES,
  NEW_ALERTS_COUNT
} from './actionType';

const initialState = {
  companyId: '',
  branchId: '',
  companies: [],
  branches: [],
  newAlertsCount: 0
};

const common = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMMON_COMPANY_ID:
      return {
        ...state,
        companyId: action.payload.companyId
      };
    case REMOVE_COMMON_COMPANY_ID:
      return {
        ...state,
        companyId: initialState.companyId
      };
    case SET_COMMON_BRANCH_ID:
      return {
        ...state,
        branchId: action.payload.branchId
      };
    case REMOVE_COMMON_BRANCH_ID:
      return {
        ...state,
        branchId: initialState.branchId
      };
    case SET_COMMON_COMPANIES:
      return {
        ...state,
        companies: action.payload.companies
      };
    case SET_COMMON_BRANCHES:
      return {
        ...state,
        branches: action.payload.branches
      };
    case NEW_ALERTS_COUNT:
      return {
        ...state,
        newAlertsCount: action.payload.newAlertsCount
      };

    default:
      return state;
  }
};

export default common;
