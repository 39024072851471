import appStyles from '../../../assets/scss/_app.module.scss';
import styles from './AssetStatus.module.scss';
import { StopRounded } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import EventView from '../../AssetDetail/AssetStatusDetail';
import { useState } from 'react';
import { AVAILABLE_STATUS, statusKeyChange } from '../../../helpers/constants';

const PickStatusColor = (label, type) => {
  let redLabels = ['repair', 'broken', 'lostOrMissing', 'sold'];
  label = statusKeyChange[label] || label;

  if (label === AVAILABLE_STATUS) {
    if (type === 'icon') {
      return appStyles.green_status_icon;
    } else {
      return appStyles.green_status_background;
    }
  } else if (redLabels.includes(label)) {
    if (type === 'icon') {
      return appStyles.red_status_icon;
    } else {
      return appStyles.red_status_background;
    }
  } else {
    if (type === 'icon') {
      return appStyles.yellow_status_icon;
    } else {
      return appStyles.yellow_status_background;
    }
  }
};

const AssetStatusDisplay = ({ status }) => {
  const { t } = useTranslation();
  return (
    <span className={appStyles.status_container}>
      <StopRounded className={PickStatusColor(status, 'icon')} />
      <span>{t(`statuses.${statusKeyChange[status] || status}`)}</span>
    </span>
  );
};

const AssetStatusButton = ({ status, status_info }) => {
  const { t } = useTranslation();
  const [isEventDetailOpen, setIsEventDetailOpen] = useState(false);

  const handleClick = () => {
    if (status_info && Object.keys(status_info).length) setIsEventDetailOpen(true);
  };

  return (
    <>
      <div
        className={`${styles.status_container} ${PickStatusColor(statusKeyChange[status] || status)} ${
          statusKeyChange[status] === AVAILABLE_STATUS ? styles.status_container_available : ''
        }`}
        onClick={handleClick}>
        <span>{t(`statuses.${statusKeyChange[status] || status}`)}</span>
      </div>
      {isEventDetailOpen && (
        <EventView status={status} eventDetails={status_info} onClose={() => setIsEventDetailOpen(false)} />
      )}
    </>
  );
};
export { AssetStatusDisplay, AssetStatusButton };
