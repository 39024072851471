import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { nanoid } from 'nanoid';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid
} from '@mui/material';
import { SettingsOutlined } from '@mui/icons-material';
import appStyles from '../../../assets/scss/_app.module.scss';
import buttonStyles from '../../../assets/scss/_button.module.scss';
import styles from './setupColumn.module.scss';

const SetupColumn = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [checkedFields, setCheckedFields] = useState([]);
  const [columns, setColumns] = useState([]);
  const storedReportColumns = useSelector((state) => state.table.reportTableColumns);

  const defaultReportColumns = [
    { key: nanoid(), id: 'asset_photo', label: 'Inventory Photo' },
    { key: nanoid(), id: 'asset_name', label: t('asset.general.assetName'), hasViewLink: true },
    { key: nanoid(), id: 'status_display', label: t('asset.general.status') },
    { key: nanoid(), id: 'tag_id', label: t('asset.general.tagId') },
    {
      key: nanoid(),
      id: 'serial_number',
      label: t('asset.general.serialNumber')
    }
  ];

  const assetFields = [
    { key: nanoid(), id: 'asset_photo', label: 'Photo' },
    { key: nanoid(), id: 'asset_name', label: t('asset.general.assetName'), hasViewLink: true },
    { key: nanoid(), id: 'tag_id', label: t('asset.general.tagId') },
    { key: nanoid(), id: 'brand', label: 'Brand' },
    { key: nanoid(), id: 'purchase_price', label: 'Cost' },
    { key: nanoid(), id: 'created_by', label: 'Created by' },
    { key: nanoid(), id: 'created_at', label: 'Date Created' },
    { key: nanoid(), id: 'description', label: 'Description' },
    { key: nanoid(), id: 'model', label: 'Model' },
    { key: nanoid(), id: 'purchase_date', label: 'Purchase Date' },
    {
      key: nanoid(),
      id: 'serial_number',
      label: t('asset.general.serialNumber')
    }
  ];

  const linkingFields = [
    { key: nanoid(), id: 'category', label: 'Category' },
    { key: nanoid(), id: 'branch_name', label: 'Branch' }
  ];

  const eventFields = [
    { key: nanoid(), id: 'assigned_to', label: t('asset.general.assignedTo') },
    { key: nanoid(), id: 'event_date', label: 'Event Date' },
    { key: nanoid(), id: 'due_date', label: 'Event Due Date' },
    { key: nanoid(), id: 'status_display', label: t('asset.general.status') }
  ];

  const handleChange = (field, checked) => {
    if (checked) setColumns((prev) => [...prev, field]);
    else setColumns(columns.filter((column) => column.id !== field.id));
  };

  const handleSubmit = () => {
    dispatch({
      type: 'SET_REPORT_TABLE_COLUMNS',
      reportTableColumns: columns
    });
    onClose();
  };

  useEffect(() => {
    if (storedReportColumns.length === 0)
      dispatch({
        type: 'SET_REPORT_TABLE_COLUMNS',
        reportTableColumns: defaultReportColumns
      });
  }, []);

  useEffect(() => setColumns(storedReportColumns), [storedReportColumns]);

  useEffect(() => setCheckedFields(columns.map((column) => column.id)), [storedReportColumns, columns]);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Box className={styles.container}>
        <div className={styles.setup_form_container}>
          <div className={`${appStyles.title_name} ${styles.text_3xl} ${styles.title}`}>
            <SettingsOutlined fontSize="medium" />
            {t('report.selectTableColumns')}
          </div>
          <FormControl className={styles.form_control_container}>
            <div className={`${appStyles.title_name} ${styles.label} ${styles.sub_text}`}>
              {t('report.customReportInstruction')}
            </div>
            <Grid container rowSpacing={3} columnSpacing={3}>
              <Grid item sm={6}>
                <FormLabel className={styles.check_box_header}>{t('report.assetFields')}</FormLabel>
                <FormGroup>
                  {assetFields.map((field) => (
                    <FormControlLabel
                      key={field.key}
                      checked={checkedFields.includes(field.id)}
                      control={<Checkbox name={field.name} className={styles.check_box} />}
                      className={styles.label}
                      onChange={(a) => handleChange(field, a.target.checked)}
                      label={field.label}
                    />
                  ))}
                </FormGroup>
              </Grid>
              <Grid item sm={6} display="flex" flexDirection="column" gap={3}>
                <div>
                  <FormLabel className={styles.check_box_header}>{t('report.linkingFields')}</FormLabel>
                  <FormGroup>
                    {linkingFields.map((field) => (
                      <FormControlLabel
                        key={field.key}
                        checked={checkedFields.includes(field.id)}
                        control={<Checkbox name={field.name} className={styles.check_box} />}
                        className={styles.label}
                        onChange={(a) => handleChange(field, a.target.checked)}
                        label={field.label}
                      />
                    ))}
                  </FormGroup>
                </div>
                <div>
                  <FormLabel className={styles.check_box_header}>{t('report.eventFields')}</FormLabel>
                  <FormGroup>
                    {eventFields.map((field) => (
                      <FormControlLabel
                        key={field.key}
                        checked={checkedFields.includes(field.id)}
                        control={<Checkbox name={field.name} className={styles.check_box} />}
                        className={styles.label}
                        onChange={(a) => handleChange(field, a.target.checked)}
                        label={field.label}
                      />
                    ))}
                  </FormGroup>
                </div>
              </Grid>
            </Grid>
          </FormControl>
        </div>
        <hr className={styles.horizontal_line} />
        <div className={buttonStyles.buttons_container}>
          <Button
            variant="contained"
            className={`${buttonStyles.save_button} ${buttonStyles.btn} ${buttonStyles.mobile_button_width}`}
            onClick={handleSubmit}>
            <span>{t('apply')}</span>
          </Button>
          <Button
            variant="contained"
            className={`${buttonStyles.cancel_button} ${buttonStyles.btn} ${buttonStyles.mobile_button_width}`}
            onClick={onClose}>
            <span>{t('cancel')}</span>
          </Button>
        </div>
      </Box>
    </Dialog>
  );
};

export default SetupColumn;
