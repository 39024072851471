import { useRef, useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsDrilldown from 'highcharts/modules/drilldown';
import colors from '../../assets/scss/_color.scss';
import styles from './dashboard.module.scss';
import { axiosApi } from '../../helpers/api_helper';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
HighchartsDrilldown(Highcharts);

const DrilldownPieChart = ({ companyId }) => {
  const { t } = useTranslation();
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState({ data: [], drilldownData: [] });
  const branchId = useSelector((state) => state.common.branchId);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isLegendAlignedRight, setIsLegendAlignedRight] = useState(false);

  const isDataExists = chartData.data && chartData.data.length > 0;

  const getBrandChartData = () => {
    axiosApi
      .get('/products/product_chart', {
        params: {
          filter: {
            company: companyId,
            branch: branchId
          }
        }
      })
      .then((res) => {
        let data = res.data.data;
        let brandData = data.brands.map(({ id, name, percentage, count }) => ({
          name,
          y: percentage,
          count,
          drilldown: id
        }));
        let drilldownData = data.product_types.map(({ id, data }) => ({
          id,
          name: id,
          data: data.map(({ name, percentage, count }) => ({ name, y: percentage, count }))
        }));
        setChartData({ data: brandData, drilldownData });
      })
      .catch(() => toast.error(t('common.somethingWentsWrong')));
  };

  useEffect(getBrandChartData, [companyId, branchId]);

  useEffect(() => {
    const chart = Highcharts.chart(chartRef.current, {
      chart: {
        type: 'pie',
        height: 300,
        events: {
          redraw: function () {
            this.series[0].points.forEach(function (point) {
              const dataLabel = point.dataLabel;
              if (windowWidth < 601 || isLegendAlignedRight) {
                if (dataLabel) dataLabel.hide();
              } else {
                if (dataLabel) dataLabel.show();
              }
            });
          }
        }
      },
      colors: [colors.primary_400, colors.peacockBlue, colors.secondary_500, colors.danger, colors.primary_2_300],
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            format: isDataExists
              ? '<span style="color:{point.color}">{point.name} - {point.y:.1f}%</span>'
              : '<span style="color:{point.color}">{point.name}</span>'
          },
          enableMouseTracking: isDataExists
        }
      },
      tooltip: {
        headerFormat: '',
        pointFormat: '<span style="color:{point.color}">Count: <b>{point.count}<br/></span>'
      },
      series: [
        {
          name: 'Brands',
          colorByPoint: true,
          data: isDataExists ? chartData.data : [{ name: 'Empty', y: 100 }]
        }
      ],
      drilldown: {
        series: chartData.drilldownData
      },
      responsive: {
        rules: [
          {
            condition: {
              callback: function () {
                return windowWidth < 601 || isLegendAlignedRight;
              }
            },
            chartOptions: {
              chart: { height: isLegendAlignedRight ? 300 : 400 },
              legend: {
                maxHeight: 110,
                itemMarginTop: 2,
                itemMarginBottom: 10,
                layout: window.innerWidth > 1024 && window.innerWidth < 1200 ? 'vertical' : 'horizontal',
                align: window.innerWidth > 1024 && window.innerWidth < 1200 ? 'right' : 'center',
                verticalAlign: window.innerWidth > 1024 && window.innerWidth < 1200 ? 'middle' : 'bottom',
                borderWidth: 0,
                labelFormatter: function () {
                  if (this.visible) {
                    return `<span style="color:${this.color}"> ${this.name} - ${this.y}% (${this.count})</span>`;
                  } else {
                    return `<span style="color:#999999"> ${this.name} - ${this.y}% (${this.count})</span>`;
                  }
                }
              },
              plotOptions: {
                pie: {
                  dataLabels: {
                    enabled: false
                  },
                  showInLegend: true
                }
              }
            }
          }
        ]
      }
    });

    return () => chart.destroy();
  }, [chartData, isLegendAlignedRight]);

  useEffect(() => {
    function handleWindowResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    setIsLegendAlignedRight(windowWidth > 1024 && windowWidth < 1200);
  }, [windowWidth]);

  return <div ref={chartRef} className={styles.drilldownPieChart}></div>;
};

export default DrilldownPieChart;
