import React, { useEffect, useState } from 'react';
import app_form_style from '../../assets/scss/_app.module.scss';
import form_field_style from '../../assets/scss/_form_field.module.scss';
import button_style from '../../assets/scss/_button.module.scss';
import app_style from '../../assets/scss/_app.module.scss';
import { useTranslation } from 'react-i18next';
import { useFormik, Formik } from 'formik';
import { Button, Paper } from '@mui/material';
import { TextField } from '../../components/Form/TextField';
import Dropdown from '../../components/Form/Dropdown';
import { axiosApi } from '../../helpers/api_helper';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { BranchSchema } from '../../schemas/BranchSchema';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import styles from './branches.module.scss';
import { faCodeBranch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import event_styles from '../../components/ModalForm/modalForm.module.scss';
import Loader from '../../components/Loader';
import { getBranchesLists } from '../../store/actions';
import { isUserSuperAdmin } from '../../helpers/access';

const BranchForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const [companies, setCompanies] = useState([]);
  const [branch, setBranch] = useState({});
  const loginUser = useSelector((state) => state.login.authUser);
  const editBranch = useLocation().pathname.includes('edit');
  const [isLoading, setIsLoading] = useState(true);
  const [prevSavedData, setPrevSavedData] = useState({});
  const [canSave, setCanSave] = useState(false);
  const [isToasterActive, setIsToasterActive] = useState(false);

  const handleSubmit = (values) => {
    const branchData = {
      branch: {
        company_id: values.company,
        name: values.name,
        address_attributes: {
          street: values.street,
          city: values.city,
          postal_code: values.postal_code,
          state: values.state,
          country: values.country
        }
      }
    };
    if (editBranch) {
      branchData['branch']['address_attributes']['id'] = branch.address_id;
      axiosApi
        .patch(`/branches/${id}`, branchData)
        .then(() => {
          navigate('/branch-list');
          toast.success('Branch updated sucessfully');
        })
        .catch((error) => {
          setIsToasterActive(true);
          toast.error(t(`branch.addBranch.form.${error.response.data.message_key ?? 'failedToUpdateBranch'}`), {
            onClose: () => setIsToasterActive(false)
          });
        });
    } else {
      axiosApi
        .post(`/branches`, branchData)
        .then(() => {
          navigate('/branch-list');
          toast.success('Branch created sucessfully');
          getBranchesLists(dispatch, true, { company_id: branchData.branch.company_id, is_dropdown: true });
        })
        .catch((error) => {
          toast.error(t(`branch.addBranch.form.${error.response.data.message_key ?? 'failedToUpdateBranch'}`));
        });
    }
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      street: '',
      city: '',
      state: '',
      postal_code: '',
      country: '',
      company: isUserSuperAdmin() ? '' : loginUser['company_id']
    },
    onSubmit: handleSubmit,
    validationSchema: BranchSchema(
      t('branch.addBranch.form.pleasseEnterBranchName'),
      t('branch.addBranch.form.pleaseEnterStreet'),
      t('branch.addBranch.form.pleaseEnterCity'),
      t('branch.addBranch.form.pleaseSelectState'),
      t('branch.addBranch.form.pleaseEnterPostalCode'),
      t('branch.addBranch.form.pleaseSelectCountry'),
      t('common.validation.pleaseSelectCompany'),
      isUserSuperAdmin()
    )
  });

  const getCompanies = () => {
    axiosApi
      .get('/companies', { params: { is_dropdown: true } })
      .then((res) => {
        setCompanies(
          res.data.data.map((company) => ({
            label: company.attributes.name,
            value: company.attributes.id
          }))
        );
      })
      .catch((err) => console.log(err));
  };

  const getBranchData = () => {
    setIsLoading(true);
    axiosApi
      .get(`/branches/${id}`)
      .then((res) => setBranch(res.data.data.attributes))
      .catch(() => toast.error('Failed to fetch branch details!!'))
      .finally(() => setIsLoading(false));
  };

  const setValues = () => {
    let obj = {
      name: branch.name,
      street: branch.street,
      city: branch.city,
      state: branch.state,
      postal_code: branch.postal_code,
      country: branch.country,
      company: branch.company_id
    };
    formik.setValues(obj);
    setPrevSavedData(obj);
  };

  useEffect(() => {
    getCompanies();
    if (editBranch && id) {
      getBranchData();
    }
  }, []);

  useEffect(() => {
    if (Object.keys(branch).length > 0) {
      setValues();
    }
  }, [branch]);

  useEffect(() => {
    if (editBranch) {
      if (JSON.stringify(prevSavedData) !== JSON.stringify(formik.values)) setCanSave(true);
      else setCanSave(false);
    }
  }, [prevSavedData, formik.values]);

  return (
    <>
      {editBranch && isLoading ? (
        <Loader isPageLoader={true} loading={isLoading} />
      ) : (
        <>
          {/* <div>
            <BackButton />
          </div> */}
          <Paper elevation={0} className={`${app_form_style.flexHeightAuto}`}>
            <div className={`${app_form_style.header_container} ${styles.headerContainer}`}>
              <FontAwesomeIcon icon={faCodeBranch} className={app_style.solid_list_icon} />
              <h6 className={`${app_form_style.title_name} ${form_field_style.text_3xl}`}>
                {t(`branch.general.${editBranch ? 'edit' : 'add'}Branch`)}
              </h6>
            </div>
            <hr className={styles.horizontal_line} />

            <Formik value={formik}>
              <form
                className={`${app_form_style.form_style} ${app_form_style.grid_container} ${app_form_style.gridContainer}`}
                onSubmit={formik.handleSubmit}>
                <div className={`${app_form_style.outerContainer}`}>
                  <div className={`${app_form_style.field_styles_con} ${styles.containerGrid}`}>
                    <div className={`${app_form_style.grid_title_name} ${app_form_style.keyContainer}`}>
                      {t('common.company')}
                      <span className={app_form_style.required}>*</span>
                    </div>
                    <div className={app_form_style.valueContainer}>
                      <Dropdown
                        className={styles.dropdown}
                        disabled={!isUserSuperAdmin()}
                        name="company"
                        value={formik.values.company}
                        onChange={formik.handleChange}
                        dataList={companies}
                        placeholder={t('common.selectCompany')}
                        error={formik.touched.company && formik.errors.company}
                      />
                    </div>
                  </div>
                  <div className={`${app_form_style.field_styles_con} ${styles.containerGrid}`}>
                    <div className={`   ${app_form_style.grid_title_name} ${app_form_style.keyContainer}`}>
                      <div>
                        {t('branch.titleName')}
                        <span className={app_form_style.required}>*</span>
                      </div>
                    </div>
                    <div
                      className={`${form_field_style.grid_text_field_container} ${event_styles.grid_textField} ${app_form_style.valueContainer}`}>
                      <TextField
                        className={`${form_field_style.textField_prefix} ${form_field_style.TextField_style} ${app_form_style.TextField}`}
                        name="name"
                        fieldType="alphaNumeric"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.touched.name && formik.errors.name}
                      />
                    </div>
                  </div>
                  <div className={`${app_form_style.field_styles_con} ${styles.containerGrid}`}>
                    <div className={`  ${app_form_style.grid_title_name} ${app_form_style.keyContainer}`}>
                      {t('branch.general.street')} <span className={app_form_style.required}>*</span>
                    </div>
                    <div
                      className={`${form_field_style.grid_text_field_container} ${event_styles.grid_textField} ${app_form_style.valueContainer}`}>
                      <TextField
                        className={`${form_field_style.textField_prefix} ${form_field_style.TextField_style} ${app_form_style.TextField}`}
                        name="street"
                        fieldType="specialChar"
                        value={formik.values.street}
                        onChange={formik.handleChange}
                        error={formik.touched.street && formik.errors.street}
                      />
                    </div>
                  </div>
                  <div className={`${app_form_style.field_styles_con} ${styles.containerGrid}`}>
                    <div className={`  ${app_form_style.grid_title_name} ${app_form_style.keyContainer}`}>
                      {t('branch.general.city')} <span className={app_form_style.required}>*</span>
                    </div>
                    <div
                      className={`${form_field_style.grid_text_field_container} ${event_styles.grid_textField} ${app_form_style.valueContainer}`}>
                      <TextField
                        className={`${form_field_style.textField_prefix} ${form_field_style.TextField_style} ${app_form_style.TextField}`}
                        name="city"
                        fieldType="specialChar"
                        value={formik.values.city}
                        onChange={formik.handleChange}
                        error={formik.touched.city && formik.errors.city}
                      />
                    </div>
                  </div>
                  <div className={`${app_form_style.field_styles_con} ${styles.containerGrid}`}>
                    <div className={`  ${app_form_style.grid_title_name} ${app_form_style.keyContainer}`}>
                      <div>
                        {t('branch.general.postalCode')}
                        <span className={app_form_style.required}>*</span>
                      </div>
                    </div>
                    <div
                      className={`${form_field_style.grid_text_field_container} ${event_styles.grid_textField} ${app_form_style.valueContainer}`}>
                      <TextField
                        className={`${form_field_style.textField_prefix} ${form_field_style.TextField_style} ${app_form_style.TextField}`}
                        name="postal_code"
                        fieldType="number"
                        maxLength="10"
                        value={formik.values.postal_code}
                        onChange={formik.handleChange}
                        error={formik.touched.postal_code && formik.errors.postal_code}
                      />
                    </div>
                  </div>
                  <div className={`${app_form_style.field_styles_con} ${styles.containerGrid}`}>
                    <div className={`  ${app_form_style.grid_title_name} ${app_form_style.keyContainer}`}>
                      <div>
                        {t('branch.general.country')}
                        <span className={app_form_style.required}>*</span>
                      </div>
                    </div>
                    <div
                      className={`${form_field_style.grid_text_field_container} ${event_styles.grid_textField} ${
                        formik.values.country === '' ? form_field_style.custom_dropdown_placeholder : ''
                      } ${app_form_style.valueContainer}`}>
                      <CountryDropdown
                        id="country"
                        defaultOptionLabel={t('branch.addBranch.form.selectCountry')}
                        name="country"
                        className={` ${styles.text_field}  ${form_field_style.icon_remove}`}
                        value={formik.values.country}
                        onChange={(_, e) => {
                          formik.handleChange(e);
                          formik.setFieldValue('state', '');
                        }}
                        errors={formik.errors.country}
                      />
                      {formik.touched.country && formik.errors.country && (
                        <div className={`${styles.error_msg}`}>{formik.errors.country}</div>
                      )}
                    </div>
                  </div>
                  <div className={`${app_form_style.field_styles_con} ${styles.containerGrid}`}>
                    <div className={`${app_form_style.grid_title_name} ${app_form_style.keyContainer}`}>
                      {t('branch.general.state')} <span className={app_form_style.required}>*</span>
                    </div>
                    <div
                      className={`${form_field_style.grid_text_field_container} ${event_styles.grid_textField} ${
                        formik.values.state === '' ? form_field_style.custom_dropdown_placeholder : ''
                      } ${app_form_style.valueContainer}`}>
                      <RegionDropdown
                        id="country"
                        name="state"
                        blankOptionLabel={t('branch.general.selectState')}
                        defaultOptionLabel={t('branch.general.selectState')}
                        className={` ${styles.text_field}  ${form_field_style.icon_remove} `}
                        country={formik.values.country}
                        value={formik.values.state}
                        onChange={(_, e) => formik.handleChange(e)}
                        error={formik.errors.state}
                      />
                      {formik.touched.state && formik.errors.state && (
                        <div className={`${styles.error_msg}`}>{formik.errors.state}</div>
                      )}
                    </div>
                  </div>
                  <div
                    className={`${app_form_style.field_styles_con} ${button_style.buttons_container} ${styles.buttons_container}`}>
                    <Button
                      disabled={editBranch && (!canSave || isToasterActive)}
                      type="submit"
                      onClick={() => formik.submitForm}
                      variant="contained"
                      className={`${button_style.save_button} ${button_style.btn} ${button_style.mobile_button_width} ${
                        editBranch && (!canSave || isToasterActive) ? button_style.disabled_btn : ''
                      }`}>
                      <span>{editBranch ? t('save') : 'add'}</span>
                    </Button>
                    <Button
                      type="button"
                      variant="contained"
                      className={`${button_style.cancel_button} ${button_style.btn} ${button_style.mobile_button_width} ml-0`}
                      onClick={() => navigate(-1)}>
                      <span>{t('cancel')}</span>
                    </Button>
                  </div>
                </div>
              </form>
            </Formik>
          </Paper>
        </>
      )}
    </>
  );
};

export default BranchForm;
