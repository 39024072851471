import { Box, Modal, Button, Grid } from '@mui/material';
import { Formik, useFormik } from 'formik';
import { TextField } from '../../../components/Form/TextField';
import appStyles from '../../../assets/scss/_app.module.scss';
import buttonStyles from '../../../assets/scss/_button.module.scss';
import formStyles from '../../../assets/scss/_form_field.module.scss';
import styles from './addForm.module.scss';
import { useTranslation } from 'react-i18next';
import { axiosApi } from '../../../helpers/api_helper';
import { toast } from 'react-toastify';
import { AddNewValueSchema } from '../../../schemas/AssetSchema';

const newValue = {
  brand: 'brand',
  category: 'category',
  type: 'product_type'
};

const AddNewValue = ({
  addValueFor,
  isOpen,
  onClose,
  onAfterAdd,
  category = '',
  setNewValue = null,
  companyId,
  editValues = null
}) => {
  const { t } = useTranslation();
  const isEdit = editValues != null;
  let url;
  switch (addValueFor) {
    case 'brand':
      url = '/brands';
      break;
    case 'category':
      url = '/categories';
      break;
    case 'type':
      url = '/product_types';
      break;
  }

  const formik = useFormik({
    initialValues: isEdit ? { value: editValues.name, id: editValues.id } : { value: '' },
    onSubmit,
    validationSchema: AddNewValueSchema(t(`asset.assetAdd.addNew.${addValueFor}ValidateMsg`))
  });

  function onSubmit() {
    let params = {
      name: formik.values.value
    };
    if (addValueFor == 'type') {
      params['category_id'] = category;
    } else {
      params['company_id'] = companyId;
    }
    if (isEdit) {
      axiosApi
        .patch(`${url}/${formik.values.id}`, params)
        .then((res) => {
          onAfterAdd();
          toast.success(t(`asset.assetAdd.addNew.${res.data.message_key}`));
          if (setNewValue) setNewValue(res.data[`${newValue[addValueFor]}`].id);
          formik.resetForm();
        })
        .catch((err) => toast.error(t(`asset.assetAdd.addNew.${err.response.data.message_key}`)));
    } else {
      axiosApi
        .post(url, params)
        .then((res) => {
          onAfterAdd();
          toast.success(t(`asset.assetAdd.addNew.${res.data.message_key}`));
          if (setNewValue) setNewValue(res.data[`${newValue[addValueFor]}`].id);
          formik.resetForm();
        })
        .catch((err) => toast.error(t(`asset.assetAdd.addNew.${err.response.data.message_key}`)));
    }
  }
  return (
    <Modal
      open={isOpen}
      onClick={() => {
        onClose();
        formik.resetForm();
      }}>
      <Box className={styles.card} onClick={(e) => e.stopPropagation()}>
        <div className={`${appStyles.header_container} ${styles.text_3xl}`}>
          <span>{`${t(`asset.assetDetail.${isEdit ? 'edit' : 'addNew'}`)} ${t(
            `asset.assetDetail.${addValueFor}`
          )}`}</span>
        </div>
        <hr className={styles.horizontal_line} />
        {!isEdit && <p className="mx-5">{t(`asset.assetAdd.addNew.${addValueFor}Msg`)}</p>}
        <Formik>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit();
            }}>
            <Grid container rowSpacing={2} columnSpacing={{ sm: 2, md: 3 }} className={appStyles.grid_container}>
              <Grid item xs={12} sm={3} md={2} className={appStyles.grid_title_name}>
                {t(`asset.assetDetail.${addValueFor}`)}
                <span className={appStyles.required}>*</span>
              </Grid>
              <Grid item xs={12} sm={9} md={10}>
                <TextField
                  name="value"
                  value={formik.values.value}
                  onChange={formik.handleChange}
                  className={`${appStyles.TextField} ${formStyles.TextField_style}`}
                />
                {formik.touched.value && formik.errors.value && (
                  <div className={styles.error_msg}>{formik.errors.value}</div>
                )}
              </Grid>
            </Grid>
            <hr className={styles.horizontal_line} />
            <div className={`${buttonStyles.buttons_container} ${styles.buttons_container}`}>
              <Button
                type="submit"
                variant="contained"
                className={`${buttonStyles.save_button} ${buttonStyles.btn} ${buttonStyles.mobile_button_width} ${styles.btn}`}>
                <span>{t(`${isEdit ? 'save' : 'add'}`)}</span>
              </Button>
              <Button
                type="button"
                variant="contained"
                className={`${buttonStyles.cancel_button} ${buttonStyles.btn} ${buttonStyles.mobile_button_width} ${styles.btn}`}
                onClick={() => {
                  onClose();
                  formik.resetForm();
                }}>
                <span>{t('cancel')}</span>
              </Button>
            </div>
          </form>
        </Formik>
      </Box>
    </Modal>
  );
};

export default AddNewValue;
