import { faBan, faCheckCircle, faHourglassHalf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GppMaybe, Info, Report, ReportProblem } from '@mui/icons-material';
import i18n from 'i18next';
import appStyles from '../../assets/scss/_app.module.scss';
import styles from './maintenanceRequest.module.scss';
import Tooltip from '@mui/material/Tooltip';

const statusColor = (status) => {
  switch (status) {
    case 'accepted':
    case 'low':
      return appStyles.green_status_icon;
    case 'pending':
    case 'medium':
      return appStyles.orange_status_icon;
    case 'rejected':
    case 'high':
      return appStyles.red_status_icon;
    default:
      return '';
  }
};

const statusIcon = (status) => {
  switch (status) {
    case 'accepted':
      return <FontAwesomeIcon icon={faCheckCircle} />;
    case 'rejected':
      return <FontAwesomeIcon icon={faBan} />;
    case 'pending':
      return <FontAwesomeIcon icon={faHourglassHalf} />;
    case 'low':
      return <GppMaybe fontSize="small" />;
    case 'medium':
      return <ReportProblem fontSize="small" />;
    case 'high':
      return <Report fontSize="small" />;
    default:
      return <FontAwesomeIcon icon={faBan} />;
  }
};

export const StatusDisplay = ({ status }) => {
  return (
    <span className={`${statusColor(status)}`}>
      {statusIcon(status)}
      <span className="pl-2">{i18n.t(`maintenanceRequest.statuses.${status}`)}</span>
    </span>
  );
};

export const PriorityDisplay = ({ priority }) => {
  return (
    <span className={`${statusColor(priority)} ${styles.statusContainer}`}>
      {statusIcon(priority)}
      <span className="pl-1">{i18n.t(`maintenanceRequest.priorities.${priority}`)}</span>
    </span>
  );
};

export const InfoButton = ({ onClick }) => {
  return (
    <Tooltip title={i18n.t('info')} arrow={true} placement="top">
      <button className={styles.info} onClick={onClick}>
        <Info />
      </button>
    </Tooltip>
  );
};
