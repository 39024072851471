import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import appStyles from '../../../../assets/scss/_app.module.scss';
import styles from '../tabStyles.module.scss';
import InfoIcon from '@mui/icons-material/Info';

const ReservationCalendar = ({ product, reservations, onEdit, onAdd, latestAvailableDate }) => {
  const { t } = useTranslation();
  const [initialView] = useState('dayGridMonth');
  const dateCorrection = (date) => {
    const tempDate = new Date(`${date}`);
    const finalDate = new Date(tempDate.setDate(tempDate.getDate() + parseInt(1)));
    return finalDate.toISOString();
  };
  const reservationsDisplay = reservations.map((data) => ({
    ...data,
    title: data.reserved_to_user,
    start: data.from_date,
    end: dateCorrection(data.to_date),
    allDay: true,
    textColor: 'black'
  }));
  const canReserve = () => {
    return product.status === 'checkIn' || product.status_info?.dueToDate;
  };

  return (
    <div className={styles.tabContentContainer}>
      <div className={styles.reserve_tab_container}>
        {canReserve() ? (
          <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin]}
            events={reservationsDisplay}
            eventClassNames={styles.events}
            eventClick={(e) => {
              onEdit(e.event._def.publicId);
            }}
            initialView={initialView}
            customButtons={{
              SecondButton: {
                text: t('asset.assetDetail.reserveForm.add'),
                click: function () {
                  onAdd();
                }
              }
            }}
            dateClick={function (info) {
              onAdd(info.date.toISOString());
            }}
            headerToolbar={{
              end: 'SecondButton,today,prev,next'
            }}
            validRange={{
              start: latestAvailableDate
            }}
          />
        ) : (
          <div className={appStyles.emptyContainer}>
            <div className={styles.infoIcon}>
              <InfoIcon />
            </div>
            <span className={styles.emptyMsg}>
              {product.status === 'checkOut'
                ? t('asset.assetDetail.reserveTab.cantReserveNoDueDate')
                : t('asset.assetDetail.reserveTab.cantReserveUnavailable')}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReservationCalendar;
