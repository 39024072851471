import { React } from 'react';
import { useState } from 'react';
import { Checkbox, Box, FormControlLabel, Menu, MenuItem, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Formik, useFormik } from 'formik';
import styles from './filterModal.module.scss';
import buttonStyles from '../../../assets/scss/_button.module.scss';
import form_field_style from '../../../assets/scss/_form_field.module.scss';
import app_form_style from '../../../assets/scss/_app.module.scss';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { TextField } from '../TextField';
import ListItemText from '@mui/material/ListItemText';
import { branchPage } from '../../../helpers/constants';

const FilterModal = ({
  pageName,
  showFilterModal,
  setShowFilterModal,
  container,
  initialValues,
  onValuesChange,
  filterFields,
  filterName
}) => {
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues
  });

  const [selectedItems, setSelectedItems] = useState(initialValues);

  const checkBoxField = (field) => {
    return (
      <>
        <FormControlLabel
          control={
            <>
              <MenuItem key={field.key} value={field.key}>
                <Checkbox
                  className={styles.checkBox}
                  checked={selectedItems[field.name] || false}
                  onChange={(e) => {
                    setSelectedItems({
                      ...selectedItems,
                      [field.name]: e.target.checked
                    });
                  }}
                  name={field.name}
                />
                <ListItemText primary={field.label} />
              </MenuItem>
            </>
          }
        />
      </>
    );
  };

  const dropDownField = (field) => {
    let DropdownComponent =
      (field.name === 'country' && CountryDropdown) ||
      (field.name === 'state' && RegionDropdown) ||
      (field.name === 'city' && RegionDropdown);
    return (
      <>
        <DropdownComponent
          className={`${styles.dropdown} ${form_field_style.icon_remove} ${
            selectedItems[field.name] ? form_field_style.custom_dropdown_placeholder : ''
          }`}
          id={field.name}
          name={field.name}
          blankOptionLabel={t(`filterModal.dropDown.${field.blankOptionLabel}`)}
          defaultOptionLabel={t(`filterModal.dropDown.${field.defaultOptionLabel}`)}
          value={selectedItems[field.name] || ''}
          {...(field.name === 'state' && {
            country: selectedItems['country']
          })}
          onChange={(_, e) => {
            if (field.name === 'country') {
              setSelectedItems({
                ...selectedItems,
                [field.name]: e.target.value,
                state: ''
              });
            } else {
              setSelectedItems({
                ...selectedItems,
                [field.name]: e.target.value
              });
            }
          }}
        />
      </>
    );
  };

  const textField = (field) => {
    return (
      <>
        <TextField
          type={field.type}
          name={field.name}
          className={`${form_field_style.textField_prefix} ${form_field_style.TextField_style} ${
            app_form_style.TextField
          } ${styles.textField}  ${pageName === branchPage ? styles.cityTextField : ''}`}
          value={selectedItems[field.name] || ''}
          placeHolder={t(`filterModal.${field.placeholder}`)}
          label={field.label}
          onChange={(e) => {
            setSelectedItems({
              ...selectedItems,
              [field.name]: e.target.value
            });
          }}
          fieldType="specialChar"
        />
      </>
    );
  };

  return (
    <>
      <Menu
        id={container}
        anchorEl={document.getElementById(container)}
        open={showFilterModal}
        onClose={() => setShowFilterModal(false)}
        className={styles.filter_modal}>
        <Box className={styles.card}>
          <Formik>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit();
              }}>
              <div className={styles.filter_container}>
                <div
                  className={`
                    ${filterName === 'branches' && styles.branches_grid_container} ${
                    filterName === 'role' && styles.users_grid_container
                  }`}>
                  {filterFields.map((field, index) => {
                    return (
                      <div key={index}>
                        {field.type === 'checkbox' && checkBoxField(field)}
                        {field.type === 'dropdown' && dropDownField(field)}
                        {field.type === 'text' && textField(field)}
                      </div>
                    );
                  })}
                </div>
                <div className={styles.button_container}>
                  <span
                    onClick={() => {
                      onValuesChange(false);
                      setShowFilterModal(false);
                    }}
                    className={styles.clear_all}>
                    {t('filterModal.clearAll')}
                  </span>
                  <Button
                    type="button"
                    variant="contained"
                    className={`${buttonStyles.cancel_button} ${buttonStyles.btn} ${buttonStyles.mobile_button_width} ${styles.apply_button} `}
                    onClick={() => {
                      Object.keys(selectedItems).length
                        ? Object.keys(selectedItems).filter((key) => selectedItems[key]).length
                          ? onValuesChange(selectedItems)
                          : onValuesChange(false)
                        : onValuesChange(false);
                      setShowFilterModal(false);
                    }}>
                    <span>{t('filterModal.filter')}</span>
                  </Button>
                </div>
              </div>
            </form>
          </Formik>
        </Box>
      </Menu>
    </>
  );
};

export default FilterModal;
