import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { getDateString } from '../../../../helpers/common_service';
import ShowDetails from '../../../../components/ShowDetail';
import { hasEditAcccess, hasDeleteAccess, MODEL_WARRANTY } from '../../../../helpers/access';

const WarrantyDetails = ({ warranty, onClose, onEdit, onDelete }) => {
  const { t } = useTranslation();

  const warrantyDetails = [
    {
      key: nanoid(),
      label: t('warranty.duration'),
      value: `${warranty.duration} ${
        warranty.duration === 1 ? t('common.month') : t('asset.assetDetail.warrantyTab.months')
      }`
    },
    {
      key: nanoid(),
      label: t('warranty.expiringOn'),
      value: getDateString(warranty.expiring_on)
    },
    {
      key: nanoid(),
      label: t('warranty.notes'),
      value: warranty.notes
    }
  ];

  return (
    <ShowDetails
      detailFor={t('asset.assetDetail.activities.warrantyDetails')}
      details={warrantyDetails}
      onClose={onClose}
      canEdit={hasEditAcccess(MODEL_WARRANTY)}
      onEdit={onEdit}
      canDelete={hasDeleteAccess(MODEL_WARRANTY)}
      onDelete={onDelete}
    />
  );
};

export default WarrantyDetails;
