import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Paper, Button, MenuItem, Menu } from '@mui/material';
import Table from '../Table';
import appStyles from '../../assets/scss/_app.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { faUserGroup, faGlobe, faCube, faMessage, faWrench, faCodeBranch } from '@fortawesome/free-solid-svg-icons';
import styles from './TableList.module.scss';
import { SET_FILTERS } from '../../store/table/actionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { userPage, feedbackPage, maintenanceRequestPage } from '../../helpers/constants';
import { Add, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import Filters from './Filters';
import Switch from 'react-switch';
import screen from '../../assets/scss/_app.scss';

function ListTable({
  pageName,
  headCells,
  rows,
  viewBtn,
  editBtn,
  deleteBtn,
  addMenuItems,
  onMenuItemClick,
  editBtnLink,
  viewBtnLink,
  deleteBtnLink,
  viewBtnFn,
  editBtnFn,
  onDelete,
  viewActionColumn,
  filterOptionsChange = () => {
    /* do nothing */
  },
  loading = false,
  isAdvanceList = false,
  filterFields,
  initialValues,
  onValuesChange,
  filterName,
  hideTable,
  badgeInvisibility,
  onChange,
  totalRowsCount,
  totalPagesCount,
  onSortingValuesChange,
  tabs = [],
  currentTab,
  onTabChange,
  canUndo,
  filterDropdownLabel,
  filterDropdownOptions = [],
  showActiveSwitch = false,
  noDataMsg,
  addButtonType = true,
  addButtonTitle = 'add'
}) {
  const PageIcons = {
    branch: <FontAwesomeIcon icon={faCodeBranch} className={appStyles.solid_list_icon} />,
    company: <FontAwesomeIcon icon={faGlobe} className={appStyles.solid_list_icon} />,
    inventory: <FontAwesomeIcon icon={faCube} className={appStyles.solid_list_icon} />,
    user: <FontAwesomeIcon icon={faUserGroup} className={appStyles.solid_list_icon} />,
    feedback: <FontAwesomeIcon icon={faMessage} className={appStyles.solid_list_icon} />,
    maintenanceRequest: <FontAwesomeIcon icon={faWrench} className={appStyles.solid_list_icon} />
  };
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const table = useSelector((state) => state.table);
  const filterValues = useSelector((state) => state.filterModal);
  const companyId = useSelector((state) => state.common.companyId);
  const branchId = useSelector((state) => state.common.branchId);
  const [filterOptions, setFilterOptions] = useState({
    searchText: filterValues?.pageName === pageName ? table.searchText : '',
    customFilter: filterValues?.pageName === pageName ? table.customFilter : '',
    activeSwitchStatus: true
  });
  const minMobileScreen = parseInt(screen.minMobile);

  const AddComponent = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    return (
      <>
        {addMenuItems && (
          <div className={styles.dropdown_container}>
            <Button
              id="demo-customized-button"
              aria-controls={open ? 'demo-customized-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              variant="contained"
              disableElevation
              onClick={(e) => {
                addButtonType ? setAnchorEl(e.currentTarget) : navigate(addMenuItems[0].link);
              }}
              startIcon={<Add />}
              endIcon={addButtonType === true && (open ? <KeyboardArrowUp /> : <KeyboardArrowDown />)}
              className={`${styles.add_btn} ${styles.addbuttonTypeContainer}`}>
              <span className={styles.add_btn_content}>{addButtonType === true ? t('add') : addButtonTitle}</span>
            </Button>
            <Menu
              id="demo-customized-menu"
              MenuListProps={{
                'aria-labelledby': 'demo-customized-button'
              }}
              className={styles.add_menu}
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: 'bottom', horizontal: window.innerWidth > minMobileScreen ? 'right' : 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: window.innerWidth > minMobileScreen ? 'right' : 'left' }}
              open={open}
              onClose={() => setAnchorEl(null)}>
              {addMenuItems.map((menuItem, index) => (
                <MenuItem
                  className={styles.menuListItem}
                  key={index}
                  onClick={() => {
                    setAnchorEl(null);
                    menuItem.link ? navigate(menuItem.link) : onMenuItemClick();
                  }}
                  disableRipple>
                  {menuItem.icon}
                  {menuItem.label}
                </MenuItem>
              ))}
            </Menu>
          </div>
        )}
      </>
    );
  };

  const handleBeforeDelete = () => setIsLoading(true);
  const handleAfterDelete = () => setIsLoading(false);

  useEffect(() => {
    filterOptionsChange(filterOptions);
    dispatch({
      type: SET_FILTERS,
      companyId: companyId,
      branchId: branchId,
      searchText: filterOptions.searchText,
      customFilter: filterOptions.customFilter
    });
  }, [filterOptions, companyId, branchId]);

  useEffect(() => {
    if (filterDropdownOptions.length > 0 && !filterOptions.customFilter)
      setFilterOptions((prev) => ({ ...prev, customFilter: filterDropdownOptions[0].value }));
  }, [filterDropdownOptions]);

  useEffect(() => setIsLoading(loading), [loading]);

  const pageNamesArray = [feedbackPage, maintenanceRequestPage];

  return (
    <>
      <Paper
        elevation={0}
        className={`${appStyles.flexHeightAuto} ${(!rows.length || isLoading) && !isAdvanceList && appStyles.h_100}`}>
        <div
          className={`${appStyles.header_container} ${styles.header_container} ${
            pageName === userPage && styles.userHeader_container
          }
           ${
             pageNamesArray.includes(pageName) &&
             styles.feedback_header_container &&
             appStyles.feedback_header_container
           }
          `}>
          <div
            className={`${pageNamesArray.includes(pageName) && styles.feedback_title_container} ${
              styles.title_container
            }`}>
            <div className={styles.pageNamestyle}>
              {PageIcons[pageName]}
              <h4 className={`${appStyles.title_name} ${styles.title_name} ${styles.text_3xl}`}>
                {t(`table.tableList.title`, {
                  name: t(`table.tableList.${pageName}`)
                })}
              </h4>
            </div>
            <div
              className={`${styles.searchParentContainer} ${
                pageNamesArray.includes(pageName) && styles.feedbackSearchParentCon
              }`}>
              {isAdvanceList && (
                <>
                  {!hideTable && (
                    <div className={styles.other_branches_header} id="otherBranches">
                      <Filters
                        setFilterOptions={setFilterOptions}
                        initialValues={initialValues}
                        filterFields={filterFields}
                        onValuesChange={onValuesChange}
                        showFilterModal={showFilterModal}
                        setShowFilterModal={setShowFilterModal}
                        filterName={filterName}
                        filterOptions={filterOptions}
                        table={table}
                        pageName={pageName}
                        badgeInvisibility={pageName === userPage ? true : badgeInvisibility}
                      />
                    </div>
                  )}
                </>
              )}
              {!isAdvanceList && (
                <div
                  className={`${appStyles.subheader_container} ${styles.subheader_container} ${
                    pageNamesArray.includes(pageName) && styles.feedback_subheader_container
                  }`}>
                  <div>
                    <Filters
                      setFilterOptions={setFilterOptions}
                      initialValues={initialValues}
                      filterFields={filterFields}
                      onValuesChange={onValuesChange}
                      showFilterModal={showFilterModal}
                      setShowFilterModal={setShowFilterModal}
                      filterName={filterName}
                      filterOptions={filterOptions}
                      table={table}
                      pageName={pageName}
                      badgeInvisibility={pageName === userPage ? true : badgeInvisibility}
                      filterDropdownLabel={filterDropdownLabel}
                      filterDropdownOptions={filterDropdownOptions}
                    />
                    {showActiveSwitch && (
                      <div className={styles.switch_container}>
                        <Switch
                          onColor={styles.primary_800}
                          checked={filterOptions.activeSwitchStatus}
                          onChange={() =>
                            setFilterOptions((prev) => ({ ...prev, activeSwitchStatus: !prev.activeSwitchStatus }))
                          }
                        />
                        <label>{filterOptions.activeSwitchStatus ? t('common.active') : t('common.inActive')}</label>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            className={`${styles.rightSubHeader} ${
              pageNamesArray.includes(pageName) && styles.feedbackRightSubHeader
            }`}>
            <div className={`${pageName === userPage && styles.userPageBtn}`}>
              {addMenuItems && !!addMenuItems.length && <AddComponent />}
            </div>
          </div>
        </div>
        <div className={`${styles.body_container} ${(!rows.length || isLoading) && appStyles.h_100}`}>
          {tabs.length > 0 && (
            <div>
              <div className={styles.tabHeader}>
                {tabs.map((tab) => {
                  return (
                    <span
                      key={tab.key}
                      className={currentTab === tab.value ? styles.selectedTab : ''}
                      onClick={() => onTabChange(tab.value)}>
                      <span className={styles.tab}>{tab.label}</span>
                    </span>
                  );
                })}
              </div>
            </div>
          )}
          {!hideTable && (
            <Table
              pageName={pageName}
              headCells={headCells}
              rows={rows}
              viewBtn={viewBtn}
              editBtn={editBtn}
              deleteBtn={deleteBtn}
              editBtnLink={editBtnLink}
              viewBtnLink={viewBtnLink}
              deleteBtnLink={deleteBtnLink}
              viewBtnFn={viewBtnFn}
              editBtnFn={editBtnFn}
              onDelete={onDelete}
              onBeforeDelete={handleBeforeDelete}
              onAfterDelete={handleAfterDelete}
              viewActionColumn={viewActionColumn}
              loading={isLoading}
              onChange={onChange}
              totalRowsCount={totalRowsCount}
              totalPagesCount={totalPagesCount}
              filterOptions={filterOptions}
              onSortingValuesChange={onSortingValuesChange}
              canUndo={canUndo}
              noDataMsg={noDataMsg}
            />
          )}
        </div>
      </Paper>
    </>
  );
}

export default ListTable;
