import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { faCheckCircle, faCube } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Checkbox, FormControlLabel, Paper } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Attachments from '../../../components/Attachments';
import Loader from '../../../components/Loader';
import Table from '../../../components/Table';
import { axiosApi } from '../../../helpers/api_helper';
import app_form_style from '../../../assets/scss/_app.module.scss';
import form_field_style from '../../../assets/scss/_form_field.module.scss';
import styles from './importUsers.module.scss';
import TemplateDoc from '../../../assets/templates/bulk_import_user_template.csv';
import { getCompanies } from '../../../helpers/common_service';
import { useSelector } from 'react-redux';
import FilterSelect from '../../../components/FilterSelect';
import { isUserSuperAdmin } from '../../../helpers/access';

function Step1Content({ onProcessAttachment }) {
  const { t } = useTranslation();
  const loginUser = useSelector((state) => state.login.authUser);
  const [companies, setCompanies] = useState([]);
  const [companyId, setCompanyId] = useState(
    isUserSuperAdmin() ? useSelector((state) => state.dashboard.companyId) : loginUser.company_id
  );
  const [attachment, setAttachment] = useState(null);
  const handleAttachmentChange = (newAttachments) => {
    if (newAttachments?.length > 0) setAttachment(newAttachments[0]);
  };
  useEffect(() => {
    if (isUserSuperAdmin()) {
      getCompanies(setCompanies, { is_dropdown: true }, false);
    }
  }, []);
  return (
    <>
      <div className={styles.subheader_container}>
        <div>
          {isUserSuperAdmin() && !!companies.length && (
            <FilterSelect
              value={companyId}
              label={t('common.company')}
              items={companies}
              placeholder={t('common.selectCompany')}
              onChange={(id) => setCompanyId(id)}
              containerStyles={styles.company_dropdown}
            />
          )}
        </div>
        <Link to={TemplateDoc} target="_blank" download="bulk import user template.csv">
          Download CSV template file
        </Link>
      </div>
      <Attachments
        containerClassName={styles.upload_container}
        acceptDoc={false}
        acceptPdf={false}
        acceptImages={false}
        acceptCsv={true}
        maxAttachments={1}
        helpText={'attachment.supportsCsv'}
        onAttachmentsChange={handleAttachmentChange}
      />
      {attachment && companyId && (
        <div className={styles.subheader_container}>
          <span></span>
          <Button
            type="submit"
            onClick={() => onProcessAttachment(companyId, attachment)}
            variant="contained"
            className={`${styles.save_button} ${styles.btn} ${styles.mobile_button_width}`}>
            {t('upload')}
          </Button>
        </div>
      )}
    </>
  );
}

function Step2Content({ attachmentFile, companyId, onUsersProcessed, onReset }) {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [isProcessed, setIsProcessed] = useState(false);
  const [message, setMessage] = useState('');

  const formData = new FormData();
  formData.append('company_id', companyId);
  formData.append('data', attachmentFile.file, attachmentFile.file.name);

  useEffect(() => {
    axiosApi
      .post('/users/validate_csv_data', formData)
      .then((res) => {
        setUsers(
          res.data.users.map((data, index) => ({
            ...data,
            id: index + 2,
            full_name: (data.first_name ?? '') + ' ' + (data.last_name ?? ''),
            is_active: data.status_code != 'error',
            status_message: data.status_message.map((data) => t('import.userErrors.' + data))
          }))
        );
        setIsProcessed(true);
      })
      .catch((e) => {
        setMessage(
          t(
            e.response?.data?.message_key
              ? 'import.errors.' + e.response?.data?.message_key
              : 'common.somethingWentsWrong'
          )
        );
      });
  }, []);
  useEffect(() => {
    if (isProcessed) {
      onUsersProcessed(users);
    }
  }, [users]);
  return (
    <div>
      {message && (
        <div className={styles.error_message}>
          <Button
            className={styles.goBackBtn}
            variant="outlined"
            startIcon={<KeyboardArrowLeftIcon className={styles.goBack} />}
            onClick={onReset}>
            {t('back')}
          </Button>
          <label> {message}</label>
        </div>
      )}
      {!message && <Loader loading={true} message={t('import.processingCsv')}></Loader>}
    </div>
  );
}

function Step3Content({ usersProcessed, onUploadRequest, onReset }) {
  const { t } = useTranslation();
  const [users, setUsers] = useState(usersProcessed);
  const [isSuccessChecked, setIsSuccessChecked] = useState(true);
  const [isErrorChecked, setIsErrorChecked] = useState(true);
  const handleRemoveUser = (id) => {
    setUsers((prev) => prev.filter((x) => x.id !== id));
  };
  const headCells = [
    {
      id: 'is_active',
      label: t('import.status'),
      width: '6%'
    },
    {
      id: 'id',
      label: t('import.row'),
      width: '5%'
    },
    {
      id: 'full_name',
      label: t('common.fullName'),
      width: '20%'
    },
    {
      id: 'email',
      label: t('common.email'),
      width: '20%'
    },
    {
      id: 'role',
      label: t('common.role'),
      width: '10%'
    },
    {
      id: 'status_message',
      label: t('alert.message')
    },
    {
      id: 'action',
      label: t('alert.action'),
      width: '5%'
    }
  ];
  return (
    <>
      <div className={`${styles.subheader_container}`}>
        <Button
          className={styles.goBackBtn}
          variant="outlined"
          startIcon={<KeyboardArrowLeftIcon className={styles.goBack} />}
          onClick={onReset}>
          {t('back')}
        </Button>
        {users.length > 0 && (
          <Button
            type="submit"
            onClick={() => onUploadRequest(users)}
            variant="contained"
            className={`${styles.save_button} ${styles.btn} ${styles.import_button}`}>
            {t('user.importUsers')}
          </Button>
        )}
      </div>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              name={t('import.success')}
              className={styles.checkBox}
              onChange={() => setIsSuccessChecked((prev) => !prev)}
              checked={isSuccessChecked}
            />
          }
          label={t('import.success', {
            count: users.filter((x) => x.status_code != 'error').length
          })}
        />
        <FormControlLabel
          control={
            <Checkbox
              name={t('import.error')}
              className={styles.checkBox}
              onChange={() => setIsErrorChecked((prev) => !prev)}
              checked={isErrorChecked}
            />
          }
          label={t('import.error', {
            count: users.filter((x) => x.status_code == 'error').length
          })}
        />
      </div>
      <Table
        pageName={'import_users'}
        rows={users.filter(
          (x) => (isSuccessChecked && x.status_code != 'error') || (isErrorChecked && x.status_code == 'error')
        )}
        headCells={headCells}
        viewBtn={false}
        editBtn={false}
        deleteBtnFn={handleRemoveUser}
        showPagination={false}></Table>
    </>
  );
}

function Step4Content({ usersToImport, companyId, onGoback, onClose }) {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [message, setMessage] = useState('');

  useEffect(() => {
    axiosApi
      .post('/users/bulk_import_create', { users: usersToImport, company_id: companyId })
      .then((res) => {
        setUsers(
          res.data.users.map((data, index) => ({
            ...data,
            id: index + 2,
            full_name: (data.first_name ?? '') + ' ' + (data.last_name ?? ''),
            status_message: data.status_message.map((data) => t('import.userErrors.' + data))
          }))
        );
      })
      .catch((e) => {
        setMessage(t(e.response?.data?.message_key || 'common.somethingWentsWrong'));
      });
  }, []);
  const errorUsers = users.filter((x) => x.status_code == 'error');
  const successUsersCount = users.filter((x) => x.status_code != 'error').length;
  useEffect(() => {
    if (users.length > 0) {
      setMessage(
        t('import.importUsersSuccessMesssage', {
          count: users.filter((x) => x.status_code != 'error').length
        })
      );
    }
  }, [users]);
  const headCells = [
    {
      id: 'id',
      label: t('import.row'),
      width: '5%'
    },
    {
      id: 'full_name',
      label: t('common.fullName'),
      width: '25%'
    },
    {
      id: 'email',
      label: t('common.email'),
      width: '25%'
    },
    {
      id: 'status_message',
      label: t('alert.message')
    }
  ];
  return (
    <div>
      {message && (
        <>
          {successUsersCount == 0 && (
            <div className={styles.error_message}>
              <Button
                className={styles.goBackBtn}
                variant="outlined"
                startIcon={<KeyboardArrowLeftIcon className={styles.goBack} />}
                onClick={() => onGoback()}>
                {t('back')}
              </Button>
              <label>{message}</label>
            </div>
          )}
          {successUsersCount > 0 && (
            <div className={styles.success_message}>
              <FontAwesomeIcon icon={faCheckCircle} className={styles.solid_list_icon} />
              <label>{message}</label>
            </div>
          )}
          {errorUsers.length > 0 && (
            <>
              <div className="mt-3">
                {t('import.error', {
                  count: errorUsers.length
                })}
              </div>
              <Table rows={errorUsers} headCells={headCells} viewActionColumn={false} showPagination={false}></Table>
            </>
          )}
          {users.length > 0 && (
            <div className={styles.subheader_container}>
              <span></span>
              <Button className={styles.goBackBtn} variant="outlined" onClick={() => onClose()}>
                {t('close')}
              </Button>
            </div>
          )}
        </>
      )}
      {!message && (
        <Loader
          loading={true}
          message={t('import.importingUsers', {
            count: usersToImport.length
          })}></Loader>
      )}
    </div>
  );
}

const ImportUsers = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [stepNo, setStepNo] = useState(1);
  const [attachmentFile, setAttachmentFile] = useState(1);
  const [users, setUsers] = useState([]);
  const [companyId, setCompanyId] = useState(null);

  const handleProcessAttachment = (companyId, newAttachment) => {
    setCompanyId(companyId);
    setAttachmentFile(newAttachment);
    setStepNo(2);
  };

  const handleUsersProcessed = (processedUsers) => {
    setUsers(processedUsers);
    setStepNo(3);
  };
  const handleUploadRequest = (processedUsers) => {
    setUsers(processedUsers);
    setStepNo(4);
  };

  const handleReset = () => {
    setUsers([]);
    setStepNo(1);
  };
  return (
    <Paper elevation={0}>
      <div className={`${app_form_style.header_container}`}>
        <div>
          <FontAwesomeIcon icon={faCube} className={app_form_style.solid_list_icon} />
          <h6 className={`${app_form_style.title_name} ${form_field_style.text_3xl}`}>{t(`user.importUsers`)}</h6>
        </div>
      </div>
      <hr className={app_form_style.horizontal_line} />
      <div className={styles.grid_container}>
        {stepNo == 1 && <Step1Content onProcessAttachment={handleProcessAttachment} />}
        {stepNo == 2 && (
          <Step2Content
            attachmentFile={attachmentFile}
            companyId={companyId}
            onUsersProcessed={handleUsersProcessed}
            onReset={handleReset}
          />
        )}
        {stepNo == 3 && (
          <Step3Content
            usersProcessed={users}
            onUploadRequest={handleUploadRequest}
            onReset={handleReset}
            companyId={companyId}
          />
        )}
        {stepNo == 4 && (
          <Step4Content
            usersToImport={users}
            companyId={companyId}
            onGoback={() => setStepNo(3)}
            onClose={() => navigate('/user-list')}
          />
        )}
      </div>
    </Paper>
  );
};

export default ImportUsers;
