import { Dialog, Button, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styles from './alertCard.module.scss';
import button_styles from '../../assets/scss/_button.module.scss';

const AlertCard = ({ isOpen, onClose, onConfirm, title, content }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <div className={styles.card}>
        {title != null && title.length > 0 && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
        {content != null && content.length > 0 && (
          <DialogContent dividers>
            <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
          </DialogContent>
        )}
        <DialogActions className="p-5">
          <Button
            variant="contained"
            className={`${button_styles.cancel_button} ${button_styles.btn}`}
            onClick={onClose}>
            {t('common.no')}
          </Button>
          <Button
            type="submit"
            variant="contained"
            className={`${button_styles.save_button} ${button_styles.btn}`}
            onClick={onConfirm}
            autoFocus>
            {t('common.yes')}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default AlertCard;
