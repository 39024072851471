import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { axiosApi } from '../../helpers/api_helper';
import WarrantyList from '../AssetDetail/Tabs/Warranty';
import { useSelector } from 'react-redux';

const WarrantyExpiring = ({ beforeFetch, afterFetch, fetchCount }) => {
  const { t } = useTranslation();
  const [warranties, setWarranties] = useState([]);
  const [totalRowsCount, setTotalRowsCount] = useState(0);
  const [totalPagesCount, setTotalPagesCount] = useState(0);
  const table = useSelector((state) => state.table);
  const [paginationDetails, setPaginationDetails] = useState({
    currentPage: 1,
    rowsPerPage: table?.tableRowCountObj?.count
  });
  const [isLoading, setIsLoading] = useState(false);
  const companyId = useSelector((state) => state.common.companyId);
  const branchId = useSelector((state) => state.common.branchId);

  const headCells = [
    {
      id: 'is_active',
      label: t('warranty.active'),
      width: '5%'
    },
    {
      id: 'asset_name',
      label: t('asset.general.assetName'),
      hasViewLink: true
    },
    {
      id: 'product_tag_id',
      label: t('asset.general.tagId')
    },
    {
      id: 'expiring_on_display',
      label: t('warranty.expiringOn')
    },
    {
      id: 'duration_display',
      label: t('warranty.duration')
    },
    {
      id: 'action',
      label: t('common.action'),
      width: '10%'
    }
  ];

  const fetchWarranties = () => {
    let params = {
      filter: { company_id: companyId, branch_id: branchId }
    };
    if (paginationDetails?.currentPage) params['page'] = paginationDetails.currentPage;
    if (paginationDetails?.rowsPerPage) params['items'] = paginationDetails.rowsPerPage;
    params['order'] = 'asc';
    setIsLoading(true);
    beforeFetch();
    axiosApi
      .get('/warranties/expiring', { params })
      .then((res) => {
        setWarranties(res.data.data);
        setTotalRowsCount(res.data.meta.total_count);
        setTotalPagesCount(res.data.meta.total_pages);
        if (res.data.data.length) afterFetch();
        fetchCount();
      })
      .catch(() => toast.error(t('common.somethingWentsWrong')))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => fetchWarranties(), [paginationDetails, companyId, branchId]);

  return (
    <WarrantyList
      alertHeadCells={headCells}
      warranties={warranties}
      fetchWarranties={fetchWarranties}
      showHeader={false}
      showDeleteBtn={false}
      isLoading={isLoading}
      onChange={(values) => {
        if (JSON.stringify(values) !== JSON.stringify(paginationDetails)) setPaginationDetails(values);
      }}
      totalRowsCount={totalRowsCount}
      totalPagesCount={totalPagesCount}
    />
  );
};

export default WarrantyExpiring;
