import { Email, ArrowBack } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { axiosApi } from '../../helpers/api_helper';
import { forgotPasswordSchema } from '../../schemas/PasswordResetSchema';
import commonStyles from '../Login/index.module.scss';
import styles from './index.module.scss';
import CommonLoginLayout from '../Login/CommonLoginLayout';

const ForgotPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);

  const onSubmit = ({ email }) => {
    setLoading(true);
    axiosApi
      .post(`/password_reset`, { email })
      .then(() => {
        navigate(`/check-email/`, { state: { email } });
        toast.success(t('forgotPassword.mailWasSentSuccessfully'));
      })
      .catch((err) => {
        toast.error(`${err['response']['data']['message']}`);
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  const { values, errors, handleChange, handleBlur, handleSubmit, touched } = useFormik({
    initialValues: { email: '' },
    validationSchema: forgotPasswordSchema(t('forgotPassword.yupEmail')),
    onSubmit
  });

  return (
    <CommonLoginLayout>
      <div className={commonStyles.formHeader}>
        <span>{t('forgotPassword.title')}</span>
      </div>
      <form
        className={commonStyles.inputContainer}
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(values);
        }}>
        <p>{t('forgotPassword.forgotPasswordInfoText')}</p>
        <div className={commonStyles.fieldContainer}>
          <div className={commonStyles.field}>
            <span className={commonStyles.iconContainer}>
              <Email className={commonStyles.icon} />
            </span>
            <input
              name="email"
              className={commonStyles.input}
              value={values.email}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
          {touched.email && errors.email && <div className={commonStyles.error_msg}>{errors.email}</div>}
        </div>
        <button type="submit" className={commonStyles.button}>
          {isLoading ? (
            <>
              <span className={commonStyles.circularProgress}>
                <CircularProgress size="small" />
              </span>
              {t('forgotPassword.sendingEmail')}
            </>
          ) : (
            t('forgotPassword.passwordReset.resetPassword')
          )}
        </button>
      </form>
      <div className={styles.backLinkContainer}>
        <Link to="/login" className={styles.backLink}>
          <ArrowBack />
          {t('forgotPassword.backToLogin')}
        </Link>
      </div>
    </CommonLoginLayout>
  );
};

export default ForgotPassword;
