import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';
import { Lock, Edit, Close } from '@mui/icons-material';
import { Drawer } from '@mui/material';
import { axiosApi } from '../../../helpers/api_helper';
import { getDateString, scrollBoxShadowStyle, useScrollVerticle } from '../../../helpers/common_service';
import { CHECK_OUT } from '../../../helpers/constants';
import DefaultImage from '../../../assets/images/DefaultProfile.svg';
import TimeLineActivities from '../../../components/TimeLineProgress';
import ChangePassword from './ChangePassword';
import ActivityDetails from './ViewActivity';
import appStyles from '../../../assets/scss/_app.module.scss';
import styles from './index.module.scss';
import UserProfile from '../Profile';

const UserDetails = ({ open, details, onClose }) => {
  const { t } = useTranslation();
  const [activities, setActivities] = useState([]);
  const [selectedActivity, setSelectedActivity] = useState({});
  const [scrollTop, scrollBottom, scrollProps] = useScrollVerticle();
  const [isPasswordResetFormOpen, setPasswordResetFormOpen] = useState(false);
  const authUserId = useSelector((state) => state.login.authUser.id);
  const isProfileInfo = details.id === authUserId;
  const [isProfileEditOpen, setProfileEditOpen] = useState(false);

  const timeLineActivities = activities.map((activity) => ({
    key: nanoid(),
    displayContent: (
      <span>
        {activity.action === 'assigned'
          ? activity.status === CHECK_OUT
            ? t('user.assetHistory.assignedDisplay', {
                assetName: activity.product_name,
                date: getDateString(activity.from_date)
              })
            : t('user.assetHistory.leasedDisplay', {
                assetName: activity.product_name,
                date: getDateString(activity.from_date)
              })
          : activity.status === CHECK_OUT
          ? t('user.assetHistory.unAssignedDisplay', {
              assetName: activity.product_name,
              date: getDateString(activity.end_date)
            })
          : t('user.assetHistory.unLeasedDisplay', {
              assetName: activity.product_name,
              date: getDateString(activity.end_date)
            })}
      </span>
    ),
    id: activity.id
  }));

  const handleView = (activityId) => setSelectedActivity(activities.find((activity) => activity.id === activityId));

  const handleNavigate = () => {
    setSelectedActivity({});
    onClose();
  };

  const getProductsHistory = () => {
    axiosApi
      .get(`/users/${details.id}/product_history`)
      .then((res) => setActivities(res.data))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (open) getProductsHistory();
  }, [open]);

  return (
    <>
      <Drawer anchor="right" open={open} onClose={onClose} className={appStyles.drawer}>
        <div className={styles.avatarContainer}>
          <img src={details.avatar || DefaultImage} alt="User image..." className={styles.avatar} />
          <span className={styles.close} onClick={onClose}>
            <Close />
          </span>
        </div>
        {isProfileInfo && (
          <div className={styles.profileActions}>
            <div className={styles.edit} onClick={() => setProfileEditOpen(true)}>
              <Edit />
              {t('myProfile.editProfile')}
            </div>

            <div className={styles.reset} onClick={() => setPasswordResetFormOpen(true)}>
              <Lock />
              {t('forgotPassword.passwordReset.resetPassword')}
            </div>
          </div>
        )}
        <div
          {...scrollProps}
          style={{
            boxShadow: scrollBoxShadowStyle(scrollTop, scrollBottom),
            transition: 'box-shadow 0.3s',
            overflowY: 'scroll'
          }}>
          <div className={styles.detailHeader}>{t('asset.assetDetail.activities.userDetails')}</div>
          <div className={styles.detailContainer}>
            <div className={styles.detail}>
              <span>{t('name')}</span>
              <span>{details.full_name || '-'}</span>
            </div>
            <div className={styles.detail}>
              <span>{t('common.email')}</span>
              <span>{details.email || '-'}</span>
            </div>
            <div className={styles.detail}>
              <span>{t('common.role')}</span>
              <span>{details.role_display || '-'}</span>
            </div>
            <div className={styles.detail}>
              <span>{t('common.mobile')}</span>
              <span>{details.mobile || '-'}</span>
            </div>
            <div className={styles.detail}>
              <span>{t('common.company')}</span>
              <span>{details.company || '-'}</span>
            </div>
            <div className={styles.detail}>
              <span>{t('common.branch')}</span>
              <span>{details.branch || '-'}</span>
            </div>
          </div>
          <div className={styles.detailHeader}>{t('asset.assetDetail.activities.title')}</div>
          {timeLineActivities.length > 0 ? (
            <TimeLineActivities records={timeLineActivities} onView={handleView} />
          ) : (
            <div className={styles.noActivities}>{t('asset.assetDetail.activities.noActivities')}</div>
          )}
        </div>
      </Drawer>
      {isPasswordResetFormOpen && (
        <ChangePassword userId={authUserId} onClose={() => setPasswordResetFormOpen(false)} />
      )}
      {Object.keys(selectedActivity).length > 0 && (
        <ActivityDetails
          activity={selectedActivity}
          onClose={() => setSelectedActivity({})}
          onNavigate={handleNavigate}
        />
      )}
      <UserProfile open={isProfileEditOpen} onClose={() => setProfileEditOpen(false)} />
    </>
  );
};

export default UserDetails;
