import { useState } from 'react';
import ReservationForm from '../../TimeLineActivity/Reservation/add';
import styles from '../tabStyles.module.scss';
import ReservationCalendar from './ReservationCalendar';
import { CHECK_OUT } from '../../../../helpers/constants';

const ReservationTab = ({
  getReservations,
  product,
  assetDetails,
  reservations,
  setCurrentReservation,
  setIsDeleteToasterOpen
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedReservation, setSelectedReservation] = useState({
    product_id: product,
    reserved_to_user_company_id: product.company_id
  });

  const checkOutEndDate = assetDetails.status === CHECK_OUT && new Date(assetDetails.status_info?.dueToDate);

  const minDateToReserve = checkOutEndDate && checkOutEndDate.setDate(checkOutEndDate.getDate() + 1);

  const handleEdit = (reservationId) => {
    setSelectedReservation(reservations.find((x) => x.id === reservationId));
    setShowModal(true);
  };

  const handleAdd = (from_date = null) => {
    if (from_date) {
      let reservation = reservations.find(
        (w) => new Date(w.from_date) <= new Date(from_date) && new Date(w.to_date) >= new Date(from_date)
      );
      if (reservation != null) setSelectedReservation(reservation);
      else
        setSelectedReservation((prev) => ({
          ...prev,
          from_date: from_date
        }));
    }
    setShowModal(true);
  };

  const resetForm = () => {
    setShowModal(false);
    setSelectedReservation({
      product_id: product,
      reserved_to_user_company_id: product.company_id
    });
  };

  return (
    <>
      <div className={styles.tabContainer}>
        <ReservationCalendar
          product={assetDetails}
          reservations={reservations}
          onEdit={handleEdit}
          onAdd={handleAdd}
          latestAvailableDate={minDateToReserve}
        />

        {showModal && (
          <ReservationForm
            getReservations={getReservations}
            productId={product}
            reservation={selectedReservation}
            onClose={resetForm}
            setCurrentReservation={setCurrentReservation}
            triggerToaster={setIsDeleteToasterOpen}
          />
        )}
      </div>
    </>
  );
};

export default ReservationTab;
