import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPenToSquare, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import button_style from '../../../assets/scss/_button.module.scss';
import styles from './ActionIconBtn.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import ConfirmationCard from '../../ConfirmationCard';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';

const ActionIconBtn = ({
  disabledAction,
  viewBtn = true,
  editBtn = true,
  deleteBtn = true,
  editBtnLink = null,
  viewBtnLink = null,
  deleteBtnLink = null,
  editBtnFn = null,
  viewBtnFn = null,
  deleteBtnFn = null,
  rowId,
  onBeforeDelete,
  handleDelete,
  deleteMessage = null,
  delete_alert_message = null
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const handleViewBtnClick = () => {
    if (viewBtnLink)
      navigate(`/${viewBtnLink}/${rowId}`, { state: { previousPath: `${location.pathname}${location.search}` } });
    else if (viewBtnFn) viewBtnFn(rowId);
  };
  const handleEditBtnClick = () => {
    if (editBtnLink)
      navigate(`/${editBtnLink}/${rowId}`, { state: { previousPath: `${location.pathname}${location.search}` } });
    else if (editBtnFn) editBtnFn(rowId);
  };

  const handleDeleteBtnClick = () => {
    onBeforeDelete?.();
    if (deleteBtnLink) {
      handleDelete(rowId);
    } else if (deleteBtnFn) {
      deleteBtnFn(rowId);
    }
    setIsOpen(false);
  };

  return (
    <>
      <div className={`${styles.actionBtnsContainer}`}>
        {viewBtn && (
          <Tooltip title={t('view')} arrow={true} placement="top">
            <Button
              disabled={disabledAction}
              type="button"
              variant="contained"
              className={`${button_style.btn} ${button_style.table_view_btn} ${styles.action_buttons} ${
                disabledAction ? styles.disabled_btn : ''
              }`}
              onClick={handleViewBtnClick}>
              <FontAwesomeIcon icon={faEye} className={styles.action_icon} />
            </Button>
          </Tooltip>
        )}
        {editBtn && (
          <Tooltip title={t('edit')} arrow={true} placement="top">
            <Button
              disabled={disabledAction}
              type="button"
              variant="contained"
              className={` ${button_style.btn}  ${styles.action_buttons} ${button_style.table_edit_btn} ${
                disabledAction ? styles.disabled_btn : ''
              }`}
              onClick={handleEditBtnClick}>
              <FontAwesomeIcon icon={('fa-thin', faPenToSquare)} className={styles.action_icon} />
            </Button>
          </Tooltip>
        )}
        {deleteBtn && (
          <Tooltip title={t('delete')} arrow={true} placement="top">
            <Button
              disabled={disabledAction}
              type="button"
              variant="contained"
              className={`${button_style.btn} ${button_style.table_delete_btn} ${styles.action_buttons} ${
                disabledAction ? styles.disabled_btn : ''
              }`}
              onClick={() => setIsOpen(true)}>
              <FontAwesomeIcon icon={faTrashCan} className={styles.action_icon} />
            </Button>
          </Tooltip>
        )}
      </div>
      <ConfirmationCard
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onDelete={handleDeleteBtnClick}
        message={deleteMessage}
        delete_alert_message={delete_alert_message}
      />
    </>
  );
};
export default ActionIconBtn;
