import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getFirstMenuLink } from '../helpers/access';
const RequireAuth = ({ children, isAuthorized }) => {
  const isLoggedIn = useSelector((state) => state.login.isAuthenticated);

  if (!isLoggedIn && isAuthorized) {
    return <Navigate to="/" />;
  } else if (isLoggedIn && !isAuthorized) {
    return <Navigate to={getFirstMenuLink()} />;
  }
  return children;
};

export default RequireAuth;
