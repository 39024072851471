import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { axiosApi } from '../../../../helpers/api_helper';
import Table from '../../../../components/Table';
import CreateButton from '../../../../components/Form/Button/CreateButton';
import InsuranceForm from './AddInsurance';
import InsuranceDetails from './ShowInsurance';
import app_form_style from '../../../../assets/scss/_app.module.scss';
import styles from '../tabStyles.module.scss';
import { dateRangeDisplay } from '../../../../helpers/common_service';
import DeleteToaster from '../../../../components/Toaster/DeleteToaster';
import { hasCreateAccess, hasDeleteAccess, hasEditAcccess, MODEL_INSURANCE } from '../../../../helpers/access';

const InsuranceList = ({
  id,
  countryId,
  alertHeadCells,
  insurances,
  canCreate,
  fetchInsurances,
  showHeader = true,
  showDeleteBtn = true,
  isLoading,
  onChange,
  totalRowsCount,
  totalPagesCount,
  showPagination
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showInsuranceForm, setShowInsuranceForm] = useState(false);
  const [showInsuranceDetails, setShowInsuranceDetails] = useState(false);
  const [selectedInsuranceDetails, setSelectedInsuranceDetails] = useState({});
  const [isDeleteToasterOpen, setIsDeleteToasterOpen] = useState(false);
  canCreate = canCreate && hasCreateAccess(MODEL_INSURANCE);

  const isInventoryDetailsPage = showHeader;

  if (insurances != null)
    insurances = insurances.map((data) => ({
      ...data,
      period_of_insurance: dateRangeDisplay(data.start_date, data.end_date)
    }));

  const headCells = [
    {
      id: 'insurance_company_name',
      numeric: false,
      disablePadding: true,
      label: t('asset.assetDetail.insuranceTab.insuranceCompanyName'),
      hasViewLink: true
    },
    {
      id: 'policy_no',
      numeric: false,
      disablePadding: true,
      label: t('asset.assetDetail.insuranceTab.policyNo'),
      width: '15%'
    },
    {
      id: 'period_of_insurance',
      numeric: false,
      disablePadding: true,
      label: t('asset.assetDetail.insuranceTab.periodOfInsurance')
    },
    {
      id: 'is_active',
      numeric: false,
      disablePadding: true,
      label: t('asset.assetDetail.insuranceTab.active'),
      width: '5%'
    },
    {
      id: 'action',
      numeric: true,
      disablePadding: false,
      label: t('common.action'),
      width: '15%'
    }
  ];

  const viewInsurance = (insuranceId) => {
    setSelectedInsuranceDetails(insurances.find((x) => x.id === insuranceId));
    setShowInsuranceDetails(true);
  };

  const editInsurance = (insuranceId) => {
    setSelectedInsuranceDetails(insurances.find((x) => x.id === insuranceId));
    setShowInsuranceForm(true);
  };

  const resetForm = () => {
    setSelectedInsuranceDetails({});
    setShowInsuranceForm(false);
  };

  const deleteInsurance = (insuranceId) => {
    axiosApi
      .delete(`/products/${id}/insurances/${insuranceId}`)
      .then(() => {
        setIsDeleteToasterOpen(true);
        fetchInsurances();
      })
      .catch((e) => {
        toast.error(e.error || t('common.somethingWentsWrong'));
      });
  };

  const handleViewClick = (insuranceId, column) => {
    if (isInventoryDetailsPage) {
      viewInsurance(insuranceId);
    } else {
      if (column === alertHeadCells[2].id) {
        viewInsurance(insuranceId);
      } else if (column === alertHeadCells[1].id) {
        let product_id = insurances.find((i) => i.id === insuranceId).product_id;
        navigate(`/inventory-details/${product_id}`);
      }
    }
  };

  return (
    <>
      {showHeader && (
        <div className={`${app_form_style.subheader_container} ${styles.tabAddButton}`}>
          {canCreate && (
            <CreateButton
              buttonTitle={t('table.tableList.addTitle', {
                titleName: 'insurance'
              })}
              onClick={() => setShowInsuranceForm(true)}
              className={`${styles.addButton}`}
            />
          )}
        </div>
      )}

      <Table
        pageName="insurance"
        headCells={alertHeadCells || headCells}
        rows={insurances}
        viewBtnFn={(id, column) => {
          handleViewClick(id, column);
        }}
        editBtnFn={editInsurance}
        editBtn={hasEditAcccess(MODEL_INSURANCE)}
        deleteBtn={showDeleteBtn && hasDeleteAccess(MODEL_INSURANCE)}
        deleteBtnFn={deleteInsurance}
        loading={isLoading}
        noDataMsg="asset.assetDetail.insuranceTab.noInsuranceHasBeenAdded"
        onChange={onChange}
        totalRowsCount={totalRowsCount}
        totalPagesCount={totalPagesCount}
        showPagination={showPagination}
      />

      {showInsuranceForm && (
        <InsuranceForm
          productId={id}
          productCountryId={countryId}
          insuranceDetails={selectedInsuranceDetails}
          onClose={resetForm}
          onSubmit={() => {
            resetForm();
            fetchInsurances();
          }}
        />
      )}

      {showInsuranceDetails && (
        <InsuranceDetails insurance={selectedInsuranceDetails} onClose={() => setShowInsuranceDetails(false)} />
      )}
      {isDeleteToasterOpen && isLoading && (
        <DeleteToaster
          message={t('asset.assetDetail.insuranceTab.insuranceDeletedSuccessfully')}
          canUndo={false}
          onClose={() => setIsDeleteToasterOpen(false)}
        />
      )}
    </>
  );
};

export default InsuranceList;
