import { store } from '../store';

export const MODEL_COMPANY = 'company';
export const MODEL_BRANCH = 'branch';
export const MODEL_PRODUCT = 'product';
export const MODEL_USER = 'user';
export const MODEL_INSURANCE = 'insurance';
export const MODEL_MAINTENANCE = 'maintenance';
export const MODEL_MAINTENANCE_REQUEST = 'maintenance_request';
export const MODEL_RESERVATION = 'reservation';
export const MODEL_WARRANTY = 'warranty';
export const MODEL_BRAND = 'brand';
export const MODEL_CATEGORY = 'category';
export const MODEL_PRODUCT_TYPE = 'product_type';

export const AUTHORITY_LEVEL_COMPANY = 'company';
export const AUTHORITY_LEVEL_BRANCH = 'branch';
export const AUTHORITY_LEVEL_SELF = 'self';

export const ACTION_WRITE = 'write';
export const ACTION_READ = 'read';
export const ACTION_DESTORY = 'destroy';

// show branch and companies dropdown in headers
const showCompanyDropdown = [
  '/dashboard',
  '/inventory-list',
  '/expiring',
  '/user-list',
  '/reports',
  '/maintenance-request-list',
  '/feedbacks',
  '/branch-list',
  '/asset-properties-list',
  '/alerts'
];
function hasAccess(modelName, action = null, authorityLevel = null, checkSuperUser = true) {
  if (checkSuperUser && isUserSuperAdmin()) return true;
  const authUser = store.getState().login.authUser;
  const model = authUser?.authorizations?.find((model) => model.name === modelName);
  if (model == null || model.actions == null || (authorityLevel != null && model['authority_level'] != authorityLevel))
    return false;
  if (action != null) return model.actions.includes(action);
  return model.actions.length > 0;
}

function hasReadAccess(modelName, authority_level = null, checkSuperUser = true) {
  if (authority_level == null)
    return (
      hasAccess(modelName, ACTION_READ, AUTHORITY_LEVEL_COMPANY, checkSuperUser) ||
      hasAccess(modelName, ACTION_READ, AUTHORITY_LEVEL_BRANCH, checkSuperUser)
    );
  else return hasAccess(modelName, ACTION_READ, authority_level, checkSuperUser);
}

function hasCreateAccess(modelName, authority_level = null, checkSuperUser = true) {
  if (authority_level == null)
    return (
      hasAccess(modelName, ACTION_WRITE, AUTHORITY_LEVEL_COMPANY, checkSuperUser) ||
      hasAccess(modelName, ACTION_WRITE, AUTHORITY_LEVEL_BRANCH, checkSuperUser)
    );
  else return hasAccess(modelName, ACTION_WRITE, authority_level, checkSuperUser);
}

function hasEditAcccess(modelName, checkSuperUser = true) {
  return (
    hasAccess(modelName, ACTION_WRITE, AUTHORITY_LEVEL_SELF, checkSuperUser) ||
    hasCreateAccess(modelName, null, checkSuperUser)
  );
}
function hasDeleteAccess(modelName, checkSuperUser = true) {
  return hasAccess(modelName, ACTION_DESTORY, null, checkSuperUser);
}

function isUserSuperAdmin() {
  const authUser = store.getState().login.authUser;
  return authUser['super_admin?'];
}

function isUserCompanyAdmin() {
  const authUser = store.getState().login.authUser;
  return authUser.role == 'company_admin';
}

function isUserSuperOrCompanyAdmin() {
  return isUserSuperAdmin() || isUserCompanyAdmin();
}

function isUserAdmin() {
  const authUser = store.getState().login.authUser;
  return isUserSuperOrCompanyAdmin() || authUser.role == 'branch_admin';
}

function showCompanyFilter(pathName = null) {
  return isUserSuperAdmin() && (pathName == null || showCompanyDropdown.includes(pathName));
}

function showBranchFilter(pathName) {
  const paths = [
    ...(hasReadAccess(MODEL_PRODUCT, AUTHORITY_LEVEL_COMPANY)
      ? ['/inventory-list', '/expiring', '/reports', '/feedbacks']
      : []),
    ...(hasReadAccess(MODEL_USER, AUTHORITY_LEVEL_COMPANY) ? ['/user-list'] : []),
    ...(hasReadAccess(MODEL_MAINTENANCE_REQUEST, AUTHORITY_LEVEL_COMPANY) ? ['/maintenance-request-list'] : []),
    ...(hasReadAccess(MODEL_PRODUCT, AUTHORITY_LEVEL_COMPANY) ||
    hasReadAccess(MODEL_WARRANTY, AUTHORITY_LEVEL_COMPANY) ||
    hasReadAccess(MODEL_INSURANCE, AUTHORITY_LEVEL_COMPANY) ||
    hasReadAccess(MODEL_RESERVATION, AUTHORITY_LEVEL_COMPANY) ||
    hasReadAccess(MODEL_MAINTENANCE, AUTHORITY_LEVEL_COMPANY)
      ? ['/alerts']
      : [])
  ];
  return paths.includes(pathName);
}

function getFirstMenuLink() {
  if (isUserAdmin()) return '/dashboard';
  else if (hasAccess(MODEL_PRODUCT)) return '/inventory-list';
  else if (hasReadAccess(MODEL_USER)) return '/user-list';
  else if (hasReadAccess(MODEL_BRANCH)) return '/branch-list';
  else if (hasAccess(MODEL_MAINTENANCE_REQUEST)) return '/maintenance-request-list';
  return '/alerts';
}
export {
  hasAccess,
  isUserSuperAdmin,
  isUserCompanyAdmin,
  isUserSuperOrCompanyAdmin,
  isUserAdmin,
  showBranchFilter,
  showCompanyFilter,
  hasReadAccess,
  hasCreateAccess,
  hasEditAcccess,
  hasDeleteAccess,
  getFirstMenuLink
};
