import { TailSpin } from 'react-loader-spinner';
import styles from './Loader.module.scss';
import variables from './Loader.module.scss';

export default function Loader({ loading, loaderClass, message, isPageLoader = false }) {
  return (
    loading && (
      <div className={`${loaderClass}`}>
        <div className={`${styles.container}  ${isPageLoader ? styles.page_loader : ''}`}>
          <TailSpin
            color={variables.primaryColor}
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
        {message && <div className={`${styles.content_container}`}>{message}</div>}
      </div>
    )
  );
}
