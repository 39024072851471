import { useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { getDateString } from '../../../helpers/common_service';
import ShowDetails from '../../../components/ShowDetail';

const AssetStatusDetail = ({ status, eventDetails, onClose }) => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);

  const getData = () => {
    switch (status) {
      case 'checkOut':
        setData(() => [
          {
            key: nanoid(),
            label: t('common.company'),
            value: eventDetails.company_name
          },
          {
            key: nanoid(),
            label: t('common.branch'),
            value: eventDetails.branch_name
          },
          {
            key: nanoid(),
            label: t('asset.general.assignedTo'),
            value: eventDetails.user.label
          },
          {
            key: nanoid(),
            label: t('asset.assetDetail.moreAction.checkOut.assignedDate'),
            value: getDateString(eventDetails.checkOutDate)
          },
          {
            key: nanoid(),
            label: t('asset.assetDetail.moreAction.checkOut.dueToDate'),
            value: getDateString(eventDetails.dueToDate)
          },
          {
            key: nanoid(),
            label: t('common.email'),
            value: eventDetails.email
          },
          {
            key: nanoid(),
            label: t('asset.assetDetail.moreAction.notes'),
            value: eventDetails.notes
          }
        ]);
        break;
      case 'checkIn':
        setData(() => [
          {
            key: nanoid(),
            label: t('asset.assetDetail.moreAction.checkIn.returnDate'),
            value: getDateString(eventDetails.returnDate)
          },
          {
            key: nanoid(),
            label: t('common.email'),
            value: eventDetails.email
          },
          {
            key: nanoid(),
            label: t('asset.assetDetail.moreAction.notes'),
            value: eventDetails.notes
          }
        ]);
        break;
      case 'lease':
        setData(() => [
          {
            key: nanoid(),
            label: t('common.company'),
            value: eventDetails.company_name
          },
          {
            key: nanoid(),
            label: t('common.branch'),
            value: eventDetails.branch_name
          },
          {
            key: nanoid(),
            label: t('asset.general.assignedTo'),
            value: eventDetails.user.label
          },
          {
            key: nanoid(),
            label: t('asset.assetDetail.moreAction.lease.leaseBegins'),
            value: getDateString(eventDetails.leaseBegins)
          },
          {
            key: nanoid(),
            label: t('asset.assetDetail.moreAction.lease.leaseExpires'),
            value: getDateString(eventDetails.leaseExpires)
          },
          {
            key: nanoid(),
            label: t('common.email'),
            value: eventDetails.email
          },
          {
            key: nanoid(),
            label: t('asset.assetDetail.moreAction.notes'),
            value: eventDetails.notes
          }
        ]);
        break;
      case 'broken':
        setData(() => [
          {
            key: nanoid(),
            label: t('asset.assetDetail.moreAction.broken.brokenDate'),
            value: getDateString(eventDetails.brokenDate)
          },
          {
            key: nanoid(),
            label: t('asset.assetDetail.moreAction.notes'),
            value: eventDetails.notes
          }
        ]);
        break;
      case 'repair':
        setData(() => [
          {
            key: nanoid(),
            label: t('asset.assetDetail.moreAction.repair.scheduleDate'),
            value: getDateString(eventDetails.scheduleDate)
          },
          {
            key: nanoid(),
            label: t('asset.assetDetail.moreAction.repair.assigedDealer'),
            value: eventDetails.assigedDealer
          },
          {
            key: nanoid(),
            label: t('asset.assetDetail.moreAction.repair.returnedDate'),
            value: getDateString(eventDetails.returnDate)
          },
          {
            key: nanoid(),
            label: t('asset.assetDetail.moreAction.repair.repairCost'),
            value: eventDetails.repairCost
          },
          {
            key: nanoid(),
            label: t('asset.assetDetail.moreAction.notes'),
            value: eventDetails.notes
          }
        ]);
        break;
      case 'sold':
        setData(() => [
          {
            key: nanoid(),
            label: t('asset.assetDetail.moreAction.sold.saleDate'),
            value: getDateString(eventDetails.saleDate)
          },
          {
            key: nanoid(),
            label: t('asset.assetDetail.moreAction.sold.soldTo'),
            value: eventDetails.soldTo
          },
          {
            key: nanoid(),
            label: t('asset.assetDetail.moreAction.sold.saleAmount'),
            value: eventDetails.saleAmount
          },
          {
            key: nanoid(),
            label: t('common.email'),
            value: eventDetails.email
          },
          {
            key: nanoid(),
            label: t('asset.assetDetail.moreAction.notes'),
            value: eventDetails.notes
          }
        ]);
        break;
      case 'lostOrMissing':
        setData(() => [
          {
            key: nanoid(),
            label: t('asset.assetDetail.moreAction.lostOrMissing.lostDate'),
            value: getDateString(eventDetails.lostDate)
          },
          {
            key: nanoid(),
            label: t('asset.assetDetail.moreAction.notes'),
            value: eventDetails.notes
          }
        ]);
        break;
      case 'dispose':
        setData(() => [
          {
            key: nanoid(),
            label: t('asset.assetDetail.moreAction.dispose.disposeDate'),
            value: getDateString(eventDetails.disposeDate)
          },
          {
            key: nanoid(),
            label: t('asset.assetDetail.moreAction.dispose.disposeTo'),
            value: eventDetails.disposeTo
          },
          {
            key: nanoid(),
            label: t('asset.assetDetail.moreAction.notes'),
            value: eventDetails.notes
          }
        ]);
        break;
      case 'donate':
        setData(() => [
          {
            key: nanoid(),
            label: t('asset.assetDetail.moreAction.donate.donatedDate'),
            value: getDateString(eventDetails.donatedDate)
          },
          {
            key: nanoid(),
            label: t('asset.assetDetail.moreAction.donate.donatedTo'),
            value: eventDetails.donatedTo
          },
          {
            key: nanoid(),
            label: t('common.email'),
            value: eventDetails.email
          },
          {
            key: nanoid(),
            label: t('asset.assetDetail.moreAction.notes'),
            value: eventDetails.notes
          }
        ]);
        break;
      default:
        return [];
    }
  };

  useEffect(getData, [status]);

  return <ShowDetails detailFor={t('asset.assetDetail.statusInfo.title')} details={data} onClose={onClose} />;
};
export default AssetStatusDetail;
