import GoogleLogo from '../../assets/images/GoogleLogo.svg';
import styles from './index.module.scss';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGoogleLogin } from '@react-oauth/google';
import { toast } from 'react-toastify';
import { apiError, loginUser } from '../../store/actions';
import { CircularProgress } from '@mui/material';
import { Email, Lock, Visibility, VisibilityOff } from '@mui/icons-material';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { googleLogin } from '../../store/actions';
import CommonLoginLayout from './CommonLoginLayout';
import { axiosApi } from '../../helpers/api_helper';

const LoginPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [passwordType, setSetPasswordType] = useState('password');
  const apiErrorMsg = useSelector((state) => state.login.error);
  const [accessToken, setAccessToken] = useState(null);

  const codeParams = new URLSearchParams(window.location.search).get('code');
  var error = apiErrorMsg;

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t('login.form.enterAnValidEmail')).required(t('common.pleaseEnterEmail')),
    password: Yup.string().required(t('login.form.pleaseEnterPassword'))
  });

  const onSubmit = (values) => {
    setIsLoading(true);
    dispatch(apiError(''));
    dispatch(loginUser(values, navigate));
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    onSubmit,
    validationSchema
  });

  const handleGoogleLogin = useGoogleLogin({
    flow: 'auth-code',
    ux_mode: 'redirect',
    enable_serial_consent: false,
    redirect_uri: `${window.location.origin}/login`,
    onError: (err) => {
      toast.error(err || t('common.somethingWentWrong'));
    }
  });

  useEffect(() => {
    dispatch(apiError(''));
  }, [dispatch]);
  useEffect(() => {
    if (codeParams) {
      const getAccessToken = async (codeParams) => {
        await axiosApi
          .get('google_get_token', {
            params: {
              access_token: codeParams
            }
          })
          .then((response) => {
            setAccessToken(response.data);
          })
          .catch((err) => toast.error(err || t('common.somethingWentWrong')));
      };
      getAccessToken(codeParams);
    }
  }, [codeParams]);

  useEffect(() => {
    if (accessToken) {
      dispatch(googleLogin(accessToken, navigate));
    }
  }, [accessToken]);

  useEffect(() => {
    if (apiErrorMsg) {
      setIsLoading(false);
    }
  }, [apiErrorMsg]);

  return (
    <CommonLoginLayout>
      <div className={styles.formHeader}>
        <span>{t('login.title')}</span>
      </div>
      <form
        className={styles.inputContainer}
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit(formik.values);
        }}>
        <div className={styles.label}>{t('common.email')}</div>
        <div className={styles.fieldContainer}>
          <div className={styles.field}>
            <span className={styles.iconContainer}>
              <Email className={styles.icon} />
            </span>
            <input name="email" className={styles.input} value={formik.values.email} onChange={formik.handleChange} />
          </div>
          {formik.touched.email && formik.errors.email && <div className={styles.error_msg}>{formik.errors.email}</div>}
        </div>
        <div className={styles.label}>{t('login.form.password')}</div>
        <div className={styles.fieldContainer}>
          <div className={styles.field}>
            <span className={styles.iconContainer}>
              <Lock className={styles.icon} />
            </span>
            <input
              type={passwordType}
              name="password"
              className={styles.input}
              value={formik.values.password}
              onChange={formik.handleChange}
            />
            {passwordType === 'text' ? (
              <Visibility onClick={() => setSetPasswordType('password')} className={styles.fieldIcon} />
            ) : (
              <VisibilityOff onClick={() => setSetPasswordType('text')} className={styles.fieldIcon} />
            )}
          </div>
          {formik.touched.password && formik.errors.password && (
            <div className={styles.error_msg}>{formik.errors.password}</div>
          )}
          {formik.values.password !== '' && error && <div className={styles.error_msg}>{error}</div>}
        </div>
        <div className={styles.forgotPasswordContainer}>
          <span onClick={() => navigate('/forgot-password')}>{t('login.form.forgotPassword?')}</span>
        </div>
        <button type="submit" className={styles.button}>
          {isLoading ? (
            <>
              <span className={styles.circularProgress}>
                <CircularProgress size="small" />
              </span>
              {t('login.form.signingIn')}
            </>
          ) : (
            t('login.form.signIn')
          )}
        </button>
      </form>
      <div className={styles.separator}>
        <div>
          <hr />
          <span className={styles.dividerText}>{t('or')}</span>
        </div>
      </div>
      <div className={styles.googleLogoContainer}>
        <span onClick={() => handleGoogleLogin()}>
          <img src={GoogleLogo} alt="Google logo" />
        </span>
      </div>
    </CommonLoginLayout>
  );
};

export default LoginPage;
