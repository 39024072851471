import { useNavigate } from 'react-router-dom';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { getDateString } from '../../../helpers/common_service';
import { CHECK_OUT } from '../../../helpers/constants';
import ShowDetails from '../../../components/ShowDetail';
import styles from './index.module.scss';

const ActivityDetails = ({ activity, onClose, onNavigate }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const activityDetails = [
    {
      key: nanoid(),
      label: t('user.assetHistory.assetName'),
      value: (
        <span
          onClick={() => {
            if (activity.status === CHECK_OUT) {
              onNavigate();
              navigate(`/inventory-details/${activity.product_id}`);
            }
          }}
          className={activity.status === CHECK_OUT ? styles.textLink : ''}>
          {activity.product_name}
        </span>
      )
    },
    {
      key: nanoid(),
      label: t('asset.general.serialNumber'),
      value: activity.serial_number
    },
    {
      key: nanoid(),
      label: t(activity.status === CHECK_OUT ? 'user.assetHistory.assignedOn' : 'user.assetHistory.leasedOn'),
      value: getDateString(activity.from_date)
    },
    {
      key: nanoid(),
      label: t(activity.status === CHECK_OUT ? 'user.assetHistory.UnAssignedOn' : 'user.assetHistory.leaseEndedOn'),
      value: getDateString(activity.end_date)
    }
  ];

  return (
    <ShowDetails
      detailFor={t('asset.assetDetail.activities.activityDetails')}
      details={activityDetails}
      onClose={onClose}
    />
  );
};

export default ActivityDetails;
