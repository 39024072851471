import * as Yup from 'yup';
import { textFieldSchema, alphaNumericSpecialCharSchema } from './CommonSchema';

export const AssetSchema = (company, branch, asset_name, serial_number, maximages, type) =>
  Yup.object().shape({
    company: Yup.string().required(company),
    branch: Yup.string().required(branch),
    asset_name: alphaNumericSpecialCharSchema(asset_name),
    serial_number: textFieldSchema(serial_number),
    images: Yup.array().max(5, maximages),
    category: Yup.string(),
    type: Yup.string().when('category', {
      is: (value) => value && value.length !== 0,
      then: Yup.string().required(type)
    })
  });

export const AddNewValueSchema = (message) =>
  Yup.object().shape({
    value: Yup.string().required(message)
  });
