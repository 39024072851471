import { SET_COMPANY_ID, REMOVE_COMPANY_ID } from './actionTypes';

const initialState = {
  companyId: ''
};

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPANY_ID:
      return {
        companyId: action.payload.companyId
      };
    case REMOVE_COMPANY_ID:
      return {
        companyId: initialState.companyId
      };
    default:
      return state;
  }
};

export default dashboard;
