import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Chart from 'react-apexcharts';
import { MONTHS } from '../../helpers/constants';
import styles from './dashboard.module.scss';
import colors from '../../assets/scss/_color.scss';
import FormControl from '@mui/material/FormControl';
import { subMonths, addMonths, addDays } from 'date-fns';
import { toast } from 'react-toastify';
import { nanoid } from 'nanoid';
import ModalForm from '../../components/ModalForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import FilterSelect from '../../components/FilterSelect';

const BarChart = ({ data, onChange }) => {
  const options = [
    { key: nanoid(), label: 'Last 12 months', value: 'Last 12 months' },
    { key: nanoid(), label: 'Last 6 months', value: 'Last 6 months' },
    { key: nanoid(), label: 'Last 3 months', value: 'Last 3 months' },
    { key: nanoid(), label: 'Custom range', value: 'custom range', clickable: true }
  ];
  const { t } = useTranslation();
  const [seriesData, setSeriesData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [fromDate, setFromDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isPrefilled, setIsPrefilled] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [customFromDate, setCustomFromDateChange] = useState(null);
  const [customEndDate, setCustomEndDateChange] = useState(null);
  const [prevCustomRange, setPrevCustomRange] = useState({ start: null, end: null });
  const [initialValues, setInitialValues] = useState({
    from_date: '',
    to_date: ''
  });

  const handleClickOpen = () => {
    if (!isPrefilled) {
      setInitialValues({
        from_date: customFromDate,
        to_date: customEndDate
      });
    }
    setIsModalOpen(true);
  };

  const fields = [
    {
      id: nanoid(),
      label: 'From Date',
      name: 'from_date',
      type: 'date',
      isRequired: true
    },
    {
      id: nanoid(),
      label: 'To Date',
      name: 'to_date',
      type: 'date',
      isRequired: true,
      minDate: initialValues.from_date,
      disabled: !initialValues.from_date
    }
  ];

  const chartData = (data, categories) => {
    return {
      series: [{ name: '', data }],
      options: {
        colors: colors.primary_500,
        chart: {
          toolbar: { show: false }
        },
        plotOptions: {
          bar: {
            borderRadius: 3,
            columnWidth: '30px'
          }
        },
        grid: {
          borderColor: colors.neutrals_100,
          strokeDashArray: 5
        },
        tooltip: {
          x: {
            show: false
          },
          marker: {
            show: false
          }
        },
        dataLabels: { enabled: false },
        xaxis: { categories },
        yaxis: {
          labels: {
            formatter: function (val) {
              if (val === Infinity) return 5;
              return (val * 2) / 2;
            },
            style: {
              fontSize: '12px'
            }
          }
        },
        responsive: [
          {
            breakpoint: 601,
            options: {
              tooltip: {
                x: {
                  show: true
                }
              },
              xaxis: {
                categories,
                show: false,
                labels: {
                  show: false
                },
                axisTicks: {
                  show: false
                }
              }
            }
          }
        ]
      }
    };
  };
  const setPrefilledValues = (i) => {
    if (i !== 'custom range') {
      setInitialValues({
        from_date: '',
        to_date: ''
      });
      setIsPrefilled(true);
      setSelectedOption(i);
      let toDate = null;
      setEndDate(new Date());
      if (i === options[0].value) {
        toDate = subMonths(new Date(), 12);
      } else if (i === options[1].value) {
        toDate = subMonths(new Date(), 6);
      } else if (i === options[2].value) {
        toDate = subMonths(new Date(), 3);
      }
      setFromDate(addDays(toDate, 1));
      onChange({ start_date: toDate, end_date: new Date() });
    } else {
      setIsPrefilled(false);
      setInitialValues({
        from_date: '',
        to_date: ''
      });
      handleClickOpen();
    }
  };
  const setCustomValues = (from, end) => {
    onChange({ start_date: from, end_date: end });
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    !initialValues.from_date && !initialValues.to_date && setPrefilledValues(selectedOption);
    setCustomFromDateChange(prevCustomRange.start);
    setCustomEndDateChange(prevCustomRange.end);
  };

  const onCustomRangeFormSubmit = () => {
    if (customEndDate !== null && customFromDate !== null && customEndDate >= customFromDate) {
      handleModalClose;
      setCustomValues(customFromDate, customEndDate);
      setPrevCustomRange({ start: customFromDate, end: customEndDate });
      setIsModalOpen(false);
    } else if (customEndDate === null || customFromDate === null) {
      toast.error('Date field Required');
    } else if (customEndDate <= customFromDate) {
      toast.error('Start date should be less than end date');
    }
  };

  useEffect(() => {
    setCustomValues(customFromDate, customEndDate);
    setPrefilledValues(options[0].value);
  }, []);

  useEffect(() => {
    let newSeriesData;
    let newCategories = [];
    if (data) {
      let betweenMonths = [];
      let betweenDaysData = [];
      let dynamicStartDate = isPrefilled ? fromDate : customFromDate;
      let dynamicEndDate = isPrefilled ? endDate : customEndDate;
      let totalDays = (dynamicEndDate.getTime() - dynamicStartDate.getTime()) / (1000 * 3600 * 24);

      if (totalDays <= 60) {
        if (dynamicStartDate !== null) {
          let startingDate = dynamicStartDate;
          while (startingDate.getTime() <= dynamicEndDate.getTime()) {
            let populatedString = `${startingDate.getDate()}/${startingDate.getMonth() + 1}`;
            betweenDaysData.push({ key: populatedString, value: data[populatedString] || 0 });
            startingDate = addDays(startingDate, 1);
          }
          for (let i = 0; i <= totalDays; i++) {
            let dateMonthArr = betweenDaysData[i].key.split('/');
            newCategories.push(
              dateMonthArr[0] === '1' && betweenDaysData[betweenDaysData.length - 1]['key'][0] <= '10'
                ? `${dateMonthArr[0]} ${MONTHS[parseInt(dateMonthArr[1]) - 1]}`
                : dateMonthArr[0]
            );
          }
          if (newCategories.length > 1) {
            if (dynamicStartDate.getDate() !== 1) {
              newCategories[0] = `${newCategories[0]} ${MONTHS[dynamicStartDate.getMonth()]}`;
            }
            if (dynamicEndDate.getDate() !== 1) {
              newCategories[newCategories.length - 1] = `${newCategories[newCategories.length - 1]} ${
                MONTHS[dynamicEndDate.getMonth()]
              }`;
            }
          }
          newSeriesData = betweenDaysData.map((i) => i.value);
        }
      } else {
        if (dynamicStartDate !== null) {
          let startingDate = dynamicStartDate;
          while (startingDate < dynamicEndDate) {
            let populatedString = `${startingDate.getMonth() + 1}/${startingDate.getFullYear()}`;
            betweenMonths.push({ key: populatedString, value: data[populatedString] || 0 });
            startingDate = addMonths(startingDate, 1);
          }
          if (
            `${dynamicStartDate.getMonth() + 1}/${dynamicStartDate.getFullYear()}` !==
              `${dynamicEndDate.getMonth() + 1}/${dynamicEndDate.getFullYear()}` &&
            dynamicStartDate.getDate() > dynamicEndDate.getDate()
          ) {
            let populatedString = `${dynamicEndDate.getMonth() + 1}/${dynamicEndDate.getFullYear()}`;
            betweenMonths.push({ key: populatedString, value: data[populatedString] || 0 });
          }
        }
        let startsAt = dynamicStartDate?.getMonth();

        for (let i = 0; i < betweenMonths.length; i++) {
          if (startsAt === 12) startsAt = 0;
          let month = MONTHS[startsAt];
          if (month === 'JAN') {
            newCategories.push(`JAN ${betweenMonths[i].key.split('/')[1]}`);
          } else {
            newCategories.push(month);
          }
          startsAt += 1;
        }
        if (newCategories.length > 1) {
          newCategories[0] = `${dynamicStartDate.getDate()} ${newCategories[0]}`;
          newCategories[newCategories.length - 1] = `${dynamicEndDate.getDate()} ${
            newCategories[newCategories.length - 1]
          }`;
        } else if (newCategories.length === 1) {
          newCategories[0] = `${dynamicStartDate.getDate()} - ${dynamicEndDate.getDate()} ${newCategories[0]}`;
        }

        newSeriesData = betweenMonths.map((i) => i.value);
      }
      setSeriesData(newSeriesData);
      setCategories(newCategories);
    }
  }, [data]);

  useEffect(() => {
    if (initialValues.from_date) {
      setIsPrefilled(false);
      setFromDate(initialValues.from_date.$d ?? initialValues.from_date);
      setCustomFromDateChange(initialValues.from_date.$d ?? initialValues.from_date);
    }
  }, [initialValues.from_date]);

  useEffect(() => {
    if (initialValues.to_date) {
      setIsPrefilled(false);
      setEndDate(initialValues.to_date.$d ?? initialValues.to_date);
      setCustomEndDateChange(initialValues.to_date.$d ?? initialValues.to_date);
    }
  }, [initialValues.to_date]);

  return (
    <>
      <div className={styles.bar_chart_header}>
        <span className={styles.chart_header}>{t('dashboard.acquiredInventory')}</span>
        <div className={styles.filter_container}>
          <FormControl
            sx={{
              m: 1,
              minWidth: 120
            }}>
            <FilterSelect
              value={isPrefilled ? selectedOption : 'custom range'}
              label="Duration"
              items={options}
              placeholder=""
              onChange={(val) => setPrefilledValues(val)}
              containerStyles={styles.barchartDropdown}
            />
          </FormControl>
        </div>
      </div>
      {isModalOpen && (
        <ModalForm
          titleIcon={<FontAwesomeIcon icon={faCalendar} />}
          formTitle={'Custom Range'}
          isEditForm={false}
          onClose={() => handleModalClose()}
          fields={fields}
          initialValues={initialValues}
          onSubmit={onCustomRangeFormSubmit}
          onValuesChange={(values) => setInitialValues(values)}
        />
      )}
      <div className={styles.bar_chart_wrapper}>
        <Chart
          key="bar-chart"
          options={chartData(seriesData, categories).options}
          series={chartData(seriesData, categories).series}
          type="bar"
          height="350px"
        />
      </div>
    </>
  );
};

export default BarChart;
