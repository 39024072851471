import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { axiosApi } from '../../helpers/api_helper';
import { getDateString } from '../../helpers/common_service';
import { PriorityDisplay, StatusDisplay } from '../MaintenanceRequest/Common';
import Table from '../../components/Table';
import { maintenanceRequestPage } from '../../helpers/constants';
import { AUTHORITY_LEVEL_COMPANY, hasReadAccess, MODEL_MAINTENANCE, showCompanyFilter } from '../../helpers/access';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import styles from './alerts.module.scss';
import DeleteButton from '../../components/Form/Button/DeleteButton';
import { InfoButton } from '../MaintenanceRequest/Common';
import RequestInfo from '../MaintenanceRequest/RequestInfo';

const MaintenanceRequestList = ({ fetchCount, beforeFetch, afterFetch }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [maintenanceRequests, setMaintenanceRequests] = useState([]);
  const [selcetedMaintenance, setSelectedMaintenace] = useState({});
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const companyId = useSelector((state) => state.common.companyId);
  const branchId = useSelector((state) => state.common.branchId);

  const headCells = [
    ...(maintenanceRequests.find((e) => e.is_new_alert === true)
      ? [
          {
            id: 'new_alert',
            label: '',
            width: '8px'
          }
        ]
      : []),
    {
      id: 'status',
      label: t('maintenanceRequest.status'),
      width: '10%'
    },
    {
      id: 'product_name',
      label: t('asset.general.assetName'),
      hasViewLink: true,
      width: '10%'
    },
    {
      id: 'description',
      label: t('maintenanceRequest.description'),
      width: '40%',
      maxWidth: '40%',
      breakWord: true
    },
    {
      id: 'admin_user_name',
      label: t('maintenanceRequest.actionDoneBy'),
      width: '10%'
    },
    {
      id: 'info',
      label: t('maintenanceRequest.alertInfo'),
      width: '5%'
    },

    {
      id: 'actions',
      label: t('common.action'),
      width: '10%'
    }
  ];

  const handleViewBtnClick = (rowId, column) => {
    let request = maintenanceRequests.find((request) => request.id === rowId);
    if (column === 'product_name') navigate(`/inventory-details/${request.product_id}?tab=Maintenance`);
  };

  const handleMarkAlertAsSeen = (alertId) => {
    setIsLoading(true);
    beforeFetch();
    axiosApi
      .get(`/alerts/${alertId}/mark_as_seen/`)
      .then(() => {
        setMaintenanceRequests((prev) => prev.filter((alert) => alert.id !== alertId));
        fetchCount();
      })
      .catch(() => {
        toast.error(t('common.somethingWentsWrong'));
      })
      .finally(() => {
        setIsLoading(false);
        afterFetch();
      });
  };

  const fetchMaintenanceRequests = () => {
    let params = {};

    params['relation'] = 'MaintenanceRequest';
    if (showCompanyFilter() && companyId) params['company_id'] = companyId;
    if (hasReadAccess(MODEL_MAINTENANCE, AUTHORITY_LEVEL_COMPANY) && branchId) params['branch_id'] = branchId;

    setIsLoading(true);
    axiosApi
      .get('/alerts', { params })
      .then((res) => {
        setMaintenanceRequests(
          res.data.data.map((maintenance_request) => ({
            ...maintenance_request.attributes.alert_detail.data.attributes,
            id: maintenance_request.attributes.id,
            status: (
              <div className={styles.statusContainer}>
                <StatusDisplay status={maintenance_request.attributes.alert_detail.data.attributes.status} />
              </div>
            ),
            new_alert: maintenance_request.attributes.alert_detail.data.attributes.is_new_alert ? (
              <FontAwesomeIcon className={styles.new_alert_indication_icon} icon={faCircle} />
            ) : (
              ' '
            ),
            actions: <DeleteButton onClick={() => handleMarkAlertAsSeen(maintenance_request.attributes.id)} />
          }))
        );
      })
      .catch(() => toast.error(t('common.somethingWentsWrong')))
      .finally(() => setIsLoading(false));
  };

  let rows = maintenanceRequests.map((maintenance_request) => {
    maintenance_request['requested_date_display'] = getDateString(maintenance_request.requested_date);
    maintenance_request['pick_up_date_display'] = getDateString(maintenance_request.pick_up_date);
    maintenance_request['priority_display'] = <PriorityDisplay priority={maintenance_request.priority} />;
    maintenance_request['info'] = (
      <div className={styles.infoContainer}>
        <InfoButton
          onClick={() => {
            setSelectedMaintenace(maintenance_request);
            setIsInfoOpen(true);
          }}
        />
      </div>
    );
    return maintenance_request;
  });

  useEffect(() => {
    fetchMaintenanceRequests();
  }, []);

  return (
    <>
      <Table
        pageName={maintenanceRequestPage}
        headCells={headCells}
        rows={rows}
        loading={isLoading}
        noDataMsg="asset.assetDetail.maintenanceTab.noMaintenanceRequestsHasBeenAdded"
        showPagination={false}
        viewActionColumn={false}
        viewBtnFn={handleViewBtnClick}
      />
      {isInfoOpen && (
        <RequestInfo maintenanceRequest={selcetedMaintenance} onClose={() => setIsInfoOpen(false)} isAlertPage={true} />
      )}
    </>
  );
};

export default MaintenanceRequestList;
