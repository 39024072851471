import React, { useState } from 'react';
import styles from './DatePicker.module.scss';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';

export default function BasicDatePicker({
  value,
  name,
  onChange,
  disabled,
  disableFuture = false,
  disablePast = false,
  minDate,
  maxDate,
  className
}) {
  const [open, setopen] = useState(false);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        disabled={disabled}
        open={open}
        onOpen={() => setopen(true)}
        onClose={() => setopen(false)}
        closeOnSelect={true}
        PaperProps={{
          classes: {
            root: styles.date_picker
          }
        }}
        label=""
        value={value || null}
        onChange={onChange}
        orientation="portrait"
        inputFormat="DD/MM/YYYY"
        disableFuture={disableFuture}
        disablePast={disablePast}
        minDate={minDate || false}
        maxDate={maxDate || null}
        renderInput={(params) => {
          return (
            <TextField
              onClick={() => !disabled && setopen(true)}
              name={name}
              {...params}
              sx={{
                '.MuiOutlinedInput-root': {
                  paddingRight: '0px',
                  padding: '0px',
                  paddingLeft: '5px',
                  height: '44px',
                  borderRadius: '1px'
                },
                '.MuiOutlinedInput-input': {
                  padding: '0px'
                }
              }}
              className={`${styles.text_field} ${className}`}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="start"
                    className={styles.Input_adornment}
                    onClick={() => !disabled && setopen(true)}>
                    <FontAwesomeIcon icon={faCalendar} />
                  </InputAdornment>
                )
              }}
            />
          );
        }}
      />
    </LocalizationProvider>
  );
}
