import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import ShowDetails from '../../components/ShowDetail';
import { getDateString } from '../../helpers/common_service';
import { PriorityDisplay, StatusDisplay } from './Common';

const RequestInfo = ({ maintenanceRequest, onClose, isAlertPage = false }) => {
  const { t } = useTranslation();

  const requestdDetails = {
    ...maintenanceRequest,
    status_display: maintenanceRequest.status && <StatusDisplay status={maintenanceRequest.status} />,
    priority_display: maintenanceRequest.priority && <PriorityDisplay priority={maintenanceRequest.priority} />
  };

  const requestInfo = [
    {
      key: nanoid(),
      label: t('asset.general.assetName'),
      value: requestdDetails.product_name
    },
    ...(isAlertPage
      ? [
          {
            key: nanoid(),
            label: t('maintenanceRequest.actionDoneBy'),
            value: requestdDetails.admin_user_name
          }
        ]
      : [
          {
            key: nanoid(),
            label: t('maintenanceRequest.user'),
            value: requestdDetails.requested_user_name
          }
        ]),

    {
      key: nanoid(),
      label: t('maintenanceRequest.date'),
      value: getDateString(requestdDetails.requested_date_display)
    },
    {
      key: nanoid(),
      label: t('asset.general.status'),
      value: isAlertPage ? requestdDetails.status : requestdDetails.status_display
    },
    {
      key: nanoid(),
      label: t('maintenanceRequest.priority'),
      value: requestdDetails.priority_display
    },
    {
      key: nanoid(),
      label: t('maintenanceRequest.pickUpDate'),
      value: getDateString(requestdDetails.pick_up_date_display)
    },
    {
      key: nanoid(),
      label: t('maintenanceRequest.requestDescription'),
      value: requestdDetails.request_description
    },
    requestdDetails.reject_description
      ? {
          key: nanoid(),
          label: t('maintenanceRequest.rejectDescription'),
          value: requestdDetails.reject_description
        }
      : {}
  ];

  return <ShowDetails detailFor={t('maintenanceRequest.info')} details={requestInfo} onClose={onClose} />;
};

export default RequestInfo;
