import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';
import { BuildOutlined } from '@mui/icons-material';
import { RepairSchema } from '../../../schemas/AssetEventsSchema';
import ModalForm from '../../../components/ModalForm';
import { REPAIR } from '../../../helpers/constants';

const Repair = ({ eventDetails = {}, onClose, onSubmit, countryId }) => {
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState({
    scheduleDate: '',
    assigedDealer: '',
    returnDate: '',
    repairCost: '',
    notes: '',
    country_id: countryId,
    ...eventDetails
  });

  const fields = [
    {
      id: nanoid(),
      label: t('asset.assetDetail.moreAction.repair.scheduleDate'),
      name: 'scheduleDate',
      type: 'date',
      maxDate: new Date(),
      isRequired: true
    },
    {
      id: nanoid(),
      label: t('asset.assetDetail.moreAction.repair.assigedDealer'),
      name: 'assigedDealer',
      type: 'text'
    },
    {
      id: nanoid(),
      label: t('asset.assetDetail.moreAction.repair.returnedDate'),
      name: 'returnDate',
      disablePast: true,
      type: 'date'
    },
    {
      id: nanoid(),
      label: t('asset.assetDetail.moreAction.repair.repairCost'),
      type: 'currency',
      selectorName: 'country_id',
      textFieldName: 'repair_cost',
      menuOpen: false
    },
    {
      id: nanoid(),
      label: t('asset.assetDetail.moreAction.notes'),
      name: 'notes',
      type: 'textArea'
    }
  ];

  const schema = RepairSchema(t('asset.assetDetail.moreAction.repair.pleaseEnterScheduleDate'));

  return (
    <ModalForm
      formFor={REPAIR}
      formTitle={t('statuses.repair')}
      titleIcon={<BuildOutlined />}
      initialValues={initialValues}
      fields={fields}
      onSubmit={onSubmit}
      onClose={onClose}
      onValuesChange={(values) => setInitialValues(values)}
      isEditForm={initialValues.scheduled_status}
      validationSchema={schema}
    />
  );
};

export default Repair;
