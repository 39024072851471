import axios from 'axios';
import Qs from 'qs';
import { store } from '../store';
import { createBrowserHistory } from 'history';

const API_URL = process.env.REACT_APP_API_URL;
var timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const axiosApi = axios.create({
  withCredentials: true,
  baseURL: API_URL
});

axiosApi.interceptors.request.use((config) => {
  const token = store.getState().login.authUser['remember_token'];
  config.headers.Authorization = `Bearer ${token}`;
  config.headers.timezone = timezone;
  config.paramsSerializer = (params) => {
    return Qs.stringify(params, {
      arrayFormat: 'brackets',
      encode: false
    });
  };
  return config;
});

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => {
    const response = error.response;
    if (response.status === 401) {
      localStorage.clear();
      window.location = '/login';
    } else if (response.status === 403) {
      createBrowserHistory().back();
    }
    return Promise.reject(error);
  }
);

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then((response) => response.data);
}

export async function post(url, data, config = {}) {
  return axiosApi.post(url, { ...data }, { ...config }).then((response) => response.data);
}

export async function patch(url, data, config = {}) {
  return axiosApi.patch(url, { ...data }, { ...config }).then((response) => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi.put(url, { ...data }, { ...config }).then((response) => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi.delete(url, { ...config }).then((response) => response.data);
}
