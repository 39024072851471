import {
  SET_COMMON_COMPANY_ID,
  REMOVE_COMMON_COMPANY_ID,
  SET_COMMON_BRANCH_ID,
  REMOVE_COMMON_BRANCH_ID,
  SET_COMMON_COMPANIES,
  SET_COMMON_BRANCHES,
  NEW_ALERTS_COUNT
} from './actionType';
import { getBranches, getCompanies } from '../../helpers/common_service';

export const setHeaderDropDownCompany = (companyId) => {
  return {
    type: SET_COMMON_COMPANY_ID,
    payload: { companyId }
  };
};

export const removeHeaderDropDownCompany = () => {
  return {
    type: REMOVE_COMMON_COMPANY_ID,
    payload: {}
  };
};
export const setHeaderDropDownBranch = (branchId) => {
  return {
    type: SET_COMMON_BRANCH_ID,
    payload: { branchId }
  };
};
export const removeHeaderDropDownBranch = () => {
  return {
    type: REMOVE_COMMON_BRANCH_ID,
    payload: {}
  };
};

export const getCompaniesLists = (dispatch, params = {}, allRequired = true) => {
  getCompanies(
    (val) => {
      dispatch({
        type: SET_COMMON_COMPANIES,
        payload: { companies: val }
      });
    },
    params,
    allRequired
  );
};

export const getBranchesLists = (dispatch, forFilter = true, params = {}) => {
  getBranches(
    (val) => {
      dispatch({
        type: SET_COMMON_BRANCHES,
        payload: { branches: val }
      });
    },
    forFilter,
    params
  );
};

export const setNewAlertsCount = (newAlertsCount) => {
  return {
    type: NEW_ALERTS_COUNT,
    payload: { newAlertsCount: newAlertsCount }
  };
};
