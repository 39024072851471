import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';
import { ThumbDownAltOutlined } from '@mui/icons-material';
import { LostOrMissingSchema } from '../../../schemas/AssetEventsSchema';
import ModalForm from '../../../components/ModalForm';
import { LOST_OR_MISSING } from '../../../helpers/constants';

const LostOrMissing = ({ eventDetails = {}, onClose, onSubmit }) => {
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState({
    lostDate: '',
    notes: '',
    ...eventDetails
  });

  const fields = [
    {
      id: nanoid(),
      label: t('asset.assetDetail.moreAction.lostOrMissing.lostDate'),
      name: 'lostDate',
      type: 'date',
      disableFuture: true,
      isRequired: true,
      label_size: 2,
      field_size: 10
    },
    {
      id: nanoid(),
      label: t('asset.assetDetail.moreAction.notes'),
      name: 'notes',
      type: 'textArea',
      label_size: 2,
      field_size: 10
    }
  ];

  const schema = LostOrMissingSchema(t('asset.assetDetail.moreAction.lostOrMissing.pleaseEnterLostDate'));

  return (
    <ModalForm
      formFor={LOST_OR_MISSING}
      formTitle={t('statuses.lostOrMissing')}
      titleIcon={<ThumbDownAltOutlined />}
      initialValues={initialValues}
      fields={fields}
      onSubmit={onSubmit}
      onClose={onClose}
      onValuesChange={(values) => setInitialValues(values)}
      isEditForm={initialValues.scheduled_status}
      validationSchema={schema}
    />
  );
};

export default LostOrMissing;
