import { Button, Box, Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import trashCanImg from '../../assets/images/trashCanImg.png';
import styles from './confirmationCard.module.scss';
import button_styles from '../../assets/scss/_button.module.scss';

const ConfirmationCard = ({ isOpen, onClose, onDelete, message, delete_alert_message }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Box className={styles.card}>
        <div className={styles.container}>
          <img src={trashCanImg} alt="Trash can..." className={styles.trash_can_img} />
          {message && <div className={styles.message}>{message}</div>}
          <div className={styles.message}>
            <span>{t('common.confirmMsg')}</span>
            {delete_alert_message && <p className={styles.alert_msg}>{delete_alert_message}</p>}
          </div>
        </div>
        <hr className={styles.horizontal_line} />
        <div className={`${button_styles.buttons_container} ${styles.btns_container}`}>
          <Button
            variant="contained"
            className={`${button_styles.cancel_button} ${button_styles.btn}
              ${button_styles.mobile_button_width} ${styles.cancel_btn}`}
            onClick={onClose}>
            {t('cancel')}
          </Button>
          <Button
            variant="contained"
            className={` ${button_styles.delete_btn} ${button_styles.btn}
              ${button_styles.mobile_button_width} ${styles.delete_btn} `}
            onClick={onDelete}>
            {t('delete')}
          </Button>
        </div>
      </Box>
    </Dialog>
  );
};

export default ConfirmationCard;
