import React from 'react';
import PhoneInput from 'react-phone-input-2';
import styles from './mobileField.module.scss';

export const MobileField = ({ id, country, className, ...props }) => {
  const hasError = props.error !== undefined ? !!props.error : false;

  return (
    <>
      <div className={styles.container} id={id}>
        <div className={`${styles.input_container} ${className}`}>
          <PhoneInput className={styles.phone} country={country} {...props} />
        </div>
      </div>
      {hasError && <div className={styles.error_msg}>{props.error}</div>}
    </>
  );
};
