import { ArrowBack } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { axiosApi } from '../../helpers/api_helper';
import checkMail from '../../assets/images/checkMail.svg';
import commonStyles from '../Login/index.module.scss';
import styles from './index.module.scss';
import CommonLoginLayout from '../Login/CommonLoginLayout';

const SuccessEmailSent = () => {
  const { state } = useLocation();
  const { t } = useTranslation();

  const resendMail = () => {
    axiosApi
      .post(`/password_reset`, state)
      .then(() => toast.success(t('forgotPassword.mailWasSentSuccessfully')))
      .catch(() => toast.error(t('forgotPassword.email.pleaseTryAgainLater')));
  };

  return (
    <CommonLoginLayout>
      <div className={commonStyles.formHeader}>
        <span>{t('forgotPassword.title')}</span>
      </div>
      <p>{t('forgotPassword.mailSentInfoText')}</p>
      <div className={styles.checkMailImgContainer}>
        <img className={styles.checkMail} src={checkMail} alt="" />
      </div>
      <div className={styles.resendMailContainer}>
        {t('forgotPassword.didntReceiveLink')}{' '}
        <span className={styles.openMail} onClick={resendMail}>
          {t('forgotPassword.resend')}
        </span>
      </div>
      <div className={styles.backLinkContainer}>
        <Link to="/login" className={styles.backLink}>
          <ArrowBack />
          {t('forgotPassword.backToLogin')}
        </Link>
      </div>
    </CommonLoginLayout>
  );
};

export default SuccessEmailSent;
