import DefaultPdfIcon from '../../assets/images/PdfIcon.png';
import styles from './AttachmentsList.module.scss';
import { useTranslation } from 'react-i18next';

const AttachmentsList = ({ attachments = [], containerClass = '' }) => {
  const { t } = useTranslation();

  const attachmentItem = (attachment) => {
    const [isFileImage, fileName] = getFileMetadaFromUrl(attachment.url);
    const uploaded_date =
      t('asset.assetDetail.insuranceTab.uploadedOn') + new Date(attachment.created_at).toLocaleDateString();
    return (
      <div className={styles.row}>
        <a href={attachment.url} target="_blank" rel="noReferrer">
          <div className={styles.thumbnail_container}>
            <img src={isFileImage ? attachment.url : DefaultPdfIcon} alt="attachment_thumbnail"></img>
          </div>
        </a>
        <div className={styles.attachment_details}>
          {fileName}
          <div className={styles.attachment_uploaded_details}>{uploaded_date}</div>
        </div>
      </div>
    );
  };

  function getFileMetadaFromUrl(url) {
    let xhr = new XMLHttpRequest();
    xhr.open('HEAD', url, false);

    xhr.send();
    const contentType = xhr.getResponseHeader('Content-Type');
    const fileName = url.split('/').pop().split('#')[0].split('?')[0];

    return [contentType.toLowerCase().startsWith('image'), fileName || 'attachment'];
  }

  return (
    <div className={`${styles.attachment_container} ${containerClass}`}>
      {attachments.map((attachment) => {
        return attachmentItem(attachment);
      })}
    </div>
  );
};

export default AttachmentsList;
