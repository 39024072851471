import { useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import { Paper } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag } from '@fortawesome/free-solid-svg-icons';
import WarrantyExpiring from './Warranty';
import InsuranceExpiring from './Insurance';
import CheckOutExpiring from './CheckOut';
import appStyles from '../../assets/scss/_app.module.scss';
import styles from './expiring.module.scss';
import { useTranslation } from 'react-i18next';
import { axiosApi } from '../../helpers/api_helper';
import { useDispatch, useSelector } from 'react-redux';
import { INSURANCE_TAB, WARRANTY_TAB, ASSIGNED_TAB } from '../../helpers/constants';
import { SET_FILTERS } from '../../store/table/actionTypes';
import { useNavigate } from 'react-router-dom';
import { hasAccess, MODEL_INSURANCE, MODEL_PRODUCT, MODEL_WARRANTY } from '../../helpers/access';

const Expiring = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(false);
  const navigate = useNavigate();
  const currentTab = new URLSearchParams(window.location.search).get('tab');

  const [alertCounts, setAlertCounts] = useState({
    warranty_count: 0,
    insurance_count: 0,
    checkout_count: 0
  });
  const companyId = useSelector((state) => state.common.companyId);
  const branchId = useSelector((state) => state.common.branchId);
  const [currentMenuTab, setCurrentMenuTab] = useState('');
  const handleTabChange = (value) => navigate({ search: `?tab=${value}` });

  const menuTabNames = [
    ...(hasAccess(MODEL_WARRANTY)
      ? [
          {
            key: nanoid(),
            value: WARRANTY_TAB,
            alertsCount: alertCounts.warranty_count
          }
        ]
      : []),
    ...(hasAccess(MODEL_INSURANCE)
      ? [
          {
            key: nanoid(),
            value: INSURANCE_TAB,
            alertsCount: alertCounts.insurance_count
          }
        ]
      : []),
    ...(hasAccess(MODEL_PRODUCT)
      ? [
          {
            key: nanoid(),
            value: ASSIGNED_TAB,
            alertsCount: alertCounts.checkout_count
          }
        ]
      : [])
  ];

  const handleBeforeFetch = () => setIsLoaded(false);
  const handleAfterFetch = () => setIsLoaded(true);

  const fetchCount = () => {
    let params = {
      filter: {
        company_id: companyId,
        branch_id: branchId
      }
    };
    axiosApi.get('/products/expiring_count', { params }).then((res) =>
      setAlertCounts((prev) => ({
        ...prev,
        warranty_count: res.data['Warranty'] || 0,
        insurance_count: res.data['Insurance'] || 0,
        checkout_count: res.data['Product'] || 0
      }))
    );
  };

  useEffect(() => {
    if (branchId !== '') fetchCount();
  }, [companyId, branchId]);

  useEffect(() => {
    dispatch({
      type: SET_FILTERS,
      companyId: companyId,
      branchId: branchId
    });
  }, [companyId, branchId]);

  useEffect(() => {
    setCurrentMenuTab(
      currentTab && currentTab === ASSIGNED_TAB
        ? ASSIGNED_TAB
        : currentTab && currentTab === INSURANCE_TAB
        ? INSURANCE_TAB
        : WARRANTY_TAB
    );
    if (!currentTab) navigate({ search: `?tab=${menuTabNames[0].value}` });
  }, [currentMenuTab, currentTab]);

  return (
    <Paper elevation={0} className={`${appStyles.flexHeightAuto} ${!isLoaded && appStyles.h_100}`}>
      <div className={`${appStyles.header_container} ${styles.header}`}>
        <div className={styles.header_name_container}>
          <FontAwesomeIcon icon={faFlag} className={appStyles.solid_list_icon} />
          <h6 className={`${appStyles.title_name} ${styles.text_3xl}`}>{t('navbarItems.expiring')}</h6>
        </div>
      </div>
      <div className={styles.tabHeader}>
        {menuTabNames.map((menu) => {
          return (
            <span
              key={menu.key}
              className={currentMenuTab === menu.value ? styles.selectedTab : ''}
              onClick={() => {
                handleTabChange(menu.value);
                setIsLoaded(false);
              }}>
              <span className={styles.tab}>
                {menu.value}
                <span className={currentMenuTab === menu.value ? styles.active_count : styles.inactive_count}>
                  {menu.alertsCount}
                </span>
              </span>
            </span>
          );
        })}
      </div>
      <div className={styles.tabContainer}>
        {currentMenuTab === WARRANTY_TAB && (
          <WarrantyExpiring beforeFetch={handleBeforeFetch} afterFetch={handleAfterFetch} fetchCount={fetchCount} />
        )}
        {currentMenuTab === INSURANCE_TAB && (
          <InsuranceExpiring beforeFetch={handleBeforeFetch} afterFetch={handleAfterFetch} fetchCount={fetchCount} />
        )}
        {currentMenuTab === ASSIGNED_TAB && (
          <CheckOutExpiring beforeFetch={handleBeforeFetch} afterFetch={handleAfterFetch} fetchCount={fetchCount} />
        )}
      </div>
    </Paper>
  );
};

export default Expiring;
