import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { axiosApi } from '../../helpers/api_helper';
import { Done, Close } from '@mui/icons-material';
import styles from './maintenanceRequest.module.scss';
import MaintenanceForm from '../AssetDetail/TimeLineActivity/Maintenance/add';
import RequestRejectForm from './RejectForm';
import Tooltip from '@mui/material/Tooltip';
import { InfoButton } from './Common';
import { useSelector } from 'react-redux';

const ACCEPTED = 'accepted';
const REJECTED = 'rejected';
const PENDING = 'pending';

const RequestActions = ({ id, status, productId, countryId, onActionChange, onClick }) => {
  const { t } = useTranslation();
  const [isRequestFormOpen, setRequestFormOpen] = useState(false);
  const [isRejectFormOpen, setIsRejectFormOpen] = useState(false);
  const authUserId = useSelector((state) => state.login.authUser.id);

  const updateMaintenanceRequestStatus = (status) =>
    axiosApi.patch(`/maintenance_requests/${id}`, { status, admin_user_id: authUserId }).then(() => onActionChange());

  const handleClick = (action) => {
    if (action === ACCEPTED) setRequestFormOpen(true);
    else setIsRejectFormOpen(true);
  };

  const handleReject = () => updateMaintenanceRequestStatus(REJECTED);

  const acceptButton = (text = null) => {
    return (
      <button
        className={`${styles.accept} ${status === ACCEPTED ? styles.accepted : ''} ${
          text === null && styles.tulipBtnStyle
        }`}
        onClick={() => {
          if (text === null) handleClick(ACCEPTED);
        }}>
        <Done />
        {text && <span>{text}</span>}
      </button>
    );
  };
  const rejectButton = (text = null) => {
    return (
      <button
        className={`${styles.reject} ${status === REJECTED ? styles.rejected : ''} ${
          text === null ? styles.tulipBtnStyle : styles.rejectedStyle
        }`}
        onClick={() => {
          if (text === null) handleClick(REJECTED);
        }}>
        <Close />
        {text && <span>{text}</span>}
      </button>
    );
  };

  return (
    <>
      <div className={styles.actionButtonsContainer}>
        <InfoButton onClick={onClick} />
        {[ACCEPTED, PENDING].includes(status) &&
          (status === PENDING ? (
            <Tooltip title={t('maintenanceRequest.statuses.accept')} arrow={true} placement="top">
              {acceptButton()}
            </Tooltip>
          ) : (
            acceptButton(t(`maintenanceRequest.statuses.${ACCEPTED}`))
          ))}
        {[REJECTED, PENDING].includes(status) &&
          (status === PENDING ? (
            <Tooltip title={t('maintenanceRequest.statuses.reject')} arrow={true} placement="top">
              {rejectButton()}
            </Tooltip>
          ) : (
            rejectButton(t(`maintenanceRequest.statuses.${REJECTED}`))
          ))}
      </div>

      {isRequestFormOpen && (
        <MaintenanceForm
          productId={productId}
          productCountryId={countryId}
          onClose={() => setRequestFormOpen(false)}
          onSubmit={() => updateMaintenanceRequestStatus(ACCEPTED)}
        />
      )}

      {isRejectFormOpen && (
        <RequestRejectForm requestId={id} onClose={() => setIsRejectFormOpen(false)} onSubmit={handleReject} />
      )}
    </>
  );
};

export default RequestActions;
