import { Block } from '@mui/icons-material';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ModalForm from '../../components/ModalForm';
import { axiosApi } from '../../helpers/api_helper';
import { MaintenanceRequestRejectSchema } from '../../schemas/MaintenanceSchema';

const RejectForm = ({ requestId, onClose, onSubmit }) => {
  const { t } = useTranslation();
  const authUserId = useSelector((state) => state.login.authUser.id);
  const [initialValues, setInitialValues] = useState({ reject_description: '' });

  const fields = [
    {
      id: nanoid(),
      label: t('common.description'),
      name: 'reject_description',
      isRequired: true,
      type: 'textArea'
    }
  ];

  const handleSubmit = (values) => {
    axiosApi
      .patch(`/maintenance_requests/${requestId}`, {
        admin_user_id: authUserId,
        reject_description: values.reject_description
      })
      .then(() => {
        toast.error(t('maintenanceRequest.rejected'));
        onSubmit();
      })
      .catch(() => t('common.somethingWentsWrong'));
  };

  return (
    <ModalForm
      formTitle={t('maintenanceRequest.reject')}
      titleIcon={<Block />}
      initialValues={initialValues}
      fields={fields}
      onSubmit={handleSubmit}
      onClose={onClose}
      onValuesChange={(values) => setInitialValues(values)}
      validationSchema={MaintenanceRequestRejectSchema}
    />
  );
};

export default RejectForm;
