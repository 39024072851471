import { SHOW_MENU, HIDE_MENU } from './actionTypes';

const initialState = {
  showMenu: 'flex'
};

const sideMenu = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MENU:
      return {
        ...state,
        showMenu: 'flex'
      };
    case HIDE_MENU:
      return {
        ...state,
        showMenu: 'none'
      };
    default:
      return state;
  }
};

export default sideMenu;
