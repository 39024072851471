import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import colors from '../../assets/scss/_color.scss';
import styles from './dashboard.module.scss';

const labelKeyNameChange = {
  checkIn: 'available',
  checkOut: 'assign'
};

const chartColorByLabel = {
  available: colors.secondary_500,
  assign: colors.orange,
  repair: colors.primary_400,
  sold: colors.danger,
  donate: colors.yellow,
  broken: colors.peacockBlue,
  dispose: colors.neutrals_400,
  lostOrMissing: colors.paleRed
};

const SemiDonutChart = ({ data }) => {
  const { t } = useTranslation();
  const isDataExist = data && Object.keys(data).length > 0;

  const chartData = {
    series: isDataExist ? Object.values(data) : [1],
    options: {
      stroke: {
        width: 0
      },
      colors: isDataExist
        ? Object.keys(data).map((label) => {
            let labelChange = labelKeyNameChange[label];
            const color = labelChange ? chartColorByLabel[labelChange] : chartColorByLabel[label];
            return color || colors.primary_400;
          })
        : colors.primary_400,
      labels: isDataExist
        ? Object.keys(data).map((label) => t(`statuses.${labelKeyNameChange[label] || label}`))
        : [t('empty')],
      chart: {
        parentHeightOffset: 0,
        foreColor: colors.neutrals_500,
        redrawOnParentResize: false
      },
      legend: {
        fontSize: '13px',
        offsetY: 15,
        fontWeight: 600,
        position: 'top',
        markers: {
          radius: 3,
          offsetX: -2
        }
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 90,
          offsetY: 30,
          offsetX: 0
        }
      },
      dataLabels: {
        enabled: isDataExist
      },
      tooltip: {
        enabled: isDataExist,
        fillSeriesColor: false,
        style: {
          color: '#000000'
        }
      },
      fill: {
        type: isDataExist ? 'solid' : 'gradient'
      },
      responsive: [
        {
          breakpoint: 1350,
          options: {
            chart: {
              width: '100%',
              height: 450
            }
          }
        },
        {
          breakpoint: 1155,
          options: {
            chart: {
              height: 430
            }
          }
        },
        {
          breakpoint: 1110,
          options: {
            chart: {
              height: 400
            }
          }
        },
        {
          breakpoint: 600,
          options: {
            chart: {
              height: 450
            }
          }
        },
        {
          breakpoint: 400,
          options: {
            chart: {
              height: 450,
              offsetY: 30
            }
          }
        }
      ]
    }
  };

  return (
    <div className={styles.chart_wrapper}>
      <Chart key="semi-donut-chart" options={chartData.options} series={chartData.series} width="550px" type="donut" />
    </div>
  );
};

export default SemiDonutChart;
