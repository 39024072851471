import * as Yup from 'yup';
import YupPassword from 'yup-password';
import { useTranslation } from 'react-i18next';
YupPassword(Yup);

export const forgotPasswordSchema = (email) =>
  Yup.object({
    email: Yup.string().email().required(email)
  });

export const resetPasswordSchema = (password, confirmPass, passMustMatch) =>
  Yup.object({
    password: Yup.string().password().required(password),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], passMustMatch)
      .required(confirmPass)
  });

export const passwordChangeSchema = (
  currentpassword,
  newPassword,
  confirmPassword,
  oldPasswordMustNotMatch,
  newPasswordMustMacth
) => {
  const { t } = useTranslation();
  return Yup.object({
    currentPassword: Yup.string().required(currentpassword),
    newPassword: Yup.string()
      .notOneOf([Yup.ref('currentPassword'), null], oldPasswordMustNotMatch)
      .min(8, t('myProfile.changePassword.mustContainAtleast8Characters'))
      .minUppercase(1, t('myProfile.changePassword.mustHaveOneUpperCaseCharacter'))
      .minNumbers(1, t('myProfile.changePassword.mustHaveOneNumber'))
      .minSymbols(1, t('myProfile.changePassword.mustHaveOneSymbol'))
      .required(newPassword),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], newPasswordMustMacth)
      .required(confirmPassword)
  });
};
