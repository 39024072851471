import { SET_TABLE_ROW_COUNT, REFRESH_ALERTS, SET_FILTERS, SET_REPORT_TABLE_COLUMNS } from './actionTypes';

const initialState = {
  alertRefresh: null,
  tableRowCountObj: {},
  companyId: '',
  branchId: '',
  searchText: '',
  feedbackType: '',
  reportTableColumns: []
};

const Table = (state = initialState, action) => {
  switch (action.type) {
    case SET_TABLE_ROW_COUNT:
      return {
        ...state,
        tableRowCountObj: action.setTableRowCount
      };
    case REFRESH_ALERTS:
      return {
        ...state,
        alertRefresh: action.alertRefresh
      };
    case SET_FILTERS:
      return {
        ...state,
        companyId: action.companyId,
        branchId: action.branchId,
        searchText: action.searchText,
        feedbackType: action.feedbackType
      };
    case SET_REPORT_TABLE_COLUMNS:
      return {
        ...state,
        reportTableColumns: action.reportTableColumns
      };
    default:
      return state;
  }
};

export default Table;
