import { axiosApi } from './api_helper';
import { store } from '../store';
import { toast } from 'react-toastify';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { statusKeyChange } from './constants';
import { AssetStatusDisplay } from '../pages/Asset/AssetStatus/AssetStatus';
import { useState } from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';
import { isUserSuperAdmin, isUserSuperOrCompanyAdmin } from './access';
import { setHeaderDropDownBranch, setHeaderDropDownCompany } from '../store/actions';

/// Common Service

// This function is used to get all the companies from the API and modify the response to be used in the company filter dropdown

function getCompanies(setCompanies, params, isAllRequired = true) {
  axiosApi
    .get('/companies', { params: { ...params } })
    .then((res) => {
      var val = res.data.data.map((company) => ({
        key: nanoid(),
        label: company.attributes.name,
        value: company.attributes.id
      }));
      if (isAllRequired) val.unshift({ key: nanoid(), label: 'All', value: '' });
      setCompanies(val);
    })
    .catch(() => {
      const { t } = useTranslation();
      toast.error(t('asset.assetDetail.statusInfo.failedCompany'));
    });
}

// This function is used to get all the branches available for the selected company from the API
// if forFilter Params is true then response is modified to be used in the branch filter dropdown
// if forFilter Params is false then response is just to get the users for the selected branch

const getBranches = (setBranches, forFilter = false, params) => {
  axiosApi
    .get('/branches', { params: { ...params } })
    .then((res) => {
      var val = res.data.data.map((branch) => ({
        key: nanoid(),
        label: branch.attributes.name,
        value: branch.attributes.id
      }));
      if (forFilter) val.unshift({ key: nanoid(), label: 'All', value: '' });
      setBranches(val);
    })
    .catch(() => {
      const { t } = useTranslation();
      toast.error(t('asset.assetDetail.statusInfo.failedBranch'));
    });
};

// This function is used to get all the users for the selected branch from the API

const getUsers = async (branchId, setUsers) => {
  axiosApi
    .get('/users', {
      params: {
        filter: { branch_id: branchId },
        is_dropdown: true
      }
    })
    .then((res) => {
      setUsers(
        res.data.data.map((user) => ({
          label: user.attributes.full_name,
          value: user.attributes.id
        }))
      );
    })
    .catch(() => {
      const { t } = useTranslation();
      toast.error(t('asset.assetDetail.statusInfo.failedUser'));
    });
};

// This function gets date input and converts it to local date string format(mm/dd/yyyy)

function getDateString(date) {
  return date && new Date(date).toLocaleDateString();
}

// This function gets from date & to date and converts them to local date string then returns them in range format (fromDate - toDate)

function dateRangeDisplay(fromDate, toDate) {
  return new Date(fromDate).toLocaleDateString() + ' to ' + (toDate ? new Date(toDate).toLocaleDateString() : ' - ');
}

/// AssetDetails Page

//  function to get image and crop it and return the cropped image

const getCroppedImg = async (image64, pixelCrop, fileName) => {
  return new Promise((resolve) => {
    const canvas = document.createElement('canvas');
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext('2d');
    const image = new Image();
    image.src = image64;
    image.onload = function () {
      ctx.drawImage(
        image,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height
      );
      canvas.toBlob((blob) => {
        let blobUrl = URL.createObjectURL(blob);
        let file = new File([blob], fileName, {
          type: 'image/jpeg',
          lastModified: new Date(),
          name: fileName
        });
        blob.data_url = blobUrl;
        blob.file = file;
        resolve(blob);
      }, 'image/jpeg');
    };
  });
};

// function to get image and resize it to fixed height and width  and return the resized image data

function resizedataURL(datas, wantedWidth, wantedHeight) {
  // here wantedWidth and wantedHeight are 500 x 500 px
  return new Promise(function (resolve) {
    var img = document.createElement('img');
    img.onload = function () {
      var canvas = document.createElement('canvas');
      var ctx = canvas.getContext('2d');
      canvas.width = wantedWidth;
      canvas.height = wantedHeight;
      ctx.drawImage(img, 0, 0, wantedWidth, wantedHeight);
      var dataURI = canvas.toDataURL();
      resolve(dataURI);
    };

    img.src = datas;
  });
}

// functiopn to check is navbar sidemenu is open or not
function isNavMenuOpen() {
  const showMenu = store.getState().sideMenu.showMenu;
  return showMenu === 'flex';
}

// function to get asset head cells when the show menu
function getAssetHeadCells(currentTab) {
  const { t } = useTranslation();
  let headCells = [
    {
      id: 'asset_name',
      numeric: true,
      disablePadding: false,
      label: t('asset.general.assetName'),
      canSort: true,
      width: isNavMenuOpen() ? '18%' : '20%',
      maxWidth: isNavMenuOpen() ? '18%' : '20%',
      minWidth: isNavMenuOpen() ? '180px' : '250px',
      hasViewLink: true
    },
    {
      id: 'tag_id',
      numeric: true,
      disablePadding: false,
      label: t('asset.general.tagId'),
      canSort: true,
      width: isNavMenuOpen() ? '18%' : '20%',
      maxWidth: isNavMenuOpen() ? '18%' : '20%',
      minWidth: isNavMenuOpen() ? '180px' : '250px'
    },
    {
      id: 'serial_number',
      numeric: true,
      disablePadding: false,
      label: t('asset.general.serialNumber'),
      canSort: true,
      width: isNavMenuOpen() ? '18%' : '20%',
      maxWidth: isNavMenuOpen() ? '18%' : '20%',
      minWidth: isNavMenuOpen() ? '180px' : '250px'
    },
    {
      id: 'status_display',
      numeric: true,
      disablePadding: false,
      label: t('asset.general.status'),
      width: isNavMenuOpen() ? '13%' : '13%',
      maxWidth: isNavMenuOpen() ? '13%' : '13%',
      minWidth: '180px'
    },
    {
      id: 'assigned_to',
      numeric: true,
      disablePadding: false,
      label: t('asset.general.assignedTo'),
      canSort: true,
      width: isNavMenuOpen() ? '20%' : '16%',
      maxWidth: isNavMenuOpen() ? '20%' : '18%',
      minWidth: '200px'
    },
    {
      id: 'action',
      numeric: true,
      disablePadding: false,
      label: t('common.action'),
      canSort: false,
      width: isNavMenuOpen() ? '15%' : '15%'
    }
  ];
  if (currentTab === 'assigned') return headCells;
  else if (currentTab === 'lost-or-expired') return headCells.filter((headCell) => headCell.id !== 'assigned_to');
  else return headCells.filter((headCell) => !['assigned_to', 'status_display'].includes(headCell.id));
}

// function to get assets data

function getAssetData(data) {
  return data.map((data) => ({
    ...data.attributes,
    status_display: <AssetStatusDisplay status={statusKeyChange[data.attributes.status] || data.attributes.status} />
  }));
}

function scrollBoxShadowStyle(scrollTop, scrollBottom) {
  return scrollTop && scrollBottom
    ? 'inset 0 -8px 5px -5px rgb(0 0 0 / 0.1), inset 0 8px 5px -5px rgb(0 0 0 / 0.1)'
    : scrollBottom
    ? 'inset 0 -8px 5px -5px rgb(0 0 0 / 0.1)'
    : scrollTop
    ? 'inset 0 8px 5px -5px rgb(0 0 0 / 0.1)'
    : 'none';
}

function useScrollVerticle() {
  const [scrollTop, setScrollTop] = useState(false);
  const [scrollBottom, setScrollBottom] = useState(false);
  const onScroll = (event) => {
    const element = event.target;
    if (element.scrollHeight > element.clientHeight) {
      setScrollTop(element.scrollTop > 0);
      setScrollBottom(element.scrollHeight > element.clientHeight + element.scrollTop + 1);
    }
  };
  return [scrollTop, scrollBottom, { onScroll }];
}

function isNotMobileWidth(width) {
  return width >= 800;
}

function displayWithCurrency(currency, amount) {
  return currency && amount ? `${getSymbolFromCurrency(currency)} ${amount}` : '';
}

function setHeaderFilters(dispatch, companyId, branchId) {
  if (!isUserSuperAdmin()) dispatch(setHeaderDropDownCompany(companyId || ''));
  if (!isUserSuperOrCompanyAdmin()) dispatch(setHeaderDropDownBranch(branchId || ''));
}

export {
  getCompanies,
  getBranches,
  getUsers,
  getDateString,
  dateRangeDisplay,
  resizedataURL,
  getCroppedImg,
  isNavMenuOpen,
  getAssetHeadCells,
  getAssetData,
  useScrollVerticle,
  scrollBoxShadowStyle,
  isNotMobileWidth,
  displayWithCurrency,
  setHeaderFilters
};
