import { useTranslation } from 'react-i18next';
import success from '../../assets/images/success.svg';
import commonStyles from '../Login/index.module.scss';
import styles from './index.module.scss';
import { useNavigate } from 'react-router';
import CommonLoginLayout from '../Login/CommonLoginLayout';

const PasswordResetSuccess = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <CommonLoginLayout className={commonStyles.container}>
      <div className={commonStyles.formHeader}>
        <span>{t('forgotPassword.resetSuccess.passwordResetSuccess')}</span>
      </div>
      <p>{t('forgotPassword.resetSuccess.infoText')}</p>
      <div className={styles.checkMailImgContainer}>
        <img className={styles.checkMail} src={success} alt="" />
      </div>
      <button type="submit" className={commonStyles.button} onClick={() => navigate('/login')}>
        {t('forgotPassword.resetSuccess.continue')}
      </button>
    </CommonLoginLayout>
  );
};

export default PasswordResetSuccess;
