import { useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import { Paper } from '@mui/material';
import CategoryIcon from '@mui/icons-material/Category';
import styles from './assetProperties.module.scss';
import { useTranslation } from 'react-i18next';
import { axiosApi } from '../../helpers/api_helper';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Table from '../../components/Table';
import AddNewValue from '../Asset/AddForm';
import FilterSelect from '../../components/FilterSelect';
import CreateButton from '../../components/Form/Button/CreateButton';
import {
  hasAccess,
  hasDeleteAccess,
  hasEditAcccess,
  isUserSuperAdmin,
  MODEL_BRAND,
  MODEL_CATEGORY,
  MODEL_PRODUCT_TYPE
} from '../../helpers/access';
import DeleteToaster from '../../components/Toaster/DeleteToaster';

const AssetPropertiesList = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const currentTab = new URLSearchParams(window.location.search).get('tab');
  const BRANDS_TAB = 'brands';
  const CATEGORIES_TAB = 'categories';
  const PRODUCT_TYPES_TAB = 'product_types';
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState('');
  const [rowData, setRowData] = useState([]);
  const [totalRowsCount, setTotalRowsCount] = useState(0);
  const [totalPagesCount, setTotalPagesCount] = useState(0);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const table = useSelector((state) => state.table);
  const companyId = isUserSuperAdmin()
    ? useSelector((state) => state.common.companyId)
    : useSelector((state) => state.login.authUser).company_id;
  const [paginationDetails, setPaginationDetails] = useState({
    currentPage: 1,
    rowsPerPage: table?.tableRowCountObj?.count
  });
  const [currentMenuTab, setCurrentMenuTab] = useState('');
  const [selectedProperty, setSelectedProperty] = useState('');
  const handleTabChange = (value) => navigate({ search: `?tab=${value}` });
  const [isDeleteToasterOpen, setIsDeleteToasterOpen] = useState(false);

  const menuTabNames = [
    ...(hasAccess(MODEL_BRAND)
      ? [
          {
            key: nanoid(),
            value: BRANDS_TAB,
            title: 'Brands'
          }
        ]
      : []),
    ...(hasAccess(MODEL_CATEGORY)
      ? [
          {
            key: nanoid(),
            value: CATEGORIES_TAB,
            title: 'Categories'
          }
        ]
      : []),
    ...(hasAccess(MODEL_PRODUCT_TYPE)
      ? [
          {
            key: nanoid(),
            value: PRODUCT_TYPES_TAB,
            title: 'Product Types'
          }
        ]
      : [])
  ];

  const headCells = [
    {
      id: 'name',
      label: t('common.name')
    },
    {
      id: 'used_by_count',
      label: t(`asset.assetAdd.addNew.${currentMenuTab}UsedCount`),
      width: '25%'
    },
    {
      id: 'action',
      label: t('common.action'),
      width: '10%'
    }
  ];

  useEffect(() => {
    if (!companyId) return;
    if (currentMenuTab == PRODUCT_TYPES_TAB) getCategories();
    else fetchProperties();
  }, [companyId]);

  useEffect(() => {
    if (!currentTab) handleTabChange(menuTabNames[0].value);
    else setCurrentMenuTab(currentTab);
  }, [currentTab]);

  useEffect(() => {
    if (!currentMenuTab) return;
    setCategoryId('');
    if (currentMenuTab == PRODUCT_TYPES_TAB) getCategories();
    else fetchProperties();
  }, [currentMenuTab]);

  useEffect(() => {
    if (!categories) return;
    if (currentMenuTab == PRODUCT_TYPES_TAB && categories.length > 0) setCategoryId(categories[0].value);
  }, [categories]);

  useEffect(() => {
    if (!categoryId) return;
    fetchProperties();
  }, [categoryId]);

  const fetchProperties = () => {
    if (!currentMenuTab || !companyId) return;
    let params = {};
    if (currentMenuTab != PRODUCT_TYPES_TAB) params['company_id'] = companyId;
    if (categoryId && currentMenuTab === PRODUCT_TYPES_TAB) params['category_id'] = categoryId;
    if (paginationDetails?.currentPage) params['page'] = paginationDetails.currentPage;
    if (paginationDetails?.rowsPerPage) params['items'] = paginationDetails.rowsPerPage;
    setIsLoading(true);
    axiosApi
      .get('/' + currentMenuTab, { params })
      .then((res) => {
        setRowData(
          res.data.data.map((item) => ({
            ...item,
            row_meta: {
              delete_message:
                item.used_by_count > 0
                  ? t(`asset.assetAdd.addNew.${currentMenuTab}UsedBy`, {
                      used_by_count: item.used_by_count
                    })
                  : null
            }
          }))
        );
        setTotalRowsCount(res.data.options.meta.total_count);
        setTotalPagesCount(res.data.options.meta.total_pages);
      })
      .catch(() => {
        toast.error(t('common.somethingWentsWrong'));
      })
      .finally(() => setIsLoading(false));
  };

  const getCategories = () => {
    if (currentMenuTab != PRODUCT_TYPES_TAB) return;
    let params = {};
    params['company_id'] = companyId;
    setIsLoading(true);
    axiosApi
      .get('/categories', { params })
      .then((res) => {
        setCategories(
          res.data.data.map((category) => ({
            key: category.id,
            label: category.name,
            value: category.id
          }))
        );
      })
      .catch(() => {
        toast.error(t('common.somethingWentsWrong'));
      })
      .finally(() => setIsLoading(false));
  };

  const handleAdd = () => {
    setSelectedProperty(null);
    setIsAddFormOpen(true);
  };

  const handleEdit = (row_id) => {
    setSelectedProperty(rowData.find((x) => x.id === row_id));
    setIsAddFormOpen(true);
  };

  const handleDelete = (row_id) => {
    axiosApi
      .delete(`/${currentMenuTab}/${row_id}`)
      .then(() => {
        setIsDeleteToasterOpen(true);
        fetchProperties();
      })
      .catch((e) => {
        toast.error(e.error || t('common.somethingWentsWrong'));
      });
  };

  const getModelName = () => {
    return currentMenuTab == BRANDS_TAB
      ? MODEL_BRAND
      : currentMenuTab == CATEGORIES_TAB
      ? MODEL_CATEGORY
      : MODEL_PRODUCT_TYPE;
  };
  return (
    <Paper elevation={0} className={`${styles.flexHeightAuto} ${styles.h_100}`}>
      <div className={`${styles.header_container} ${styles.header}`}>
        <div className={styles.header_name_container}>
          <CategoryIcon className={styles.solid_list_icon} />
          <h6 className={`${styles.title_name} ${styles.text_3xl}`}>{t('navbarItems.assetProperties')}</h6>
        </div>
        <div className={styles.filter_container}>
          <CreateButton
            buttonTitle={t('add')}
            onClick={() => handleAdd()}
            className={`${styles.btn} ${styles.mobile_button_width} ${styles.CreateInventoryProperties}`}
          />
        </div>
      </div>
      <div className={styles.tabHeader}>
        {menuTabNames.map((menu) => {
          return (
            <span
              key={menu.key}
              className={currentMenuTab === menu.value ? styles.selectedTab : ''}
              onClick={() => {
                handleTabChange(menu.value);
              }}>
              <span className={styles.tab}>{menu.title}</span>
            </span>
          );
        })}
      </div>
      <div className={styles.tab_container}>
        {currentMenuTab == PRODUCT_TYPES_TAB && (
          <div className={styles.category_container}>
            <FilterSelect
              value={categoryId}
              label={t('asset.assetDetail.category')}
              items={categories}
              placeholder={t('asset.assetAdd.selectCategory')}
              onChange={(id) => {
                setCategoryId(id);
              }}
            />
          </div>
        )}
        <Table
          pageName={currentMenuTab}
          headCells={headCells}
          rows={rowData}
          viewBtn={false}
          editBtn={hasEditAcccess(getModelName())}
          editBtnFn={handleEdit}
          deleteBtn={hasDeleteAccess(getModelName())}
          deleteBtnFn={handleDelete}
          loading={isLoading}
          noDataMsg="table.tableNoData"
          onChange={(values) => {
            JSON.stringify(values) !== JSON.stringify(paginationDetails) && setPaginationDetails(values);
          }}
          totalRowsCount={totalRowsCount}
          totalPagesCount={totalPagesCount}
          showPagination={true}
        />
        {isAddFormOpen && (
          <AddNewValue
            isOpen={isAddFormOpen}
            companyId={companyId}
            addValueFor={
              currentMenuTab == BRANDS_TAB ? 'brand' : currentMenuTab == CATEGORIES_TAB ? 'category' : 'type'
            }
            onClose={() => setIsAddFormOpen(false)}
            onAfterAdd={() => {
              fetchProperties();
              setIsAddFormOpen(false);
            }}
            category={categoryId}
            editValues={selectedProperty}
          />
        )}
      </div>

      {isDeleteToasterOpen && (
        <DeleteToaster
          onClose={() => {
            setIsDeleteToasterOpen(false);
          }}
          message={t(`common.deletedSuccessfully`)}
          canUndo={false}
        />
      )}
    </Paper>
  );
};

export default AssetPropertiesList;
