import Table from '../../components/Table';
import { useCallback, useEffect, useState } from 'react';
import { axiosApi } from '../../helpers/api_helper';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { isNavMenuOpen } from '../../helpers/common_service';
import CreateButton from '../../components/Form/Button/CreateButton';
import styles from './addRole.module.scss';
import { Paper } from '@mui/material';
import AddRole from './AddRole';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import DeleteToaster from '../../components/Toaster/DeleteToaster';

const RoleList = () => {
  const [roles, setRoles] = useState([]);
  const [totalRowsCount, setTotalRowsCount] = useState(0);
  const [totalPagesCount, setTotalPagesCount] = useState(0);
  const table = useSelector((state) => state.table);
  const [paginationDetails, setPaginationDetails] = useState({
    currentPage: 1,
    rowsPerPage: table?.tableRowCountObj?.count
  });
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [isDeleteToasterOpen, setIsDeleteToasterOpen] = useState(false);

  const headCells = [
    {
      id: 'label',
      label: t('common.name')
    },
    {
      id: 'users_count',
      label: t('navbarItems.users')
    },
    {
      id: 'action',
      label: t('common.action'),
      canSort: false,
      width: isNavMenuOpen() ? '10%' : '10%',
      maxWidth: isNavMenuOpen() ? '20%' : '20%'
    }
  ];

  const fetchRoles = useCallback(() => {
    let parameters = {};

    if (paginationDetails?.currentPage) parameters['page'] = paginationDetails.currentPage;
    if (paginationDetails?.rowsPerPage) parameters['items'] = paginationDetails.rowsPerPage;

    setIsLoading(true);
    axiosApi
      .get('/roles', {
        params: parameters
      })
      .then((res) => {
        setRoles(
          res.data.data.map((role) => {
            return {
              ...role.attributes,
              row_meta: {
                can_modify_row: role.attributes.can_edit,
                delete_message:
                  role.attributes.users_count > 0
                    ? t(`authorizableModels.add.rolesUsedBy`, {
                        used_by_count: role.attributes.users_count
                      })
                    : null
              }
            };
          })
        );
        setTotalRowsCount(res.data.meta.total_count);
        setTotalPagesCount(res.data.meta.total_pages);
      })
      .catch(() => {
        toast.error(t('common.somethingWentsWrong'));
      })
      .finally(() => setIsLoading(false));
  }, [paginationDetails]);

  useEffect(() => {
    fetchRoles();
  }, []);

  const handleAdd = () => {
    setSelectedRole(null);
    setIsAddFormOpen(true);
  };
  const handleEdit = (row_id) => {
    setSelectedRole(roles.find((x) => x.id === row_id));
    setIsAddFormOpen(true);
  };

  const handleDelete = (row_id) => {
    axiosApi
      .delete(`/roles/${row_id}`)
      .then(() => {
        setIsDeleteToasterOpen(true);
        fetchRoles();
      })
      .catch((e) => {
        toast.error(e.error || t('common.somethingWentsWrong'));
      });
  };
  return (
    <Paper elevation={0} className={`${styles.flexHeightAuto} ${styles.h_100}`}>
      <div className={`${styles.header_container} ${styles.header}`}>
        <div className={styles.header_name_container}>
          <FontAwesomeIcon icon={faKey} className={styles.solid_list_icon} />
          <h6 className={`${styles.title_name} ${styles.text_3xl}`}>{t('navbarItems.roles')}</h6>
        </div>
        <div className={styles.filter_container}>
          <CreateButton
            buttonTitle={t('add')}
            onClick={() => handleAdd()}
            className={`${styles.btn} ${styles.mobile_button_width} ${styles.CreateInventoryProperties}`}
          />
        </div>
      </div>
      <div className={styles.table_container}>
        <Table
          pageName="roles"
          headCells={headCells}
          rows={roles}
          viewBtn={false}
          editBtnFn={handleEdit}
          deleteBtnFn={handleDelete}
          loading={isLoading}
          noDataMsg="table.tableNoData"
          onChange={(values) => {
            JSON.stringify(values) !== JSON.stringify(paginationDetails) && setPaginationDetails(values);
          }}
          totalRowsCount={totalRowsCount}
          totalPagesCount={totalPagesCount}
          showPagination={true}
        />
      </div>
      {isAddFormOpen && (
        <AddRole
          onClose={() => setIsAddFormOpen(false)}
          afterSubmit={() => {
            fetchRoles();
            setIsAddFormOpen(false);
          }}
          roleId={selectedRole?.id}
        />
      )}

      {isDeleteToasterOpen && (
        <DeleteToaster
          onClose={() => {
            setIsDeleteToasterOpen(false);
          }}
          message={t(`common.deletedSuccessfully`)}
          canUndo={false}
        />
      )}
    </Paper>
  );
};

export default RoleList;
