import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import ShowDetails from '../../../../components/ShowDetail';
import { getDateString } from '../../../../helpers/common_service';

const InsuranceDetails = ({ insurance, onClose }) => {
  const { t } = useTranslation();
  const insuranceDetails = [
    {
      key: nanoid(),
      label: t('asset.assetDetail.insuranceTab.insuranceCompanyName'),
      value: insurance.insurance_company_name
    },
    {
      key: nanoid(),
      label: t('asset.assetDetail.insuranceTab.policyNo'),
      value: insurance.policy_no
    },
    {
      key: nanoid(),
      label: t('asset.assetDetail.insuranceTab.startDate'),
      value: getDateString(insurance.start_date)
    },
    {
      key: nanoid(),
      label: t('asset.assetDetail.insuranceTab.endDate'),
      value: getDateString(insurance.end_date)
    },
    {
      key: nanoid(),
      label: t('asset.assetDetail.insuranceTab.insuranceCost'),
      value: insurance.insurance_cost
    },
    {
      key: nanoid(),
      label: t('asset.assetDetail.insuranceTab.insuranceCoverage'),
      value: insurance.insurance_coverage
    },
    {
      key: nanoid(),
      label: t('asset.assetDetail.insuranceTab.notes'),
      value: insurance.notes
    }
  ];

  return (
    <ShowDetails
      detailFor={t('asset.assetDetail.insuranceTab.showInsurance')}
      details={insuranceDetails}
      attachments={insurance.attachments}
      onClose={onClose}
    />
  );
};

export default InsuranceDetails;
