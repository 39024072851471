import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, Menu, MenuItem, Paper } from '@mui/material';
import UserForm from '../User/Form';
import {
  Add,
  KeyboardArrowDown,
  PeopleAlt,
  Verified,
  NotificationImportant,
  KeyboardArrowUp,
  ArrowForward
} from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCube, faGlobe, faCodeBranch, faUserGroup } from '@fortawesome/free-solid-svg-icons';
import { axiosApi } from '../../helpers/api_helper';
import DrillPieChart from './DrillPieChart';
import SemiDonutChart from './SemiDonutChart';
import BarChart from './BarChart';
import appStyles from '../../assets/scss/_app.module.scss';
import buttonStyles from '../../assets/scss/_button.module.scss';
import styles from './dashboard.module.scss';
import { SET_MODAL_FILTER } from '../../store/filterModal/actionTypes';
import { SET_FILTERS } from '../../store/table/actionTypes';
import { inventoryPage, userPage, expiringPage } from '../../helpers/constants';
import AssetList from './AssetList';
import { hasCreateAccess, isUserSuperAdmin, MODEL_BRANCH, MODEL_PRODUCT, MODEL_USER } from '../../helpers/access';
import { setHeaderFilters } from '../../helpers/common_service';

const Dashboard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [dashboardData, setDashboardData] = useState({});
  const loginUser = useSelector((state) => state.login.authUser);
  const companyId = useSelector((state) => state.common.companyId);
  const [filterValues, setFilterValues] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isUserFormOpen, setisUserFormOpen] = useState('');

  const handleDispatch = (pageName) => {
    dispatch({
      type: SET_FILTERS,
      companyId: companyId,
      branchId: '',
      searchText: ''
    });
    dispatch({
      type: SET_MODAL_FILTER,
      filterModal: {},
      pageName: pageName
    });
  };

  const handleDrawerClose = () => setisUserFormOpen('');

  const addMenuItems = [
    ...(isUserSuperAdmin()
      ? [
          {
            id: 'company',
            label: 'Company',
            link: '/add-company',
            icon: <FontAwesomeIcon icon={faGlobe} />
          }
        ]
      : []),
    ...(hasCreateAccess(MODEL_BRANCH)
      ? [
          {
            id: 'branch',
            label: 'Branch',
            link: '/add-branch',
            icon: <FontAwesomeIcon icon={faCodeBranch} />
          }
        ]
      : []),
    ...(hasCreateAccess(MODEL_USER)
      ? [
          {
            id: 'user',
            label: 'User',
            icon: <FontAwesomeIcon icon={faUserGroup} />
          }
        ]
      : []),
    ...(hasCreateAccess(MODEL_PRODUCT)
      ? [
          {
            id: 'inventory',
            label: 'Inventory',
            link: '/add-inventory',
            icon: <FontAwesomeIcon icon={faCube} />
          }
        ]
      : [])
  ];
  const getDashboardData = () => {
    let params = {
      ...(isUserSuperAdmin() ? { company_id: companyId } : {}),
      ...filterValues
    };

    axiosApi
      .get('/users/dashboard', { params })
      .then((res) => {
        setDashboardData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const progressCounts = [
    {
      key: 'total_users',
      label: 'Total Users',
      value: dashboardData.dashboard_details_count?.users_count || 0,
      icon: <PeopleAlt />,
      link: '/user-list',
      navigationState: () => handleDispatch(userPage)
    },
    {
      key: 'total_inventory',
      label: 'Total Inventory',
      value: dashboardData.dashboard_details_count?.total_products_count || 0,
      icon: <FontAwesomeIcon icon={faCube} size="lg" />,
      link: '/inventory-list',
      navigationState: () => handleDispatch(inventoryPage)
    },
    {
      key: 'available_inventory',
      label: 'Available Inventory',
      value: dashboardData.dashboard_details_count?.available_products_count || 0,
      icon: <Verified />,
      link: '/inventory-list?tab=available',
      navigationState: () => handleDispatch(inventoryPage)
    },
    {
      key: 'expiring_inventory',
      label: 'Expiring Inventory',
      value: dashboardData.dashboard_details_count?.expiring_products_count || 0,
      icon: <NotificationImportant />,
      link: '/expiring',
      navigationState: () => handleDispatch(expiringPage)
    }
  ];

  const getColor = (key) => {
    return (
      (key === 'total_users' && styles.total_users) ||
      (key === 'total_inventory' && styles.total_assets) ||
      (key === 'available_inventory' && styles.available_assets) ||
      (key === 'expiring_inventory' && styles.expiring_assets)
    );
  };

  useEffect(() => {
    if (companyId) getDashboardData();
  }, [filterValues, companyId]);

  useEffect(() => {
    setHeaderFilters(dispatch, loginUser.company_id, loginUser.branch_id);
  }, []);

  useEffect(() => {
    function handleWindowResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [windowWidth]);

  return (
    <>
      <div className={styles.dropdown_container}>
        <Button
          id="demo-customized-button"
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="contained"
          disableElevation
          onClick={(e) => setAnchorEl(e.currentTarget)}
          startIcon={<Add />}
          endIcon={open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          className={styles.add_btn}>
          <span className={styles.add_btn_content}>{t('add')}</span>
        </Button>
        <Menu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button'
          }}
          className={styles.add_menu}
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}>
          {addMenuItems.map((menuItem, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                setAnchorEl(null);
                if (menuItem.link) navigate(`${menuItem.link}`);
                else setisUserFormOpen(menuItem.id);
              }}
              disableRipple>
              {menuItem.icon}
              {menuItem.label}
            </MenuItem>
          ))}
        </Menu>
      </div>
      <div>
        <Grid
          container
          rowSpacing={2}
          paddingX={'unset'}
          columnSpacing={{ sm: 2, md: 3 }}
          className={appStyles.grid_container}>
          {progressCounts.map((progress) => (
            <Grid key={progress.key} item xs={12} sm={6} md={3}>
              <Paper
                elevation={0}
                className={`${appStyles.paperScrollable} ${styles.paper}`}
                onClick={() => {
                  progress.navigationState();
                  progress.link && navigate(progress.link);
                }}>
                <div className={styles.details}>
                  <div className={styles.value}>{progress.value}</div>
                  <small className={`${getColor(progress.key)} ${styles.label}`}>{progress.label}</small>
                </div>
                <div className={`${getColor(progress.key)} ${styles.icon_container}`}>{progress.icon}</div>
              </Paper>
            </Grid>
          ))}

          <Grid item xs={12} md={windowWidth > 1024 ? 6 : 12}>
            <Paper elevation={0} className={`${styles.chart_container} ${styles.pie_chart}`}>
              <div className={styles.chart_header}>{t('dashboard.inventoryBrands')}</div>
              <DrillPieChart companyId={companyId} />
            </Paper>
          </Grid>

          <Grid item xs={12} md={windowWidth > 1024 ? 6 : 12}>
            <Paper elevation={0} className={`${styles.chart_container} ${styles.semi_donut}`}>
              <div className={styles.chart_header}>{t('dashboard.inventoryStatus')}</div>
              <SemiDonutChart data={dashboardData.status_count_of_products} />
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper elevation={0} className={styles.bar_chart_container}>
              <BarChart
                data={dashboardData.product_purchase_history}
                filterValues={filterValues}
                onChange={(values) => setFilterValues(values)}
              />
            </Paper>
          </Grid>
        </Grid>
      </div>
      <Paper elevation={0} className={styles.assetListContainer}>
        <div className={styles.assetListHeader}>
          <div className={styles.chart_header}>{t('navbarItems.recentInventory')}</div>
          <Button
            classes={{ root: styles.viewAllButtonRoot }}
            onClick={() => {
              handleDispatch(inventoryPage, {});
              navigate('/inventory-list');
            }}
            className={`${buttonStyles.filter_btn} ${styles.viewAllButton}`}>
            <span>{t('viewAll')}</span>
            <ArrowForward className={styles.assetListViewAllBtn} />
          </Button>
        </div>
        <AssetList companyId={companyId} />
      </Paper>
      <UserForm
        open={isUserFormOpen === 'user'}
        details={{}}
        onClose={handleDrawerClose}
        onSubmit={handleDrawerClose}
      />
    </>
  );
};

export default Dashboard;
