import React, { useEffect, useState } from 'react';
import app_form_style from '../../assets/scss/_app.module.scss';
import form_field_style from '../../assets/scss/_form_field.module.scss';
import buttonStyles from '../../assets/scss/_button.module.scss';
import { TextField } from '../../components/Form/TextField/index';
import DatePicker from '../../components/Form/DatePicker';
import { useFormik, Form, FormikProvider } from 'formik';
import { Paper, Grid, Button } from '@mui/material';
import { Cancel as CancelIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import ImageUploading from 'react-images-uploading';
import IconImage from '../../assets/images/icon-image.svg';
import DefaultImage from '../../assets/images/image-upload-1.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCube } from '@fortawesome/free-solid-svg-icons';
import { axiosApi } from '../../helpers/api_helper';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import DropDown from '../../components/Form/Dropdown';
import { AssetStatusDisplay } from './AssetStatus/AssetStatus';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { AssetSchema } from '../../schemas/AssetSchema';
import AddNewValue from './AddForm';
import AssetEvents from '../AssetDetail/Events';
import styles from './addAsset.module.scss';
import Loader from '../../components/Loader';
import CurrencyField from '../../components/Form/CurrencyField';
import {
  AUTHORITY_LEVEL_COMPANY,
  hasCreateAccess,
  isUserSuperAdmin,
  MODEL_PRODUCT,
  showCompanyFilter
} from '../../helpers/access';

const AddAsset = () => {
  const loginUser = useSelector((state) => state.login.authUser);
  const editAsset = useLocation().pathname.includes('edit');
  const [assetData, setAssetData] = useState({});
  const [branches, setBranches] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);
  const [types, setTypes] = useState([]);
  const [removedImageIds, setRemovedImageIds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  let cancelledImageIds = [];
  const maxImageLimit = 5;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [addValueFor, setAddValueFor] = useState('');
  const [status, setStatus] = useState('');
  const canEditEvent = assetData.status_info && Object.keys(assetData.status_info).length > 0;
  const [isToasterActive, setIsToasterActive] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const [prevSavedData, setPrevSavedData] = useState({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const onSubmit = (values) => {
    const formData = new FormData();
    formData.append('asset_name', values.asset_name);
    formData.append('product[tag_id]', values.tag_id);
    formData.append('product[product_type_id]', values.type);
    formData.append('product[description]', values.description);
    formData.append('product[purchase_date]', values.purchase_date);
    formData.append('product[purchase_price]', values.cost);
    formData.append('product[country_id]', values.country);
    formData.append('product[serial_number]', values.serial_number);
    formData.append('product[brand_id]', values.brand);
    formData.append('product[model]', values.model);

    if (hasCreateAccess(MODEL_PRODUCT, AUTHORITY_LEVEL_COMPANY)) {
      formData.append('product[branch_id]', values.branch);
    }

    if (editAsset && id) {
      values.images.map((file) => {
        if (!file.id) {
          formData.append('new_images[]', file.file, file.file.name);
        }
      });
      if (removedImageIds.length) formData.append('removed_image_ids', removedImageIds);

      axiosApi
        .patch(`/products/${id}`, formData, {
          headers: {
            Authorization: `Bearer ${loginUser['remember_token']}`,
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((res) => {
          navigate(`/inventory-details/${res.data.data.attributes.id}`);
          toast.success(t('asset.assetAdd.inventoryUpdatedSuccessfully'));
        })
        .catch((err) => {
          setIsToasterActive(true);
          toast.error(t(err.response?.data?.message_key ?? 'asset.assetAdd.failedToUpdateInventory'), {
            onClose: () => setIsToasterActive(false)
          });
        });
    } else {
      values.images.map((file) => {
        formData.append('images[]', file.file, file.file.name);
      });
      axiosApi
        .post('/products', formData, {
          headers: {
            Authorization: `Bearer ${loginUser['remember_token']}`,
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((res) => {
          navigate(`/inventory-details/${res.data.data.attributes.id}`);
          toast.success(t('asset.assetAdd.inventoryCreatedSuccessfully'));
        })
        .catch((err) => {
          toast.error(t(`asset.assetAdd.${err.response?.data?.message_key ?? 'failedToCreateNewInventory'}`));
        });
    }
  };

  const formik = useFormik({
    initialValues: {
      description: '',
      model: '',
      tag_id: '',
      asset_name: '',
      brand: '',
      category: '',
      serial_number: '',
      type: '',
      cost: '',
      country: '',
      purchase_date: '',
      images: [],
      status: editAsset ? '' : 'checkIn',
      company: showCompanyFilter() ? '' : loginUser.company_id,
      branch: hasCreateAccess(MODEL_PRODUCT, AUTHORITY_LEVEL_COMPANY) ? '' : loginUser.branch_id
    },
    onSubmit,
    validationSchema: AssetSchema(
      t('common.validation.pleaseSelectCompany'),
      t('common.validation.pleaseSelectBranch'),
      t('asset.assetAdd.pleaseEnterassetName'),
      t('asset.assetAdd.pleaseEnterSerailNumber'),
      t('asset.assetAdd.pleaseSelectLessThanSixImage'),
      t('asset.assetAdd.pleaseSelectTypeAfterCategorySelection')
    )
  });

  const getCompanies = () => {
    axiosApi
      .get('/companies', { params: { is_dropdown: true } })
      .then((res) =>
        setCompanies(
          res.data.data.map((company) => ({
            label: company.attributes.name,
            value: company.attributes.id
          }))
        )
      )
      .catch((err) => console.log(err));
  };

  const getBranches = () => {
    if (!formik.values.company) return;
    axiosApi
      .get('/branches', {
        params: { company_id: formik.values.company, is_dropdown: true }
      })
      .then((res) => {
        setBranches(
          res.data.data.map((branch) => ({
            label: branch.attributes.name,
            value: branch.attributes.id
          }))
        );
      })
      .catch((err) => console.log(err));
  };

  const getBrands = () => {
    if (!formik.values.company) return;
    axiosApi
      .get('/brands', {
        params: { company_id: formik.values.company }
      })
      .then((res) =>
        setBrands(
          res.data.data.map((brand) => ({
            label: brand.name,
            value: brand.id
          }))
        )
      )
      .catch((err) => console.log(err));
  };

  const getCategories = () => {
    if (!formik.values.company) return;
    axiosApi
      .get('/categories', {
        params: { company_id: formik.values.company }
      })
      .then((res) =>
        setCategories(
          res.data.data.map((category) => ({
            label: category.name,
            value: category.id
          }))
        )
      )
      .catch((err) => console.log(err));
  };

  const getTypes = () => {
    if (!formik.values.category) return;
    axiosApi
      .get('/product_types', {
        params: {
          category_id: formik.values.category,
          company_id: formik.values.company
        }
      })
      .then((res) => {
        setTypes(
          res.data.data.map((type) => ({
            label: type.name,
            value: type.id
          }))
        );
      })
      .catch((err) => console.log(err));
  };

  const getAssetData = () => {
    setIsLoading(true);
    axiosApi
      .get(`/products/${id}`)
      .then((res) => {
        setAssetData(res.data.data.attributes);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsLoading(false));
  };

  const setValues = () => {
    let obj = {
      description: assetData.description || '',
      model: assetData.model || '',
      tag_id: assetData.tag_id || '',
      asset_name: assetData.asset_name || '',
      brand: assetData.brand_id || '',
      category: assetData.category_id || '',
      serial_number: assetData.serial_number || '',
      type: assetData.product_type_id || '',
      cost: assetData.purchase_price_display || '',
      country: assetData.country_id || '',
      purchase_date: assetData.purchase_date || '',
      images: assetData.images || [],
      company: assetData.company_id || '',
      branch: assetData.branch_id || '',
      status: assetData.status || ''
    };
    formik.setValues(obj);
    setPrevSavedData(obj);
  };
  const canUpdateImages = (images) => {
    if (
      !cancelledImageIds.length &&
      (!formik.values.images.length
        ? formik.values.images.length - cancelledImageIds.length + (images.length - formik.values.images.length)
        : images.length) > maxImageLimit
    ) {
      toast.warning(
        t('asset.assetAdd.imageLimitReachedMaximumNumOfImages', {
          uploadLimit: canUploadNumber
        })
      );
    } else {
      formik.setFieldValue('images', images);
    }
  };

  useEffect(() => {
    function handleWindowResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    getCompanies();
    if (isUserSuperAdmin()) {
      getBranches();
      getBrands();
      getCategories();
    }
    if (editAsset && id) {
      setIsLoading(true);
      getAssetData();
    }
  }, []);

  useEffect(() => {
    if (Object.keys(assetData).length) {
      setValues();
    }
  }, [assetData]);

  useEffect(() => {
    if (formik.values.category !== assetData.category_id) formik.setFieldValue('type', '');
    if (formik.values.category) getTypes();
  }, [formik.values.category]);

  useEffect(() => {
    if (formik.values.company) {
      getBranches();
      getBrands();
      getCategories();
    }
  }, [formik.values.company]);

  useEffect(() => {
    if (editAsset) {
      if (JSON.stringify(prevSavedData) !== JSON.stringify(formik.values)) setCanSave(true);
      else setCanSave(false);
    }
  }, [prevSavedData, formik.values]);

  const canUploadNumber = formik.values.images.length ? maxImageLimit - formik.values.images.length : maxImageLimit;
  return (
    <>
      {editAsset && isLoading ? (
        <Loader loading={isLoading} isPageLoader={true} />
      ) : (
        <>
          {/* <div>
            <BackButton />
          </div> */}
          <Paper elevation={0} className={`${app_form_style.flexHeightAuto}`}>
            <div className={`${app_form_style.header_container} ${styles.headerContainer}`}>
              <FontAwesomeIcon icon={faCube} className={app_form_style.solid_list_icon} />
              <h6 className={`${app_form_style.title_name} ${form_field_style.text_3xl}`}>
                {t(`asset.general.${editAsset ? 'edit' : 'add'}Inventory`)}
              </h6>
            </div>
            <hr className={styles.horizontal_line} />

            <FormikProvider value={formik}>
              <Form className={app_form_style.form_style}>
                <Grid
                  container
                  rowSpacing={4}
                  columnSpacing={4}
                  className={`${app_form_style.grid_container} ${styles.gridContainer}`}>
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    md={1.9}
                    className={`${app_form_style.grid_title_name} ${styles.gridTitleName}`}>
                    {t('common.company')}
                    <span className={app_form_style.required}>*</span>
                  </Grid>
                  <Grid item xs={12} sm={9} md={4} spacing={2} columnGap={3}>
                    <DropDown
                      disabled={!isUserSuperAdmin()}
                      name="company"
                      type="text"
                      value={formik.values.company}
                      onChange={(e) => {
                        formik.setFieldValue('category', '');
                        formik.setFieldValue('brand', '');
                        formik.handleChange(e);
                      }}
                      className={styles.grid_textField}
                      dataList={companies}
                      placeholder={t('common.selectCompany')}
                      error={formik.touched.company && formik.errors.company}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    md={1.3}
                    style={windowWidth > 900 ? { marginLeft: '40px' } : {}}
                    className={`${app_form_style.grid_title_name} ${styles.gridTitleName}`}>
                    {t('common.branch')}
                    <span className={app_form_style.required}>*</span>
                  </Grid>
                  <Grid item xs={12} sm={9} md={4}>
                    <DropDown
                      disabled={!hasCreateAccess(MODEL_PRODUCT, AUTHORITY_LEVEL_COMPANY)}
                      className={styles.grid_textField}
                      name="branch"
                      type="text"
                      value={formik.values.branch}
                      onChange={formik.handleChange}
                      dataList={branches}
                      placeholder={t('common.selectBranch')}
                      error={formik.touched.branch && formik.errors.branch}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    md={1.9}
                    display="flex"
                    alignItems="center"
                    className={`${app_form_style.grid_title_name} ${styles.gridTitleName}`}>
                    <div className={styles.gridKeyName}>
                      {t('asset.general.assetName')}
                      <span className={app_form_style.required}>*</span>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={9} md={4}>
                    <TextField
                      className={`${app_form_style.TextField} ${form_field_style.TextField_style} ${styles.grid_textField}`}
                      name="asset_name"
                      fieldType="specialChar"
                      value={formik.values.asset_name}
                      onChange={formik.handleChange}
                      error={formik.touched.asset_name && formik.errors.asset_name}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    md={1.3}
                    style={windowWidth > 900 ? { marginLeft: '40px' } : {}}
                    className={`${`${app_form_style.grid_title_name} ${styles.gridTitleName}`} `}>
                    {t('asset.assetAdd.serialNo')}
                    <span className={app_form_style.required}>*</span>
                  </Grid>
                  <Grid item xs={12} sm={9} md={4}>
                    <TextField
                      className={`${app_form_style.TextField} ${form_field_style.TextField_style} ${styles.grid_textField}`}
                      name="serial_number"
                      fieldType="specialChar"
                      value={formik.values.serial_number}
                      onChange={formik.handleChange}
                      error={formik.touched.serial_number && formik.errors.serial_number}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    md={1.9}
                    className={`${app_form_style.grid_title_name} ${styles.gridTitleName}`}>
                    {t('asset.general.model')}
                  </Grid>
                  <Grid item xs={12} sm={9} md={4}>
                    <TextField
                      className={`${app_form_style.TextField} ${form_field_style.TextField_style} ${styles.iconInput} ${styles.grid_textField}`}
                      name="model"
                      fieldType="alphaNumeric"
                      value={formik.values.model}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    md={1.3}
                    style={windowWidth > 900 ? { marginLeft: '40px' } : {}}
                    className={`${app_form_style.grid_title_name} ${styles.gridTitleName}`}>
                    {t('asset.general.tagId')}
                  </Grid>
                  <Grid item xs={12} sm={9} md={4}>
                    <TextField
                      name="tag_id"
                      className={`${app_form_style.TextField} ${form_field_style.TextField_style} ${styles.iconInput} ${styles.grid_textField}`}
                      fieldType="alphaNumeric"
                      value={formik.values.tag_id}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    md={1.9}
                    className={`${app_form_style.grid_title_name} ${styles.gridTitleName}`}>
                    {t('asset.assetDetail.brand')}
                  </Grid>
                  <Grid item xs={9} sm={7} md={3}>
                    <DropDown
                      disabled={!formik.values.company}
                      name="brand"
                      value={formik.values.brand}
                      onChange={formik.handleChange}
                      dataList={brands}
                      className={`${styles.dropdown} ${styles.grid_textField}`}
                      placeholder={t('asset.assetAdd.selectBrand')}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sm={2}
                    md={1}
                    display="flex"
                    alignItems="center"
                    className={` ${styles.addButtonContainer}`}>
                    <Button
                      disabled={!formik.values.company}
                      variant="contained"
                      className={`${buttonStyles.btn} ${styles.btn}  ${!formik.values.company && styles.disabled_btn} ${
                        styles.grid_textField
                      }`}
                      onClick={() => {
                        setAddValueFor('brand');
                        setIsAddFormOpen(true);
                      }}>
                      <span>{t('add')}</span>
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    md={1.3}
                    display="flex"
                    alignItems="center"
                    style={windowWidth > 900 ? { marginLeft: '40px' } : {}}
                    className={`${app_form_style.grid_title_name} ${styles.gridTitleName}`}>
                    {t('asset.assetAdd.category')}
                  </Grid>
                  <Grid item xs={9} sm={7} md={3} display="flex" alignItems="center">
                    <DropDown
                      className={`${styles.grid_textField}`}
                      disabled={!formik.values.company}
                      name="category"
                      value={formik.values.category}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      dataList={categories}
                      placeholder={t('asset.assetAdd.selectCategory')}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sm={2}
                    md={1}
                    display="flex"
                    alignItems="center"
                    className={` ${styles.addButtonContainer}`}>
                    <Button
                      disabled={!formik.values.company}
                      variant="contained"
                      className={`${buttonStyles.btn} ${styles.btn}  ${!formik.values.company && styles.disabled_btn} ${
                        styles.grid_textField
                      }`}
                      onClick={() => {
                        setAddValueFor('category');
                        setIsAddFormOpen(true);
                      }}>
                      <span>{t('add')}</span>
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    md={1.9}
                    className={`${app_form_style.grid_title_name} ${styles.gridTitleName}`}>
                    {t('asset.assetAdd.type')}
                  </Grid>
                  <Grid item xs={9} sm={7} md={3}>
                    <DropDown
                      disabled={!formik.values.category}
                      name="type"
                      value={formik.values.type}
                      onChange={formik.handleChange}
                      dataList={types}
                      className={styles.grid_textField}
                      placeholder={t('asset.assetAdd.selectType')}
                      error={formik.touched.type && formik.errors.type}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sm={2}
                    md={1}
                    display="flex"
                    alignItems="center"
                    className={` ${styles.addButtonContainer}`}>
                    <Button
                      disabled={!formik.values.category}
                      variant="contained"
                      className={`${buttonStyles.btn} ${styles.btn} ${!formik.values.category && styles.disabled_btn} ${
                        styles.grid_textField
                      }`}
                      onClick={() => {
                        setAddValueFor('type');
                        setIsAddFormOpen(true);
                      }}>
                      <span>{t('add')}</span>
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    md={1.3}
                    style={windowWidth > 900 ? { marginLeft: '40px' } : {}}
                    className={`${app_form_style.grid_title_name} ${styles.gridTitleName}`}>
                    {t('asset.general.cost')}
                  </Grid>
                  <Grid item xs={12} sm={9} md={4}>
                    <CurrencyField
                      selectorName="currency"
                      textFieldName="cost"
                      selectorValue={formik.values.country}
                      textFieldValue={formik.values.cost}
                      onValueChange={formik.handleChange}
                      onCountryChange={(value) => formik.setFieldValue('country', value)}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    md={1.9}
                    className={`${app_form_style.grid_title_name} ${styles.gridTitleName}`}>
                    <div className={styles.gridKeyName}>{t('asset.assetAdd.purchaseDate')}</div>
                  </Grid>
                  <Grid item xs={12} sm={9} md={4}>
                    <DatePicker
                      disableFuture={true}
                      name="purchase_date"
                      onChange={(value) => formik.setFieldValue('purchase_date', value)}
                      value={formik.values.purchase_date}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    md={1.3}
                    style={windowWidth > 900 ? { marginLeft: '40px' } : {}}
                    className={`${app_form_style.grid_title_name} ${styles.gridTitleName}`}>
                    {t('asset.assetAdd.status')}
                  </Grid>
                  <Grid
                    item
                    xs={canEditEvent ? 9 : 12}
                    sm={canEditEvent ? 7 : 9}
                    md={canEditEvent ? 3 : 4}
                    className={`${app_form_style.grid_title_name}`}
                    justifyContent="left">
                    <AssetStatusDisplay status={formik.values.status} />
                  </Grid>
                  {canEditEvent && assetData.status !== 'checkIn' && (
                    <Grid item xs={3} sm={2} md={1} className={`${styles.addButtonContainer}`}>
                      <Button
                        variant="contained"
                        className={`${buttonStyles.btn} ${styles.btn} ${styles.grid_textField}`}
                        onClick={() => setStatus(formik.values.status)}>
                        <span>{t('edit')}</span>
                      </Button>
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    md={1.9}
                    className={`${`${app_form_style.grid_title_name} ${styles.gridTitleName}`}`}>
                    {t('common.description')}
                  </Grid>
                  <Grid item xs={12} sm={9} md={5} className={styles.grid_textArea}>
                    <textarea
                      className={` ${form_field_style.TextField_style} ${app_form_style.TextArea}`}
                      name="description"
                      type="text"
                      rows={4}
                      value={formik.values.description}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={10.1}
                    className={`${app_form_style.grid_title_name} ${styles.gridTitleName}`}>
                    <div className={`${app_form_style.card_company_details} pl-0 pt-2 ${styles.imageUploadContainer}`}>
                      <div className={` ${app_form_style.title_name_container} pl-0`}>
                        <img src={IconImage} alt="" className={app_form_style.icon_image} />
                        <div className={`${app_form_style.title_name} ${form_field_style.text_xl}`}>
                          {t('asset.assetAdd.image')}
                        </div>
                      </div>
                      <p
                        className={`${app_form_style.name_details} ${form_field_style.text_xl} ${styles.sub_title} pl-0`}>
                        {t('asset.assetAdd.uploadImage')}
                      </p>
                      <div className={app_form_style.upload_image_container}>
                        <ImageUploading
                          multiple={true}
                          name="images"
                          value={formik.values.images}
                          dataURLKey="url"
                          onChange={(images) => {
                            canUpdateImages(images);
                          }}>
                          {({ onImageUpload, onImageRemove, isDragging, dragProps }) => (
                            <button
                              className={form_field_style.button_upload}
                              style={isDragging ? { color: 'red' } : null}
                              onClick={onImageUpload}
                              {...dragProps}
                              type="button">
                              {!formik.values.images?.length ? (
                                <img className={form_field_style.default_upload_image} src={DefaultImage} alt={''} />
                              ) : (
                                <div className={styles.images_container}>
                                  {formik.values.images?.map((image, index) => (
                                    <div
                                      key={index}
                                      className={styles.image_container}
                                      onClick={(e) => e.stopPropagation()}>
                                      <span className={styles.remove_btn_container}>
                                        <CancelIcon
                                          className={styles.remove_btn}
                                          onClick={() => {
                                            onImageRemove(index);
                                            cancelledImageIds.push(image.id);
                                            setRemovedImageIds((prev) => [...prev, image.id]);
                                          }}
                                        />
                                      </span>
                                      <img
                                        key={index}
                                        className={`${form_field_style.default_upload_image} `}
                                        src={image.url}
                                        alt={''}
                                        onClick={() => console.log(image)}
                                      />
                                    </div>
                                  ))}
                                </div>
                              )}
                              <span className={form_field_style.text_drop_image}>
                                {t('attachment.dropYourImageHere')}
                              </span>
                              <span className={form_field_style.text_image_type}>
                                {t('attachment.supportsJpgGifPng')}
                              </span>
                              <span className={form_field_style.text_image_type}>
                                {canUploadNumber === 0
                                  ? t('asset.assetAdd.imageLimitReachedMaximumNumOfImages', {
                                      uploadLimit: canUploadNumber
                                    })
                                  : canUploadNumber === maxImageLimit
                                  ? t('asset.assetAdd.uploadLimitError', {
                                      uploadLimit: canUploadNumber
                                    })
                                  : t('asset.assetAdd.uploadLimitError', {
                                      uploadLimit: canUploadNumber
                                    })}
                              </span>{' '}
                              {formik.touched.images && formik.errors.images && (
                                <span className={`${styles.error_msg} ${form_field_style.text_image_type}`}>
                                  {formik.errors.images}
                                </span>
                              )}
                            </button>
                          )}
                        </ImageUploading>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={11.9}>
                    <div className={`${buttonStyles.buttons_container}`}>
                      <Button
                        disabled={editAsset && (!canSave || isToasterActive)}
                        type="submit"
                        variant="contained"
                        className={`${buttonStyles.save_button} ${buttonStyles.btn} ${
                          buttonStyles.mobile_button_width
                        } ${editAsset && (!canSave || isToasterActive) ? buttonStyles.disabled_btn : ''}`}>
                        <span>{t(editAsset ? 'save' : 'add')}</span>
                      </Button>
                      <Button
                        type="button"
                        variant="contained"
                        className={`${buttonStyles.cancel_button} ${buttonStyles.btn} ${buttonStyles.mobile_button_width}`}
                        onClick={() => navigate(-1)}>
                        <span>{t('cancel')}</span>
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Form>
            </FormikProvider>
            <AddNewValue
              isOpen={isAddFormOpen}
              companyId={formik.values.company}
              addValueFor={addValueFor}
              onClose={() => setIsAddFormOpen(false)}
              onAfterAdd={() => {
                (addValueFor === 'brand' && getBrands()) ||
                  (addValueFor === 'category' && getCategories()) ||
                  (addValueFor === 'type' && getTypes());
                setIsAddFormOpen(false);
              }}
              category={addValueFor === 'type' && formik.values.category}
              setNewValue={(newValue) => formik.setFieldValue(addValueFor, newValue)}
            />

            {status && (
              <AssetEvents
                isEditEvent={editAsset}
                status={status}
                assetId={assetData.id}
                onClose={() => setStatus('')}
                afterSubmit={() => {
                  setStatus('');
                  getAssetData();
                }}
                assetData={assetData}
                eventDetails={assetData.status_info || {}}
              />
            )}
          </Paper>
        </>
      )}
    </>
  );
};

export default AddAsset;
