import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import login from './auth/login/reducer';
import sideMenu from './layout/reducer';
import table from './table/reducer';
import dashboard from './dashboard/reducer';
import common from './common/reducer';
import filterModal from './filterModal/reducer';

const persistConfig = {
  key: 'root',
  storage
};

const rootReducer = combineReducers({
  login,
  sideMenu,
  table,
  dashboard,
  common,
  filterModal
});

export default persistReducer(persistConfig, rootReducer);
