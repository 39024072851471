import { Dialog, Box, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { faCube, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import appStyles from '../../assets/scss/_app.module.scss';
import buttonStyles from '../../assets/scss/_button.module.scss';
import AttachmentsList from '../../components/Attachments/AttachmentsList';
import styles from './showDetail.module.scss';
import { Edit, DeleteForever } from '@mui/icons-material';

const ShowDetails = ({
  detailFor,
  details,
  moreDetailsFn,
  moreDetailsLink,
  tabItems,
  currentTab,
  handleTabChange,
  onClose,
  attachments,
  showEmptyFields,
  canEdit,
  onEdit,
  canDelete,
  onDelete,
  showMoreButton = true
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Dialog open={true} onClose={onClose}>
      <Box className={styles.card}>
        <div className={styles.header_container}>
          <div className={`${appStyles.header_container} ${styles.header}`}>
            <span className={styles.header_icon}>
              <FontAwesomeIcon icon={faCube} className={appStyles.solid_list_icon} />
              <h6 className={`${appStyles.title_name} ${styles.title} ${styles.text_3xl}`}>{detailFor}</h6>
              {canEdit && (
                <span className={styles.editContainer} onClick={onEdit}>
                  <Edit className={styles.editIcon} />
                </span>
              )}
              {canDelete && (
                <span className={styles.destroyContainer} onClick={onDelete}>
                  <DeleteForever className={styles.destroyIcon} />
                </span>
              )}
            </span>
            <CloseIcon className={styles.close_icon} onClick={onClose} />
          </div>
          {tabItems?.length > 1 ? (
            <div className={styles.tabHeader}>
              {tabItems.map((tabItem) => {
                return (
                  <span
                    key={tabItem.id}
                    className={`${currentTab === tabItem.key && styles.selectedTab}`}
                    onClick={() => handleTabChange(tabItem.key)}>
                    {tabItem.value}
                  </span>
                );
              })}
            </div>
          ) : (
            <hr className={styles.horizontal_line} />
          )}
        </div>
        <div className={styles.body_container}>
          <div className={styles.table_container}>
            <table className={`${styles.asset_details_table} ${styles.table}`}>
              <tbody>
                {details
                  .filter((detail) => showEmptyFields === true || detail.value)
                  .map((detail) => (
                    <tr key={detail.key} className={styles.asset_detail_row}>
                      <td className={styles.asset_key}>{detail.label}</td>
                      <td className={styles.asset_value}>{detail.value || '-'}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          {attachments?.length > 0 && (
            <div className={styles.attachment_container}>
              <div className={styles.attachment_title}>
                <FontAwesomeIcon icon={faPaperclip} className={`${styles.title_icon}`} size="lg" />
                <span className={`${appStyles.header_container} ${styles.text_3xl}`}>{t('common.attachments')}</span>
              </div>
              <AttachmentsList attachments={attachments} containerClass={styles.attachments} />
            </div>
          )}
        </div>
        {(moreDetailsLink || moreDetailsFn) && showMoreButton && (
          <div className={styles.more_details_btn_container}>
            <Button
              variant="contained"
              className={`${buttonStyles.save_button} ${buttonStyles.btn} ${buttonStyles.mobile_button_width} ${styles.more_details_btn}`}
              onClick={() => {
                moreDetailsLink ? navigate(moreDetailsLink) : moreDetailsFn();
              }}>
              <span>{t('asset.assetDetail.reserveTab.showDetail.moreDetails')}</span>
            </Button>
          </div>
        )}
      </Box>
    </Dialog>
  );
};

export default ShowDetails;
