import { useEffect, useState } from 'react';
import { Button, Drawer, TextField, Dialog } from '@mui/material';
import { Close } from '@mui/icons-material';
import styles from './companyDetail.module.scss';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { axiosApi } from '../../../helpers/api_helper';
import { toast } from 'react-toastify';
import defaultCompanyLogo from '../../../assets/images/CompanyIcon.jpeg';
import buttonStyles from '../../../assets/scss/_button.module.scss';
import { useFormik, Formik } from 'formik';
import { CompanySchema } from '../../../schemas/CompanySchema';
import Loader from '../../../components/Loader';
import ImageUploading from 'react-images-uploading';
import app_form_style from '../../../assets/scss/_app.module.scss';
import form_style from '../../../assets/scss/_form_field.module.scss';
import button_style from '../../../assets/scss/_button.module.scss';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { resizedataURL, getCroppedImg } from '../../../helpers/common_service';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';

const CompanyDetails = ({ open, onClose }) => {
  const minTabletWidth = parseInt(screen.minTablet);
  const authUser = useSelector((state) => state.login.authUser);
  const selectedCompanyId = useSelector((state) => state.common.companyId);
  const [canSave, setCanSave] = useState(false);
  const [prevSavedData, setPrevSavedData] = useState({});
  const [isToasterActive, setIsToasterActive] = useState(false);

  const { t } = useTranslation();
  const [loginUserCompanyDetails, setLoginUserCompanyDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [image, setImages] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [crop, setCrop] = useState({
    aspect: 9 / 16,
    x: 81,
    y: 81,
    width: 361,
    height: 361,
    unit: 'px'
  });
  const [fixedImageResolutions, setFixedImageResolutions] = useState({
    width: 500,
    height: 500
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleImageChanges = async (image) => {
    let newImage = await resizedataURL(image, fixedImageResolutions.width, fixedImageResolutions.height);
    setImages(newImage);
    setIsOpen(true);
  };
  const cropImageNow = () => {
    if (completedCrop) {
      getCroppedImg(image, completedCrop, 'newFile.jpeg').then((res) => {
        formik.setFieldValue('new_logo', res);
      });
    } else {
      getCroppedImg(image, crop, 'newFile.jpeg').then((res) => {
        formik.setFieldValue('new_logo', res);
      });
    }
    setIsOpen(false);
    setImages(null);
    setCompletedCrop(null);
  };

  const handleSubmit = (values) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('company[name]', formik.values.name);
    formData.append('company[website]', formik.values.website);

    if (values.new_logo?.file) {
      formData.append('updated_logo', values.new_logo.file);
    }

    if (!values.logo) formData.append('removed_logo', true);

    axiosApi
      .patch(`/companies/${selectedCompanyId}`, formData, {
        headers: {
          Authorization: `Bearer ${authUser['remember_token']}`,
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(() => {
        setPrevSavedData(formik.values);
        toast.success(t('company.addCompany.companyUpdateSuccess'));
      })
      .catch(() => {
        setIsToasterActive(true);
        t('company.addCompany.companyUpdateFailed'),
          {
            onClose: () => setIsToasterActive(false)
          };
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getLoginUserCompanyDetails = (companyId) => {
    axiosApi
      .get(`/companies/${companyId}`)
      .then((res) => {
        setLoginUserCompanyDetails(res.data.data.attributes);
        formik.setFieldValue('logo', res.data.data.attributes.logo);
        formik.setFieldValue('name', res.data.data.attributes.name);
        formik.setFieldValue('website', res.data.data.attributes.website);
      })
      .catch(() => toast.error(t('company.companyList.failedToFetchCompanyDetail')))
      .finally(() => {
        setLoading(false);
      });
  };

  const setValues = () => {
    let obj = {
      name: loginUserCompanyDetails.name,
      website: loginUserCompanyDetails.website,
      logo: loginUserCompanyDetails.logo,
      new_logo: ''
    };
    formik.setValues(obj);
    setPrevSavedData(obj);
  };

  const formik = useFormik({
    initialValues: {
      logo: '',
      name: '',
      website: ''
    },
    onSubmit: handleSubmit,
    validationSchema: CompanySchema(t('company.addCompany.yupCompanyName'))
  });

  useEffect(() => {
    if (JSON.stringify(formik.values) !== JSON.stringify(prevSavedData)) setCanSave(true);
    else setCanSave(false);
  }, [formik.values, prevSavedData]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    if (windowWidth < minTabletWidth && fixedImageResolutions.width > 200) {
      setFixedImageResolutions({ width: 200, height: 200 });
      setCrop({ aspect: 9 / 16 });
    } else if (fixedImageResolutions.width < 500 && windowWidth > minTabletWidth) {
      setFixedImageResolutions({ width: 500, height: 500 });
      setCrop({ aspect: 9 / 16 });
    }
    return () => window.removeEventListener('resize', handleResize);
  }, [windowWidth]);

  useEffect(() => {
    if (authUser.company_id) getLoginUserCompanyDetails(authUser.company_id);
  }, [authUser]);

  useEffect(() => {
    if (Object.keys(loginUserCompanyDetails).length > 0) {
      setValues();
    }
  }, [loginUserCompanyDetails]);

  return (
    <>
      <Drawer anchor="right" open={open} onClose={onClose} className={styles.drawer}>
        {loading ? (
          <Loader loading={loading} />
        ) : (
          <>
            <Formik>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();
                }}
                className={styles.form}>
                <div className={styles.logoContainer}>
                  <div className={`${app_form_style.upload_image_container} ${styles.uploadImgCon}`}>
                    <ImageUploading
                      name="logo"
                      value={formik.values.logo}
                      dataURLKey="data_url"
                      acceptType={['jpg', 'jpeg', 'png']}
                      onChange={(image) => {
                        if (image.length && image[0].data_url) handleImageChanges(image[0].data_url);
                        else formik.setFieldValue('new_logo', '');
                      }}>
                      {({ onImageUpload, isDragging, dragProps }) => (
                        <button
                          className={`${form_style.button_upload} ${styles.btnUpload}`}
                          style={isDragging ? { color: 'red' } : null}
                          onClick={onImageUpload}
                          {...dragProps}
                          type="button">
                          {!formik.values.new_logo || (!formik.values.logo && !formik.values.new_logo) ? (
                            <>
                              <img
                                className={`${form_style.default_upload_image}${styles.imageTag}`}
                                src={loginUserCompanyDetails.logo || defaultCompanyLogo}
                                alt={''}
                              />
                              <div className={`${styles.editOverlay}`}>
                                <PhotoCameraIcon />
                              </div>
                            </>
                          ) : (
                            <div className={`${form_style.image_container} ${styles.btnUpload}`}>
                              <img
                                className={`${form_style.default_upload_image}${styles.imageTag}`}
                                src={formik.values.new_logo ? formik.values.new_logo.data_url : formik.values.logo}
                                alt={''}
                              />
                              <div className={`${styles.editOverlay}`}>
                                <PhotoCameraIcon />
                              </div>
                            </div>
                          )}

                          {formik.submitCount > 0 && formik.errors.logo && (
                            <span className={`${styles.error_msg} ${form_style.text_image_type}`}>
                              {formik.errors.logo}
                            </span>
                          )}
                        </button>
                      )}
                    </ImageUploading>
                  </div>
                  <span className={styles.close} onClick={onClose}>
                    <Close />
                  </span>
                </div>
                {image && (
                  <Dialog open={isOpen}>
                    <div>
                      <ReactCrop
                        src={image}
                        crop={crop}
                        onChange={(c) => setCrop(c)}
                        onComplete={(c) => setCompletedCrop(c)}
                        aspect={1 / 1}
                        ruleOfThirds>
                        <img
                          src={image}
                          alt="Crop"
                          height={fixedImageResolutions.height}
                          width={fixedImageResolutions.width}
                        />
                      </ReactCrop>
                    </div>
                    <div className={styles.crop_button_container}>
                      <Button
                        type="button"
                        variant="contained"
                        className={`${button_style.cancel_button} ${button_style.btn} ${button_style.mobile_button_width}`}
                        onClick={() => {
                          setIsOpen(false);
                          setImages(null);
                        }}>
                        {t('cancel')}
                      </Button>
                      <Button
                        type="button"
                        variant="contained"
                        className={`${button_style.save_button} ${button_style.btn} ${button_style.mobile_button_width}`}
                        onClick={() => {
                          cropImageNow();
                        }}>
                        {t('crop')}
                      </Button>
                    </div>
                  </Dialog>
                )}
                <div className={styles.companyDetailContainer}>
                  <div>
                    <span className={styles.label}>{t('company.companyDetails.companyName')}</span>
                    <div>
                      <TextField
                        name="name"
                        variant="standard"
                        value={formik.values.name}
                        onChange={(e) => formik.handleChange(e)}
                        className={styles.field}
                        sx={{
                          '& .MuiInputBase-root::before': {
                            height: '44px',
                            outline: 'none',
                            borderColor: '#d0d5dd'
                          },

                          '& .MuiInputBase-input:focus .MuiInputBase-root::after': {
                            border: 'none',
                            outline: 'none'
                          },
                          '& .MuiInputBase-root:hover:not(.Mui-disabled):before': {
                            outline: 'unset',
                            borderBottom: '1px solid #d0d5dd'
                          }
                        }}
                      />

                      {formik.submitCount > 0 && formik.errors.name && (
                        <div className={styles.error_msg}>{formik.errors.name}</div>
                      )}
                    </div>
                  </div>
                  <div>
                    <span className={styles.label}>{t('company.companyDetails.websiteUrl')}</span>
                    <div>
                      <TextField
                        name="website"
                        variant="standard"
                        value={formik.values.website}
                        onChange={(e) => formik.handleChange(e)}
                        className={styles.field}
                        sx={{
                          '& .MuiInputBase-root::before': {
                            height: '44px',
                            outline: 'none',
                            borderColor: '#d0d5dd'
                          },

                          '& .MuiInputBase-input:focus .MuiInputBase-root::after': {
                            border: 'none',
                            outline: 'none'
                          },
                          '& .MuiInputBase-root:hover:not(.Mui-disabled):before': {
                            outline: 'unset',
                            borderBottom: '1px solid #d0d5dd'
                          }
                        }}
                      />
                      {formik.submitCount > 0 && formik.errors.website && (
                        <div className={styles.error_msg}>{formik.errors.website}</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={`${buttonStyles.buttons_container} ${styles.btnCon}`}>
                  <Button
                    disabled={!canSave || isToasterActive}
                    type="submit"
                    variant="contained"
                    className={`${buttonStyles.save_button} ${buttonStyles.btn} ${buttonStyles.mobile_button_width} ${
                      !canSave || isToasterActive || loading ? button_style.disabled_btn : ''
                    }`}>
                    <span>{t('update')}</span>
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    className={`${buttonStyles.cancel_button} ${buttonStyles.btn} ${buttonStyles.mobile_button_width}`}
                    onClick={onClose}>
                    <span>{t('cancel')}</span>
                  </Button>
                </div>
              </form>
            </Formik>
          </>
        )}
      </Drawer>
    </>
  );
};

export default CompanyDetails;
