import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { nanoid } from 'nanoid';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Radio,
  RadioGroup
} from '@mui/material';
import { axiosApi } from '../../helpers/api_helper';
import { TextField } from '../../components/Form/TextField';
import { RoleSchema } from '../../schemas/UserSchema';
import modalFormStyles from '../../components/ModalForm/modalForm.module.scss';
import styles from './addRole.module.scss';
import LoaderOverlay from '../../components/LoaderOverlay';
import {
  AUTHORITY_LEVEL_COMPANY,
  AUTHORITY_LEVEL_BRANCH,
  AUTHORITY_LEVEL_SELF,
  MODEL_MAINTENANCE_REQUEST,
  MODEL_MAINTENANCE,
  ACTION_READ,
  ACTION_WRITE,
  ACTION_DESTORY
} from '../../helpers/access';

const AddRole = ({ onClose, afterSubmit, roleId = null }) => {
  const { t } = useTranslation();
  const [authorizableModels, setAuthorizableModels] = useState([]);
  const [canOnlyReadMaintenanceRequests, setCanOnlyReadMaintenanceRequests] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const editRole = roleId != null;

  const handleCheckBoxChange = (modelName, action, isChecked) => {
    let authModels = authorizableModels.map((model) => {
      if (model.name === modelName) {
        isChecked
          ? !model.actions.includes(action) && model.actions.push(action)
          : action === ACTION_READ
          ? (model.actions = [])
          : action === ACTION_WRITE
          ? (model.actions = model.actions.filter((current_action) => current_action === ACTION_READ))
          : (model.actions = model.actions.filter((prevAction) => prevAction !== action));
      }
      if (model.name === MODEL_MAINTENANCE) {
        setCanOnlyReadMaintenanceRequests(model.actions.length > 1);
      }
      return model;
    });
    // For reUndu the "Inventory" model actions after selection then submodels(warranty/insurance etc) actions would be clean checkbox by using this condition
    if (authorizableModels.find((name) => name.depends === modelName) && !isChecked) {
      authModels.map((model) => {
        if (model.depends === modelName) {
          action === ACTION_READ
            ? (model.actions = [])
            : action === ACTION_WRITE
            ? (model.actions = model.actions.filter((current_action) => current_action === ACTION_READ))
            : '';
        }
      });

      // if (model.name === MODEL_MAINTENANCE) {
      //   setCanOnlyReadMaintenanceRequests(model.actions.length > 1);
      // }
    }

    setAuthorizableModels(authModels);
  };

  const handleRadioButtonChange = (modelName, authorityLevel) => {
    let updatedAuthorizedModels = authorizableModels.map((model) => {
      if ([model.name, model.depends].includes(modelName)) model.authorityLevel = authorityLevel;
      if (authorityLevel && model.actions.includes(ACTION_DESTORY) && model.name === modelName) {
        model.actions = model.actions.filter((action) => action !== ACTION_DESTORY);
      }
      return model;
    });
    setAuthorizableModels(updatedAuthorizedModels);
  };

  const getModel = (modelName) => {
    return authorizableModels.find((model) => model.name === modelName);
  };

  const isChecked = (modelName, action) => {
    let model = getModel(modelName);
    if (!model) return false;
    return model.actions.includes(action);
  };

  const selectedValue = (modelName) => {
    let model = getModel(modelName);
    return model?.authorityLevel ?? AUTHORITY_LEVEL_COMPANY;
  };

  const isRadioDisabled = (modelName) => {
    let model = getModel(modelName);
    if (!model) return true;
    return model.actions.length === 0;
  };

  const isCheckBoxDisabled = (modelName) => {
    let model = getModel(modelName);
    if (
      !model ||
      (model.name === MODEL_MAINTENANCE_REQUEST &&
        canOnlyReadMaintenanceRequests &&
        model.actions.includes(ACTION_READ))
    )
      return true;
    return !model.actions.includes(ACTION_READ);
  };

  const onSubmit = (values) => {
    let params = {
      name: values.name,
      custom_authorizations_attributes: authorizableModels
        .filter((model) => model.actions.length > 0)
        .map((model) => ({
          name_of_model: model.name,
          actions: model.actions,
          authority_level: model.authorityLevel
        }))
    };
    if (editRole) {
      axiosApi
        .patch(`/roles/${roleId}`, params)
        .then(() => {
          toast.success(t('authorizableModels.add.roleUpdated'));
          afterSubmit();
        })
        .catch((err) => {
          let message = err.response.data.message_key;
          toast.error(t(message ? `authorizableModels.add.${message}` : 'common.somethingWentsWrong'));
        });
    } else {
      axiosApi
        .post('/roles', { role: params })
        .then(() => {
          toast.success(t('authorizableModels.add.roleCreated'));
          afterSubmit();
        })
        .catch((err) => {
          let message = err.response.data.message_key;
          toast.error(t(message ? `authorizableModels.add.${message}` : 'common.somethingWentsWrong'));
        });
    }
  };

  const formik = useFormik({
    initialValues: { name: '' },
    onSubmit,
    validationSchema: RoleSchema(t('authorizableModels.add.pleaseEnterRoleName'))
  });

  const getAuthorizableModels = () => {
    let tempAuthorizableModels;
    setIsLoading(true);
    axiosApi
      .get('/authorizable_models')
      .then((res) => {
        tempAuthorizableModels = res.data.map((model) => ({
          key: nanoid(),
          label: t(`authorizableModels.${model.name}`),
          name: model.name,
          authorityLevels: model.authority_levels,
          authorityLevel: AUTHORITY_LEVEL_COMPANY,
          depends: model.depends,
          selfDestroy: model.self_destroy,
          actions: []
        }));

        if (editRole) {
          axiosApi
            .get(`/roles/${roleId}`)
            .then((res) => {
              tempAuthorizableModels = tempAuthorizableModels.map((model) => {
                let cur_val = res.data.data.attributes.custom_authorizations.find((data) => data.name === model.name);
                if (cur_val) {
                  (model.authorityLevel = cur_val.authority_level), (model.actions = cur_val.actions);
                }
                return model;
              });
              formik.setValues({ name: res.data.data.attributes.label });
              setAuthorizableModels(tempAuthorizableModels);
            })
            .catch(() => {
              toast.error(t('user.addUser.failedToGetRoles'));
              afterSubmit();
            })
            .finally(() => {
              setIsLoading(false);
            });
        } else {
          setAuthorizableModels(tempAuthorizableModels);
          setIsLoading(false);
        }
      })
      .catch(() => {
        toast.error(t('common.somethingWentsWrong'));
        afterSubmit();
        return;
      });
  };

  const isValidToShow = (modelName) => {
    let model = getModel(modelName);
    if (model.depends) {
      let dependsModel = getModel(model.depends);
      return dependsModel.actions.length !== 0;
    } else {
      return true;
    }
  };
  const isValidRuleToShow = (modelName, action) => {
    let model = getModel(modelName);
    let dependsModel = getModel(model.depends);
    if (model.depends) {
      switch (action) {
        case ACTION_READ:
          return dependsModel?.actions.includes(ACTION_READ);
        case ACTION_WRITE:
          return model.actions.includes(ACTION_READ) && dependsModel.actions.includes(ACTION_WRITE);
        case ACTION_DESTORY:
          return model.actions.includes(ACTION_WRITE) && dependsModel.actions.includes(ACTION_WRITE);
      }
    } else return true;
  };

  useEffect(() => {
    getAuthorizableModels();
  }, []);

  useEffect(() => {
    if (canOnlyReadMaintenanceRequests) {
      const newAuthorizableModels = authorizableModels.map((model) =>
        model.name === 'maintenance_request' && model.actions.length ? { ...model, actions: [ACTION_READ] } : model
      );
      setAuthorizableModels(newAuthorizableModels);
    }
  }, [canOnlyReadMaintenanceRequests]);

  return (
    <Dialog open={true} onClose={onClose}>
      <Box className={modalFormStyles.card}>
        <LoaderOverlay isLoading={isLoading}>
          <div className={styles.headerFixed}>
            <div className={`${styles.header_container} ${modalFormStyles.text_3xl}`}>
              {t(`authorizableModels.${editRole ? 'edit' : 'add'}Role`)}
            </div>
            <hr className={modalFormStyles.horizontal_line} />
          </div>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit();
            }}>
            <Grid container rowGap={2} className={styles.gridContainer}>
              <Grid item xs={4} className={`${styles.grid_title_name} ${styles.gridLabel}`}>
                {t('authorizableModels.roleName')}
                <span className={styles.required}>*</span>
              </Grid>
              <Grid item xs={7} className={styles.grid_text_field_container}>
                <TextField
                  fieldType="alphaNumeric"
                  name="name"
                  value={formik.values.name}
                  className={`${styles.TextField} ${styles.TextField_style}`}
                  onChange={formik.handleChange}
                  error={formik.touched.name && formik.errors.name}
                />
              </Grid>
            </Grid>

            <div className={`${styles.gridContainer} pt-0`}>
              {!editRole && <div className={`${styles.text_md} mb-3`}>{`(${t('authorizableModels.note')})`}</div>}
              <div className={styles.accessibilityGroupContainer}>
                {authorizableModels?.map((model) => (
                  <div
                    key={model.key}
                    className={`${!isValidToShow(model.name) ? styles.disable : ''} ${styles.groupContainer} `}>
                    <div className={`${styles.text_2xl} ${styles.groupHeader} `}>{model.label}</div>

                    <div className={styles.formControlContainer}>
                      <div>
                        <FormLabel>{t('warranty.actions')}</FormLabel>
                        <FormGroup onChange={(e) => handleCheckBoxChange(model.name, e.target.name, e.target.checked)}>
                          {model.name === 'company' ? (
                            <FormControlLabel
                              checked={isChecked(model.name, ACTION_WRITE)}
                              control={<Checkbox className={styles.checkBox} name={ACTION_WRITE} />}
                              className={styles.label}
                              label={t('authorizableModels.actions.write')}
                            />
                          ) : (
                            <>
                              <FormControlLabel
                                className={`${!isValidRuleToShow(model.name, ACTION_READ) ? styles.disable : ''} ${
                                  styles.label
                                }`}
                                checked={isChecked(model.name, ACTION_READ)}
                                control={<Checkbox className={styles.checkBox} name={ACTION_READ} />}
                                label={t('authorizableModels.actions.read')}
                              />
                              <FormControlLabel
                                className={` ${styles.label}`}
                                checked={isChecked(model.name, ACTION_WRITE)}
                                disabled={
                                  model.depends
                                    ? !isValidRuleToShow(model.name, ACTION_WRITE)
                                    : isCheckBoxDisabled(model.name)
                                }
                                control={
                                  <Checkbox
                                    className={
                                      (
                                        model.depends
                                          ? !isValidRuleToShow(model.name, ACTION_WRITE)
                                          : isCheckBoxDisabled(model.name)
                                      )
                                        ? ''
                                        : styles.checkBox
                                    }
                                    name={ACTION_WRITE}
                                  />
                                }
                                label={t('authorizableModels.actions.write')}
                              />
                              <FormControlLabel
                                checked={isChecked(model.name, ACTION_DESTORY)}
                                disabled={
                                  (model.selfDestroy && model.authorityLevel === AUTHORITY_LEVEL_SELF) ||
                                  (model.depends
                                    ? !isValidRuleToShow(model.name, ACTION_DESTORY)
                                    : isCheckBoxDisabled(model.name))
                                }
                                control={
                                  <Checkbox
                                    className={
                                      (model.selfDestroy && model.authorityLevel === AUTHORITY_LEVEL_SELF) ||
                                      (model.depends
                                        ? !isValidRuleToShow(model.name, ACTION_DESTORY)
                                        : isCheckBoxDisabled(model.name))
                                        ? ''
                                        : styles.checkBox
                                    }
                                    name={ACTION_DESTORY}
                                  />
                                }
                                className={styles.label}
                                label={t('authorizableModels.actions.destroy')}
                              />
                            </>
                          )}
                        </FormGroup>
                      </div>
                      {model.authorityLevels.length > 0 && (
                        <div className={model.depends && styles.disable}>
                          <FormLabel id="demo-radio-buttons-group-label">
                            {t('authorizableModels.authorityLevel.header')}
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={AUTHORITY_LEVEL_COMPANY}
                            value={selectedValue(model.name)}
                            name="radio-buttons-group"
                            onChange={(e) => handleRadioButtonChange(model.name, e.target.value)}>
                            {model.authorityLevels.includes(AUTHORITY_LEVEL_COMPANY) && (
                              <FormControlLabel
                                disabled={isRadioDisabled(model.name)}
                                value={AUTHORITY_LEVEL_COMPANY}
                                control={<Radio className={!isRadioDisabled(model.name) ? styles.checkBox : ''} />}
                                className={styles.label}
                                label={t('authorizableModels.authorityLevel.company')}
                              />
                            )}
                            {model.authorityLevels.includes(AUTHORITY_LEVEL_BRANCH) && (
                              <FormControlLabel
                                disabled={isRadioDisabled(model.name)}
                                value={AUTHORITY_LEVEL_BRANCH}
                                control={<Radio className={!isRadioDisabled(model.name) ? styles.checkBox : ''} />}
                                className={styles.label}
                                label={t('authorizableModels.authorityLevel.branch')}
                              />
                            )}
                            {model.authorityLevels.includes(AUTHORITY_LEVEL_SELF) && (
                              <FormControlLabel
                                disabled={isRadioDisabled(model.name)}
                                value={AUTHORITY_LEVEL_SELF}
                                control={<Radio className={!isRadioDisabled(model.name) ? styles.checkBox : ''} />}
                                className={styles.label}
                                label={t('authorizableModels.authorityLevel.self')}
                              />
                            )}
                          </RadioGroup>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.bottomFixed}>
              <hr className={styles.horizontal_line} />
              <div className={`${styles.buttons_container} p-4`}>
                <Button
                  type="submit"
                  variant="contained"
                  className={`${styles.save_button} ${styles.btn} ${styles.mobile_button_width}`}>
                  {t(`authorizableModels.${editRole ? 'edit' : 'add'}Role`)}
                </Button>
                <Button
                  variant="contained"
                  className={`${styles.cancel_button} ${styles.btn} ${styles.mobile_button_width}`}
                  onClick={onClose}>
                  {t('cancel')}
                </Button>
              </div>
            </div>
          </form>
        </LoaderOverlay>
      </Box>
    </Dialog>
  );
};

export default AddRole;
