import { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { nanoid } from 'nanoid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCube, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { axiosApi } from '../../../../helpers/api_helper';
import { InsuranceSchema } from '../../../../schemas/InsuranceSchema';
import ModalForm from '../../../../components/ModalForm';
import appStyles from '../../../../assets/scss/_app.module.scss';
import ModalFormStyles from '../../../../components/ModalForm/modalForm.module.scss';

const InsuranceForm = ({ productId, productCountryId, insuranceDetails, onClose, onSubmit }) => {
  const { t } = useTranslation();
  const isEditInsurance = insuranceDetails?.id ? true : false;
  const loginUser = useSelector((state) => state.login.authUser);
  const title = t(`asset.assetDetail.insuranceTab.${isEditInsurance ? 'edit' : 'add'}Insurance`);
  let newAttachments, removedAttachmentIds;

  const [initialValues, setInitialValues] = useState({
    insurance_company_name: '',
    policy_no: '',
    start_date: '',
    end_date: '',
    insurance_cost: '',
    insurance_coverage: '',
    country_id: productCountryId,
    notes: '',
    attachments: [],
    ...insuranceDetails
  });

  const fields = [
    {
      id: nanoid(),
      label: t('asset.assetDetail.insuranceTab.insuranceCompanyName'),
      name: 'insurance_company_name',
      type: 'text',
      isRequired: true,
      label_size: 2,
      field_size: 10
    },
    {
      id: nanoid(),
      label: t('asset.assetDetail.insuranceTab.policyNo'),
      name: 'policy_no',
      type: 'text',
      label_size: 2,
      field_size: 10
    },
    {
      id: nanoid(),
      label: t('asset.assetDetail.insuranceTab.startDate'),
      name: 'start_date',
      type: 'date',
      isRequired: true,
      label_size: 2,
      field_size: 4
    },
    {
      id: nanoid(),
      label: t('asset.assetDetail.insuranceTab.endDate'),
      name: 'end_date',
      type: 'date',
      isRequired: true,
      disabled: !initialValues.start_date,
      minDate: initialValues.start_date,
      label_size: 2,
      field_size: 4,
      paddingLeft: '2.5vw !important'
    },
    {
      id: nanoid(),
      label: t('asset.assetDetail.insuranceTab.insuranceCost'),
      selectorName: 'country_id',
      textFieldName: 'insurance_cost',
      type: 'currency',
      label_size: 2,
      field_size: 4,
      menuOpen: false
    },
    {
      id: nanoid(),
      label: t('asset.assetDetail.insuranceTab.insuranceCoverage'),
      selectorName: 'country_id',
      textFieldName: 'insurance_coverage',
      type: 'currency',
      label_size: 2,
      field_size: 4,
      menuOpen: false,
      paddingLeft: '2.5vw !important'
    },
    {
      id: nanoid(),
      label: t('asset.assetDetail.moreAction.notes'),
      name: 'notes',
      type: 'textArea',
      label_size: 2,
      field_size: 10
    },
    {
      id: nanoid(),
      name: 'attachments',
      type: 'attachment',
      attachments: initialValues.attachments
    }
  ];

  const schema = InsuranceSchema(
    t('asset.assetDetail.insuranceAdd.pleaseSelectStartDate'),
    t('asset.assetDetail.insuranceAdd.pleaseSelectEndDate'),
    t('asset.assetDetail.insuranceAdd.endDateEalierThanStartDate'),
    t('asset.assetDetail.insuranceAdd.insuranceCompanyNameRequired')
  );

  const handleUpdateAttachments = (incommingNewAttachments, removedAttachments) => {
    newAttachments = incommingNewAttachments;
    removedAttachmentIds = removedAttachments;
  };

  const handleSubmit = (values) => {
    const formData = new FormData();
    formData.append('insurance[insurance_company_name]', values.insurance_company_name);
    formData.append('insurance[policy_no]', values.policy_no);
    formData.append('insurance[insurance_cost]', values.insurance_cost || '');
    formData.append('insurance[insurance_coverage]', values.insurance_coverage || '');
    formData.append('insurance[country_id]', values.country_id || '');
    formData.append('insurance[start_date]', values.start_date);
    formData.append('insurance[end_date]', values.end_date);
    formData.append('insurance[notes]', values.notes);
    let header = {
      headers: {
        Authorization: `Bearer ${loginUser['remember_token']}`,
        'Content-Type': 'multipart/form-data'
      }
    };
    if (isEditInsurance) {
      newAttachments.forEach((file) => {
        formData.append('new_attachments[]', file.file, file.file.name);
      });
      formData.append('removed_attachment_ids', removedAttachmentIds);
      axiosApi
        .patch(`/products/${productId}/insurances/${insuranceDetails.id}`, formData, header)
        .then((res) => {
          onSubmit(res.data.data.attributes);
          toast.success(t('asset.assetDetail.insuranceTab.insuranceUpdatedSuccessfully'));
        })
        .catch((err) => {
          const response = err.response?.data?.error?.base;
          toast.error(
            response && response[0]?.key
              ? t(`asset.assetDetail.insuranceAdd.${response[0]?.key}`, { end_date: response[0].end_date })
              : response[0]
              ? response[0]
              : t('asset.assetDetail.insuranceTab.failedAddInsurance')
          );
        });
    } else {
      newAttachments.forEach((file) => {
        formData.append('attachments[]', file.file, file.file.name);
      });
      axiosApi
        .post(`/products/${productId}/insurances`, formData, header)
        .then((res) => {
          onSubmit(res.data.data.attributes);
          toast.success(t('asset.assetDetail.insuranceTab.insuranceAddedSuccessfully'));
        })
        .catch((err) => {
          const response = err.response?.data?.error?.base;
          toast.error(
            response && response[0]?.key
              ? t(`asset.assetDetail.insuranceAdd.${response[0]?.key}`, { end_date: response[0].end_date })
              : response[0]
              ? response[0]
              : t('asset.assetDetail.insuranceTab.failedAddInsurance')
          );
        });
    }
  };

  const TitleIcon = (
    <FontAwesomeIcon icon={isEditInsurance ? faPenToSquare : faCube} className={appStyles.solid_list_icon} />
  );

  return (
    <ModalForm
      className={ModalFormStyles.addInsuranceStyle}
      preSavedData={isEditInsurance ? initialValues : {}}
      formTitle={title}
      titleIcon={TitleIcon}
      isEditForm={isEditInsurance}
      initialValues={initialValues}
      fields={fields}
      onSubmit={handleSubmit}
      onClose={onClose}
      onValuesChange={(values) => setInitialValues(values)}
      validationSchema={schema}
      onUpdateAttachments={handleUpdateAttachments}
    />
  );
};

export default InsuranceForm;
