import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { AssetStatusButton } from '../../../Asset/AssetStatus/AssetStatus';
import ShowDetails from '../../../../components/ShowDetail';
import { useNavigate } from 'react-router-dom';
import { hasCreateAccess, MODEL_PRODUCT } from '../../../../helpers/access';

const LinkingProductDetails = ({ assetDetails, onClose, onDelete }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const assetInfo = [
    {
      key: nanoid(),
      label: t('asset.general.tagId'),
      value: assetDetails.tag_id
    },
    {
      key: nanoid(),
      label: t('asset.general.assetName'),
      value: assetDetails.asset_name
    },
    {
      key: nanoid(),
      label: t('asset.general.model'),
      value: assetDetails.model
    },
    {
      key: nanoid(),
      label: t('asset.general.serialNumber'),
      value: assetDetails.serial_number
    },
    {
      key: nanoid(),
      label: t('asset.general.cost'),
      value: assetDetails.purchase_price
    },
    {
      key: nanoid(),
      label: t('asset.general.status'),
      value: <AssetStatusButton status={assetDetails.status} status_info={assetDetails.status_info} />
    }
  ];

  return (
    <ShowDetails
      detailFor={t('asset.assetDetail.linkingTab.linkInventory')}
      details={assetInfo}
      moreDetailsFn={() => {
        onClose();
        navigate(`/inventory-details/${assetDetails.id}`);
      }}
      onClose={onClose}
      canDelete={hasCreateAccess(MODEL_PRODUCT)}
      onDelete={onDelete}
      showMoreButton={assetDetails.can_read_linked_product}
    />
  );
};

export default LinkingProductDetails;
