import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';
import { CheckCircleOutlineOutlined } from '@mui/icons-material';
import { CheckInSchema } from '../../../schemas/AssetEventsSchema';
import ModalForm from '../../../components/ModalForm';
import { CHECK_IN } from '../../../helpers/constants';

const CheckIn = ({ eventDetails = {}, onClose, onSubmit }) => {
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState({
    returnDate: '',
    notes: '',
    ...eventDetails
  });

  const fields = [
    {
      id: nanoid(),
      label: t('asset.assetDetail.moreAction.checkIn.returnDate'),
      name: 'returnDate',
      type: 'date',
      maxDate: new Date(),
      isRequired: true,
      label_size: 2,
      field_size: 10
    },
    {
      id: nanoid(),
      label: t('asset.assetDetail.moreAction.notes'),
      name: 'notes',
      type: 'textArea',
      label_size: 2,
      field_size: 10
    }
  ];

  const schema = CheckInSchema(t('asset.assetDetail.moreAction.checkIn.pleaseEnterReturnDate'));

  return (
    <ModalForm
      formFor={CHECK_IN}
      formTitle={t('statuses.checkIn')}
      titleIcon={<CheckCircleOutlineOutlined />}
      initialValues={initialValues}
      fields={fields}
      onSubmit={onSubmit}
      onClose={onClose}
      onValuesChange={(values) => setInitialValues(values)}
      validationSchema={schema}
    />
  );
};

export default CheckIn;
