import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';
import { SendOutlined } from '@mui/icons-material';
import { LeaseSchema } from '../../../schemas/AssetEventsSchema';
import { axiosApi } from '../../../helpers/api_helper';
import { toast } from 'react-toastify';
import ModalForm from '../../../components/ModalForm';
import { faIndianRupeeSign } from '@fortawesome/free-solid-svg-icons';
import { LEASE } from '../../../helpers/constants';
import UserForm from '../../User/Form';

const Lease = ({
  eventDetails = {},
  onClose,
  assetData,
  onSubmit,
  prefilledDetails,
  scheduledStatus,
  onDeleteScheduledEvent
}) => {
  const { t } = useTranslation();
  const [companies, setCompanies] = useState([]);
  const [branches, setBranches] = useState([]);
  const [users, setUsers] = useState([]);
  const [isUserFormOpen, setisUserFormOpen] = useState(false);
  const [isNewUserAdded, setIsNewUserAdded] = useState(false);

  let obj = {};
  if (prefilledDetails) {
    obj = {
      ...prefilledDetails?.formData,
      leaseBegins:
        prefilledDetails.formData.leaseBegins !== '' ? new Date(prefilledDetails.formData.leaseBegins.$d) : '',
      leaseExpires:
        prefilledDetails.formData.leaseExpires !== '' ? new Date(prefilledDetails.formData.leaseExpires.$d) : '',
      user: prefilledDetails.newUserId
    };
  }

  let initialData = {
    company: scheduledStatus ? scheduledStatus.company : '',
    branch: scheduledStatus ? scheduledStatus.branch : '',
    user: scheduledStatus ? scheduledStatus.user : '',
    leaseBegins: scheduledStatus ? new Date(scheduledStatus.leaseBegins) : '',
    leaseExpires: scheduledStatus ? new Date(scheduledStatus.leaseExpires) : '',
    notes: scheduledStatus ? scheduledStatus.notes : '',
    leaseAmount: '',
    ...eventDetails
  };

  const [initialValues, setInitialValues] = useState(initialData);

  let preSavedData = Object.keys(obj).length ? obj : initialData;

  const fields = [
    {
      id: nanoid(),
      label: t('common.company'),
      name: 'company',
      type: 'dropDown',
      isRequired: true,
      values: companies
    },
    {
      id: nanoid(),
      label: t('common.branch'),
      name: 'branch',
      type: 'dropDown',
      isRequired: true,
      values: branches
    },
    {
      id: nanoid(),
      label: t('common.user'),
      name: 'user',
      type: 'autoComplete',
      isRequired: true,
      disabled: !initialValues.branch,
      addModel: () => {
        setisUserFormOpen(true);
      },
      values: users
    },
    {
      id: nanoid(),
      label: t('asset.assetDetail.moreAction.lease.leaseBegins'),
      name: 'leaseBegins',
      type: 'date',
      isRequired: true
    },
    {
      id: nanoid(),
      label: t('asset.assetDetail.moreAction.lease.leaseExpires'),
      name: 'leaseExpires',
      type: 'date',
      disabled: !initialValues.leaseBegins,
      minDate: initialValues.leaseBegins,
      isRequired: true
    },
    {
      id: nanoid(),
      label: t('asset.assetDetail.moreAction.lease.leaseAmount'),
      name: 'leaseAmount',
      type: 'text',
      inputType: 'number',
      prefixIcon: faIndianRupeeSign
    },
    {
      id: nanoid(),
      label: t('asset.assetDetail.moreAction.notes'),
      name: 'notes',
      type: 'textArea'
    }
  ];

  const schema = LeaseSchema(
    t('common.validation.pleaseSelectCompany'),
    t('common.validation.pleaseSelectBranch'),
    t('common.validation.pleaseSelectUser'),
    t('asset.assetDetail.moreAction.lease.pleaseEnterLeaseBeginsDate'),
    t('asset.assetDetail.moreAction.lease.pleaseEnterLeaseExpiresDate')
  );

  const getCompanies = () => {
    axiosApi
      .get('/companies', {
        params: { is_dropdown: true, lease_product: assetData.company_id }
      })
      .then((res) =>
        setCompanies(
          res.data.data.map((company) => ({
            label: company.attributes.name,
            value: company.attributes.id
          }))
        )
      )
      .catch(() => toast.error(t('asset.assetDetail.statusInfo.failedCompany')));
  };

  const getBranches = () => {
    axiosApi
      .get('/branches', {
        params: {
          company_id: initialValues.company,
          is_dropdown: true,
          lease_product: assetData.company_id
        }
      })
      .then((res) => {
        setBranches(
          res.data.data.map((branch) => ({
            label: branch.attributes.name,
            value: branch.attributes.id
          }))
        );
      })
      .catch(() => toast.error(t('asset.assetDetail.statusInfo.failedBranch')));
  };

  const getUsers = () => {
    axiosApi
      .get('/users', {
        params: {
          filter: { branch_id: initialValues.branch },
          is_dropdown: true
        }
      })
      .then((res) => {
        setUsers(
          res.data.data.map((user) => ({
            label: user.attributes.full_name,
            value: user.attributes.id
          }))
        );
      })
      .catch(() => toast.error(t('asset.assetDetail.statusInfo.failedUser')));
  };

  const handleNewUser = (newUser) => {
    getUsers();
    setInitialValues({ ...initialValues, user: newUser });
    setIsNewUserAdded(true);
  };

  useEffect(() => getCompanies(), []);

  useEffect(() => {
    if (initialValues.company) getBranches();
  }, [initialValues.company]);

  useEffect(() => {
    if (initialValues.branch) getUsers();
  }, [initialValues.branch]);
  return (
    <>
      <ModalForm
        preSavedData={preSavedData}
        formFor={LEASE}
        isNewUserAdded={isNewUserAdded}
        setIsNewUserAdded={setIsNewUserAdded}
        formTitle={t('statuses.lease')}
        titleIcon={<SendOutlined />}
        initialValues={Object.keys(obj).length ? obj : initialValues}
        fields={fields}
        onSubmit={onSubmit}
        onClose={onClose}
        onValuesChange={(values) => setInitialValues(values)}
        validationSchema={schema}
        scheduledStatus={scheduledStatus}
        isEditForm={scheduledStatus && Object.keys(scheduledStatus).length}
        allowDelete={scheduledStatus ? true : false}
        onDelete={onDeleteScheduledEvent}
      />
      <UserForm
        isNavigatedFromEventsForm={true}
        isPreFilledValues={initialValues}
        open={isUserFormOpen}
        details={{}}
        onClose={() => setisUserFormOpen(false)}
        onSubmit={() => setisUserFormOpen(false)}
        onNewUserAdded={handleNewUser}
      />
    </>
  );
};

export default Lease;
