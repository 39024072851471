import { Button, Menu, MenuItem } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCube, faPenToSquare, faRecycle } from '@fortawesome/free-solid-svg-icons';
import {
  AccessTime,
  Add,
  Build,
  BuildOutlined,
  CheckCircleOutlineOutlined,
  DeleteForever,
  DeleteOutlineOutlined,
  DiscountOutlined,
  Edit,
  ExtensionOffOutlined,
  FavoriteBorderOutlined,
  Logout,
  SendOutlined,
  ThumbDownAltOutlined,
  DateRange
} from '@mui/icons-material';
import appStyles from '../../assets/scss/_app.module.scss';
import buttonStyles from '../../assets/scss/_button.module.scss';
import styles from './index.module.scss';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import {
  IRREVERSIBLE_STATUSES,
  ISCHECKEDOUT_OR_LEASED,
  STATUSES_FOR_CHECKEDOUT_OR_LEASED_ASSET
} from '../../helpers/constants';
import { axiosApi } from '../../helpers/api_helper';
import { useNavigate, useParams } from 'react-router-dom';
import { MoreVert } from '@mui/icons-material';
import {
  hasCreateAccess,
  hasDeleteAccess,
  hasEditAcccess,
  MODEL_INSURANCE,
  MODEL_MAINTENANCE,
  MODEL_MAINTENANCE_REQUEST,
  MODEL_PRODUCT,
  MODEL_WARRANTY
} from '../../helpers/access';

const AssetDetailHeader = ({
  maintenanceRequestId = null,
  assetDetails,
  scheduledStatus,
  canRequestMaintenance,
  onMaintenanceRequest,
  afterDestroyMaintenanceRequest,
  onStatusChange,
  onAddOptionClick
}) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [statuses, setStatuses] = useState([]);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const open1 = Boolean(anchorEl1);
  const open2 = Boolean(anchorEl2);

  const addableMenuItems = [
    ...(hasCreateAccess(MODEL_INSURANCE) ? [{ label: 'Insurance', value: 'insurance' }] : []),
    ...(hasCreateAccess(MODEL_WARRANTY) ? [{ label: 'Warranty', value: 'warranty' }] : []),
    ...(hasCreateAccess(MODEL_MAINTENANCE) ? [{ label: 'Maintenance', value: 'maintenance' }] : []),
    ...(hasCreateAccess(MODEL_PRODUCT) && !assetDetails.parent_id ? [{ label: 'Link Parent', value: 'parent' }] : []),
    ...(hasCreateAccess(MODEL_PRODUCT) ? [{ label: 'Link Child', value: 'child' }] : [])
  ];

  let itemIcons = {
    checkIn: <CheckCircleOutlineOutlined />,
    repair: <BuildOutlined />,
    lostOrMissing: <ThumbDownAltOutlined />,
    lease: <SendOutlined />,
    sold: <DiscountOutlined />,
    dispose: <FontAwesomeIcon icon={faRecycle} />,
    broken: <ExtensionOffOutlined />,
    checkOut: <Logout />,
    donate: <FavoriteBorderOutlined />,
    edit: <FontAwesomeIcon icon={faPenToSquare} />,
    reservation: <DateRange />,
    delete: <DeleteOutlineOutlined />
  };

  var filteredStatuses = statuses.filter((status) => ![`${assetDetails.status}`, 'lease'].includes(status.value));
  if (scheduledStatus) {
    filteredStatuses = [{ label: t('edit'), value: 'edit' }];
  } else if (ISCHECKEDOUT_OR_LEASED.includes(assetDetails.status)) {
    filteredStatuses = filteredStatuses.filter((status) =>
      STATUSES_FOR_CHECKEDOUT_OR_LEASED_ASSET.includes(status.value)
    );
  } else if (IRREVERSIBLE_STATUSES.includes(assetDetails.status)) {
    filteredStatuses = [];
  }
  filteredStatuses.push(
    { label: t('Reservation'), value: 'reservation' },
    { label: t('delete'), value: 'delete', addSeparator: filteredStatuses.length > 1 }
  );

  filteredStatuses = filteredStatuses.map((status) => ({
    ...status,
    itemIcon: itemIcons[status.value],
    addSeparator: status.addSeparator || false
  }));

  const handleStatusChange = (status) => {
    onStatusChange(status);
    setAnchorEl2(null);
  };

  const handleAddOptionClick = (option) => {
    onAddOptionClick(option);
    setAnchorEl1(null);
  };

  const destroyMaintenanceRequest = () => {
    axiosApi
      .delete(`/maintenance_requests/${maintenanceRequestId}`)
      .then(afterDestroyMaintenanceRequest)
      .catch(() => toast.error(t('common.somethingWentsWrong')));
  };

  const getStatuses = () => {
    axiosApi
      .get('/products/fetch_product_statuses')
      .then((res) => {
        setStatuses(
          Object.keys(res.data).map((key) => ({
            label: t(`statuses.${key}`),
            value: key
          }))
        );
      })
      .catch((err) => toast.error(err ? t(`${err}`) : t('common.somethingWentsWrong')));
  };

  useEffect(getStatuses, []);

  return (
    <div className={`${appStyles.header_container} ${styles.header}`}>
      <div className={styles.header_name_container}>
        <FontAwesomeIcon icon={faCube} className={styles.cube_icon} />
        <span className={styles.header_name}>{t('asset.assetDetail.title')}</span>
      </div>
      <div className={styles.action_btns}>
        {hasEditAcccess(MODEL_PRODUCT) && (
          <>
            <button className={styles.editBtn} onClick={() => navigate(`/edit-inventory/${id}`)}>
              <FontAwesomeIcon icon={faPenToSquare} className={buttonStyles.edit_icon} />
            </button>
            <button
              aria-controls={open1 ? 'add-option' : undefined}
              aria-haspopup="true"
              aria-expanded={open1 ? 'true' : undefined}
              className={styles.btn}
              onClick={(e) => setAnchorEl1(e.currentTarget)}>
              <Add />
            </button>
            <Menu
              id="add-option"
              anchorEl={anchorEl1}
              open={open1}
              onClose={() => setAnchorEl1(null)}
              className={styles.actionMenu}>
              {addableMenuItems.map((option) => (
                <MenuItem key={option.value} onClick={() => handleAddOptionClick(option.value)}>
                  {option.label}
                </MenuItem>
              ))}
            </Menu>
            <button
              aria-controls={open2 ? 'more-action' : undefined}
              aria-haspopup="true"
              aria-expanded={open2 ? 'true' : undefined}
              className={styles.btn}
              onClick={(e) => setAnchorEl2(e.currentTarget)}>
              <MoreVert className={`${styles.moreIcon} ${open2 ? styles.rotate : ''}`} />
            </button>
            <Menu
              id="more-action"
              anchorEl={anchorEl2}
              open={open2}
              onClose={() => setAnchorEl2(null)}
              className={`${styles.actionMenu} ${styles.redHover}`}>
              {filteredStatuses.map((status) => (
                <MenuItem key={status.value} onClick={() => handleStatusChange(status.value)}>
                  <span className={styles.statusIcons}>{status.itemIcon}</span>
                  {status.label}
                </MenuItem>
              ))}
            </Menu>
          </>
        )}
        {canRequestMaintenance && (
          <Button
            disableElevation
            variant="contained"
            className={`${buttonStyles.filter_btn} ${styles.requestBtn}`}
            startIcon={<Build />}
            onClick={onMaintenanceRequest}>
            <span>{t('maintenanceRequest.request')}</span>
          </Button>
        )}
        {!canRequestMaintenance &&
          (maintenanceRequestId ?? maintenanceRequestId) &&
          hasDeleteAccess(MODEL_MAINTENANCE_REQUEST) && (
            <span className={styles.maintenanceRequestedContainer}>
              <span className={styles.requested}>
                <AccessTime />
                {t('maintenanceRequest.requested')}
              </span>
              <button className={styles.editBtn} onClick={onMaintenanceRequest}>
                <Edit />
              </button>
              <button className={styles.deleteBtn} onClick={destroyMaintenanceRequest}>
                <DeleteForever />
              </button>
            </span>
          )}
      </div>
    </div>
  );
};

export default AssetDetailHeader;
