import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import getSymbolFromCurrency from 'currency-symbol-map';
import ShowDetails from '../../../../components/ShowDetail';
import { getDateString } from '../../../../helpers/common_service';
import { hasDeleteAccess, hasEditAcccess, MODEL_MAINTENANCE } from '../../../../helpers/access';

const MaintenanceDetails = ({ maintenance, onClose, onEdit, onDelete }) => {
  const { t } = useTranslation();

  const maintenanceDetails = [
    {
      key: nanoid(),
      label: t('maintenance.title'),
      value: maintenance.title
    },
    {
      key: nanoid(),
      label: t('maintenance.maintenanceBy'),
      value: maintenance.maintenance_by
    },
    {
      key: nanoid(),
      label: t('maintenance.dueDate'),
      value: getDateString(maintenance.due_date)
    },
    {
      key: nanoid(),
      label: t('maintenance.dateCompleted'),
      value: getDateString(maintenance.date_completed)
    },
    {
      key: nanoid(),
      label: t('maintenance.repairCost'),
      value: maintenance.repair_cost ? `${getSymbolFromCurrency(maintenance.currency)} ${maintenance.repair_cost}` : ''
    },
    {
      key: nanoid(),
      label: t('maintenance.status'),
      value: maintenance.status_display
    },
    {
      key: nanoid(),
      label: t('asset.assetDetail.moreAction.notes'),
      value: maintenance.notes
    },
    {
      key: nanoid(),
      label: t('asset.assetDetail.moreAction.status'),
      value: maintenance.status
    }
  ];

  return (
    <ShowDetails
      detailFor={t('asset.assetDetail.maintenanceTab.showMaintenance')}
      details={maintenanceDetails}
      onClose={onClose}
      canEdit={hasEditAcccess(MODEL_MAINTENANCE)}
      onEdit={onEdit}
      canDelete={hasDeleteAccess(MODEL_MAINTENANCE)}
      onDelete={onDelete}
    />
  );
};

export default MaintenanceDetails;
