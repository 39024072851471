import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import TableList from '../../components/TableList';
import { axiosApi } from '../../helpers/api_helper';
import { getAssetData, getAssetHeadCells, setHeaderFilters } from '../../helpers/common_service';
import { toast } from 'react-toastify';
import {
  inventoryPage,
  BROKEN,
  CHECK_IN,
  CHECK_OUT,
  DISPOSE,
  DONATE,
  LEASE,
  LOST_OR_MISSING,
  REPAIR,
  SOLD
} from '../../helpers/constants';
import { faFileCirclePlus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { nanoid } from 'nanoid';
import { useNavigate } from 'react-router-dom';
import { hasCreateAccess, hasDeleteAccess, hasEditAcccess, MODEL_PRODUCT } from '../../helpers/access';

const AssetList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentTab = new URLSearchParams(window.location.search).get('tab');
  const table = useSelector((state) => state.table);
  const showMenu = useSelector((state) => state.sideMenu.showMenu);
  const authUser = useSelector((state) => state.login.authUser);
  const [assets, setAssets] = useState([]);
  const [totalRowsCount, setTotalRowsCount] = useState(0);
  const [totalPagesCount, setTotalPagesCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const filterValues = useSelector((state) => state.filterModal);
  const companyId = useSelector((state) => state.common.companyId);
  const branchId = useSelector((state) => state.common.branchId);
  const dispatch = useDispatch();

  const [filterOptions, setFilterOptions] = useState({
    searchText: filterValues?.pageName === inventoryPage ? table.searchText : ''
  });
  const [sortingOptions, setSortingOptions] = useState({});
  const [paginationDetails, setPaginationDetails] = useState({
    currentPage: 1,
    rowsPerPage: table?.tableRowCountObj?.count
  });
  const fetchAssetHeadCells = useCallback(() => getAssetHeadCells(currentTab), [showMenu, currentTab]);

  const tabs = [
    { key: nanoid(), label: t('statuses.assign'), value: 'assigned', statuses: [LEASE, CHECK_OUT] },
    { key: nanoid(), label: t('statuses.available'), value: 'available', statuses: [CHECK_IN] },
    {
      key: nanoid(),
      label: t('statuses.lostOrExpired'),
      value: 'lost-or-expired',
      statuses: [SOLD, DONATE, LOST_OR_MISSING, DISPOSE, BROKEN, REPAIR]
    }
  ];

  const handleTabChange = (value) => navigate({ search: `?tab=${value}` });

  const fetchAssets = useCallback(() => {
    if (!currentTab && hasCreateAccess(MODEL_PRODUCT)) return;

    let parameters = { filter: { linking: { user_id: authUser.id } } };
    if (currentTab && hasCreateAccess(MODEL_PRODUCT))
      parameters.filter['statuses'] = tabs.find((tab) => tab.value === currentTab).statuses;

    if (filterOptions) {
      parameters = {
        filter: {
          ...parameters.filter,
          ...(filterOptions && filterOptions.searchText
            ? { search: filterOptions.searchText.replace(/\+/g, '%2B') }
            : {})
        }
      };
    }

    if (companyId) {
      parameters['filter']['company'] = companyId;
    }

    if (branchId) {
      parameters['filter']['branch'] = branchId;
    }

    if (sortingOptions) {
      parameters['order'] = sortingOptions.order;
      parameters['column'] = sortingOptions.column;
    }

    if (paginationDetails?.currentPage) parameters['page'] = paginationDetails.currentPage;
    if (paginationDetails?.rowsPerPage) parameters['items'] = paginationDetails.rowsPerPage;
    setIsLoading(true);
    axiosApi
      .get('/products', {
        params: parameters
      })
      .then((res) => {
        setAssets(getAssetData(res.data.data));
        setTotalRowsCount(res.data.meta.total_count);
        setTotalPagesCount(res.data.meta.total_pages);
      })
      .catch(() => toast.error(t('common.somethingWentsWrong')))
      .finally(() => setIsLoading(false));
  }, [authUser.id, filterOptions, sortingOptions, paginationDetails, currentTab, companyId, branchId]);

  useEffect(() => {
    if (Object.keys(sortingOptions).length || filterOptions) fetchAssets();
  }, [filterOptions, sortingOptions, fetchAssets]);

  useEffect(() => {
    if (!currentTab && hasCreateAccess(MODEL_PRODUCT)) navigate({ search: `?tab=${tabs[0].value}` });
  }, [currentTab]);

  useEffect(() => {
    setHeaderFilters(dispatch, authUser.company_id, authUser.branch_id);
  }, []);

  const addMenuItems = [
    ...(hasCreateAccess(MODEL_PRODUCT)
      ? [
          {
            label: t('asset.general.addInventory'),
            link: '/add-inventory',
            icon: <FontAwesomeIcon icon={faPlus} />
          },
          {
            label: t('import.bulkImport'),
            link: '/import-assets',
            icon: <FontAwesomeIcon icon={faFileCirclePlus} />
          }
        ]
      : [])
  ];

  return (
    <TableList
      pageName={inventoryPage}
      headCells={fetchAssetHeadCells()}
      rows={assets}
      addMenuItems={addMenuItems}
      addBtnLink="add-inventory"
      editBtnLink="edit-inventory"
      deleteBtnLink="products"
      viewBtnLink="inventory-details"
      onDelete={fetchAssets}
      deleteBtn={hasDeleteAccess(MODEL_PRODUCT)}
      editBtn={hasEditAcccess(MODEL_PRODUCT)}
      filterOptionsChange={(values) => setFilterOptions(values)}
      loading={isLoading}
      filterName={'status'}
      onChange={(values) => setPaginationDetails(values)}
      totalRowsCount={totalRowsCount}
      totalPagesCount={totalPagesCount}
      onSortingValuesChange={(values) => setSortingOptions(values)}
      viewActionColumn={hasEditAcccess(MODEL_PRODUCT) || hasDeleteAccess(MODEL_PRODUCT)}
      tabs={hasCreateAccess(MODEL_PRODUCT) && tabs}
      currentTab={currentTab}
      onTabChange={handleTabChange}
      noDataMsg={t('asset.assetDetail.noAsset')}
    />
  );
};

export default AssetList;
