import { useTranslation } from 'react-i18next';
import ImageSlider from '../../../components/ImageSlider';
import { getDateString, displayWithCurrency } from '../../../helpers/common_service';
import { AssetStatusButton } from '../../Asset/AssetStatus/AssetStatus';
import styles from './index.module.scss';

const BasicInfo = ({ details }) => {
  const { t } = useTranslation();

  const assetDetails = [
    {
      key: 'tag_id',
      label: t('asset.general.tagId'),
      value: details.tag_id
    },
    {
      key: 'asset_name',
      label: t('asset.general.assetName'),
      value: details.asset_name
    },
    {
      key: 'model',
      label: t('asset.general.model'),
      value: details.model
    },
    {
      key: 'serial_number',
      label: t('asset.general.serialNumber'),
      value: details.serial_number
    },
    {
      key: 'cost',
      label: t('asset.general.cost'),
      value: displayWithCurrency(details.currency, details.purchase_price_display)
    },
    {
      key: 'status',
      label: t('asset.assetDetail.status'),
      value: details.status
    },
    {
      key: 'company',
      label: t('common.company'),
      value: details.company_name
    },
    {
      key: 'brand',
      label: t('asset.assetDetail.brand'),
      value: details.brand
    },
    {
      key: 'type',
      label: t('asset.assetDetail.type'),
      value: details.product_type
    },
    {
      key: 'branch',
      label: t('common.branch'),
      value: details.branch_name
    },
    {
      key: 'category',
      label: t('asset.assetDetail.category'),
      value: details.category
    },
    {
      key: 'purchase_date',
      label: t('asset.assetDetail.purchaseDate'),
      value: getDateString(details.purchase_date)
    }
  ];

  return (
    <div className={styles.container}>
      <div
        className={`${styles.assetImageContainer} ${
          details.images && details.images.length > 1 ? styles.noBottomPadding : ''
        }`}>
        <ImageSlider
          images={details.images}
          imageContainerClass={`${styles.imageContainer} ${
            details.images && details.images.length === 0 ? styles.emptyContainer : ''
          }`}
          imageClass={styles.assetImage}
        />
      </div>
      <div>
        {assetDetails.map((detail) => (
          <span key={detail.key} className={styles.assetDetailRow}>
            <span className={styles.assetKey}>{detail.label}</span>
            <span className={styles.assetValue}>
              {detail.key === 'status' && detail.value ? (
                <AssetStatusButton status={detail.value} status_info={details.serialized_status_info} />
              ) : (
                detail.value || '-'
              )}
            </span>
          </span>
        ))}
      </div>
    </div>
  );
};

export default BasicInfo;
