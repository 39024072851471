import { FormControl, InputLabel, Paper } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { axiosApi } from '../../helpers/api_helper';
import styles from './index.module.scss';
import AssetBasicInfo from './BasicInfo';
import { useSelector } from 'react-redux';
import { scrollBoxShadowStyle, useScrollVerticle } from '../../helpers/common_service';
import ConfirmationCard from '../../components/ConfirmationCard';
import AssetEvents from './Events';
import MaintenanceRequestForm from '../MaintenanceRequest/RequestForm';
import Header from './Header';
import AssetTimeLineProgress from './TimeLineActivity';
import { Drawer } from '@mui/material';
import ReservationTab from './Tabs/Reservation';
import Loader from '../../components/Loader';
import { hasCreateAccess, MODEL_MAINTENANCE_REQUEST, AUTHORITY_LEVEL_SELF } from '../../helpers/access';
import DeleteToaster from '../../components/Toaster/DeleteToaster';

import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import { Chip } from '@mui/material';
import { TimelineOptionsList, RESERVATION } from '../../helpers/constants';
const AssetDetails = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const authUser = useSelector((state) => state.login.authUser);
  const [assetDetails, setAssetDetails] = useState({});
  const [initialLoad, setInitialLoad] = useState(true);
  const [scheduledStatus, setScheduleStatus] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [currentMaintenances, setCurrentMaintenances] = useState([]);
  const [canRequestMaintenance, setCanRequestMaintenance] = useState(false);
  const [isMaintenaceRequestFormOpen, setIsMaintenaceRequestFormOpen] = useState(false);
  const [maintenaceRequestedDetails, setMaintenanceRequestDetails] = useState({});
  const isPrefilledDetailsPresent = location.state?.previousFormValues;
  const [status, setStatus] = useState(isPrefilledDetailsPresent?.status ?? '');
  const [addOptionValue, setAddOptionValue] = useState('');
  const [reloadTimeLine, setReloadTimeLine] = useState(false);
  const [openReservation, setOpenReservation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reservations, setReservations] = useState([]);
  const [scrollTop, scrollBottom, scrollProps] = useScrollVerticle();
  const [activities, setActivities] = useState([]);
  const [currentReservation, setCurrentReservation] = useState({});
  const [isDeleteToasterOpen, setIsDeleteToasterOpen] = useState(false);

  const handleNaviagateState = () => {
    navigate(`/inventory-details/${assetDetails.id}`, {
      state: ''
    });
  };

  const handleStatusChange = (status) => {
    if (status === RESERVATION) {
      getReservations();
      setOpenReservation(true);
    }
    if (status === 'delete') setIsOpen(true);
    else if (status === 'edit') setStatus(scheduledStatus['scheduled_status']);
    else setStatus(status);
  };
  const getReservations = () => {
    setLoading(true);
    axiosApi
      .get('reservations', {
        params: {
          filter: { product: assetDetails.id }
        },
        headers: {
          Authorization: `Bearer ${authUser['remember_token']}`,
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((res) => {
        setReservations(res.data.data.map((data) => ({ ...data.attributes })));
      })
      .catch(() => {
        toast.error(t(`asset.assetDetail.reserveTab.reservationFetchFailed`));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleMaintenanceRequest = () => {
    setCanRequestMaintenance(false);
    setIsMaintenaceRequestFormOpen(false);
    getPendingMaintenance();
    setReloadTimeLine(true);
  };

  const handleDelete = () => {
    axiosApi
      .delete(`/products/${id}`)
      .then(() => {
        navigate('/inventory-list');
        toast.success(t('asset.assetDetail.deleteSuccess'));
      })
      .catch(() => toast.error(t('asset.assetDetail.deleteFailed')));
  };

  const handleDestroyMaintenanceRequest = () => {
    setCanRequestMaintenance(true);
    setMaintenanceRequestDetails({});
    toast.error(t('maintenanceRequest.cancelled'));
    setReloadTimeLine(true);
  };

  const getAssetDetails = () => {
    axiosApi
      .get(`/products/${id}`)
      .then((res) => {
        setAssetDetails(res.data.data.attributes);
        if (!isPrefilledDetailsPresent) setStatus(res.data.data.attributes.status);
        if (initialLoad) setInitialLoad(false);
      })
      .catch((err) => toast.error(err ? t(`${err}`) : t('common.somethingWentsWrong')));
  };

  const getPendingMaintenance = () => {
    axiosApi
      .get(`/products/${id}/pending_maintenance_request`)
      .then((res) => {
        if (res?.data) {
          if (res.data.data.type === 'maintenance_request') {
            setMaintenanceRequestDetails(res.data.data.attributes);
          } else if (res.data.data[0].type === 'maintenance') {
            setCurrentMaintenances(res.data.data);
          }
        }
      })
      .catch(() => toast.error(t('common.somethingWentsWrong')));
  };
  function hasScheduledStatusChange(assetData) {
    return (
      assetData.status_info &&
      Object.keys(assetData.status_info).length &&
      assetData['status_info']['scheduled_status'] &&
      assetData['status_info']['scheduled_status'] !== assetData.status
    );
  }
  useEffect(getAssetDetails, [window.location.pathname]);

  useEffect(() => {
    if (hasScheduledStatusChange(assetDetails)) setScheduleStatus(assetDetails['status_info']);
    if (hasCreateAccess(MODEL_MAINTENANCE_REQUEST, AUTHORITY_LEVEL_SELF, false)) getPendingMaintenance();
  }, [assetDetails]);

  useEffect(() => {
    setCanRequestMaintenance(
      Object.keys(maintenaceRequestedDetails).length === 0 &&
        hasCreateAccess(MODEL_MAINTENANCE_REQUEST, AUTHORITY_LEVEL_SELF, false)
    );
  }, [maintenaceRequestedDetails]);

  useEffect(() => {
    setCanRequestMaintenance(
      currentMaintenances.length === 0 && hasCreateAccess(MODEL_MAINTENANCE_REQUEST, AUTHORITY_LEVEL_SELF, false)
    );
  }, [currentMaintenances]);

  useEffect(() => {
    if (currentReservation && currentReservation !== {}) {
      let allActivities = [...activities];
      if (currentReservation['action'] === 'create') {
        allActivities.unshift({
          model_name: 'reservation',
          values: currentReservation['detail']
        });
      } else if (currentReservation['action'] == 'edit') {
        let cuurent = activities.find((i) => i.values.id === currentReservation.detail.id);
        let indexVal = activities.indexOf(cuurent);
        cuurent.values = currentReservation.detail;
        allActivities[indexVal] = cuurent;
      } else if (currentReservation['action'] == 'delete') {
        allActivities.splice(
          activities.find((i) => i.values.id === currentReservation.id),
          1
        );
      }
      setActivities(allActivities);
    }
  }, [currentReservation]);

  const fetchTimelineActivities = (value = null) => {
    axiosApi
      .get(`/products/${id}/timeline_activities`, { params: { filtered_record: value } })
      .then((res) => setActivities(res.data))
      .catch(() => {
        toast.error(t('common.somethingWentsWrong'));
      });
  };

  const MenuProps = {
    PaperProps: {
      className: styles.menuPaper,
      style: {
        maxHeight: 240,
        width: 260,
        marginLeft: 68,
        marginTop: 10,
        position: 'relative',
        paddingInlineStart: 2
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const [filter, setfilter] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const selectRef = useRef(null);

  const handleChange = (event) => {
    const value = event.target.value;
    setfilter(value);
    let valuesArray = TimelineOptionsList.filter((i) => value.includes(i.label)).map((i) => i.value);
    fetchTimelineActivities(valuesArray);
  };

  const handleClearAll = () => {
    setfilter([]);
    fetchTimelineActivities([]);
  };

  const handleOpen = () => setIsMenuOpen(true);
  const handleCloses = () => setIsMenuOpen(false);
  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      // check if the clicked element is not inside the menu
      const menu = document.getElementsByClassName('MuiMenu-paper');
      if (menu.length > 0 && !menu[0].contains(event.target)) {
        setIsMenuOpen(false);
      }
    }
  };

  return (
    <>
      <Paper elevation={0} className={styles.container}>
        <div className={styles.headerContainer}>
          <Header
            maintenanceRequestId={maintenaceRequestedDetails?.id}
            assetDetails={assetDetails}
            scheduledStatus={scheduledStatus}
            canRequestMaintenance={canRequestMaintenance}
            onMaintenanceRequest={() => setIsMaintenaceRequestFormOpen(true)}
            afterDestroyMaintenanceRequest={handleDestroyMaintenanceRequest}
            onStatusChange={handleStatusChange}
            onAddOptionClick={(value) => setAddOptionValue(value)}
          />
          <hr className={styles.horizontal_line} />
        </div>
        <div className={styles.bodyContainer}>
          <AssetBasicInfo details={assetDetails} />
          <div className={`${styles.timeLineContainer} container`}>
            <div className={`text_2xl pb-3 ${styles.timeLineHeader}`}>Activities</div>
            <div
              className={styles.assetTimeLineParent}
              {...scrollProps}
              style={{
                boxShadow: scrollBoxShadowStyle(scrollTop, scrollBottom),
                transition: 'box-shadow 0.3s',
                overflowY: 'scroll'
              }}>
              <AssetTimeLineProgress
                setCurrentReservation={setCurrentReservation}
                activities={activities}
                setActivities={setActivities}
                reload={reloadTimeLine}
                afterReload={() => setReloadTimeLine(false)}
                formName={addOptionValue}
                initialLoad={initialLoad}
                assetDetails={assetDetails}
                resetFormOpen={() => {
                  setStatus('');
                  setAddOptionValue('');
                }}
                setAssetParent={(parentId) => setAssetDetails((prev) => ({ ...prev, parent_id: parentId }))}
              />
            </div>
          </div>
          <div className={styles.searchBox}>
            <FormControl sx={{ m: 1, width: 180 }}>
              <InputLabel sx={{ '&.Mui-focused ': { color: '#00b3c3' } }}>Filter</InputLabel>
              <Select
                className={styles.select}
                sx={{
                  border: 'none',
                  color: 'black',
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: 'black'
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#f98a0c'
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#f98a0c'
                  },
                  '.MuiSvgIcon-root ': {
                    fill: 'grey'
                  }
                }}
                onBlur={handleCloses}
                onOpen={handleOpen}
                onClose={handleCloses}
                ref={selectRef}
                open={isMenuOpen}
                IconComponent={() => null}
                endAdornment={
                  <IconButton sx={{ visibility: filter.length > 0 ? 'visible' : 'hidden' }} onClick={handleClearAll}>
                    <ClearIcon />
                  </IconButton>
                }
                multiple
                value={filter}
                onChange={handleChange}
                input={<OutlinedInput label="Name" className={styles.input} />}
                MenuProps={MenuProps}
                renderValue={(selected) => {
                  const displayText = selected.length > 0 ? selected[0] : '';
                  const hiddenCount = selected.length - 1;
                  return (
                    <div className={styles.chipContainer}>
                      <Chip className={styles.chip} label={displayText} variant="outlined" />
                      {hiddenCount > 0 && <div style={{ color: '#666' }}>{`+${hiddenCount}`}</div>}
                    </div>
                  );
                }}>
                {TimelineOptionsList.map((i) => (
                  <MenuItem key={i.key} value={i.label} className={styles.menuItem}>
                    <Checkbox checked={filter.indexOf(i.label) > -1} className={styles.menuItemCheckbox} />
                    {TimelineOptionsList.map}
                    {i.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
      </Paper>
      <ConfirmationCard isOpen={isOpen} onClose={() => setIsOpen(false)} onDelete={handleDelete} />
      {status && (
        <AssetEvents
          status={status}
          assetStatus={assetDetails.status}
          assetId={assetDetails.id}
          assetData={assetDetails}
          onClose={() => {
            handleNaviagateState();
            setStatus(isPrefilledDetailsPresent ? isPrefilledDetailsPresent.status : assetDetails.status);
          }}
          afterSubmit={getAssetDetails}
          isPrefilledDetailsPresent={isPrefilledDetailsPresent}
          handleNaviagateState={handleNaviagateState}
          scheduledStatus={scheduledStatus}
        />
      )}

      <Drawer
        anchor={'right'}
        open={openReservation}
        onClose={() => {
          setOpenReservation(false);
          setLoading(false);
        }}
        PaperProps={{
          sx: { width: '45%', padding: '15px', display: 'flex', justifyContent: 'center' }
        }}>
        {loading ? (
          <Loader loading={loading} />
        ) : (
          <ReservationTab
            setCurrentReservation={setCurrentReservation}
            getReservations={getReservations}
            product={assetDetails.id}
            assetDetails={assetDetails}
            authUser={authUser}
            reservations={reservations}
            isLoading={(val) => setLoading(val)}
            setIsDeleteToasterOpen={(val) => setIsDeleteToasterOpen(val)}
          />
        )}
      </Drawer>
      {isMaintenaceRequestFormOpen && (
        <MaintenanceRequestForm
          productId={id}
          userId={authUser.id}
          prefilledDetails={maintenaceRequestedDetails}
          onSubmit={handleMaintenanceRequest}
          onClose={() => setIsMaintenaceRequestFormOpen(false)}
        />
      )}
      {isDeleteToasterOpen && loading && (
        <DeleteToaster
          message={t('asset.assetDetail.reserveTab.reservationDeletedSuccessfully')}
          onClose={() => setIsDeleteToasterOpen(false)}
          canUndo={false}
        />
      )}
    </>
  );
};

export default AssetDetails;
