import { useState, useEffect, useCallback } from 'react';
import TableList from '../../components/TableList';
import { axiosApi } from '../../helpers/api_helper';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { nanoid } from 'nanoid';
import { toast } from 'react-toastify';
import { SET_MODAL_FILTER } from '../../store/filterModal/actionTypes';
import { branchPage } from '../../helpers/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { getBranchesLists } from '../../store/actions';
import styles from './branches.module.scss';
import {
  AUTHORITY_LEVEL_COMPANY,
  hasCreateAccess,
  hasDeleteAccess,
  hasEditAcccess,
  hasReadAccess,
  MODEL_BRANCH
} from '../../helpers/access';

const BranchList = () => {
  const loginUser = useSelector((state) => state.login.authUser);
  const table = useSelector((state) => state.table);
  const filterModalValues = useSelector((state) => state.filterModal);
  const [branches, setBranches] = useState([]);
  const [totalRowsCount, setTotalRowsCount] = useState(0);
  const [totalPagesCount, setTotalPagesCount] = useState(0);
  const [paginationDetails, setPaginationDetails] = useState({
    currentPage: 1,
    rowsPerPage: table?.tableRowCountObj?.count
  });
  const dispatch = useDispatch();
  const [badgeInvisible, setBadgeInvisible] = useState(true);
  // const [loginUserbranchDetails, setLoginUserBranchDetails] = useState({});
  const [filterValues, setFilterValues] = useState({});
  const [filterOptions, setFilterOptions] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const companyId = useSelector((state) => state.common.companyId);
  const { t } = useTranslation();
  const canShowBranchDetail = loginUser.branch_id;

  const branchHeadCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: t('branch.titleName')
    },
    {
      id: 'street',
      numeric: true,
      disablePadding: false,
      label: t('branch.general.street')
    },
    {
      id: 'city',
      numeric: true,
      disablePadding: false,
      label: t('branch.general.city')
    },
    {
      id: 'state',
      numeric: true,
      disablePadding: false,
      label: t('branch.general.state')
    },
    {
      id: 'postal_code',
      numeric: true,
      disablePadding: false,
      label: t('branch.general.postalCode')
    },
    {
      id: 'country',
      numeric: true,
      disablePadding: false,
      label: t('branch.general.country')
    },
    {
      id: 'action',
      numeric: true,
      disablePadding: false,
      label: t('common.action')
    }
  ];

  const filterFields = [
    {
      key: nanoid(),
      name: 'country',
      type: 'dropdown',
      label: 'country',
      blankOptionLabel: 'selectCountry',
      defaultOptionLabel: 'selectCountry'
    },
    {
      key: nanoid(),
      name: 'state',
      type: 'dropdown',
      label: 'state',
      blankOptionLabel: 'selectState',
      defaultOptionLabel: 'selectState'
    },
    {
      key: nanoid(),
      name: 'city',
      type: 'text',
      label: 'city',
      placeholder: 'enterCity'
    }
  ];

  const getBranches = useCallback(() => {
    setIsLoading(true);
    let parameters = { restrict_own_branch: true };
    if (filterOptions || filterValues) {
      parameters = {
        ...parameters,
        filter: {
          ...(hasReadAccess(MODEL_BRANCH, AUTHORITY_LEVEL_COMPANY) && {
            company_id: companyId
          }),
          ...(filterOptions && filterOptions.searchText
            ? { search: filterOptions.searchText.replace(/\+/g, '%2B') }
            : {}),
          ...(filterValues && filterValues.country ? { country: filterValues.country } : {}),
          ...(filterValues && filterValues.state ? { state: filterValues.state } : {}),
          ...(filterValues && filterValues.city ? { city: filterValues.city } : {})
        }
      };
    }

    if (paginationDetails?.currentPage) parameters['page'] = paginationDetails.currentPage;
    if (paginationDetails?.rowsPerPage) parameters['items'] = paginationDetails.rowsPerPage;

    axiosApi
      .get('/branches', {
        params: parameters
      })
      .then((res) => {
        setBranches(
          res.data.data
            .map((data) =>
              data.attributes.id === canShowBranchDetail
                ? {
                    ...data.attributes,
                    name: (
                      <>
                        <span className={styles.branchName}>{data.attributes.name}</span>
                        <span className={styles.myBranchTag}>{t('navbarItems.myBranch')}</span>
                      </>
                    )
                  }
                : data.attributes
            )
            .map((data) => {
              return {
                ...data,
                row_meta: {
                  delete_alert_message: t(`branch.branchList.branchAlertDeleteMsg`)
                }
              };
            })
        );
        setTotalRowsCount(res.data.meta.total_count);
        setTotalPagesCount(res.data.meta.total_pages);
        getBranchesLists(dispatch, true, { company_id: companyId, is_dropdown: true });
      })
      .catch(() => toast.error(t('asset.assetDetail.statusInfo.failedBranch')))
      .finally(() => setIsLoading(false));
  }, [filterOptions, filterValues, paginationDetails, companyId]);

  // const getLoginUserBranchDetails = () => {
  //   axiosApi
  //     .get(`/branches/${loginUser.branch_id}`)
  //     .then((res) => {
  //       setLoginUserBranchDetails(res.data.data.attributes);
  //     })
  //     .catch(() => toast.error(t('asset.assetDetail.statusInfo.failedBranch')));
  // };

  const onValuesChange = (values) => {
    if (!Object.keys(filterValues).length && !Object.keys(values).length) return;
    if (values.country !== filterValues.country && values.state === filterValues.state) {
      values = { ...values, state: '' };
    }
    setFilterValues(values);
    setBadgeInvisible(false);
    dispatch({
      type: SET_MODAL_FILTER,
      filterModal: values,
      pageName: branchPage
    });
    if (!values) {
      setFilterValues({});
      setBadgeInvisible(true);
      dispatch({
        type: SET_MODAL_FILTER,
        filterModal: {},
        pageName: branchPage
      });
    }
  };

  // useEffect(() => {
  //   if (canShowBranchDetail) getLoginUserBranchDetails();
  // }, []);

  useEffect(() => {
    if (Object.keys(filterValues).length) getBranches();
    if (JSON.stringify(filterValues) === JSON.stringify({ country: '', state: '', city: '' })) {
      setBadgeInvisible(true);
    }
  }, [filterValues, getBranches]);

  useEffect(() => {
    if (filterOptions && JSON.stringify(filterOptions) !== JSON.stringify({ country: '', state: '', city: '' }))
      getBranches();
  }, [filterOptions, getBranches]);

  useEffect(() => {
    if (
      filterModalValues.filterModal &&
      filterModalValues.pageName === branchPage &&
      Object.keys(filterModalValues.filterModal).length
    ) {
      setFilterValues(filterModalValues.filterModal);
      setBadgeInvisible(false);
    } else if (filterModalValues.pageName !== branchPage) {
      dispatch({
        type: SET_MODAL_FILTER,
        filterModal: {},
        pageName: branchPage
      });
    }
  }, []);

  const addMenuItems = [
    ...(hasCreateAccess(MODEL_BRANCH)
      ? [
          {
            label: t('branch.general.addBranch'),
            link: '/add-branch',
            icon: <FontAwesomeIcon icon={faPlus} />
          }
        ]
      : [])
  ];
  return (
    <>
      <TableList
        pageName={MODEL_BRANCH}
        headCells={branchHeadCells}
        rows={branches}
        addMenuItems={addMenuItems}
        viewBtn={false}
        viewActionColumn={hasEditAcccess(MODEL_BRANCH) || hasDeleteAccess(MODEL_BRANCH)}
        editBtnLink="edit-branch"
        deleteBtnLink="branches"
        onDelete={getBranches}
        filterOptionsChange={(values) => {
          JSON.stringify(values) !== JSON.stringify(filterOptions) && setFilterOptions(values);
        }}
        isAdvanceList={canShowBranchDetail}
        branchId={loginUser.branch_id}
        filterFields={filterFields}
        initialValues={filterValues}
        onValuesChange={onValuesChange}
        filterName={'branches'}
        badgeInvisibility={badgeInvisible}
        onChange={(values) => setPaginationDetails(values)}
        totalRowsCount={totalRowsCount}
        totalPagesCount={totalPagesCount}
        loading={isLoading}
        addButtonType={false}
        addButtonTitle={'Add'}
      />
    </>
  );
};
export default BranchList;
