import React, { useEffect } from 'react';
import Layout from './components/Layout';
import RequireAuth from './routes/RequireAuth';
import { allRoutes as authenticatedRoutes, nonAuthenticatedRoutes } from './routes/allRoutes';
import { Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'react-toastify/dist/ReactToastify.css';
import styles from './assets/scss/_app.module.scss';
import PageNotFound from './pages/Page404';

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true,
      mirror: false
    });
  }, []);

  return (
    <>
      <span className={styles.toastContainer}>
        <ToastContainer limit={1} />
      </span>
      <Routes>
        {authenticatedRoutes().map((route, idx) => (
          <Route
            key={idx}
            path={route.path}
            element={
              <RequireAuth isAuthorized={true} path={route.path}>
                <Layout>
                  <route.component />
                </Layout>
              </RequireAuth>
            }
          />
        ))}
        {nonAuthenticatedRoutes.map((route, idx) => (
          <Route
            key={idx}
            path={route.path}
            element={
              <RequireAuth isAuthorized={false}>
                <route.component />
              </RequireAuth>
            }
          />
        ))}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

export default App;
