import * as Yup from 'yup';

const dateValidation = (message) => Yup.date().required(message);
const stringValidation = (message) => Yup.string().required(message);
const emailValidation = (message) =>
  Yup.string()
    .email()
    .when('isSendMail', {
      is: true,
      then: stringValidation(message)
    });

export const RepairSchema = (message) =>
  Yup.object().shape({
    scheduleDate: dateValidation(message)
  });

export const CheckOutSchema = (company, branch, user, checkOutDate) =>
  Yup.object().shape({
    company: stringValidation(company),
    branch: stringValidation(branch),
    user: Yup.object().required(user),
    checkOutDate: dateValidation(checkOutDate)
  });

export const CheckInSchema = (message) =>
  Yup.object().shape({
    returnDate: dateValidation(message)
  });

export const LeaseSchema = (company, branch, user, leaseBegins, leaseExpires) =>
  Yup.object().shape({
    company: stringValidation(company),
    branch: stringValidation(branch),
    user: Yup.object().required(user),
    leaseBegins: dateValidation(leaseBegins),
    leaseExpires: dateValidation(leaseExpires)
  });

export const LostOrMissingSchema = (message) =>
  Yup.object().shape({
    lostDate: dateValidation(message)
  });

export const BrokenSchema = (message) =>
  Yup.object().shape({
    brokenDate: dateValidation(message)
  });

export const DisposeSchema = (message) =>
  Yup.object().shape({
    disposeDate: dateValidation(message)
  });

export const DonateSchema = (donatedDate, email) =>
  Yup.object().shape({
    donatedDate: dateValidation(donatedDate),
    isSendMail: Yup.boolean(),
    email: emailValidation(email)
  });

export const SellSchema = (saleDate, saleAmount, email) =>
  Yup.object().shape({
    saleDate: dateValidation(saleDate),
    saleAmount: Yup.number().required(saleAmount),
    isSendMail: Yup.boolean(),
    email: emailValidation(email)
  });
