import TableList from '../../components/TableList';
import { useCallback, useEffect, useState } from 'react';
import { axiosApi } from '../../helpers/api_helper';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { roleValueToLabel, userPage } from '../../helpers/constants';
import { isNavMenuOpen, setHeaderFilters } from '../../helpers/common_service';
import { faFileCirclePlus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UserDetails from './Detail';
import UserForm from './Form';
import _ from 'lodash';
import { hasCreateAccess, hasDeleteAccess, hasEditAcccess, isUserSuperAdmin, MODEL_USER } from '../../helpers/access';

const UserList = () => {
  const showMenu = useSelector((state) => state.sideMenu.showMenu);
  const [users, setUsers] = useState([]);
  const [totalRowsCount, setTotalRowsCount] = useState(0);
  const [totalPagesCount, setTotalPagesCount] = useState(0);
  const table = useSelector((state) => state.table);
  const filterValues = useSelector((state) => state.filterModal);
  const [paginationDetails, setPaginationDetails] = useState({
    currentPage: 1,
    rowsPerPage: table?.tableRowCountObj?.count
  });
  const [filterOptions, setFilterOptions] = useState({
    activeSwitchStatus: true,
    searchText: filterValues?.pageName === userPage ? table.searchText : ''
  });
  const [sortingOptions, setSortingOptions] = useState({});
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const companyId = useSelector((state) => state.common.companyId);
  const branchId = useSelector((state) => state.common.branchId);
  const [selectedUser, setSelectedUser] = useState({});
  const [drawerOpenAction, setDrawerOpenAction] = useState('');
  const loginUser = useSelector((state) => state.login.authUser);
  const dispatch = useDispatch();

  const fetchUserHeadCells = useCallback(() => {
    return [
      {
        id: 'full_name',
        numeric: false,
        disablePadding: true,
        label: t('common.name'),
        canSort: true,
        width: isNavMenuOpen() ? '15%' : '20%',
        maxWidth: isNavMenuOpen() ? '15%' : '20%',
        minWidth: isNavMenuOpen() ? '150px' : '150px',
        hasViewLink: true
      },
      {
        id: 'role_display',
        numeric: true,
        disablePadding: false,
        label: t('common.role'),
        canSort: true,
        width: isNavMenuOpen() ? '9%' : '11%',
        maxWidth: isNavMenuOpen() ? '13%' : '15%',
        minWidth: isNavMenuOpen() ? '100px' : '130px'
      },
      {
        id: 'email',
        numeric: true,
        disablePadding: false,
        label: t('common.email'),
        canSort: true,
        width: isNavMenuOpen() ? '21%' : '27%',
        maxWidth: isNavMenuOpen() ? '21%' : '27%',
        minWidth: isNavMenuOpen() ? '250px' : '340px'
      },
      {
        id: 'mobile_number',
        numeric: true,
        disablePadding: false,
        label: t('common.mobile'),
        canSort: true,
        width: '14%',
        maxWidth: '14%',
        minWidth: '165px'
      },
      ...(isUserSuperAdmin()
        ? [
            {
              id: 'company',
              label: t('common.company'),
              canSort: true,
              width: '14%',
              maxWidth: '14%',
              minWidth: '125px'
            }
          ]
        : []),
      {
        id: 'branch',
        numeric: true,
        disablePadding: false,
        label: t('common.branch'),
        canSort: true,
        width: '14%',
        maxWidth: '14%',
        minWidth: '125px'
      },
      ...(filterOptions.activeSwitchStatus
        ? [
            {
              id: 'action',
              numeric: true,
              disablePadding: false,
              label: t('common.action'),
              canSort: false,
              width: isNavMenuOpen() ? '10%' : '10%',
              maxWidth: isNavMenuOpen() ? '20%' : '20%',
              minWidth: '200px'
            }
          ]
        : [])
    ];
  }, [showMenu, filterOptions]);

  const fetchUsers = useCallback(() => {
    let parameters = {};
    if (filterOptions) {
      parameters = {
        filter: {
          ...(filterOptions && filterOptions.searchText
            ? { search: filterOptions.searchText.replace(/\+/g, '%2B') }
            : {}),
          ...(filterOptions && filterOptions.activeSwitchStatus == false ? { discarded: true } : {})
        }
      };
    }
    if (companyId) {
      parameters['filter']['company'] = companyId;
    }

    if (branchId) {
      parameters['filter']['branch_id'] = branchId;
    }

    if (sortingOptions) {
      parameters = {
        ...parameters,
        order: sortingOptions.order,
        column: sortingOptions.column
      };
    }

    if (paginationDetails?.currentPage) parameters['page'] = paginationDetails.currentPage;
    if (paginationDetails?.rowsPerPage) parameters['items'] = paginationDetails.rowsPerPage;

    setIsLoading(true);
    axiosApi
      .get('/users', {
        params: parameters
      })
      .then((res) => {
        setUsers(
          res.data.data.map((user) => {
            return {
              ...user.attributes,
              role_display: roleValueToLabel[user.attributes.role] || _.startCase(user.attributes.role)
            };
          })
        );
        setTotalRowsCount(res.data.meta.total_count);
        setTotalPagesCount(res.data.meta.total_pages);
      })
      .catch(() => toast.error(t('common.somethingWentsWrong')))
      .finally(() => setIsLoading(false));
  }, [filterOptions, paginationDetails, sortingOptions, companyId, branchId]);

  useEffect(() => {
    if (Object.keys(sortingOptions).length || Object.keys(filterOptions).length) fetchUsers();
  }, [filterOptions, sortingOptions, fetchUsers]);

  const addMenuItems = [
    ...(hasCreateAccess(MODEL_USER)
      ? [
          {
            label: t('user.addUser.title'),
            icon: <FontAwesomeIcon icon={faPlus} />
          },
          {
            label: t('import.bulkImport'),
            link: '/import-users',
            icon: <FontAwesomeIcon icon={faFileCirclePlus} />
          }
        ]
      : [])
  ];

  const handleUserAction = (selectedUserId, action) => {
    let user = users.find((user) => user.id === selectedUserId);
    setSelectedUser(user);
    setDrawerOpenAction(action);
  };

  const handleDrawerClose = () => {
    setSelectedUser({});
    setDrawerOpenAction('');
  };

  useEffect(() => {
    setHeaderFilters(dispatch, loginUser.company_id, loginUser.branch_id);
  }, []);
  return (
    <>
      <TableList
        pageName={MODEL_USER}
        headCells={fetchUserHeadCells()}
        rows={users}
        deleteBtnLink="users"
        viewBtnFn={(userId) => handleUserAction(userId, 'view')}
        editBtnFn={(userId) => handleUserAction(userId, 'form')}
        editBtn={hasEditAcccess(MODEL_USER)}
        deleteBtn={hasDeleteAccess(MODEL_USER)}
        addMenuItems={addMenuItems}
        onMenuItemClick={() => setDrawerOpenAction('form')}
        onDelete={fetchUsers}
        filterName={'role'}
        loading={isLoading}
        viewActionColumn={
          (hasEditAcccess(MODEL_USER) || hasDeleteAccess(MODEL_USER)) && filterOptions.activeSwitchStatus
        }
        filterOptionsChange={(values) => setFilterOptions(values)}
        onChange={(values) => setPaginationDetails(values)}
        totalRowsCount={totalRowsCount}
        totalPagesCount={totalPagesCount}
        onSortingValuesChange={(values) => setSortingOptions(values)}
        showActiveSwitch={true}
      />
      <UserDetails open={drawerOpenAction === 'view'} details={selectedUser} onClose={handleDrawerClose} />
      <UserForm
        open={drawerOpenAction === 'form'}
        details={selectedUser}
        onClose={handleDrawerClose}
        onSubmit={fetchUsers}
      />
    </>
  );
};

export default UserList;
