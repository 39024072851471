import * as Yup from 'yup';
import { textFieldSchema, websiteSchema, alphaNumericSpecialCharSchema } from './CommonSchema';

export const CompanySchema = (name, city, country) =>
  Yup.object({
    name: textFieldSchema(name),
    website: websiteSchema().nullable(),
    city: alphaNumericSpecialCharSchema(city).nullable(),
    country: Yup.string().required(country).nullable()
  });
