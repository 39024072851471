import { useEffect, useState } from 'react';
import { Paper } from '@mui/material';
import { ClickAwayListener } from '@mui/base';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import styles from './filterSelect.module.scss';

const FilterSelect = ({ value, label, items, placeholder, onChange, containerStyles, isHeaderFilters = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const itemLabel = items.find((item) => item.value === selectedValue)?.label;

  const handleChange = (item) => {
    setIsOpen(false);
    if (!item?.clickable && item.value === selectedValue) return;
    setSelectedValue(item.value);
    onChange(item.value);
  };

  useEffect(() => {
    if (value !== selectedValue) setSelectedValue(value);
  }, [value]);

  return (
    <>
      <div className={`${styles.mainContainer} ${styles.headerMainContainer}`}>
        <div className={`${styles.selectContainer}  ${isHeaderFilters ? styles.headerFilters : ''}`}>
          <span className={styles.filterLabel}>{label} : </span>
          <span onClick={() => setIsOpen((prev) => !prev)} className={`${styles.itemLabelContainer}`}>
            <span className={styles.itemLabel}>{itemLabel || placeholder}</span>
            {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </span>
        </div>
        {isOpen && (
          <ClickAwayListener onClickAway={() => setIsOpen(false)}>
            <Paper elevation={2} className={`${styles.itemsContainer} ${containerStyles}`}>
              {items.map((item) => (
                <div
                  key={item.key}
                  onClick={() => handleChange(item)}
                  className={`${styles.item} ${selectedValue === item.value ? styles.activeItem : ''}`}>
                  {item.label}
                </div>
              ))}
            </Paper>
          </ClickAwayListener>
        )}
      </div>
    </>
  );
};

export default FilterSelect;
