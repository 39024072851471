import styles from './CreateButton.module.scss';
import { useEffect, useState } from 'react';
import button_style from '../../../assets/scss/_button.module.scss';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import screen from '../../../assets/scss/_app.scss';

const maxMobileScreen = parseInt(screen.maxMobile);

const CreateButton = ({ buttonTitle, onClick, className = '', displayword = 0 }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const buttonWord = buttonTitle.split(' ');
  const buttonText = window.innerWidth < maxMobileScreen ? (displayword ? buttonWord[displayword] : '') : buttonTitle;

  useEffect(() => {
    function handleWindowResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [windowWidth]);

  return (
    <>
      <Button
        sx={{ transition: 'scale 100ms ease' }}
        classes={{ root: styles.createButtonRoot }}
        className={`${button_style.btn} ${styles.add_button} ${className}`}
        onClick={onClick}>
        <FontAwesomeIcon icon={faPlus} />
        <span className={`${styles.addBtnText} ${buttonText === '' ? styles.plusIconText : ''}`}>{buttonText}</span>
      </Button>
    </>
  );
};

export default CreateButton;
