import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Menu, MenuItem, Divider } from '@mui/material';
import { Menu as MenuIcon, LogoutOutlined } from '@mui/icons-material';
import UserImage from '../../../assets/images/DefaultProfile.svg';
import AppLogo from '../../../assets/images/AppLogo.svg';
import { logoutUser } from '../../../store/actions';
import styles from './header.module.scss';
import navBarstyles from '../NavBar/navbar.module.scss';
import app_style from '../../../assets/scss/_app.module.scss';
import FilterSelect from '../../FilterSelect';
import { setHeaderDropDownCompany, setHeaderDropDownBranch } from '../../../store/actions';
import { roleValueToLabel } from '../../../helpers/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { getCompaniesLists, getBranchesLists } from '../../../store/actions';
import CompanyDetails from '../../../pages/Company/CompanyDetail/Details';
import UserDetails from '../../../pages/User/Detail';
import {
  getFirstMenuLink,
  hasEditAcccess,
  isUserSuperAdmin,
  MODEL_COMPANY,
  showBranchFilter,
  showCompanyFilter
} from '../../../helpers/access';
import lodash from 'lodash';

const Header = () => {
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authUser = useSelector((state) => state.login.authUser);
  const showMenu = useSelector((state) => state.sideMenu.showMenu);
  const companies = useSelector((state) => state.common.companies);
  const branches = useSelector((state) => state.common.branches);
  const companyId = useSelector((state) => state.common.companyId);
  const branchId = useSelector((state) => state.common.branchId);
  const newAlertsCount = useSelector((state) => state.common.newAlertsCount);
  const previousCompanyId = useRef(null);
  const [isCompanyOpen, setCompanyOpen] = useState(false);
  const [isProfileOpen, setProfileOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const canShowCompanyFilter = !!companies.length && showCompanyFilter(location.pathname);
  const canShowBranchFilter = companyId && !!branches.length && showBranchFilter(location.pathname);
  if (Object.keys(authUser).length > 0) {
    var authUserDetails = {
      ...authUser,
      role_display: roleValueToLabel[authUser.role] || lodash.startCase(authUser.role)
    };
  }

  const path = ['/']; // landing page pathName
  const includesPaths = ['/dashboard', '/asset-properties-list'];
  const canAccessDashboard = getFirstMenuLink() == '/dashboard';

  const setCompanyId = (companyId) => {
    dispatch(setHeaderDropDownCompany(companyId));
  };
  const setBranchId = (branchId) => {
    dispatch(setHeaderDropDownBranch(branchId));
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (companies[0]?.value !== '' && !includesPaths.includes(location.pathname) && isUserSuperAdmin()) {
      getCompaniesLists(dispatch, { is_dropdown: true });
    }

    if (isUserSuperAdmin() && includesPaths.includes(location.pathname)) {
      if (!companyId) previousCompanyId.current = true;
      getCompaniesLists(dispatch, { is_dropdown: true }, false);
    }
  }, [location.pathname]);

  useEffect(() => {
    // Here,for (Object.keys(authUser).length === 0), authUser is {}, but its giving false while checking (authUser === {})
    if (!companyId || Object.keys(authUser).length === 0) return;
    getBranchesLists(dispatch, true, { company_id: companyId, is_dropdown: true });

    if (isUserSuperAdmin()) {
      dispatch(setHeaderDropDownCompany(companyId));
      dispatch(setHeaderDropDownBranch(branchId));
    }
  }, [companyId]);

  useEffect(() => {
    if (companies.length) {
      if (!companyId && includesPaths.includes(location.pathname)) {
        setCompanyId(companies[0].value);
      }
      if (!includesPaths.includes(location.pathname) && previousCompanyId.current) {
        setCompanyId('');
        previousCompanyId.current = null;
      }
    }
    if (!companyId && !!branches.length) setBranchId(branches[0].value);
  }, [companies]);

  useEffect(() => {
    function handleWindowResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [windowWidth]);

  return (
    <>
      <div className={styles.header_container}>
        <div className={`${styles.header} ${canShowCompanyFilter || canShowBranchFilter ? styles.mob_header : ''}`}>
          <div className={styles.sub_header_con}>
            <div className={styles.side_menu_btn_container}>
              {!path.includes(location.pathname) && (
                <div>
                  <span
                    className={navBarstyles.company_menu_btn}
                    onClick={() =>
                      dispatch({
                        type: showMenu === 'none' ? 'SHOW_MENU' : 'HIDE_MENU'
                      })
                    }>
                    <MenuIcon sx={{ fontSize: '14px', width: '2em', height: '2em' }} />
                  </span>
                </div>
              )}
              <div className={`${styles.app_logo} ${canAccessDashboard ? styles.curser : ''}`}>
                <img
                  src={AppLogo}
                  alt={''}
                  onClick={() => {
                    canAccessDashboard && navigate('/dashboard');
                  }}
                />
              </div>
            </div>
            {!path.includes(location.pathname) && (
              <div className={`${styles.header_right}`}>
                <div className={`${styles.filter_container} ${styles.hide_company_filter}`}>
                  {canShowCompanyFilter && (
                    <FilterSelect
                      value={companyId}
                      label={t('common.company')}
                      items={companies}
                      placeholder={t('common.selectCompany')}
                      onChange={(id) => setCompanyId(id)}
                    />
                  )}

                  {canShowBranchFilter && (
                    <FilterSelect
                      value={branchId}
                      label={t('common.branch')}
                      items={branches}
                      placeholder={t('common.selectBranch')}
                      onChange={(id) => setBranchId(id)}
                    />
                  )}
                </div>
                <div className={styles.notificationBell} onClick={() => navigate('/alerts')}>
                  <FontAwesomeIcon
                    icon={faBell}
                    className={location.pathname === '/alerts' ? app_style.solid_list_icon : navBarstyles.font_icon}
                  />
                  {newAlertsCount > 0 && (
                    <span
                      className={`${styles.notification_badge} ${
                        location.pathname === '/alerts' ? '' : styles.notification_badge_inactive
                      }`}>
                      {newAlertsCount}
                    </span>
                  )}
                </div>
                <div className={styles.drop_down_box}>
                  <div id="dropdown_link_menu" className={styles.profile_pic_container}>
                    <img
                      id="demo-customized-button"
                      src={authUser?.avatar || UserImage}
                      alt="Profile"
                      className={styles.profile_pic}
                      onClick={handleClick}
                    />
                  </div>
                  {authUser && (
                    <>
                      <Menu
                        style={{ marginTop: 17 }}
                        MenuListProps={{
                          'aria-labelledby': 'demo-customized-button'
                        }}
                        className={styles.menu}
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        anchorEl={document.getElementById('dropdown_link_menu')}
                        PaperProps={{
                          sx: {
                            minWidth: '291px !important',
                            right: '20px !important',
                            top:
                              window.innerWidth <= 600 && (canShowCompanyFilter || canShowBranchFilter)
                                ? '30px !important'
                                : '40px !important'
                          }
                        }}
                        open={open}
                        onClose={handleClose}>
                        <MenuItem>
                          <div className={styles.profile_details}>
                            <div id="dropdown_link_menu" className={styles.profile_pic_container}>
                              <img src={authUser?.avatar || UserImage} alt="Profile" className={styles.profile_pic} />
                            </div>
                            <div className={styles.profile_info}>
                              <div className={`${styles.profile_name} ${styles.bold} ${styles.text_lg}`}>
                                {authUser?.first_name + ' ' + authUser?.last_name}
                              </div>
                              <div className={`${styles.profile_role} ${styles.text_md}`}>{t(authUser?.email)}</div>
                            </div>
                          </div>
                        </MenuItem>
                        <Divider></Divider>
                        <MenuItem
                          onClick={() => {
                            setProfileOpen(true);
                            setAnchorEl(false);
                          }}>
                          <FontAwesomeIcon icon={faUser} className={styles.iconCon} />

                          {t('layout.header.myProfile')}
                        </MenuItem>
                        {hasEditAcccess(MODEL_COMPANY) && (
                          <MenuItem
                            onClick={() => {
                              setCompanyOpen(true);
                              setAnchorEl(false);
                            }}>
                            <FontAwesomeIcon icon={faGlobe} className={styles.iconCon} />

                            {t('navbarItems.myCompany')}
                          </MenuItem>
                        )}
                        <MenuItem onClick={() => dispatch(logoutUser(navigate))} id="logout_menu">
                          <LogoutOutlined fontSize="small" />
                          <span>{t('layout.header.logout')}</span>
                        </MenuItem>
                      </Menu>
                    </>
                  )}
                </div>
              </div>
            )}
            {path.includes(location.pathname) && (
              <Link to="/login">
                <button type="submit" className={`${styles.loginButton} ${styles.button}`}>
                  Login
                </button>
              </Link>
            )}
          </div>
          <div className={canShowCompanyFilter || canShowBranchFilter ? styles.mobile_filter : ''}>
            {!path.includes(location.pathname) && (
              <div className={`${styles.mob_filter_container}`}>
                {canShowCompanyFilter && (
                  <FilterSelect
                    value={companyId}
                    label={t('common.company')}
                    items={companies}
                    placeholder={t('common.selectCompany')}
                    onChange={(id) => setCompanyId(id)}
                  />
                )}
                {canShowBranchFilter && (
                  <FilterSelect
                    value={branchId}
                    label={t('common.branch')}
                    items={branches}
                    placeholder={t('common.selectBranch')}
                    onChange={(id) => setBranchId(id)}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <CompanyDetails open={isCompanyOpen} onClose={() => setCompanyOpen(false)} />
      <UserDetails open={isProfileOpen} details={authUserDetails} onClose={() => setProfileOpen(false)} />
    </>
  );
};

export default Header;
