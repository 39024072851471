import Header from './Header/Header';
import NavBar from './NavBar/NavBar';
import styles from './index.module.scss';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import screen from '../../assets/scss/_app.scss';
import { axiosApi } from '../../helpers/api_helper';
import { setNewAlertsCount } from '../../store/actions';
import { AUTHORITY_LEVEL_COMPANY, hasReadAccess, MODEL_PRODUCT, showCompanyFilter } from '../../helpers/access';
const Layout = (props) => {
  const showMenu = useSelector((state) => state.sideMenu.showMenu);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();
  const minDesktopScreen = parseInt(screen.minDesktop);
  const location = useLocation();
  const companyId = useSelector((state) => state.common.companyId);
  const branchId = useSelector((state) => state.common.branchId);

  useEffect(() => {
    fetchAlertsCount();
    function handleWindowResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  const fetchAlertsCount = () => {
    let params = {};
    if (showCompanyFilter() && companyId) params['company_id'] = companyId;
    if (hasReadAccess(MODEL_PRODUCT, AUTHORITY_LEVEL_COMPANY) && branchId) params['branch_id'] = branchId;
    axiosApi
      .get('/alerts/alerts_count', { params })
      .then((res) => dispatch(setNewAlertsCount(res.data.new_alerts_count['Total'] || 0)));
  };
  useEffect(() => {
    fetchAlertsCount();
  }, [companyId, branchId]);
  const path = ['/'];

  return (
    <div className={styles.layout}>
      <Header />
      <div
        className={`${path.includes(location.pathname) && styles.full_height_container} ${
          showMenu === 'flex' ? styles.body_container : styles.hide_body_container
        }`}>
        <div>
          <NavBar />
        </div>
        <div
          className={`${styles.main_container} ${showMenu === 'flex' && styles.hide_main_container}`}
          onClick={() => {
            if (windowWidth < minDesktopScreen && showMenu === 'flex') {
              dispatch({ type: 'HIDE_MENU' });
            }
          }}>
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
