export const rowsPerPageOptions = [
  { label: 5, value: 5 },
  { label: 10, value: 10 },
  { label: 25, value: 25 },
  { label: 50, value: 50 },
  { label: 100, value: 100 }
];

export const RowsPerPage = 50;

/// Cnnstants in Modal Form Page

// The DATE_FILTER_TODAY array contains the names of the fields that will be used to filter the query results.
// The fields listed in the array will be used to search for a date that occurs today.
// If the date occurs today, then the record is added to the query results.
// Otherwise, the record is not added to the query results.

export const DATE_FILTER_TODAY = ['saleDate', 'donatedDate', 'leaseBegins', 'checkOutDate'];

// if the field name of the dynamic date time picker is equal to END_TIME array field that that field date's time is manually set to 11.59 pm.

export const END_TIME = ['leaseExpires', 'dueToDate', 'expiring_on', 'end_date', 'due_date', 'to_date'];

//Constants in the Asset Detail Page

// Show Maintence Tab in asset details page only if  'checkIn', 'broken', 'repair' are present in the asset status is equal to any one of the following array values.

// Statuses constants

export const CHECK_OUT = 'checkOut';
export const CHECK_IN = 'checkIn';
export const BROKEN = 'broken';
export const REPAIR = 'repair';
export const LEASE = 'lease';
export const SOLD = 'sold';
export const DONATE = 'donate';
export const LOST_OR_MISSING = 'lostOrMissing';
export const DISPOSE = 'dispose';
export const RESERVATION = 'reservation';

export const MAINTENANCE_TAB = ['checkIn', 'broken', 'repair'];

export const ISCHECKEDOUT_OR_LEASED = ['checkOut', 'lease']; // const to check if the asset is checkout or lease

export const STATUSES_FOR_CHECKEDOUT_OR_LEASED_ASSET = ['checkIn', 'repair', 'lostOrMissing', 'broken']; // STATUSES FOR CHECKEDOUT OR LEASED ASSET TO APPEAR IN THE MORE ACTIONS DROPDOWN

export const CHECK_IN_STATUS = 'assign';
export const AVAILABLE_STATUS = 'available';

export const statusKeyChange = {
  checkIn: 'available',
  checkOut: 'assign'
};

/// Constants in the Expiring Page

//  Menu tab values constants

export const WARRANTY_TAB = 'Warranty';
export const INSURANCE_TAB = 'Insurance';
export const ASSIGNED_TAB = 'Assigned';

export const IRREVERSIBLE_STATUSES = ['donate', 'sold', 'dispose']; // Irreversible statuses for assets

export const MONTHS = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

// PageName constansts

export const branchPage = 'branch';
export const companyPage = 'company';
export const employeePage = 'employee';
export const inventoryPage = 'product';
export const userPage = 'user';
export const reportPage = 'report';
export const feedbackPage = 'feedback';
export const expiringPage = 'expiring';
export const maintenanceRequestPage = 'maintenanceRequest';
export const changePasswordPage = 'changePassword';

// user roles constants value to Label

export const roleValueToLabel = {
  super_admin: 'Super Admin',
  company_admin: 'Company Admin',
  branch_admin: 'Branch Admin',
  employee: 'Employee'
};

export const CHILD = 'child';
export const PARENT = 'parent';

// timeline activity

export const INSURANCE = 'insurance';
export const WARRANTY = 'warranty';
export const MAINTENANCE = 'maintenance';
export const LINKED_PRODUCT = 'linked_product';
export const USER = 'user';
export const MAINTENANCE_REQUEST = 'maintenance_request';
export const STATUS_HISTORY = 'status_history';

export const TimelineOptionsList = [
  { label: 'Warranty', value: 'warranty' },
  { label: 'Maintenance', value: 'maintenance' },
  { label: 'Insurances', value: 'insurances' },
  { label: 'Reservation', value: 'reservation' },
  { label: 'Maintenance Requests', value: 'maintenance_requests' },
  { label: 'Previous users', value: 'previous_users' },
  { label: 'Product Status Histories', value: 'product_status_histories' },
  { label: 'Linked Product', value: 'linked_product' }
];
